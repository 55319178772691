<template>
	<div>
		<div>
			<div>

			</div>
			<div class="flex justify-end">
				<span class="bg-white rounded ">
					<span class="p-2  border rounded-l-md cursor-pointer" v-on:click="switchTab(1)"
						  :class="{'bg-pink-500 text-white': tab===1}">
					{{ $t('userProfile') }}
				</span>
				<span class="p-2 border rounded-r-md cursor-pointer" v-on:click="switchTab(2)"
					  :class="{'bg-pink-500 text-white': tab===2}">
					{{ $t('companyInformation') }}
				</span>
				</span>
			</div>
		</div>
		<div class="mt-3">
			<user-profile v-if="tab === 1" :user="company" :key="componentID"/>
			<company-profile v-if="tab === 2 && company.company" :company="company.company" :key="componentID"/>
		</div>
	</div>
</template>

<script>
import userProfile from "./userProfile";
import companyProfile from "./companyProfile";

export default {
	name: "editCompanyProfile",
	components: {userProfile, companyProfile},
	props: {
		company: {
			type: Object
		}
	},
	data: function () {
		return {
			tab: 1,
			componentID: new Date().getMilliseconds()
		}
	},
	methods: {
		switchTab: function (tab) {
			this.componentID = new Date().getMilliseconds()
			this.tab = parseInt(tab)
		}
	}
}
</script>

<style scoped>

</style>
