import moment from 'moment'

moment.locale('se');

import {setUserCompany, storeTourGuide} from './../../../../services/storage-window'

export default {
	UPDATE_FAQ_DATA(state, payload) {
		const data = payload.filter(item => item.visible_for_customers_only === false && item.hidden === false)
		state.faqData = data
		state.copyFaqData = data
	},
	REMOVE_USER_DATA(state, payload) {
		state.userFirmID = payload
	},
	UPDATE_FAQ_CATEGORY(state, payload) {
		state.faqCategory = payload
		state.copyFaqCategory = payload
		if (state.faqData.length > 0) {
			state.faqCategory.forEach(element => {
				const objct = state.faqData.filter(item => item.category === element.id)
				element.subLink = objct
			})
		}
	},
	FILTER_CATEGORY(state, payload) {
		// let filterFaq = []
		// const filterCategory = state.copyFaqCategory.filter(item =>
		//     item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		// )
		const filterFaq = state.copyFaqData.filter(item =>
			item.question.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)

		if (filterFaq.length < 1) {
			const filterCategory = state.copyFaqCategory.filter(item =>
				item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
			)
			// console.log('maian:', filterCategory.length)
			if (filterCategory.length > 0) {
				state.faqCategory = filterCategory
				const filterq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
				// console.log('fil;', filterq)
				state.faqData = filterq
				// console.log('state data:', state.faqData)
			} else {
				state.faqCategory = state.copyFaqCategory
			}
		} else {
			state.faqCategory = state.copyFaqCategory
		}
		// if (filterCategory.length > 0) {
		//     filterFaq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
		// }
		// state.faqCategory = filterCategory
		// state.faqData = filterFaq
		state.faqData = filterFaq
	},
	SET_FAQ_LIST(state, payload) {
		console.log(payload)
		const filterList = state.copyFaqData.filter(item => item.id === payload.id)
		console.log(filterList)
		state.faqData = filterList
	},
	CATEGORY_FAQ_ALL(state, _) {
		state.faqCategory = state.copyFaqCategory
		state.faqData = state.copyFaqData
	},
	UPDATE_APP_INTEGRATION(state, payload) {
		const appFilter = []
		const appFetch = payload
		const sort = payload.results.sort((a, b) => a.name > b.name)
		appFetch.current_page = 1
		appFetch.results = sort
		state.appIntegration = JSON.parse(JSON.stringify(appFetch))
		state.copyAppIntegration = JSON.parse(JSON.stringify(appFetch))
		payload.results.forEach(element => {
			if (!appFilter.includes(element.name)) {
				appFilter.push(element.name)
			}
		})

		state.appFilterState = appFilter
	},
	PUSH_APP_INTEGRATION(state, payload) {
		const copyApp = {...state.appIntegration}
		copyApp.current_page += 1
		const sort = payload.results.sort((a, b) => a.name > b.name)
		copyApp.results.append(sort)
		state.appIntegration = copyApp
		state.copyAppIntegration = copyApp
	},
	SUBSCRIPTION_ALL(state, payload) {
		state.allSubscription = payload
	},
	UPDATE_SINGLE_SUBSCRIPTION(state, payload) {
		state.subscription = payload
	},
	UPDATE_ACCOUNTING_TYPE(state, payload) {
		state.getAccountingType = payload
	},
	UPDATE_DASHBOARD_METRICS(state, payload) {
		state.dashboardMetrics = payload
	},
	UPDATE_NOTIFICATION(state, payload) {
		state.notification = payload
	},
	UPDATE_SYSTEM_LOGS(state, payload) {
		state.sytemAppLogs = JSON.parse(JSON.stringify(payload))
		state.copySystemLogs = JSON.parse(JSON.stringify(payload))

		payload.forEach(element => {
			if (!state.appType.includes(element.app_name)) {
				state.appType.push(element.app_name)
			}
		})
	},
	DEFAULT_SYSTEM_LOG(state, _) {
		state.sytemAppLogs = state.copySystemLogs
	},
	FILTER_SYSTEM_LOGS(state, payload) {
		const filter = state.copySystemLogs.filter(item => item.app_name === payload)
		state.sytemAppLogs = filter
	},
	FILTER_SYSTEM_DATE(state, payload) {
		if (payload !== null) {
			const filter = state.copySystemLogs.filter(item => moment(item.timestamp.split('T')[0], 'YYYY-MM-DD').isBetween(payload[0], payload[1]))
			state.sytemAppLogs = filter
		} else {
			state.sytemAppLogs = state.copySystemLogs
		}
	},
	FILTER_AUDIT_DATE(state, payload) {
		if (payload !== null) {
			const filter = state.copyAppLog.filter(item => moment(item.timestamp).isBetween(payload[0], payload[1]))
			state.AppLogs = filter
		} else {
			state.AppLogs = state.copyAppLog
		}
	},

	UPDATE_APP_LOGS(state, payload) {
		state.AppLogs = JSON.parse(JSON.stringify(payload))
		state.copyAppLog = JSON.parse(JSON.stringify(payload))
	},
	DEFAULT_APP_LOGS(state, _) {
		state.AppLogs = state.copyAppLog
	},
	FILTER_USER_LOGS(state, payload) {
		const filter = state.copyAppLog.filter(item =>
			item.username.toLowerCase().includes(payload.target.value.toLowerCase())
		)
		state.AppLogs = filter
	},
	UPDATE_BILLING_HISTORY(state, payload) {
		state.billingHistory = JSON.parse(JSON.stringify(payload))
		state.copyBillingHistory = JSON.parse(JSON.stringify(payload))
	},
	SORT_DATE_ASC(state, _) {
		if (state.billingHistory.Invoices) {
			const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
				return new Date(b.InvoiceDate) - new Date(a.InvoiceDate)
			})
			state.billingHistory.Invoices = filterD
		}
	},
	SORT_DATE_DESC(state, _) {
		if (state.billingHistory.Invoices) {
			const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
				return new Date(a.InvoiceDate) - new Date(b.InvoiceDate)
			})
			state.billingHistory.Invoices = filterD
		}
	},
	SORT_DUE_DATE_ASC(state, _) {
		if (state.billingHistory.Invoices) {
			const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
				return new Date(b.DueDate) - new Date(a.DueDate)
			})
			state.billingHistory.Invoices = filterD
		}
	},
	SORT_DUE_DATE_DESC(state, _) {
		if (state.billingHistory.Invoices) {
			const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
				return new Date(a.DueDate) - new Date(b.DueDate)
			})
			state.billingHistory.Invoices = filterD
		}
	},
	SORT_PRICE_ASC(state, _) {
		if (state.billingHistory.Invoices) {
			const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
				return a.Total - b.Total
			})
			state.billingHistory.Invoices = filterD
		}
	},
	SORT_PRICE_DESC(state, _) {
		if (state.billingHistory.Invoices) {
			const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
				return b.Total - a.Total
			})
			state.billingHistory.Invoices = filterD
		}
	},
	GET_FILTER_APP_SELECT(state, payload) {
		const filterApp = state.copyAppIntegration.results.filter(item => item.accounting.includes(parseInt(payload.value)))
		state.appIntegration.results = filterApp
	},
	FILTER_WEBAPPS(state, payload) {
		const filterApp = state.copyAppIntegration.results.filter(item =>
			item.name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)
		state.appIntegration.results = filterApp
	},
	GET_ALL_APPS(state, _) {
		state.appIntegration = state.copyAppIntegration
	},
	SET_USER_COMPANY_ID(state, payload) {
		state.userCompanyID = payload
		setUserCompany(payload, 'company')
	},
	SET_USER_FIRM_ID(state, payload) {
		state.userFirmID = payload
		setUserCompany(payload, 'Firm')
	},
	SET_USER_COMPANY_BILLING(state, payload) {
		state.companyBilling = payload
	},
	SET_USER_COMPANY_PAYMENT_TYPE(state, payload) {
		state.setPaymentType = payload
	},
	UPDATE_OWN_COMPANY(state, payload) {
		state.ownerCompany = payload
	},
	UPDATE_ASSOCIATE_COM(state, payload) {
		state.associateCompany = payload
	},
	UPDATE_USER_INFO(state, payload) {
		state.UserInfo = payload
	},
	REMOVE_COMPANY_USER(state, payload) {
		state.userCompanyID = payload
	},
	UPDATE_CUSTOMER_TOUR(state, payload) {
		state.customerTour[payload.view] = true
		storeTourGuide(state.customerTour)
	},
	UPDATE_CUSTOMER_TOUR_ALL(state, payload) {
		state.customerTour = payload
		storeTourGuide(state.customerTour)
	},
	UPDATE_COLLEAGUE_COMPANY(state, payload) {
		state.colleagueCompany = payload
	},
	UPDATE_IS_COLLEAGUE(state, payload) {
		state.isColleague = payload.status
	},
	UPDATE_SYNCHRONIZE_REPORT(state, payload) {
		state.syncReport = payload.data
	},
	SET_USER_FIRM(state, payload) {
		state.userFirmID = null
	}
}
