<template>
	<div class="container">

		<form v-on:submit.prevent="onSubmit">
			<div class="row mt-4">
				<div class="col-md-4 mb-2" v-for="(form,i) in formList">
					<div class="form-group" v-if="form.type === 'text'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<input cols="30" id="title" data-cy='title' rows="3" type="text"
							   class='form-control font-light text-xs'
							   :placeholder="$t(`${form.name}`)" v-model="form.value"/>
					</div>
					<div class="form-group" v-else-if="form.type === 'email'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<input cols="30" :id="`${form.name}`" data-cy='title' rows="3"
							   class='form-control font-light text-xs' type="email"
							   :placeholder="$t(`${form.name}`)" v-model="form.value"/>
					</div>
					<div class="form-group" v-else-if="form.type === 'select'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<el-select v-model="form.value" :placeholder="$t(`${form.name}`)" filterable
								   class="w-full"
								   size="large">
							<el-option
								v-for="(item,key) in form.valueList"
								:key="key"
								:label="`${item.name}` "
								:value="item.key">
							</el-option>
						</el-select>
					</div>
					<div class="form-group" v-else-if="form.type === 'date'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<el-date-picker
							class="w-full text-xs font-light app-input"
							v-model="form.value"
							type="date"
							size="mini"
							value-format="yyyy-MM-dd"
							format="yyyy/MM/dd"
							start-placeholder="Start datum">
						</el-date-picker>
					</div>
					<div class="form-group" v-else-if="form.type === 'datetime'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<el-date-picker
							class="w-full text-xs font-light app-input"
							v-model="form.value"
							type="datetime"
							size="mini"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="yyyy/MM/dd HH:mm:ss"
							start-placeholder="Start datum">
						</el-date-picker>
					</div>
					<div class="form-group" v-else-if="form.type === 'checkbox'">
						<div class="flex justify-between">
							<label for="title" class="text-xs text-gray-800 font-medium">{{
									$t(`${form.name}`)
								}}</label>

							<div class="ml3">
								<input type="checkbox" class="checkbox-height" v-model="form.value"/>
							</div>
						</div>
					</div>
					<div class="form-group" v-else-if="form.type === 'multiple-select'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<el-select v-model="form.value" multiple :placeholder="$t(`${form.name}`)" filterable remote
								   class="w-full" :remote-method="remoteMethod" :loading-text="$t('loading')"
								   :no-data-text="$t('noData')"
								   :no-match-text="$t('noData')"
								   size="large" v-if="form.source === 'user' && form.name === 'partner_accountants'"
								   :loading="form.loading">
							<el-option
								v-for="(item,key) in form.valueList"
								:key="key"
								:label="`${item.first_name} - ${item.last_name}` "
								:value="item.id">
							</el-option>
						</el-select>
						<el-select v-model="form.value" multiple :placeholder="$t(`${form.name}`)" filterable remote
								   class="w-full" :remote-method="assistantAccount" :loading-text="$t('loading')"
								   :no-data-text="$t('noData')"
								   :no-match-text="$t('noData')"
								   size="large" v-if="form.source === 'user' && form.name === 'assisting_accountants'"
								   :loading="form.loading">
							<el-option
								v-for="(item,key) in form.valueList"
								:key="key"
								:label="`${item.first_name} - ${item.last_name}` "
								:value="item.id">
							</el-option>
						</el-select>
						<el-select v-model="form.value" multiple :placeholder="$t(`${form.name}`)" filterable remote
								   class="w-full" :remote-method="colleagueSearch" :loading-text="$t('loading')"
								   :no-data-text="$t('noData')"
								   :no-match-text="$t('noData')"
								   size="large" v-if="form.source === 'user' && form.name === 'assisting_colleagues'"
								   :loading="form.loading">
							<el-option
								v-for="(item,key) in form.valueList"
								:key="key"
								:label="`${item.first_name} - ${item.last_name}` "
								:value="item.id">
							</el-option>
						</el-select>
					</div>

				</div>
				<div class="col-md-12 mb-2">
					<button type="submit" data-cy='btn-add-faq' :disabled='creating'
							class="btn hover:text-white text-base btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creating" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </span>
						<span v-else class="text-xs">{{ $t('update') }}</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import {DatePicker} from "element-ui";


Vue.use(DatePicker)
export default {
	name: "userProfile",
	props: {
		user: {
			type: Object
		}
	},
	data: function () {
		return {
			creating: false,
			formList: [
				{
					type: 'text',
					name: 'username',
					value: '',
					required: true
				},
				{
					type: 'text',
					name: 'first_name',
					value: '',
					required: true
				},
				{
					type: 'text',
					name: 'last_name',
					value: '',
					required: true
				},
				{
					type: 'email',
					name: 'email',
					value: '',
					required: true
				},
				{
					type: 'text',
					name: 'phone',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'company_name',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'company_number',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'accounting_app_customer_number',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'contract_interval',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'invoice_email_address',
					value: '',
					required: false
				},
				{
					type: 'select',
					name: 'payment_method',
					value: '',
					required: false,
					valueList: [
						{
							name: "INVOICE", key: 0
						},
						{name: 'CARD', key: 1}
					]
				},
				{
					type: 'datetime',
					name: 'date_joined',
					value: '',
					required: false
				},
				{
					type: 'date',
					name: 'sent_confirmation_date',
					value: '',
					required: false
				},
				{
					type: 'date',
					name: 'date_activated',
					value: '',
					required: false
				},
				{
					type: 'date',
					name: 'date_created',
					value: '',
					required: false
				},
				{
					type: 'date',
					name: 'accounting_firm_connection_start_date',
					value: '',
					required: false
				},
				{
					type: 'date',
					name: 'accounting_firm_connection_end_date',
					value: '',
					required: false
				},
				{
					type: 'multiple-select',
					name: 'partner_accountants',
					value: [],
					valueList: [],
					source: 'user',
					loading: false,
					required: false
				},
				{
					type: 'multiple-select',
					name: 'assisting_accountants',
					value: [],
					valueList: [],
					source: 'user',
					required: false,
					loading: false
				},
				{
					type: 'multiple-select',
					name: 'assisting_colleagues',
					value: [],
					valueList: [],
					source: 'user',
					required: false,
					loading: false
				},
				{
					type: 'textarea',
					name: 'comment',
					required: false,
					value: ''
				},

				{
					type: 'checkbox',
					name: 'email_verified',
					required: false,
					value: ''
				},
				{
					type: 'checkbox',
					name: 'is_staff',
					required: false,
					value: ''
				},
				{
					type: 'checkbox',
					name: 'is_active',
					required: false,
					value: ''
				},
				{
					type: 'checkbox',
					name: 'is_accountant',
					required: false,
					value: ''
				},
			]
		}
	},
	methods: {
		remoteMethod($event) {
			if ($event !== '') {
				this.loadingSub = false
				let findIndex = this.formList.findIndex(item => item.name === 'partner_accountants')
				if (findIndex > -1) {
					this.formList[findIndex].loading = true
				}
				const URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?search=${$event}&is_accountant=1`
				this.$store.dispatch('house/customGetRequest', {URL: URL})
					.then(response => {
						let findIndex = this.formList.findIndex(item => item.name === 'partner_accountants')
						if (findIndex > -1) {
							this.formList[findIndex].valueList = response.data.data.results
							this.formList[findIndex].loading = false
						}
					}).catch((err) => {
					if (findIndex > -1) {
						this.formList[findIndex].loading = false
					}
				})
			}
		},
		assistantAccount($event) {
			if ($event !== '') {
				this.loadingSub = false
				let findIndex = this.formList.findIndex(item => item.name === 'assisting_accountants')
				if (findIndex > -1) {
					this.formList[findIndex].loading = true
				}
				const URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?search=${$event}&is_accountant=1`
				this.$store.dispatch('house/customGetRequest', {URL: URL})
					.then(response => {
						let findIndex = this.formList.findIndex(item => item.name === 'assisting_accountants')
						if (findIndex > -1) {
							this.formList[findIndex].valueList = response.data.data.results
							this.formList[findIndex].loading = false
						}
					}).catch((err) => {
					if (findIndex > -1) {
						this.formList[findIndex].loading = false
					}
				})
			}
		},
		colleagueSearch($event) {
			if ($event !== '') {
				this.loadingSub = false
				let findIndex = this.formList.findIndex(item => item.name === 'assisting_colleagues')
				if (findIndex > -1) {
					this.formList[findIndex].loading = true
				}
				const URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?search=${$event}`
				this.$store.dispatch('house/customGetRequest', {URL: URL})
					.then(response => {
						if (findIndex > -1) {
							this.formList[findIndex].valueList = response.data.data.results
							this.formList[findIndex].loading = false
						}
					}).catch((err) => {
					if (findIndex > -1) {
						this.formList[findIndex].loading = false
					}
				})
			}
		},
		onSubmit: function () {
			let payload = {}
			this.creating = true
			for (let i = 0; i < this.formList.length; i++) {
				let item = this.formList[i]
				if (item.required === true) {
					if (item.value === '' || item.value === null || item.value === undefined) {
						this.creating = false
						this.$services.helpers.notification(this.$t(`${item.name.replace('_', ' ')} ${this.$t('required')}`))
						return false
					} else {
						payload[item.name] = item.value
					}
				} else {
					if (item.value !== '' && item.value !== null && item.value !== undefined) {
						if (item.type === 'date') {
							payload[item.name] = moment(item.value).format('YYYY-MM-DD')
						} else if (item.type === 'datetime') {
							payload[item.name] = moment(item.value).format('YYYY-MM-DD')
						} else {
							payload[item.name] = item.value
						}

					}
				}
			}
			const URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `/${this.user.id}/`
			this.$store.dispatch('house/customPutRequest', {URL: URL, data: payload}).then(resp => {
				console.log(resp)
				this.creating = false
				this.$services.helpers.notification(this.$t('success'), 'success', this)
			}).catch(err => {
				this.creating = false
				if (err.response.data.hasOwnProperty('errors')) {
					this.$services.helpers.notification(err.response.data.errors, 'error', this)
				} else {
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				}

			})
		},
		filterUserIds(type, ids) {
			let findIndex = this.formList.findIndex(item => item.name === type)
			if (ids.length > 0) {
				const URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?user_ids=${ids.join(";")}`
				this.$store.dispatch('house/customGetRequest', {URL: URL})
					.then(response => {
						if (findIndex > -1) {
							this.formList[findIndex].valueList = response.data.data.results
						}
					}).catch((err) => {
				})
			}

		}
	},
	mounted() {

		this.formList.map(item => {
			if (item.name in this.user) {
				item.value = this.user[item.name]
				if (['partner_accountants', 'assisting_colleagues', 'assisting_accountants'].includes(item.name)) {
					if (item.value.length > 0) {
						this.filterUserIds(item.name, item.value)
					}
				}
			}
		})

	}
}
</script>

<style scoped>

</style>
