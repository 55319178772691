<template>
	<div class="app-container body-tabs-shadow">
		<div class="container">
			<div class="">
				<div class="d-flex">
					<div class="text-center mt-4 float-left">
						<img id="v-step-0" class="h-12 logo-top pl-5" src="./../../assets/images/header_logo.png"
							 alt="">
					</div>
				</div>
				<div class="row">
					<div class="col-md-8">
						<div class="md:ml-32 w-1/2">
							<img class="img-fluid d-none d-lg-block" src="./../../assets/images/illus/auth.svg"
								 alt="authentication">
							<!-- <h3 class="text-gray-800 whitespace-nowrap mt-10 md:-mt-0 font-semibold text-2xl">{{ $t('loginHeader') }}</h3> -->
						</div>
					</div>
					<div class="col-md-4 md:mt-10">
						<div class="login-border-section md:mt-20">
							<ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
								<form class="mt-5 mb-2" @submit.prevent="handleSubmit(loginUser)">
									<!-- <div class="form-group">
										<label for="exampleEmail" class="override-pb override-pt col-form-label label-context -mt-3"></label>
										<div>
											<div class="font-semibold text-2xl link-color whitespace-nowrap">{{ $t('loginHeader') }}</div><br>
										</div>
									</div> -->

									<div class="form-group v-step-1">
										<label for="exampleEmail" class="text-base font-medium">{{
												$t('emailUsername')
											}}</label>
										<div>
											<ValidationProvider name="emailUsername" rules="required"
																v-slot="{ errors }">
												<input v-model="email" data-cy='email' type="text"
													   class="form-control font-light text-xs override-form-field-l"
													   required>
												<small class="text-red-500">{{ errors[0] }}</small>
											</ValidationProvider>
										</div>
									</div>
									<div class="form-group">
										<label for="exampleEmail" class="text-base font-medium">{{
												$t('password')
											}}</label>
										<div>
											<ValidationProvider name="password" rules="required" v-slot="{ errors }">
												<input v-model="password" data-cy='password' type="password"
													   class="form-control font-light text-xs override-form-field-l"
													   required>
												<small class="text-red-500">{{ errors[0] }}</small>
											</ValidationProvider>
										</div>
									</div>
									<div class="form-group">
										<label for="exampleEmail"></label>
										<div>
											<button data-v-step="2" data-cy="loginBtn"
													class="w-full font-semibold shadow btn btn-custom btn-primary btn-lg btn-wide round-btn custom-btn-color"
													type="submit" :disabled="invalid || processing">
												<span v-if="processing">Logging in...</span>
												<span v-else>{{ $t('login') }}</span>
											</button>
										</div>
									</div>
									<div class="form-group mt-1">
										<label for="exampleEmail"
											   class="override-pb override-pt col-form-label label-context"></label>
										<div>
											<span class="font-md-login">{{ $t('forgetPassword') }}? <router-link
												:to="{name : 'forget'}" class="no-deco no-de-hover link-color">{{
													$t('reset')
												}}</router-link></span><br><br>
											<span class="font-md-login">{{ $t('notCustomer') }}? <router-link
												:to="{name : 'register'}"
												class="no-deco no-de-hover link-color">{{ $t('welcome') }}</router-link></span>
										</div>
									</div>
									<div class="form-group mt-10">
										<div>
                                        <span class="font-md-login text-base text-gray-900">{{ $t('golf') }}
                                            <a target="_blank" href="https://golf.automatiseramera.se/login/"
											   class="font-semibold text-base hover:no-underline text-blue-700">
                                                {{ $t('login') }}
                                            </a>
                                        </span><br><br>
										</div>
									</div>
								</form>
							</ValidationObserver>
							<!-- <div class="form-group">
								<label for="exampleEmail" class="col-sm-3 override-pb override-pt col-form-label label-context"></label>
								<div class="col-sm-9 pl-4 text-center">
									<div class="md:ml-5 font-semibold text-xl text-gray-900 whitespace-nowrap">{{ $t('golf') }}</div><br>
									<a target="_blank" href="https://golf.automatiseramera.se/login/" class="btn w-3/5 md:ml-10 text-white btn-custom btn-primary btn-lg btn-wide round-btn custom-btn-color">
										{{ $t('login') }}
									</a>
								</div>
							</div> -->
						</div>
					</div>
				</div>
				<div class="container font-light text-xs text-center md:bottom-0 md:absolute mb-4">
					<span>Copyright © <span>{{ current }} Automatisera Mera</span></span>
					<span> {{ $t('switch') }}
                        <a @click="changeLocale('en')" href="javascript:void(0);"
						   class="font-bold no-deco no-de-hover link-color"
						   v-if="GET_LOCALE_LANG === 'se'">{{ $t('english') }}</a>
                        <a @click="changeLocale('se')" href="javascript:void(0);"
						   class="font-bold no-deco no-de-hover link-color" v-else>Swedish</a>
                    </span>
				</div>
			</div>
		</div>
		<Notification/>
		<v-tour name="myTour" :steps="steps"></v-tour>
	</div>
</template>

<script>
// import Vue from 'vue'
import {mapGetters} from 'vuex'
import {ValidationProvider, ValidationObserver, localeChanged} from 'vee-validate'
import i18n from '@/plugins/i18n'
import Notification from './../../customComponent/NotificationAlert'
import bus from '../../bus'
import {deleteUserData, getPath} from '../../services/storage-window'

export default {
	name: 'login',
	components: {
		Notification,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			current: new Date().getFullYear(),
			baseURL: window.location.origin,
			loaded: false,
			email: '',
			password: '',
			processing: false,
			steps: [
				{
					target: '#v-step-0',  // We're using document.querySelector() under the hood
					content: `Discover <strong>Vue Tour logo</strong>!`
				},
				{
					target: '.v-step-1',
					content: 'An awesome plugin made with Vue.js! Login'
				},
				{
					target: '[data-v-step="2"]',
					content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs. Button',
					params: {
						placement: 'top'
					}
				}
			],
			bus
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			GET_USER_UTIL: 'accountant/GET_USER_UTIL',
			GET_MASTER_ACCOUNTANT: 'accountant/GET_MASTER_ACCOUNTANT',
			GET_COLLEAGUE: 'colleague/GET_COLLEAGUE',
			GET_ASSIST_ACCOUNTANT: 'assistanceAccountant/GET_ASSIST_ACCOUNTANT',
			GET_IN_HOUSE_UTIL: 'house/GET_IN_HOUSE_UTIL',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		// if (this.USER_TOKEN) {
		//     console.log('log:: mounted', this.GET_IN_HOUSE_UTIL)
		//     if (this.GET_IN_HOUSE_UTIL === 'true') {
		//         // window.location.href = this.baseURL + '/regular'
		//     }
		//     if (this.GET_USER_UTIL === 'true') {
		//         // window.location.href = this.baseURL + '/accountant'
		//     } else {
		//         // window.location.href = this.baseURL + '/automatisera'
		//     }
		// }
		deleteUserData()

	},
	beforeMount() {
		// If user token is set, proceed to dashboard
		// if (this.USER_TOKEN) {
		// 	if (this.GET_IN_HOUSE_UTIL === 'true') {
		// 		this.$router.replace({name: 'regulardash'})
		// 	} else if (this.GET_USER_UTIL === 'true') {
		// 		this.$router.replace({name: 'accountantdash'})
		// 	} else if (this.GET_MASTER_ACCOUNTANT) {
		// 		this.$router.replace({name: 'master_accountant_dashboard'})
		// 	} else if (this.GET_ASSIST_ACCOUNTANT) {
		// 		this.$router.replace({name: 'accountant_assistance'})
		// 	} else if (this.GET_COLLEAGUE) {
		// 		this.$router.replace({name: 'colleague_settings'})
		// 	} else {
		// 		this.$router.replace({name: 'dashboard'})
		// 	}
		// }
	},
	methods: {
		// eventBus : () => bus,
		loginUser() {
			if (this.email === '' || this.password === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.processing = true
				this.$store.dispatch('auth/loginUser', {username: this.email, password: this.password})
					.then(res => {
						this.processing = false
						this.$services.helpers.notification(this.$t('loginSuccess'), 'success', this)
						this.setRandomColor()
						this.$store.commit('customer/UPDATE_IS_COLLEAGUE', {status: false})
						this.$store.commit('auth/SET_USER_CREDENTIAL', {username: this.email, password: this.password})
						const pathURL = getPath()

						if (res.data.data.user.dashboard_type === 2) {
							this.$store.commit('house/UPDATE_IN_HOUSE', res.data.data)
							if (pathURL) {
								window.location.href = pathURL
							} else {
								// Supeadmin redirction
								setTimeout(() => {
									this.$router.replace({name: 'regulardash'})
								}, 2000)
							}
						} else if (res.data.data.user.dashboard_type === 1) {
							// Redirect to Accountant page
							this.$store.commit('accountant/UPDATE_ACCOUNTANT', res.data.data)
							if (pathURL) {
								window.location.href = pathURL
							} else {
								setTimeout(() => {
									this.$router.replace({name: 'accountantdash'})
								}, 2000)
							}
						} else if (res.data.data.user.dashboard_type === 3) {
							this.$store.commit('customer/SET_USER_FIRM', null)
							this.$store.commit('accountant/UPDATE_MASTER_ACCOUNTANT', res.data.data)

							if (pathURL) {
								window.location.href = pathURL
							} else {
								setTimeout(() => {
									this.$router.replace({name: 'master_accountant_dashboard'})
								}, 2000)
							}
						} else if (res.data.data.user.dashboard_type === 4) {
							// Redirect to Colleague landing page
							this.$store.commit('colleague/UPDATE_COLLEAGUE', true)
							if (pathURL) {
								window.location.href = pathURL
							} else {
								setTimeout(() => {
									this.$router.replace({name: 'colleague_settings'})
								}, 2000)
							}
						} else if (res.data.data.user.dashboard_type === 5) {
							this.$store.commit('assistanceAccountant/UPDATE_ASSIST_ACCOUNTANT', true)
							if (pathURL) {
								window.location.href = pathURL
							} else {
								// Redirect to Colleague landing page
								setTimeout(() => {
									this.$router.replace({name: 'accountant_assistance'})
								}, 2000)
							}
						} else {
							if (pathURL) {
								window.location.href = pathURL
							} else {
								// Redirect to user page
								setTimeout(() => {
									this.$router.replace({name: 'dashboard'})
								}, 2000)
							}
						}
					}).catch((err) => {
					this.processing = false
					if (err.response.status === 400) {
						this.$services.helpers.notification(this.$t('loginError'), 'error', this)
					}
					if (err.response.status === 401) {
						this.$services.helpers.notification(err.response.data.detail, 'error', this)
					}
					// this.$services.helpers.notification(err.response.data.data, 'error', this)
				})
			}
		},
		changeLocale(locale) {
			i18n.locale = locale
			this.$store.commit('utility/CHANGE_LOCALE', locale)
			localeChanged()
		},
		setRandomColor() {
			const color = ['#512DA8', '#7E3469', '#EE4D81', '#344258', '#546295', '#1E2C41']
			const choose = color[Math.floor(Math.random() * color.length)]
			if (!window.localStorage.getItem('randomc')) {
				window.localStorage.setItem('randomc', choose)
			}
			return false
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import "~vue-tour/dist/vue-tour.css";

#app {
	overflow-y: hidden !important;
}

.login-head-text {
	font-size: 1.2rem !important;
}

.login-head-text-xl {
	font-size: 1.5rem !important;
}

.override-form-field {
	border: 0;
	border-bottom: 1px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: unset !important;
	border-radius: unset !important;
}

html, body {
	background: #fff !important;
	overflow: hidden;
}

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}
</style>
