import moment from "moment"

const LOCALE = 'xlocale'
const FIRM = 'xfirm'
const XDJANGO = 'xmdjango'
const CURRENT_SUBSCRIPTION = 'current'
export const saveLocale = (payload) => {
	window.localStorage.setItem(LOCALE, payload)
}
export const getLocale = () => {
	return window.localStorage.getItem(LOCALE)
}

export const saveFirm = data => {
	// console.log('wht is data:', JSON.stringify(data))
	window.sessionStorage.setItem(FIRM, JSON.stringify(data))
}

export const getFirm = () => {
	return window.sessionStorage.getItem(FIRM)
}
export const deleteFirm = () => {
	return window.sessionStorage.removeItem(FIRM)
}

export const saveXAmDjango = data => {
	window.sessionStorage.setItem(XDJANGO, data)
}

export const getXAmDjango = () => {
	return window.sessionStorage.getItem(XDJANGO)
}

export const storeSystemNotify = data => {
	return window.localStorage.setItem('amSys', JSON.stringify(data))
}

export const getSystemNotify = () => {
	const system = window.localStorage.getItem('amSys')
	if (!system || system === 'undefined') {
		window.localStorage.removeItem('amSys')
	} else {
		return JSON.parse(window.localStorage.getItem('amSys'))
	}
}

export const storeTourGuide = data => {
	return window.localStorage.setItem('__settingsTourGuide', JSON.stringify(data))
}

export const getTourStorage = () => {
	return JSON.parse(window.localStorage.getItem('__settingsTourGuide'))
}

export const deleteUserData = () => {
	window.sessionStorage.removeItem(FIRM)
	window.sessionStorage.removeItem(XDJANGO)
}

export const setUserCompany = (data, entity) => {
	if (entity === 'Firm') {
		saveFirm(data)
	} else {
		saveXAmDjango(data)
	}
}

export const storePath = (data) => {
	window.localStorage.setItem('__authPath__', data)
}

export const getPath = () => {
	return window.localStorage.getItem('__authPath__')
}

export const destroyPath = () => {
	window.localStorage.removeItem('__authPath__')
}

export const saveDataLocalStorage = (key, data) => {
	window.localStorage.setItem(key, data)
}
export const getDataLocalStorage = (key) => {
	return window.localStorage.getItem(key)
}
export const removeDataLocalStorage = (key) => {
	window.localStorage.removeItem(key)
	return true
}
export const removeDataSessionStorage = (key) => {
	window.sessionStorage.removeItem(key)
	return true
}
export const checkIfExist = (key) => {
	if (window.localStorage.getItem(key) !== null) {
		return true
	} else {
		return false
	}
}


export default {
	saveLocale,
	getLocale,
	setUserCompany,
	storeTourGuide,
	getTourStorage,
	deleteUserData,
	storeSystemNotify,
	getSystemNotify,
	checkIfExist, getDataLocalStorage, saveDataLocalStorage, removeDataLocalStorage

}
