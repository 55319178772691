export default {
	GET_IN_HOUSE_UTIL(state) {
		return state.regularHouse
	},
	GET_IN_HOUSE_DATA(state) {
		return state.regularHouse
	},
	GET_ALL_FIRM(state) {
		return state.allFirms
	},
	GET_ALL_COMPANY(state) {
		return state.allCompanies
	},
	GET_CONNECTED_COMPANY(state) {
		return state.connectedCompany
	},
	GET_HOUSE_METRICS(state) {
		return state.houseMetrics
	},
	GET_HOUSE_GRAPH_METRICS(state) {
		return state.houseGraphMetrics
	},
	GET_ACCOUNT_COMPANY(state) {
		return state.accountantCompany
	},
	GET_FIRM(state) {
		return state.firm
	},
	GET_FIRM_ACCOUNTANT(state) {
		return state.allAccountants
	},
	GET_FAQ_DATA(state) {
		return state.faqData
	},
	GET_FAQ_CATEGORY(state) {
		return state.faqCategory
	},
	GET_SUBSCRIPTION(state) {
		return state.allSubscription
	},

}
