// Adding localization to vue app
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {se, en} from './ValidateMessages'

Vue.use(VueI18n)

const messages = {
	en: {
		fields: {
			username: 'Username',
			email: 'E-mail Address',
			emailUsername: 'Email Address / Username',
			password: 'Password',
			firstName: 'Firstname',
			lastName: 'Lastname',
			companyNumber: 'Company Number',
			companyEmail: 'Company Email',
			companyName: 'Company Name',
		},
		validation: en.messages,
		dashboard: 'Dashboard',
		loginError: 'Login credentials are invalid',
		addApp: 'Add Apps',
		inviteUsers: 'Invite Users',
		exitUser: 'Exit User View',
		billing: 'Billing',
		appLogs: 'App Logs',
		report: 'Report',
		exportExcel: 'Export Excel',
		elementType: 'Element type',
		loading: 'Loading',
		viewAndEditCompanyInformation: "View and edit company information",
		generateReport: 'Generate Reports',
		may: 'May',
		discrepancyReport: 'Discrepancy Report',
		logs: 'Logs',
		transactions: 'Transactions',
		help: 'Help',
		loginHeader: 'Login to Client Portal',
		forgetPassword: 'Forgot Password',
		notCustomer: 'Not a Customer',
		welcome: 'Welcome to Sign Up',
		golf: 'Client Portal for GOLF clients',
		reset: 'Reset Password',
		resetYourPassword: 'Reset your password',
		resetting: 'Resetting...',
		successReset: 'Password reset successfully',
		sendLink: 'Sending link',
		already: 'Already a Customer',
		signup: 'Sign Up',
		creatingAccount: 'Creating Account...',
		createNewAccount: 'Create New Account',
		login: 'Login here',
		headNotAvailable: 'Looks like you\'re lost',
		pageNotFound: 'The page you are looking for is not available!',
		previous: 'Go to previous page',
		settingPrevious: 'Previous',
		settings: 'App Settings',
		FAQHead: 'Common Questions',
		FAQSmall: 'Frequently asked questions',
		noCategory: 'No FAQ category lists',
		searchCategory: '',
		searchApp: '',
		filterApp: 'Accounting type',
		type: 'Type',
		systemLog: 'System Logs',
		auditLog: 'All Audit Logs',
		date: 'Date',
		apps: 'Apps',
		detail: 'Details',
		user: 'Users',
		level: 'Level',
		all: 'All',
		filterBy: 'Filter by',
		filterUser: 'Users',
		paymentType: 'Current Payment Type',
		update: 'Update',
		updating: 'Updating...',
		switchInvoice: 'Switch to Invoice Billing',
		billingHistory: 'Billing History',
		save: 'Save',
		cancel: 'Cancel',
		yes: 'yes',
		create: 'Create',
		creditCard: 'Credit Card',
		invoicing: 'Invoicing',
		payWithCard: 'Pay with Card',
		noDataTable: 'No data available in table',
		logout: 'Logout',
		account: 'Account',
		accountConfirm: 'Account confirm successfully',
		userRole: 'Select User Role',
		loginSuccess: 'Account login successfully',
		fieldRequired: 'All fields are required',
		allAccountFieldRequired: 'All accounts field are required',
		passwordGreater: 'Password should be greater than 6',
		passwordMatch: 'Password do not match',
		invalidToken: 'Invalid token',
		daysBack: 'We offer 7 days of retroactive sync. If you wish to buy for retroactive accounting further back in time, you are welcome to contact support at info@automatiseramera.se',
		switchYearlySuccess: 'Thank you, an invoice will be emailed to the address provided shortly',
		invalidCompany: 'Invalid Company Number',
		accountCreated: 'Account created successfully',
		readInfo: 'Read Information',
		configure: 'Configure and Save Settings',
		activate: 'Activate App',
		activating: 'Activating',
		deactivate: 'Deactivate',
		deactivating: 'Deactivating',
		status: 'Status',
		config: 'Configure',
		transactionHistory: 'Transaction History',
		activateApp: 'Activate Current App',
		activateNow: 'Activate',
		updateInfo: 'Update Information',
		upload: 'Upload Photo',
		fileUpload: 'Upload File',
		connected: 'All Connected Companies',
		connected_companies: 'Connected Companies',
		connecting: 'Connecting',
		companies: 'Companies',
		firm: 'Accounting Firm',
		language: 'Choose Language',
		companyName: 'Company Name',
		companyNumber: 'Company Number',
		AccountingFirmName: 'Accounting Firm Name',
		firmName: 'Firm Name',
		firmNumber: 'Accounting Firm Number',
		notify: 'Notification',
		have: 'You have',
		unread: 'unread messages',
		switch: 'Switch to',
		english: 'English',
		bookKept: 'Total Transaction Bookkept',
		totalSaving: 'Total Savings in Accounting cost',
		subscription: 'Subscriptions',
		dueDate: 'Due Date',
		file: 'File',
		zipCode: 'Zip Code',
		city: 'City',
		email: 'Email',
		invoiceEmail: 'Invoice Email',
		billingAddress: 'Billing Address',
		role: 'Role',
		management: 'Management',
		dateAdded: 'Date Added',
		phoneNumber: 'Phone Number',
		accountant: 'Accountant',
		name: 'Name',
		totalBookKept: 'Total Transaction Bookkept',
		totalSubscription: 'Total Subscriptions',
		mostSold: 'Most Sold Apps',
		totalPartners: 'Total Partners',
		amountDue: 'Amount Due',
		imageUploadSuccessful: 'Image uploaded successfully',
		createNote: 'Creating note...',
		noteCreated: 'Note created',
		emptyNote: 'Note field cannot be empty',
		updateNote: 'Updating note...',
		noteUpdated: 'Note updated',
		pleaseWait: 'Please wait...',
		successCompanyUpdated: 'Company information updated successful',
		requireCompanyNumber: 'Company name/Company number is required',
		requireCompanyAddress: 'Company address is required',
		requirePhonenumber: 'Phone Number is required',
		requireZipCode: 'Company zip code is required',
		requireCity: 'Company city is required',
		updateApp: 'Updated Apps successfully',
		redirectYou: 'Please wait while we direct you.',
		confirmInvite: 'You have confirmed the invitation',
		hi: 'Hi',
		almostDone: 'You\'re almost done',
		massReassignAccountant: 'Mass Reassign Accountant',
		crop: 'Crop',
		cropping: 'Cropping',
		finishedUpload: 'Finished & Upload',
		uploading: 'Uploading',
		processing: 'processing',
		firstName: 'Firstname',
		lastName: 'Lastname',
		other: 'Other',
		creating: 'Creating...',
		accountantRemove: 'Accountant remove successfully',
		accountantAdded: 'Accountant added successfully',
		action: 'Action',
		accountNumberRequired: 'Account Number required',
		createdCustom: 'Account Number mapping created successfully',
		deletedCustom: 'Account Number mapping deleted successfully',
		activatedSubscription: 'Subscription activated successfully',
		deactivatedSubscription: 'Subscription deactivated successfully',
		createdDeliverySubscription: 'Delivery settings added',
		deletedDeliverySubscription: 'Delivery settings deleted successfully',
		fileRequired: 'File field required',
		swishFileSuccess: 'File uploaded successfully',
		invalidFile: 'Invalid file format',
		assign: 'Assign',
		accountantCompanies: 'Accounting Firms',
		reassignAccountant: 'Re-Assign Accountant',
		assignTo: 'Assign To',
		assigning: 'Assigning',
		continue: 'Continue',
		prev: 'Previous',
		prev_: 'Previous',
		next: 'Next',
		uploadFinished: 'Finished & Uploaded',
		invalidAccountID: 'Invalid account ID',
		selectAssignee: 'Please select assignee',
		selectCompany: 'Please select at least a company',
		selectCompanies: 'Select companies',
		successAssign: 'Assigned Successfully',
		visma: 'Visma credential was added successfully',
		invalidSession: 'Invalid Session from User. Redirecting...',
		subscriptionPage: 'Subscription Page',
		switchCard: 'Your Current Payment Type has been switch to Card',
		redirectWait: 'Please wait whiles we redirect you',
		setUpAccount: 'Set Up Account',
		emailAddress: 'Email Address',
		emailUsername: 'Email Address / Username',
		password: 'Password',
		confirmPassword: 'Confirm Password',
		newPassword: 'New Password',
		confirmNewPassword: 'Confirm New Password',
		createdPayTermSubscription: 'Payment Term Subscription created successfully',
		deletedPayTermSubscription: 'Payment Term Subscription deleted successfully',
		accountNumber: 'Account Number',
		addAccountNumber: 'Add Account Number',
		adding: 'Adding',
		enterNumber: 'Enter Account Number',
		invalidNumber: 'Account Numbers should be Numbers Only',
		accountAddedSuccess: 'Account Number added successfully',
		// companies : 'Companies',
		search: 'Search',
		chooseFile: 'Choose a file',
		addFAQ: 'Add Question',
		addFrequent: 'Add a frequently asked question',
		question: 'Question',
		category: 'Category',
		addCategory: 'Add Category',
		answer: 'Answer',
		dashboardViewOnly: 'Dashboard view only',
		add: 'Add',
		awesome: 'Write something awesome', // Write something awesome
		addNote: 'addNote',
		paymentInterval: 'Payment Interval',
		monthly: 'Monthly',
		switchYear: 'Switch to Yearly',
		subscriptionMonth: 'Subscriptions created this month',
		faqAdded: 'FAQ added successfully',
		switchSuccess: 'Thank you, the company is now set up as an invoice customer',
		totalAccountant: 'Total Accountants',
		connectedCustomer: 'Total Connected Customers',
		// totalSubscription : 'Total Subscriptions',
		transactionBookkept: 'Total Transaction Bookkept',
		totalKickback: 'Total Kickbacks/Discount',
		subscriptionType: 'Subscription Type',
		saveMoney: 'Total money save in time',
		totalHours: 'Total work hours saved',
		appCatalogue: 'App Catalogue',
		overview: 'Overview',
		categorySelect: 'Please select a category',
		chooseCategory: 'Choose Category',
		genericQuestion: 'Generic Question',
		testOrder: 'Test One Order Only',
		testSend: 'Send',
		notFoundText: 'Oh no! Page Not Found',
		backHome: 'Back to Homepage',
		optionalSetting: 'Optional Settings',
		paymentMappingSetting: 'Payment Mapping',
		accountSetting: 'Account Mapping',
		bambooHRSetting: 'BambooHR Mapping',
		deliverySetting: 'Delivery Mapping',
		more: 'more',
		less: 'less',
		at: 'at',
		markRead: 'Mark as read',
		testing: 'Testing',
		bambooCreated: 'BambooHR created successfully',
		bambooDeleted: 'Data deleted successfully',
		connectShopify: 'Connect shopify',
		connectStripe: 'Connect Stripe',
		buyNow: 'Buy Now',
		price: 'Price',
		notifyRedirect: 'Link for more info',
		fieldValue: 'Field value cannot be longer than 50 Characters',
		invalidEmail: 'Invalid Email Address',
		invitedSuccessful: 'Invitation sent to user',
		paid: 'Paid',
		unPaid: 'Unpaid',
		fileSuccessHead: 'Following vouchers have been posted successfully',
		testOrderHead: 'invoice_id from BE',
		orderTopicSuccess: 'A test order has now been sent to the finance program with the following number',
		paymenetTopicSuccess: 'A test payment is sent to the accounting app for following invoice',
		stateSuccessful: 'Successful',
		noDataSentFortnox: 'No data was sent to FORTNOX',
		noDataSentVisma: 'No data was sent to VISMA',
		fileProcessingSent: 'The vouchers are reported to accounting',
		fileProcessingSentVisma: 'The vouchers are reported to accounting',
		termsAndCondition: 'Terms & Cond.',
		processingFile: 'File is being processed, please hold',
		skuFileProcessing: 'Please hold while the product data is examined',
		perfectSku: 'All SKUs are good',
		fileResponseSuccess: 'Following vouchers were successfully posted',
		uniqueSkuNumber: 'Products that do not have unique SKU numbers',
		fileResponseError: 'The upload failed, see below error message from the accounting app',
		testResponseError: 'The test could not be posted, se below error message from accounting application',
		viewNotification: 'View Notifications',
		skipTour: 'Skip Tour',
		previousTour: 'Previous',
		nextTour: 'Next',
		finishedTour: 'Finished',
		dashboardTourProfilePicture: 'Click here to log out or to add a profile picture to your account.',
		dashboardTourTranslationButton: 'Click here to change the language in your dashboard. You can choose between Swedish and English',
		dashboardTourNotification: 'This is where you find alerts we send out, such as news or prompts for necessary adjustments in your settings.',
		dashboardTourMetrics: 'These boxes shows you a summary of how many transactions your integrations automatically have booked, how much you\'ve saved' +
			'in accounting costs by using Automatisera Mera\'s integrations and how many active integrations you currently have.',
		dashboardTourTransactionHistory: 'These graphs gives you an overview of how much each of your integrations booked on a daily basis.',
		dashboardTourAppSetting: 'Click here to get to the settings page for your integration(s).',
		appTabInfoGuide: 'Here is the information guide for your integration. It contains information about what the integration does and the steps necessary to get started.',
		appTabContinue: 'Scroll down and press ”Continue” to get to the configurations page.',
		appTabConfigSettings: 'This is the configuration page for the integration. Here is where you have the power to configure the integration according to your specific needs.'
			+ 'If anything is unclear, simply hover over the question mark by each field for a short description of what is requested.',
		appTabUpdateBtn: 'Once you\'ve completed your settings, click ”Update” to save them.',
		appTabConfigContinue: 'Click ”Continue” to test and activate your integration.',
		appTabSendTest: 'Click here to send one invoice/order/voucher to Fortnox. Check your Fortnox account to make sure it got imported as you wanted.',
		appTabActivate: 'Once you\'re happy with the results of the first import, click ”Activate” to activate your integration.'
			+ 'You have now completed the configurations of your integration and you can sit back and relax and let the integration do the heavy work for you.',
		appTabAddIntegration: 'Click here to add an integration.',
		addAppFilter: 'Click here to filter apps on accounting software.',
		addAppSearchBar: 'Use the search bar to search fo the app you\'re looking for.',
		addAppIntegration: 'Click the app you want to add and you\'ll be redirected to where you can activate/buy it.',
		addAppClickPayment: 'Click ”Payments” to get to your billing details, billing history and receipts for previous payments.',
		billingPage: 'This is where you see your current billing details. Here you can update billing details and payment method (card payment/invoice payment).',
		billingUpdate: 'Click ”Update” once you\'ve updated your details above to save the changes.',
		billingTable: 'This is a list of all your previous payments with total amount, payment date, due date and if the payment has been finalised or not. Click the download icon to download the receipt of your payment.',
		billingFaq: 'Got a question? Click here to get to the page of frequently asked questions.',
		faqSearchBar: 'Use the search bar to search your question. Most likely the answer you\'re looking for will be here.',
		faqInviteStaff: 'Ready to invite a staff member or you accountant? Click here to get to the invitation page.',
		inviteModalTour: 'Fill in the details of the person you\'re trying to invite and click ”Send”. Your team member will get an invitation to create his or her own account, that he or she can use to do access your' +
			'integrations and configurations.Fill in the details of the person you\'re trying to invite and click ”Send”. Your team member will get an invitation to create his or her own account, that he or she can use to do access your integrations and configurations.',
		ruleType: 'Rule Type',
		accountType: 'Account Type',
		forCompany: 'For Company',
		forShipping: 'For Shipping',
		forFeeLines: 'For fee lines',
		enterCompanyInfo: 'Go to the company\'s user account',
		noteHovering: 'Save notes or comments',
		deleteHovering: 'Disconnect the company from the agency',
		warning: 'Warning',
		removeUser: 'Are you sure you want to disconnect user',
		removeCompany: 'Are you sure you want to disconnect company',
		successfulDisconnectCompany: 'Successful disconnected a company',
		appManagement: 'App Management',
		order: 'Order',
		customer: 'Customer',
		country: 'Country',
		amount: 'Amount',
		currency: 'Currency',
		webshop: 'Webshop',
		quantity: 'Quantity',
		connectedStripe: 'Connected',
		connectedShopify: 'Connected',
		shopNameRequire: 'Please enter shop name',
		update_information: 'Update information',
		assign_accountant: 'Assign accountant',
		assign_companies: 'Assign companies',
		all_linked_companies: 'All Linked Companies',
		reassign_accountant: 'Re-Assign accountant',
		cancelling: 'cancelling',
		change_platform: 'Changing platforms',
		company_closedown: 'Company Closedown',
		not_please_with_connector: 'Not Pleased with the Connector',
		comments: 'Comments',
		subscription_cancel_text: 'Your Subscription is on a Fixed Contract and will end on',
		generic_error: 'An error occur on our side and we are currently working on it, Kindly try again',
		sales_account_se: 'Sales account SE',
		sales_account: 'Sales account',
		sales_account_export: 'Sales account Export',
		all_fields_are_required: 'All fields are required',
		is_required: 'is required',
		eu_vat_account: 'EU vat account',
		export_vat_account: 'Export vat account',
		cancellation_information: 'Cancellation information',
		cancellation_text_one: 'Note that when the subscription is cancelled, all data is deleted in our database' +
			' within 7 days of cancellation date.',
		cancellation_text_two: 'This means we will not be able to assist you in any customer inquiries' +
			' or support tickets about your accounting data the day the cancellation takes effect.',
		reasons: 'Reason',
		select_reasons: 'Select reason for cancellation',
		cancel_subscription: 'Cancel subscription',
		dataProcessing: 'Data Processing',
		examineProgress: 'Product data analysis being executed',
		unpaid: 'Unpaid',
		support: 'Support',
		assign_to: 'Assign to',
		company: 'Company',
		select_company: 'Select company',
		select_accountant: 'Select Accountant',
		searchCompany: 'Select Company',
		searchAccountant: 'Select Accountant',
		selectRegion: 'Kindly select a region',
		authSuccess: 'Authorization successful',
		authFailed: 'Authorization Failed',
		orders: 'Orders',
		article: 'Products',
		chooseSubscription: 'Choose Subscription',
		orderNumber: 'Order #',
		syncStatus: 'Sync Status',
		accounting: 'Accounting #',
		articleNumber: 'Product #',
		articleName: 'Product Name',
		shop: 'Shop',
		synced: 'Synced',
		notSync: 'Not Synced',
		failed: 'Failed',
		salesPanel: 'Sales Panel',
		firmPayout: 'Kickback Payouts',
		serial: 'Serial',
		amount_: 'Amount',
		invoiceDate: 'Invoice Date',
		invoicePayDate: 'Invoice Pay Date',
		clear: 'Clear',
		filter: 'Filter',
		pending: 'Pending',
		payoutStatus: 'Payout Status',
		dateRange: 'Date Range',
		done: 'Done',
		otherServices: 'Other Services',
		otherApps: 'Other apps from Automatisera Mera',
		optimizeWork: 'Optimize and automate your worflow with smart tools',
		readMore: 'Read more',
		AppSynchronizePage: 'Trigger and View Sync Events',
		createSync: 'Trigger Sync',
		app_name: 'App Name',
		sync_type: 'Type',
		createSyncEntry: 'Trigger Sync',
		appSelectError: 'Select App',
		dateRequired: 'Start Date is required',
		syncDateError: 'The selected date is previous to your purchase date of the app, please select a later date',
		articleNumberError: 'Article number is required',
		orderNumberError: 'Order number is required',
		entryCreated: 'Entry created successfully',
		generalError: 'An error occurred while processing your request',
		triggerSync: 'Trigger',
		message: 'Message',
		success: 'Success',
		all_product: 'All Products',
		all_order: 'All Order',
		payment: 'Payments',
		order_specific: 'Specific Order ',
		product_specific: 'Specific Product ',
		no_date_available: 'No data available',
		paymentSync: 'Following InvoicePayments or Vouchers were posted to the Accounting app',
		paymentNotSync: 'Following InvoicePayments or Vouchers were not posted to the Accounting app:',
		articleSync: 'Following Articles were posted to the Accounting app',
		articleNotSync: 'Following Articles were not posted to the Accounting app',
		orderSync: 'Following orders have been synced',
		orderNotSync: 'Following Orders were not posted to the Accounting app',
		article_number: 'Article Number',
		order_number: 'Order Number',
		sales_panel: 'Sales Panel',
		shopifyGuide: 'See Guide on how to access api key',
		apiQueries: 'Api Queries',
		exportJsonFile: 'Export Json file',
		app: 'App',
		storeIdentifier: 'Store Identifier',
		element: 'Element',
		select: 'Select',
		elementId: 'Element ID',
		client: 'Client',
		queryApi: 'Query API',
		queryLocalDb: 'Query Local DB',
		appData: 'App Data',
		accountingData: 'Accounting Data',
		cost_center: 'Cost Center',
		costCenterMapping: 'Cost Center Mapping',
		costCenterMappingHelpText: 'Here you can map different cost centers based on the buyer\'s country',
		createdSuccessfully: 'Created Successfully',
		deletedSuccessfully: 'Deleted Successfully',
		bankGiroNumber: 'Bank Giro Number',
		bank: 'Bank',
		accountFirmPayout: 'Accounting Firm Payout',
		searchDoesNotMatch: 'No entry matches your search',
		invoiceNumber: 'Invoice Number',
		back: 'Back',
		runSync: 'Run Sync',
		reason: 'Reason',
		invoice_payment: 'Invoice Payment',
		invoice_accrual: 'Invoice Accrual',
		invoice: 'Invoice',
		voucher: 'Vouchers',
		activateSubscription: 'Activate Subscription',
		order_reference: 'Order Reference',
		see_details: 'See Details',
		forDiscount: 'For discount',
		forGiftCard: 'For gift card',
		feeVatAcctDebit: 'Fee vat account debit',
		reverseFeeVatDebitAccount: 'Reverse Vat on Fee Debit',
		reverseFeeVatCreditAccount: 'Reverse Vat on Fee Credit',
		providerFeeAccount: 'Provider fee account',
		payoutAccountBank: 'Payout account bank',
		payout: 'Payout',
		receivable_acct: 'Receivable Account',
		vat_acct: 'Vat Account',
		productDeleteError: 'Product could not be deleted',
		orderDeleteError: 'Order could not be deleted',
		deleteSuccess: 'Deleted successfully',
		deleteConfirmation: 'This will permanently delete the entry. Continue',
		updateCustomAccount: 'Update Account Mapping',
		customAccountUpdated: 'Custom Account Updated',
		vat: 'Vat',
		description: 'Description',
		createFaqCategory: 'Create Faq Category',
		customerFaqAdded: 'Customer Faq Added,You will be contacted once our support respond to your request',
		isHidden: 'Is Hidden',
		visible_for_customers_only: 'Visible for customer only',
		notifyUserForUpdate: 'Notify customer for update',
		costCenterHelpText: 'Select the Costcenter code in the accounting app',
		landHelpText: 'Select the costcenter which should be assigned per buyers country',
		selectedCostCenterDoesNotExist: 'Selected cost center does not exist',
		allLocation: 'All Locations',
		accrualAccountIncome: 'Accrual account Income',
		feeVatAccount: 'Fee Vat Account',
		lastUpdated: 'Last updated',
		additionalParameter: 'Additional Parameter',
		DocumentNumber: 'Document Number',
		CustomerNumber: 'Customer Number',
		CustomerName: 'Customer Name',
		OrderDate: 'Order Date',
		Total: 'Total',
		TotalToPay: 'Total To Pay',
		'EmployeeId': 'Employee ID',
		'PersonalIdentityNumber': 'Personal Identity Number',
		'FirstName': 'First Name',
		'LastName': 'Last Name',
		'FullName': 'Full Name',
		'Address1': 'Address 1',
		'Address2': 'Address 2',
		'PostCode': 'Post Code',
		'City': 'City',
		'Country': 'Country',
		'Phone2': 'Phone 2',
		'Comments': 'Comments',
		'ReferenceNumber': 'Reference Number',
		'ReferenceType': 'Reference Type',
		'TransactionDate': 'Transaction Date',
		'VoucherNumber': 'Voucher Number',
		'VoucherSeries': 'Voucher Series',
		'Year': 'Year',
		'ApprovalState': 'Approval State',
		'FromDate': 'From Date',
		'ToDate': 'To Date',
		'AccountingMethod': 'Accounting Method',
		'accountCharts': 'Account Charts',
		'Name': 'Name',
		'Phone1': 'Phone 1',
		'Email': 'Email',
		'ZipCode': 'Zip Code',
		'Currency': 'Currency',
		"Number": 'Number',
		"InvoiceCustomerName": 'Invoice Customer Name',
		"InvoiceCustomerNumber": 'Invoice Customer Number',
		"InvoiceNumber": 'Invoice  Number',
		"InvoiceTotal": 'Invoice Total',
		"PaymentDate": 'Payment Date',
		"ContractDate": 'Contract Date',
		"PeriodStart": 'Period Start',
		"PeriodEnd": 'Period End',
		"ArticleNumber": 'Article Number',
		"Description": 'Description',
		"SalesPrice": 'Sales Price',
		"PurchasePrice": 'Purchase Price',
		"QuantityInStock": 'Quantity In Stock',
		"Active": 'Active',
		"DueDate": 'Due Date',
		"InvoiceDate": 'Invoice Date',
		"OrganisationNumber": 'Organization Number',
		"company_id": 'Organization Number',
		'number_in_accounting': 'Number in accounting',
		'number_in_app': 'Number in app',
		'date_created': 'Date created',
		total_price: 'Total Price',
		vat_included: 'Vat included',
		customer_type: 'Customer type',
		stock_quantity: 'Stock quantity',
		amount_paid_in_sek: 'Amount paid in sek',
		accounting_invoice_number: 'Accounting invoice number',
		payout_id: 'Payout id',
		payment_date: 'Payment date',
		start_date: 'Start date',
		end_date: 'End date',
		total_amount_customer_currency: 'Total amount customer currency',
		total_amount_in_sek: 'Total amount in sek',
		syncEntryReport: 'Sync Entry Report',
		genericResource: 'Generic Resource',
		customAccounts: 'Custom Account',
		periodicTasks: 'Period Tasks',
		notifications: 'Notifications',
		billing_email: 'Billing email',
		'accrualAccountCredit': 'Accrual account credit',
		'accrualAccountDebit': 'Accrual account debit',
		vouchers: "Vouchers",
		date_updated: 'Last Updated',
		phone: 'Phone Number',
		zipcode: 'Zip Code',
		accountingType: "Accounting Type",
		appType: "App Type",
		app_type: "App Type",
		created_at: "Created At",
		read: "Created At",
		scheduleDate: "Schedule Date",
		schedule_date: "Schedule Date",
		title: "Title",
		text: "Text",
		addNotification: 'Add Notification',
		notificationSuccess: "Notification created successfully",
		notificationUpdated: "Notification updated successfully",
		media_file: 'Media file',
		delete_accounts: 'Delete Accounts',
		uploadCustomAccount: 'Upload custom accounts',
		unprocessedSubscription: "Unprocessed Subscription", app_settings_link: "App setting link",
		last_processed_date: "Last processed date", username: "Username",
		userProfile: "User Profile",
		companyInformation: "Company information",
		viewAndEditCompanyInformaton: "View and Edit company information",
		last_name: "Last Name",
		first_name: "First Name",
		company_name: "Company name",
		company_number: "Company Number",
		accounting_app_customer_number: "Accounting app customer number",
		contract_interval: "Contract interval",
		invoice_email_address: "Invoice email address",
		payment_method: "Payment method",
		date_joined: "Date joined",
		sent_confirmation_date: "Sent confirmation date",
		date_activated: "Date activated",
		partner_accountants: "Partner accountants",
		accounting_firm_connection_end_date: 'Accounting firm connection end date',
		accounting_firm_connection_start_date: "Accounting firm connection start date",
		assisting_accountants: "Assisting accountants",
		assisting_colleagues: "Assisting colleagues",
		email_verified: "Email verified",
		is_accountant: "Is accountant",
		is_staff: "Is staff",
		is_active: "Is active",
		phone_office: "Phone office",
		phone_mobile: "Phone mobile",
		zip_code: "Zip code",
		admin_alert_email: "Admin alert email",
		syncEntrySuccess: "Sync has been triggered. Please note it can take up to a few minutes until the sync is completed based on the datavolume",
		transaction_date: "Transaction date",
		no: 'no',
		noData: 'No data',
		colleague: 'Colleague',
		non_partner_accountant: 'Non partner accountant',
		partner_accountant: 'Partner accountant',
		master_accountant: 'Master accountant',
		inhouse_user: 'In house user',
		active: 'Active',
		inactive: 'Inactive',
		cancelled: 'Cancelled',
		remove: 'Removed',
		paused: 'Paused',
		dragAndDropCsvfile: 'Drag and drop csv file',
		deleteAccounts: 'Delete accounts',
		uploadFile: 'Upload file',
		generate: 'Generate',
		code: 'Code',
		ambiguous: 'Ambiguous',
		updateFrequent: 'Update frequently asked question',
		single_order: 'Get order by order number',
		single_article: 'Get product by article number',
		single_invoice: 'Get invoice by document number',
		singe_contract: 'Get contract by document number',
		contract: 'Contract',
		single_customer: 'Get customer by number',
		single_customer_by_name: 'Get customer by name',
		financial_years: 'Financial year',
		employee: 'Employee',
		single_employee: 'Get employee by number',
		order_date: 'Order date',
		order_country: 'Order country',
		series: 'Series',
		original_transaction_date: 'Original transaction date',
		fiscal_year: 'Fiscal year',
		customer_email: 'Customer email',
		customer_name: 'Customer name',
		activeSubscription: 'Active subscription',
		inactiveSubscription: 'Inactive subscription',
		removeSubscription: 'Remove subscription',
		pauseSubscription: 'Pause subscription',
		cancelledSubscription: 'Cancelled subscription',
		companyOwner: 'Company owner',
		privateOwner: 'Private customer',
		customerType: 'Customer Type',
		optionalParams: 'Optional Params',
		addParams: 'Add Param',
		momskonto: "Default kontot som ska användas för momsen. Om ni vill särskilja och ha ett specifikt momskonto för ett specifikt land kan du lägga till det i kontomappningen nedan",
		kundfodranskonto: "Default kontot som ska användas för kundfodran. Om ni vill särskilja och ha ett specifikt kundfodranskonto för ett specifikt land kan du lägga till det i kontoinställningarna nedan",
		forsaljningskonto: "Default kontot som ska användas för intäkter inom SE. Om ni vill särskilja och ha ett specifikt kundforanskonto för ett specifikt land kan du lägga till det i kontomappningen nedan. Du bör också lägga till intäktskonto för EU och Export i kontomappningen",
		utbetalningskonto: "Default kontot som ska användas för netto utbetalt belopp.",
		avgifter_betalvaxel: "Default kontot som ska användas för betalväxelns avgifter.",
		omvand_moms_pa_avgifter_debit: "Momskonto för omvänd moms debet på betalväxelns avgifter.",
		omvand_moms_pa_avgifter_kredit: "Momskonto för omvänd moms kredit på betalväxelns avgifter"

	},
	se: {
		fields: {
			username: 'Användarnamn',
			email: 'E-post',
			emailUsername: 'E-postadress / Användarnamn',
			password: 'Lösenord',
			firstName: 'Förnamn',
			lastName: 'Efternamn',
			companyNumber: 'Orgnummer',
			companyEmail: 'Företag E-post',
			companyName: 'Företag',
		},
		validation: se.messages,
		dashboard: 'Lobby',
		accountType: 'Kontotyp',
		// currency: 'Valuta',
		update_information: 'Uppdatera information',
		loginError: 'Ogiltigt lösenord',
		addApp: 'Lägg till app',
		inviteUsers: 'Bjud in användare',
		exitUser: 'Avsluta användarvyn',
		appLogs: 'Apploggar',
		report: 'Rapporter',
		vat: 'Moms',
		exportExcel: 'Exportera Excel',
		elementType: 'Bokföringspost',
		generateReport: 'Generera rapporter',
		may: 'Maj',
		discrepancyReport: 'Avvikelserapport',
		billing: 'Betalningar',
		logs: 'Loggar',
		transactions: 'Fakturering',
		help: 'Hjälp',
		allLocation: 'Alla anläggningar',
		loginHeader: 'Logga in',
		forgetPassword: 'Glömt ditt lösenord',
		notCustomer: 'Inte en kund ännu',
		welcome: 'Skapa konto',
		golf: 'Klientportal för GOLF-kunder',
		reset: 'Återställ lösenord',
		resetYourPassword: 'Återställ ditt lösenord',
		resetting: 'Återställ',
		successReset: 'Lösenordet har återställts',
		sendLink: 'Skickar länk',
		already: 'Redan kund',
		signup: 'Skapa konto',
		creatingAccount: 'Skapar konto...',
		createNewAccount: 'Skapa nytt Konto',
		login: 'Logga in',
		headNotAvailable: 'Hoppsan, sidan finns inte',
		pageNotFound: 'Hoppsan, sidan finns inte',
		previous: 'Föregående sida',
		settingPrevious: 'Föregående',
		settings: 'App Inställningar',
		FAQHead: 'Vanliga frågor',
		FAQSmall: 'Vanligt ställda frågor',
		noCategory: 'Ingen kategorilista',
		searchCategory: '',
		searchApp: '',
		filterApp: 'Redovisningstyp',
		type: 'Typ',
		systemLog: 'Systemloggar',
		auditLog: 'Alla loggar',
		date: 'Datum',
		apps: 'Appar',
		detail: 'Detaljer',
		user: 'Användare',
		level: 'Meddelandetyp',
		all: 'Alla',
		filterBy: 'Filtrera efter',
		filterUser: 'Användare',
		paymentType: 'Aktuell betalningstyp',
		update: 'Spara',
		updating: 'Uppdaterar...',
		switchInvoice: 'Byt till fakturabetalning',
		billingHistory: 'Faktureringshistorik',
		save: 'Spara',
		cancel: 'Sparar',
		yes: 'Ja',
		create: 'Skicka',
		creditCard: 'Kreditkort',
		invoicing: 'Faktura',
		payWithCard: 'Betala med kort',
		noDataTable: 'Ingen data tillgänglig i tabellen',
		logout: 'Logga ut',
		account: 'Konto',
		accountConfirm: 'Inställningar sparade',
		userRole: 'Välj användarroll',
		loginSuccess: 'Kontoinloggning lyckades',
		fieldRequired: 'Alla fält är obligatoriska',
		passwordGreater: 'Lösenordet bör vara större än 6',
		passwordMatch: 'Lösenordet matchar inte',
		invalidToken: 'Ogiltigt token',
		daysBack: 'Vi bjuder på 7 dagars retroaktiv synk. Om ni önskar köpa till retroaktiv bokföring längre bak i tiden är ni välkomna att kontakt supporten på info@automatiseramera.se',
		switchYearlySuccess: 'Tack, en faktura kommer mejlas ut inom kort',
		invalidCompany: 'Ogiltigt företagsnummer',
		accountCreated: 'Kontot har skapats',
		readInfo: 'Informationsguide',
		configure: 'Konfigurera och spara inställningar',
		activate: 'Aktivera app',
		activating: 'Aktiverar',
		deactivate: 'Avaktivera',
		deactivating: 'Inaktiverar',
		status: 'Status',
		config: 'Konfigurera',
		transactionHistory: 'Transaktionshistorik',
		activateApp: 'Aktivera aktuell app',
		activateNow: 'Aktivera',
		updateInfo: 'Spara',
		upload: 'Ladda upp foto',
		fileUpload: 'Skicka',
		connected: 'Alla anslutna företag',
		connected_companies: 'Anslutna företag',
		connecting: 'Ansluter',
		companies: 'Företag',
		firm: 'Byråer',
		language: 'Välj Språk',
		companyName: 'Företag',
		companyNumber: 'Orgnummer',
		AccountingFirmName: 'Byrå',
		firmName: 'Byrå',
		firmNumber: 'Orgnummer',
		notify: 'Meddelande',
		have: 'Du har',
		unread: 'Olästa meddelanden',
		switch: '  Växla till',
		english: 'Engelska',
		bookKept: 'Totalt bokförda transaktioner',
		totalSaving: 'Totala besparingar i redovisningskostnader',
		subscription: 'Integrationer',
		dueDate: 'Förfallodatum',
		file: 'Underlag',
		zipCode: 'Postnummer',
		zipcode: 'Postnummer',
		city: 'Stad',
		email: 'E-Post',
		invoiceEmail: 'E-post fakturautskick',
		billingAddress: 'Fakturaadress',
		role: 'Roll',
		management: 'Redigera',
		dateAdded: 'Datum tillagt',
		phoneNumber: 'Telefonnummer',
		accountant: 'Redovisningskonsult',
		name: 'Namn',
		totalBookKept: 'Totalt bokförda transaktioner',
		totalSubscription: 'Totalt prenumerationer',
		mostSold: 'Mest sålda appar',
		totalPartners: 'Totalt antal partners',
		amountDue: 'Belopp',
		imageUploadSuccessful: 'Bilden har laddats upp',
		createNote: 'Skapar anteckning',
		noteCreated: 'Kommentar skapad',
		emptyNote: 'Anteckningsfältet kan inte vara tomt',
		updateNote: 'Uppdaterar anteckningen ...',
		noteUpdated: 'Kommentar uppdaterad',
		pleaseWait: 'Vänta...',
		successCompanyUpdated: 'Företagsinformation uppdaterad',
		requireCompanyNumber: 'Företagsnamn / företagsnummer krävs',
		requireCompanyAddress: 'Adress krävs',
		requirePhonenumber: 'Telefonnummer krävs',
		requireZipCode: 'Postnummer krävs',
		requireCity: 'Stad krävs',
		updateApp: 'Uppdaterat',
		redirectYou: 'Ett ögonblick..',
		confirmInvite: 'Du har bekräftat en inbjudan',
		hi: 'Hej',
		almostDone: 'Välkommen att skapa ett konto',
		massReassignAccountant: 'Omfördela konsulter',
		assign_accountant: 'Länka Konsult',
		assign_companies: 'Fördela kunder',
		all_linked_companies: 'Anslutna bolag',
		reassign_accountant: 'Fördela kunder',
		accountantRemove: 'Redovisningskonsulten har bortkopplats',
		crop: 'Beskär',
		cropping: 'Beskär bild',
		finishedUpload: 'Färdig uppladdning',
		uploading: 'Uppladdning',
		processing: 'Bearbetar',
		firstName: 'Förnamn',
		lastName: 'Efternamn',
		other: 'Övrig',
		creating: 'Skicka',
		action: 'Händelse',
		accountNumberRequired: 'Intäktskonto krävs',
		createdCustom: 'Konto inställningar är skapade',
		deletedCustom: 'Konto inställningar är raderade',
		activatedSubscription: 'Aktiv integration',
		deactivatedSubscription: 'Avaktiverad integration',
		createdDeliverySubscription: 'Leveransmappning skapad',
		deletedDeliverySubscription: 'Leveransmappning raderad',
		fileRequired: 'Fil krävs',
		swishFileSuccess: 'Filen är mottagen och inrapporterad',
		invalidFile: 'Ogiltig fil',
		assign: 'Tilldela',
		accountantCompanies: 'Redovisningsbyrå',
		reassignAccountant: 'Omfördela',
		assignTo: 'Tilldela till',
		assigning: 'Tilldela',
		continue: 'Fortsätt',
		prev: 'Föregående',
		next: 'Nästa',
		uploadFinished: 'Filuppladning klar',
		invalidAccountID: 'Ogiltigt ID',
		// selectAssignee: 'Välj person',
		// selectCompany: 'Välj åtmindstonde ett bolag',
		// selectCompanies: 'Välj företag',
		// successAssign: 'Lyckad Omfördelning',
		// assign: 'Spara',
		// reassignAccountant: 'Omfördela',
		// assignTo: 'Länka till',
		// assigning: 'Sparar',
		selectAssignee: 'Välj anställd',
		selectCompany: 'Välj kund',
		selectCompanies: 'Välj kund',
		successAssign: 'Sparat',
		visma: 'Lyckad Visma anslutning',
		invalidSession: 'Något gick fel..',
		subscriptionPage: 'Inställningar',
		switchCard: 'Betalningsmetod uppdaterad till kort',
		redirectWait: 'Ett ögonblick..',
		setUpAccount: 'Aktivera konto',
		emailAddress: 'E-postadress',
		emailUsername: 'E-postadress / Användarnamn',
		password: 'Lösenord',
		confirmPassword: 'Bekräfta lösenord',
		newPassword: 'Nytt lösenord',
		confirmNewPassword: 'Bekräfta nytt lösenord',
		createdPayTermSubscription: 'Betalsätt tillagd',
		deletedPayTermSubscription: 'Betalsätt raderad',
		accountNumber: 'Kontonummer',
		addAccountNumber: 'Lägg till konto',
		adding: 'Skapar..',
		enterNumber: 'Kontonummer',
		invalidNumber: 'Kontonummer får bestå av max 5 siffror',
		accountAddedSuccess: 'Kontonummer tillagd',
		// companies : 'Företag',
		search: 'Sök',
		chooseFile: 'Ladda upp fil',
		addFAQ: 'Ställ en fråga',
		addFrequent: 'Lägg till en vanlig fråga',
		question: 'Fråga',
		category: 'Kategori',
		addCategory: 'Lägg till Kategori',
		answer: 'Svar',
		dashboardViewOnly: 'Endast översiktsvy',
		add: 'Lägg till',
		awesome: 'Skriv något fantastiskt',
		addNote: 'Skapa anteckning',
		paymentInterval: 'Betalningsintervall',
		monthly: 'Månadsfakturering',
		switchYear: 'Byt till Årsfakturering',
		subscriptionMonth: 'Nya prenumerationer denna månad',
		faqAdded: 'FAQ tillagd',
		switchSuccess: 'Tack, bolaget är nu uppsatt som fakturakund',
		totalAccountant: 'Totalt revisorer',
		connectedCustomer: 'Totalt antal anslutna kunder',
		// totalSubscription : 'Totalt prenumerationer',
		transactionBookkept: 'Totalt bokfört transaktion',
		totalKickback: 'Vunna rabatter',
		subscriptionType: 'Prenumerationstyp',
		saveMoney: 'Totalt besparad arbetstid i pengar',
		totalHours: 'Totalt sparade arbetstimmar',
		appCatalogue: 'Appkatalog',
		overview: 'Översikt',
		categorySelect: 'Välj en kategori',
		chooseCategory: 'Välj kategori',
		genericQuestion: 'Allmänfråga',
		testOrder: 'Skicka ett test verifikat',
		testSend: 'Skicka',
		notFoundText: 'Å nej! Sidan hittas inte',
		backHome: 'Tillbaka till startsidan',
		optionalSetting: 'Valfria inställningar',
		paymentMappingSetting: 'Betalväxlar mappning',
		accountSetting: 'Konto Mappning',
		bambooHRSetting: 'BambooHR Mappning',
		deliverySetting: 'Leveranssätt Mappning',
		more: 'Visa',
		less: 'Dölj',
		at: 'på',
		markRead: 'Markera som läst',
		testing: 'Test',
		bambooCreated: 'Inställningar skapades framgångsrikt',
		bambooDeleted: 'Inställningar har raderats',
		connectShopify: 'Anslut med Shopify',
		connectStripe: 'Anslut med Stripe',
		buyNow: 'Köp nu',
		price: 'Pris',
		notifyRedirect: 'Länk för mer info',
		fieldValue: 'Värdet får ej vara längre än 50 tecken',
		invalidEmail: 'Ogiltig e-mail',
		invitedSuccessful: 'Inbjudan skickad',
		paid: 'Betald',
		unPaid: 'Obetald',
		fileSuccessHead: 'Följande verifikat har inrapporterats',
		testOrderHead: 'En test är nu skickad till ekonomiprogrammet med följande nummer',
		orderTopicSuccess: 'En test order är nu skickat till ekonomiprogrammet med följande nummer',
		paymenetTopicSuccess: 'En test betalrapportering är nu skickat till ekonomiprogrammet för fakturan med följande nummer',
		stateSuccessful: 'framgångsrik',
		noDataSentFortnox: 'Verifikat är redan bokförda i Fortnox för denna fil',
		noDataSentVisma: 'Det gick inte att skicka in uppgifter',
		fileProcessingSent: 'Verifikaten inrapporteras till Fortnox',
		fileProcessingSentVisma: 'Verifikaten inrapporteras till Visma',
		termsAndCondition: 'Tjänstevillkor',
		processingFile: 'Filen bearbetas för inskanning till ekonomiprogrammet, vänligen vänta',
		skuFileProcessing: 'Kontrollerar att alla produkter har SKU nummer, vänligen vänta',
		perfectSku: 'Alla produkter har SKU nummer - toppen! Då aktiveras appen',
		fileResponseSuccess: 'Följande verifikat skapades upp',
		uniqueSkuNumber: 'Produkter som saknar unika SKU nummer',
		fileResponseError: 'Det gick inte att inrapportera verifikaten, se felmeddelande från ekonomiprogrammet',
		testResponseError: 'Verifikatet gick inte att importera, felmeddelande från ekonomiprogrammet',
		viewNotification: 'Visa alla notifikationer',
		skipTour: 'Hoppa över guiden',
		previousTour: 'Föregående',
		nextTour: 'Nästa',
		finishedTour: 'Klart!',
		dashboardTourProfilePicture: 'Klicka här för att logga ut eller ladda upp en profilbild.',
		dashboardTourTranslationButton: 'Klicka här för att byta språk. Du kan välja mellan svenska och engelska.',
		dashboardTourNotification: 'Här hittar du notifikationer som vi skickar ut, så som nyheter eller information om justeringar som behöver göras i era inställningar.',
		dashboardTourMetrics: 'I dessa boxar finner du en sammanfattning av hur många transaktioner era integrationer',
		ruleType: 'Omständighet',
		forCompany: 'Företagskund',
		forShipping: 'Frakt',
		forFeeLines: 'Fakturaavgifter',
		enterCompanyInfo: 'Till kundens vy',
		noteHovering: 'Spara anteckningar',
		deleteHovering: 'Koppla bort bolaget från er byrå',
		warning: 'Varning',
		removeUser: 'Är du säker på att du vill koppla bort användaren',
		removeCompany: 'Är du säker på att du vill koppla bort bolaget',
		successfulDisconnectCompany: 'Bolag bortkopplad',
		appManagement: 'Kontrollpanel',
		order: 'Order',
		customer: 'Kund',
		country: 'Land',
		amount: 'Ordersumma',
		currency: 'Valuta',
		webshop: 'Webbshop',
		quantity: 'Antal',
		cancelling: 'Sparar',
		connectedStripe: 'Ansluten',
		connectedShopify: 'Ansluten',
		shopNameRequire: 'Fyll i butiksnamn',
		support: 'Support',
		sales_account_se: 'Försäljningskonto SE',  //FOR BEST PRACTICES, PLEASE WE DON'T USE UNDERSCORE IN JAVASCRIPT VARIABLES
		sales_account: 'Försäljningskonto',
		sales_account_export: 'Försäljningskonto Export',
		all_fields_are_required: 'Vänligen fyll i alla fält',
		is_required: 'Obligatoriskt fält',
		eu_vat_account: 'Momskonto EU',
		export_vat_account: 'Momskonto Export',
		cancellation_information: 'Information vid uppsägning',
		cancellation_text_one: 'Observera att när prenumerationen cancelleras raderas all data i Automatisera Mera databas inom 7 dagar från uppsägningsdatum.',
		cancellation_text_two: 'Detta innebär att vi inte kommer att kunna hjälpa dig med några supportärenden ' +
			'om dina bokföringsposter den dag då uppsägningen träder i kraft.',
		select_reasons: 'Välj anledning till uppsägning',
		cancel_subscription: 'Avsluta prenumeration',
		comments: 'Kommentarer',
		change_platform: 'Byte av plattform',
		company_closedown: 'Bolaget ska avvecklas',
		not_please_with_connector: 'Inte nöjd med kopplingen',
		reasons: 'Alternativ',
		dataProcessing: 'Bearbetar data',
		examineProgress: 'Analyserar produktdata från webbshoppen',
		unpaid: 'Obetald',
		assign_to: 'Tilldela till',
		company: 'Företag',
		select_company: 'Välj företag',
		select_accountant: 'Välj Revisor',
		searchCompany: 'Sök företag',
		searchAccountant: 'Sök revisor',
		selectRegion: 'Vänligen välj en region',
		authSuccess: 'Auktoriseringen lyckades',
		authFailed: 'Auktorisering misslyckades',
		firmPayout: 'Utbetalningar',
		serial: 'Serie',
		amount_: 'Summa',
		clear: 'Rensa',
		filter: 'Filtrera',
		pending: 'Bearbetas',
		payoutStatus: 'Utbetalningsstatus',
		dateRange: 'Datumintervall',
		done: 'Spara',
		//
		// firmPayout: 'Kickback-utbetalningar',
		// serial: 'Serie',
		// amount_: 'Summa',
		// clear: 'Klar',
		// filter: 'Filtrera',
		// pending: 'I väntan på',
		// payoutStatus: 'Utbetalningsstatus',
		// dateRange: 'Datumintervall',
		// done: 'Gjort',
		// authFailed: 'Auktorisering misslyckades',
		orders: 'Ordrar',
		article: 'Produkter',
		chooseSubscription: 'Välj koppling',
		orderNumber: 'Order #',
		syncStatus: 'Synk Status',
		accounting: 'Nummer i Ekonomiprogram ',
		articleNumber: 'Produktnummer',
		articleName: 'Titel',
		shop: 'Webbshop',
		synced: 'Synkad',
		failed: 'Ej Synkad',
		salesPanel: 'Kontrollpanel',
		otherServices: 'Övriga tjänster',
		otherApps: 'Andra appar från Automatisera Mera',
		optimizeWork: 'Effektivisera ert arbetsflöde med appar som hjälper er digitalisera och jobba smartare',
		readMore: 'Läs Mer',
		prev_: 'Föregående',
		AppSynchronizePage: 'Trigga & se synkhändelser',
		createSync: 'Kör synk',
		app_name: 'Integration',
		sync_type: 'Typ',
		createSyncEntry: 'Välj ordrar, produkter eller utbetalningar och trigga direkt synk',
		appSelectError: 'Ange vilken integration',
		syncDateError: 'Datumet du har angett är tidigare än köpdatumet av integrationen. Vänligen välj ett datum efter ditt köp',
		dateRequired: 'Ange från vilket startdatum synken ska triggas',
		articleNumberError: 'Vänligen ange Artikelnummer',
		orderNumberError: 'Vänligen ange webbutikens Ordernumber',
		entryCreated: 'Posten har synkats',
		generalError: 'Något gick snett',
		triggerSync: 'Trigga Synk',
		message: 'Meddelande',
		success: 'Lyckade Synk',
		all_product: 'Alla produkter',
		all_order: 'Alla ordrar',
		payment: 'Betalning',
		order_specific: 'Beställningsspecifik',
		product_specific: 'Produktspecifik',
		no_date_available: 'Ingen data tillgänglig',
		paymentSync: 'Följande Verifikat är skapade i ekonomiprogrammet',
		articleSync: 'Följande Artiklar är skapade i ekonomiprogrammet',
		orderSync: 'Följande ordrar har synkats till ekonomiprogrammet',
		articleNotSync: 'Följande Artiklar är inte skapade i ekonomiprogrammet',
		orderNotSync: 'Följande ordrar är inte skapade i ekonomiprogrammet',
		paymentNotSync: 'Följande Verifikat är inte skapade i ekonomiprogrammet',
		article_number: 'Artikelnummer',
		order_number: 'Ordernummer',
		sales_panel: 'Försäljningspanel',
		shopifyGuide: 'Läs denna guide om hur du hämtar ut en Shopify API nyckel',
		apiQueries: 'Api-frågor',
		exportJsonFile: 'Exportera Json-fil',
		app: 'App',
		storeIdentifier: 'Butiksidentifierare',
		element: 'Element',
		select: 'Välj',
		elementId: 'Element ID',
		client: 'Klient',
		queryApi: 'Anropa Extern API',
		queryLocalDb: 'Anropa Lokal API',
		appData: 'Applikationsdata',
		accountingData: 'Bokföringsdata',
		cost_center: 'Kostnadsställe',
		costCenterMapping: 'Kostnadsställe Mappning',
		costCenterMappingHelpText: 'Här kan du mappa olika kostnadsställen baserat på köparens land',
		createdSuccessfully: 'Skapat',
		deletedSuccessfully: 'Raderat',
		bankGiroNumber: 'Bankgironummer',
		bank: 'Bank',
		invoiceDate: 'Fakturadatum',
		invoicePayDate: 'Utbetalningsdatum',
		accountFirmPayout: 'Kompensationsöversikt',
		searchDoesNotMatch: 'Ingen post matchar din sökning',
		invoiceNumber: 'Fakturanummer',
		back: 'Tillbaka',
		runSync: 'Kör synk',
		reason: 'Felmeddelande',
		invoice_payment: 'Inbetalningar',
		invoice_accrual: 'Periodiseringar',
		invoice: 'Faktura',
		voucher: 'Verifikat',
		activateSubscription: 'Aktivera prenumeration',
		order_reference: 'Order Referens',
		see_details: 'Se detaljer',
		forDiscount: 'Rabatter',
		forGiftCard: 'Presentkort',
		feeVatAcctDebit: 'Moms på avgifter',
		reverseFeeVatDebitAccount: 'Omvänd Moms på avgifter Debit',
		reverseFeeVatCreditAccount: 'Omvänd Moms på avgifter  Kredit',
		providerFeeAccount: 'Avgifter Betalväxel',
		payoutAccountBank: 'Utbetalningskonto Bank',
		payout: 'Utbetalningskonto',
		receivable_acct: 'Kundfodranskonto',
		vat_acct: 'Momskonto',
		productDeleteError: 'Det gick inte att radera produkten',
		orderDeleteError: 'Det gick inte att radera ordern',
		deleteSuccess: 'Raderat',
		deleteConfirmation: 'Detta kommer radera produkten. Klicka för att radera',
		updateCustomAccount: "Uppdatera konto",
		customAccountUpdated: "Kontoinställningar är uppdaterat",
		description: '',
		createFaqCategory: 'Skapa FAQ Kategori',
		customerFaqAdded: 'Din fråga är mottagen och vår support kommer att kontakta dig via mejl inom kort',
		isHidden: 'Är dold',
		visible_for_customers_only: 'Synlig enbart i kundportal',
		notifyUserForUpdate: 'Avisera kunden om svar',
		costCenterHelpText: 'Välj kostnadsställe koden i ekonomiprogrammet',
		landHelpText: 'Välj vilket kostnadsställe som ska gälla baserat på köparens land',
		accrualAccountIncome: 'Periodiseringskonto Intäkt',
		allAccountFieldRequired: 'Vänligen fyll i alla bokföringskonton',
		feeVatAccount: 'Momskonto för factoringavgifter',
		lastUpdated: 'Senast uppdaterat',
		notSync: 'Ej synkad',
		syncEntryReport: 'Avvikkelserapport',
		genericResource: 'Allmän objekt',
		customAccounts: 'Bokföringskonto',
		periodicTasks: 'Synk Triggers',
		notifications: 'Notifikationer',
		additionalParameter: 'Parameter',
		DocumentNumber: 'Fakturanummer',
		CustomerNumber: 'Kundnummer',
		CustomerName: 'Kundnamn',
		OrderDate: 'Orderdatum',
		Total: 'Saldo',
		TotalToPay: 'Totalt',
		'EmployeeId': 'AnställningsID',
		'PersonalIdentityNumber': 'Personnummer',
		'FirstName': 'Förnamn',
		'LastName': 'Efternamn',
		'FullName': 'Namn',
		'Address1': 'Address 1',
		'Address2': 'Address 2',
		'PostCode': 'Postnummer',
		'City': 'Stad',
		'Country': 'Land',
		'Phone2': 'Telefon 2',
		'Comments': 'Kommentar',
		'ReferenceNumber': 'Referensnummer',
		'ReferenceType': 'Referenstyp',
		'TransactionDate': 'Datum',
		'VoucherNumber': 'V-nummer',
		'VoucherSeries': 'V-serie',
		'Year': 'År',
		'ApprovalState': 'Status',
		'FromDate': 'Från',
		'ToDate': 'Till',
		'AccountingMethod': 'Redovisningsmetod',
		'accountCharts': 'Kontoplan',
		'Name': 'Namn',
		'Phone1': 'Telefon',
		'Email': 'E-post',
		'ZipCode': 'Postnr',
		'Currency': 'Valuta',
		"Number": 'Nummer',
		"InvoiceCustomerName": 'Kund',
		"InvoiceCustomerNumber": 'Kundnummer',
		"InvoiceNumber": 'Fakturanummer',
		"InvoiceTotal": 'Totalt',
		"PaymentDate": 'Betaldatum',
		"ContractDate": 'Avtalsdatum',
		"PeriodStart": 'Periodstart',
		"PeriodEnd": 'Periodslut',
		"ArticleNumber": 'Artikelnummer',
		"Description": 'Titel',
		"SalesPrice": 'Utpris',
		"PurchasePrice": 'Inköpspris',
		"QuantityInStock": 'Lagersaldo',
		"Active": 'Aktiv',
		"DueDate": 'Förfallodatum',
		"InvoiceDate": 'Fakturadatum',
		"OrganisationNumber": 'Org Nummer',
		"company_id": 'Org Nummer',
		"billing_email": 'Faktura e-post',
		'accrualAccountCredit': 'Periodiseringskonto Kredit',
		'accrualAccountDebit': 'Periodiseringskonto Debet',
		invoicePayment: "",
		vouchers: "Verifikat",
		date_updated: 'Senast uppdaterat',
		phone: 'Telefonnummer',
		AccountingType: "Ekonomiprogram",
		appType: "Integration",
		scheduleDate: "Schemalagd datum",
		schedule_date: "Schemalagd datum",
		title: "Titel",
		text: "Text",
		app_type: "Integration",
		addNotification: "Skapa Notifikation",
		notificationSuccess: "Notifikation skapats",
		notificationUpdated: "Notifikation uppdaterat skapats",
		media_file: 'Fil',
		delete_accounts: 'Radera',
		uploadCustomAccount: 'Ladda up fil',
		unprocessedSubscription: "Ej synkade subs", app_settings_link: "App inställning",
		last_processed_date: "Senast synkdatum", username: "Användare",
		userProfile: "Användarprofil",
		companyInformation: "Företagsinformation",
		viewAndEditCompanyInformation: "Redigera företagsinfo",
		last_name: "Förnamn",
		first_name: "Efternamn",
		company_name: "Företagsnamn",
		company_number: "Företagsnummer",
		accounting_app_customer_number: "Kundnummer i ekonomipgrogrammet",
		contract_interval: "Kontraktintervall",
		invoice_email_address: "Faktura adress",
		payment_method: "Betalmetod",
		date_joined: "Anslutningsdatum",
		sent_confirmation_date: "Bekräftelsedatum",
		date_activated: "Aktiveringsdatum",
		partner_accountants: "Byråpartner ekonom",
		accounting_firm_connection_end_date: "Byråpartner av anslutningsdatum",
		accounting_firm_connection_start_date: "Byråpartner anslutningsdatum",
		assisting_accountants: "Assisterande ekonomer",
		assisting_colleagues: "Länkade kollegor",
		email_verified: "Är mejlverifierad",
		is_accountant: "Är Ekonom",
		is_staff: " Är Personal",
		is_active: "Är Aktiv",
		phone_office: "Telefon",
		phone_mobile: "Mobilnummer",
		zip_code: "Postnummer",
		no: "Nej",
		syncEntrySuccess: "Synken är igångsatt, vänligen notera att det kan ta en till flera minuter innan det är slutfört beroende på transaktionsvolymen",
		transaction_date: "Transaktionsdatum",
		noData: 'Ingen data tillgänglig',
		colleague: 'Kollega',
		non_partner_accountant: 'Redovisningskonsult',
		partner_accountant: 'Byråpartnerkonsult',
		master_accountant: 'Chef Byråpartner',
		inhouse_user: 'Support',
		active: 'Aktiv',
		inactive: 'Inaktiv',
		cancelled: 'Cancellerad',
		remove: 'Radera',
		paused: 'Pausad',
		dragAndDropCsvfile: 'Släpp filen här',
		deleteAccounts: 'Radera konton',
		uploadFile: 'Ladda up fil',
		generate: 'Skapa',
		code: 'Felkod',
		ambiguous: 'Oklart',
		updateFrequent: 'Uppdatera FAQ',
		single_order: 'Hämta Order per OrderID',
		single_article: 'Hämta Artikel per Artikelnummer',
		single_invoice: 'Hämta Faktura per Fakturanummer',
		singe_contract: ' Hämta Avtal per Avtalsnummer',
		contract: 'Avtal',
		single_customer: 'Hämta Kund per Kundnummer',
		single_customer_by_name: 'Hämta Order per Namn',
		financial_years: 'Räkenskapsår',
		employee: 'Anställd',
		single_employee: 'Hämta Anställd Per ID',
		notes: 'Anteckingar',
		'number_in_accounting': 'Nummer i ekono',
		'number_in_app': 'Nummer i app',
		'date_created': 'Datum skapad',
		total_price: 'Total Pris',
		vat_included: 'Moms inkluderat',
		customer_type: 'Kundtyp',
		stock_quantity: 'Lagersaldo',
		amount_paid_in_sek: 'Totalt i SEK',
		accounting_invoice_number: 'Fakturanummer',
		payout_id: 'UtbetalningsID',
		payment_date: 'Utbetalningsdatum',
		start_date: 'Startdatum',
		end_date: 'slutdatum',
		total_amount_customer_currency: 'Totalt is SEK',
		total_amount_in_sek: 'Totalt is SEK',
		terms: 'Villkor',
		order_date: 'Orderdatum',
		order_country: 'Land',
		series: 'V-Serie',
		original_transaction_date: 'Transaktionsdatum',
		fiscal_year: 'Räkenskapsår',
		customer_email: 'Kund e-post',
		customer_name: 'Kund namn',
		optionalParams: 'Valfria Parametrar',
		addParams: 'Lägg till Parameter',
		momskonto: "Default kontot som ska användas för momsen. Om ni vill särskilja och ha ett specifikt momskonto för ett specifikt land kan du lägga till det i kontomappningen nedan",
		kundfodranskonto: "Default kontot som ska användas för kundfodran. Om ni vill särskilja och ha ett specifikt kundfodranskonto för ett specifikt land kan du lägga till det i kontoinställningarna nedan",
		forsaljningskonto: "Default kontot som ska användas för intäkter inom SE. Om ni vill särskilja och ha ett specifikt kundforanskonto för ett specifikt land kan du lägga till det i kontomappningen nedan. Du bör också lägga till intäktskonto för EU och Export i kontomappningen",
		utbetalningskonto: "Default kontot som ska användas för netto utbetalt belopp.",
		avgifter_betalvaxel: "Default kontot som ska användas för betalväxelns avgifter.",
		omvand_moms_pa_avgifter_debit: "Momskonto för omvänd moms debet på betalväxelns avgifter.",
		omvand_moms_pa_avgifter_kredit: "Momskonto för omvänd moms kredit på betalväxelns avgifter"

	},
}

const i18n = new VueI18n({
	locale: 'se', // set locale
	fallbackLocale: 'en', // set fallback locale
	messages, // set locale messages
})

export default i18n
