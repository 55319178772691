
export default {
    UPDATE_COLLEAGUE_COMPANY (state, payload) {
        state.colleagueCompany = payload
    },
    UPDATE_COLLEAGUE (state, payload) {
        state.isColleague = payload
    },
    REMOVE_COLLEAGUE (state, payload) {
        state.isColleague = null
    }
}
