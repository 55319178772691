<template>
	<div class="app-sidebar sidebar-shadow sidebar-bg-custom">
		<div class="app-header__logo">
			<div class="logo-srcs"></div>
			<div class="header__pane ml-auto">
				<div>
					<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
							data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
					</button>
				</div>
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
		</div>
		<div class="scrollbar-sidebar">
			<div class="app-sidebar__inner">
				<ul class="vertical-nav-menu">
					<li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">Menu</li>
					<li class="mm-active menu-link">
						<router-link :to="{ name : 'regulardash' }">
							<i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }}
						</router-link>
					</li>
					<!-- <li class="menu-link">
						<a>s
							<i class="metismenu-icon iconly-brokenUser1"></i>{{ $t('firm') }}
						</a>
					</li>
					<li class="menu-link">
						<a>
							<i class="metismenu-icon iconly-brokenUser"></i> {{ $t('comp') }}
						</a>
					</li> -->
					<li class="menu-link">
						<router-link :to="{ name : 'reglogs' }">
							<i class="metismenu-icon iconly-brokenPaper"></i> {{ $t('appLogs') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'api-queries' }" class="position-relative">
							<i class="metismenu-icon api-queries-icon">
								<svg width="16" height="18" viewBox="0 0 18 18" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path
										d="M14.0586 14.0889C14.4065 13.7433 14.9641 13.7433 15.312 14.0889L17.6112 15.9448H17.6511C18.1163 16.4149 18.1163 17.1772 17.6511 17.6474C17.186 18.1175 16.4318 18.1175 15.9667 17.6474L14.0586 15.4606L13.9862 15.3789C13.8514 15.2082 13.7769 14.9954 13.7769 14.7747C13.7769 14.5173 13.8783 14.2705 14.0586 14.0889ZM7.71988 0C9.76731 0 11.7309 0.822093 13.1787 2.28543C14.6264 3.74876 15.4398 5.73347 15.4398 7.80293C15.4398 12.1124 11.9834 15.6059 7.71988 15.6059C3.45631 15.6059 0 12.1124 0 7.80293C0 3.49349 3.45631 0 7.71988 0Z"
										fill="#F5F5F5"/>
								</svg>
							</i> {{ $t('apiQueries') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'user-management' }" class="position-relative">
							<i class="metismenu-icon fa fa-user"></i> {{ $t('users') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'periodic-tasks' }" class="position-relative">
							<i class="metismenu-icon fa fa-sync">
							</i> {{ $t('periodicTasks') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'admin-unprocessed_subscription' }" class="position-relative">
							<i class="metismenu-icon fa fa-address-book">
							</i> {{ $t('unprocessedSubscription') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'accounts' }" class="position-relative">
							<i class="metismenu-icon fa fa-balance-scale">
							</i> {{ $t('customAccounts') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'generic-resource' }" class="position-relative">
							<i class="metismenu-icon fa fa-list-alt">
							</i> {{ $t('genericResource') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'admin-sync-report' }" class="position-relative">
							<i class="metismenu-icon fa fa-sync-alt">
							</i> {{ $t('syncEntryReport') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'admin-notifications' }" class="position-relative">
							<i class="metismenu-icon fa fa-bell">
							</i> {{ $t('notifications') }}
						</router-link>
					</li>
					<li class="menu-link" data-cy='faq'>
						<router-link :to="{ name : 'regfaq' }">
							<i class="metismenu-icon iconly-brokenChat"></i> FAQ
						</router-link>
					</li>
					<!-- <li>
						<router-link :to="{ name : 'billings' }">
							<i class="metismenu-icon pe-7s-cash"></i> Billings
						</router-link>
					</li>
					<li class="app-sidebar__heading">Logs</li>
					<li>
						<router-link :to="{ name : 'applogs' }">
							<i class="metismenu-icon pe-7s-note2"></i> App Logs
						</router-link>
					</li> -->
					<!-- <li class="app-sidebar__heading">Help</li>
					<li>
						<router-link :to="{ name : 'faq' }">
							<i class="metismenu-icon pe-7s-help1"></i> FAQ
						</router-link>
					</li>
					<li v-if="GET_USER_UTIL === 'true' || (userObject !== null && userObject.company !== null)">
						<a href="javascript:void(0);" @click="$store.commit('OPEN_INVITE_MODAL')">
							<i class="metismenu-icon pe-7s-add-user"></i> Invite Users
						</a>
					</li>
					<li v-if="GET_USER_UTIL === 'true'">
						<a :href="baseURL+'/accountant'">
							<i class="metismenu-icon lnr-exit"></i> Exit User View
						</a>
					</li> -->
					<!-- <li>
						<a href="#">
							<i class="metismenu-icon pe-7s-car"></i> Components
							<i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
						</a>
						<ul>
							<li>
								<a href="components-tabs.html" >
									<i class="metismenu-icon"></i>Tabs
								</a>
							</li>
							<li>
								<a href="components-accordions.html" >
									<i class="metismenu-icon"></i>Accordions
								</a>
							</li>
							<li>
								<a href="components-notifications.html" >
									<i class="metismenu-icon"></i>Notifications
								</a>
							</li>
							<li>
								<a href="components-modals.html" >
									<i class="metismenu-icon"></i>Modals
								</a>
							</li>
							<li>
								<a href="components-loading-blocks.html" >
									<i class="metismenu-icon"></i>Loading Blockers
								</a>
							</li>
							<li>
								<a href="components-progress-bar.html" >
									<i class="metismenu-icon"></i>Progress Bar
								</a>
							</li>
							<li>
								<a href="components-tooltips-popovers.html" >
									<i class="metismenu-icon"> </i>Tooltips &amp; Popovers
								</a>
							</li>
							<li>
								<a href="components-carousel.html" >
									<i class="metismenu-icon"></i>Carousel
								</a>
							</li>
							<li>
								<a href="components-calendar.html" >
									<i class="metismenu-icon"></i>Calendar
								</a>
							</li>
							<li>
								<a href="components-pagination.html" >
									<i class="metismenu-icon"></i>Pagination
								</a>
							</li>
							<li>
								<a href="components-count-up.html" >
									<i class="metismenu-icon"></i>Count Up
								</a>
							</li>
							<li>
								<a href="components-scrollable-elements.html" >
									<i class="metismenu-icon"></i>Scrollable
								</a>
							</li>
							<li>
								<a href="components-tree-view.html" >
									<i class="metismenu-icon"></i>Tree View
								</a>
							</li>
							<li>
								<a href="components-maps.html" >
									<i class="metismenu-icon"></i>Maps
								</a>
							</li>
							<li>
								<a href="components-ratings.html" >
									<i class="metismenu-icon"></i>Ratings
								</a>
							</li>
							<li>
								<a href="components-image-crop.html" >
									<i class="metismenu-icon"></i>Image Crop
								</a>
							</li>
							<li>
								<a href="components-guided-tours.html" >
									<i class="metismenu-icon"></i>Guided Tours
								</a>
							</li>
						</ul>
					</li>
					<li   >
						<a href="#">
							<i class="metismenu-icon pe-7s-display2"></i> Tables
							<i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
						</a>
						<ul   >
							<li>
								<a href="tables-data-tables.html" >
									<i class="metismenu-icon"> </i>Data Tables
								</a>
							</li>
							<li>
								<a href="tables-regular.html" >
									<i class="metismenu-icon"></i>Regular Tables
								</a>
							</li>
							<li>
								<a href="tables-grid.html" >
									<i class="metismenu-icon"></i>Grid Tables
								</a>
							</li>
						</ul>
					</li>
					<li class="app-sidebar__heading">Dashboard Widgets</li>
					<li>
						<a href="widgets-chart-boxes.html" >
							<i class="metismenu-icon pe-7s-graph"></i>Chart Boxes 1
						</a>
					</li>
					<li>
						<a href="widgets-chart-boxes-2.html" >
							<i class="metismenu-icon pe-7s-way"></i>Chart Boxes 2
						</a>
					</li>
					<li>
						<a href="widgets-chart-boxes-3.html" >
							<i class="metismenu-icon pe-7s-ball"></i>Chart Boxes 3
						</a>
					</li>
					<li>
						<a href="widgets-profile-boxes.html" >
							<i class="metismenu-icon pe-7s-id"></i>Profile Boxes
						</a>
					</li>
					<li class="app-sidebar__heading">Forms</li>
					<li
							>
						<a href="#">
							<i class="metismenu-icon pe-7s-light"></i> Elements
							<i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
						</a>
						<ul
								>
							<li>
								<a href="forms-controls.html" >
									<i class="metismenu-icon"></i>Controls
								</a>
							</li>
							<li>
								<a href="forms-layouts.html" >
									<i class="metismenu-icon"></i>Layouts
								</a>
							</li>
							<li>
								<a href="forms-validation.html" >
									<i class="metismenu-icon"></i>Validation
								</a>
							</li>
							<li>
								<a href="forms-wizard.html" >
									<i class="metismenu-icon"></i>Wizard
								</a>
							</li>
						</ul>
					</li>
					<li

							>
						<a href="#">
							<i class="metismenu-icon pe-7s-joy"></i> Widgets
							<i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
						</a>
						<ul

								>
							<li>
								<a href="forms-datepicker.html" >
									<i class="metismenu-icon"></i>Datepicker
								</a>
							</li>
							<li>
								<a href="forms-range-slider.html" >
									<i class="metismenu-icon"></i>Range Slider
								</a>
							</li>
							<li>
								<a href="forms-input-selects.html" >
									<i class="metismenu-icon"></i>Input Selects
								</a>
							</li>
							<li>
								<a href="forms-toggle-switch.html" >
									<i class="metismenu-icon"></i>Toggle Switch
								</a>
							</li>
							<li>
								<a href="forms-wysiwyg-editor.html" >
									<i class="metismenu-icon"></i>WYSIWYG Editor
								</a>
							</li>
							<li>
								<a href="forms-input-mask.html" >
									<i class="metismenu-icon"></i>Input Mask
								</a>
							</li>
							<li>
								<a href="forms-clipboard.html" >
									<i class="metismenu-icon"></i>Clipboard
								</a>
							</li>
							<li>
								<a href="forms-textarea-autosize.html" >
									<i class="metismenu-icon"></i>Textarea Autosize
								</a>
							</li>
						</ul>
					</li>
					<li class="app-sidebar__heading">Charts</li>
					<li>
						<a href="charts-chartjs.html" >
							<i class="metismenu-icon pe-7s-graph2"></i>ChartJS
						</a>
					</li>
					<li>
						<a href="charts-apexcharts.html" >
							<i class="metismenu-icon pe-7s-graph"></i>Apex Charts
						</a>
					</li>
					<li>
						<a href="charts-sparklines.html" >
							<i class="metismenu-icon pe-7s-graph1"></i>Chart Sparklines
						</a>
					</li> -->
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	name: 'sidebar-dash',
	data() {
		return {
			baseURL: window.location.origin,
			userSubscription: [],
			userObject: null,
			showSubscription: false
		}
	},
	watch: {
		// $route (to, from) {
		//     // console.log('to::', to.path)
		//     // console.log('from::', from.path)
		//     if (to.path !== from.path) {
		//         this.showSubscription = false
		//         document.getElementById('sub_id').classList.remove('mm-show')
		//     }
		// }
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_USER_UTIL: 'accountant/GET_USER_UTIL'
		})
	},
	mounted() {
	},
	methods: {}
}
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

.app-sidebar .app-sidebar__inner {
	padding: unset !important;
}

.sidebar-bg-custom {
	background: $sidebar-bg-color !important;
	border-right: 1px solid #344258;
}

.app-sidebar__heading {
	color: #ffffff !important;
	font-size: 0.6rem;
}

.vertical-nav-menu li.mm-active > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	font-weight: unset !important;
}

.vertical-nav-menu li.menu-link:hover {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
}

.vertical-nav-menu li a {
	color: #bfc3ce !important;
	font-size: 0.8rem;

}

.vertical-nav-menu ul > li > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	transition: all .3s ease;
}

.vertical-nav-menu li a:hover:not(.router-link-exact-active) {
	background: unset !important;
	text-decoration: none;
	margin-left: 13px;

	i.metismenu-state-icon {
		margin-left: 10px;
		position: sticky;
	}
}

.vertical-nav-menu i.metismenu-icon {
	opacity: unset !important;
}

.vertical-nav-menu ul {
	transition: padding 300ms;
	padding: .5em 0 0 0.6rem !important;
}

.router-link-exact-active {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
}

.api-queries-icon {
	position: absolute !important;
	top: 25px !important;
	left: 15px !important;
}

// .subscription-section li .router-link-exact-active{
//     color: #bfc3ce !important;
//     font-size: 0.8rem;
//     font-weight: bold;
// }
</style>
