import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './../../store'
import {getRefreshToken, getToken} from './../services-jwt'

Vue.use(VueAxios, axios)

const http = Vue.axios.create({
	baseURL: process.env.VUE_APP_BASE_URL_EXTERNAL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})

http.interceptors.request.use(
	// config => {
	//     config.headers['Access-Control-Max-Age'] = 86400
	//     return config
	// },
	function (req) {
		const token = `${getToken()}`
		if (token) {
			req.headers.common.Authorization = `Bearer ${token}`
		}
		// console.clear()
		return req
	},
	function (err) {
		return Promise.reject(err)
	}
)
http.interceptors.response.use(
	response => {
		return response
	},
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 400) {
			if (error.response.data.code === 401) {
				window.Bus.$emit('sign-out')
				// if (getRefreshToken()) {
				// 	store.dispatch('auth/refreshUserToken', getRefreshToken()).then(resp => {
				// 		http.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`
				// 		return http(originalRequest)
				// 	}).catch(() => {
				// 		store.commit('auth/CLEAR_AUTH_USER', null)
				// 		window.Bus.$emit('sign-out')
				// 	})
				// } else {
				// 	store.commit('auth/CLEAR_AUTH_USER', null)
				// 	window.Bus.$emit('sign-out')
				// }
			}
		}
		return Promise.reject(error)
	}
)

export default http

