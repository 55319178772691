import HTTP from './../../../../services/vue-axios/axios'
import endpoints from './../../../../services/endpoint'
import TYPES from './../types'
import DownloadExcel from "../../../../services/vue-axios/download_excel";

export default {
	async getHouseMetrics({commit}, _) {
		await HTTP.get(endpoints.GET_IN_HOUSE_METRICS)
			.then(res => {
				// console.log('house metrics:', res)
				commit(TYPES.UPDATE_HOUSE_METRICS, res.data.data)
			})
			.catch(err => {
				if (err.response.data.code === 401) {
					commit('auth/CLEAR_AUTH_USER', null, {root: true})
				}
			})
	},
	async getHouseGraphMetrics({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_METRICS_GRAPH)
				.then(res => {
					commit(TYPES.UPDATE_HOUSE_GRAPH_METRICS, res.data.data)
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAllFirms({commit}, payload) {
		return new Promise((resolve, reject) => {
			const URL = payload.page ? endpoints.IN_HOUSE_FIRMS + `?page=${payload.page}` : endpoints.IN_HOUSE_FIRMS
			HTTP.get(URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_ALL_FIRM, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async searchAccountingFirm({commit}, payload) {
		return new Promise((resolve, reject) => {
			const URL = endpoints.IN_HOUSE_SEARCH_FIRMS + `?search=${payload.value}`
			HTTP.get(URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_SEARCH_FIRM, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAllCompanies({commit}, payload) {
		return new Promise((resolve, reject) => {
			const URL = payload.page ? endpoints.IN_HOUSE_COMPANIES + `?page=${payload.page}` : endpoints.IN_HOUSE_COMPANIES
			HTTP.get(URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_ALL_COMPANY, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async searchCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			const URL = payload.num ? endpoints.IN_HOUSE_COMPANIES + `?company_id=${payload.value}` : endpoints.IN_HOUSE_COMPANIES + `?search=${payload.value}`
			HTTP.get(URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_SEARCH_COMPANY, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async addFaqData({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(endpoints.CREATING_FAQ, payload)
				.then(res => {
					if (res.status === 201) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async updateFaqData({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(endpoints.UPDATE_FAQ + `${payload.id}/`, payload.data)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAccountingList({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_ACCOUNTING_TYPE)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSubscriptionTypes({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_SUBSCRIPTIONS_TYPE)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async addFaqCategoryData({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(endpoints.GET_FAQ_CATEGORY_LIST + 'create/', payload)
				.then(res => {
					if (res.status === 201) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getFaqList({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_FAQ_LIST)
				.then(res => {
					if (res.status === 200) {

						commit('UPDATE_FAQ_DATA', res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getFaqCategoryList({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_FAQ_CATEGORY_LIST)
				.then(res => {
					if (res.status === 200) {
						commit('UPDATE_FAQ_CATEGORY', res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getConnectedCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_CONNECTED_COM, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async uploadEditorImage({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(endpoints.UPLOAD_FAQ_IMAGE, payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async assignAccountant({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(endpoints.IN_HOUSE_REASSIGNING, payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAccountantSpecificCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						commit(TYPES.UPDATE_ACCOUNTANT_COMPANY, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getFirmInformation({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FIRM, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getFirmAccountants({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FIRM_ACCOUNTANT, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async updateFirmInfo({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.data)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async removeAccount({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAllSubscription({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`${endpoints.GET_SUBSCRIPTIONS}?${payload.data}`)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customGet({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSubscriptionList({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					commit(TYPES.SUBSCRIPTION_ALL, res.data.data)
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customDeleteRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customPostRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.data)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customPutRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.data)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customGetRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async exportSyncReport({commit}, payload) {
		return new Promise((resolve, reject) => {
			DownloadExcel.get(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
}
