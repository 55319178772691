<template>
	<div class="app-sidebar sidebar-shadow sidebar-bg-custom"
		 :class="{'prevent-click': $route.name === 'colleague_settings'}">
		<div class="app-header__logo">
			<div class="logo-srcs"></div>
			<div class="header__pane ml-auto">
				<div>
					<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
							data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
					</button>
				</div>
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
		</div>
		<div class="scrollbar-sidebar" id="top-side-to-scroll" data-cy='top-side-to-scroll'>
			<div class="app-sidebar__inner mt-10">
				<ul class="vertical-nav-menu overflow-x-hidden mt-20">
					<!-- <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">Menu</li> -->
					<li class="menu-link mm-active">
						<router-link :to="{ name : 'dashboard' }">
							<i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }}
						</router-link>
					</li>
					<li class="menu-link">
						<a data-cy='open-subscription' href="javascript:void(0);" @click="openSubscription">
							<i class="metismenu-icon iconly-brokenSetting"></i> {{ $t('settings') }}
							<i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
						</a>
						<ul id="sub_id"
							class="subscription-section font-light overflow-y-scroll overflow-x-hidden h-60 mm-collapse"
							:class="showSubscription ? 'mm-show' : ''"> <!--override-height-->
							<li v-for="(sub, i) in GET_SUBSCRIPTION.all_subscriptions" :key="i">
<!--								<router-link class="subscription-link" :data-cy="'subscription-'+sub.app_type"-->
<!--											 v-on:click="{ name : 'appsettings', query: { key : sub.id, type : sub.app_type, acc : sub.accounting_type }, params : { type : sub.app_type, acc : sub.accounting_type } }"-->
<!--											 :to="{ name : 'appsettings', query: { key : sub.id, type : sub.app_type, acc : sub.accounting_type }, params : { type : sub.app_type, acc : sub.accounting_type } }">-->
<!--									<i class="metismenu-icon"></i>-->
<!--									{{ sub.app_type_name + '/' + sub.accounting_name | removeUnderscores }}-->
<!--								</router-link>-->
																<a href="javascript:void(0)" class="subscription-link"
																   :data-cy="'subscription-'+sub.app_type"
																   v-on:click="openUrl({ name : 'appsettings', query: { key : sub.id, type : sub.app_type, acc : sub.accounting_type }, params : { type : sub.app_type, acc : sub.accounting_type } })">
																	<i class="metismenu-icon"></i>
																	{{ sub.app_type_name + '/' + sub.accounting_name | removeUnderscores }}
																</a>
							</li>
						</ul>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'appreport' }"
									 :class="{'router-link-exact-active router-link-active': appRouteNameValidate($route.name) === true}">
							<i class="metismenu-icon iconly-brokenDocument"></i> {{ $t('report') }}
						</router-link>
					</li>
					<li class="menu-link v-step-5 v-app-8" data-cy='add-app'>
						<router-link :to="{ name : 'addapps' }">
							<i class="metismenu-icon iconly-brokenPlus"></i>{{ $t('addApp') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'sales' }">
							<i class="metismenu-icon iconly-brokenDocument"></i> {{ $t('salesPanel') }}
						</router-link>
					</li>
					<!-- <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('transactions') }}</li> -->
					<li class="menu-link v-addapp-4" data-cy='billing'>
						<router-link :to="{ name : 'billings' }">
							<i class="metismenu-icon iconly-brokenBag-2"></i> {{ $t('billing') }}
						</router-link>
					</li>
					<!-- <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('logs') }}</li> -->
					<!-- <li class="menu-link">
						<router-link :to="{ name : 'applogs' }">
							<i class="metismenu-icon iconly-brokenDocument"></i> {{ $t('appLogs') }}
						</router-link>
					</li> -->
					<!--                   <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7" v-if="IS_COLLEAGUE === true">{{ GET_USER.dashboard_type }}</li>-->
					<li class="menu-link v-bill-4" data-cy='faq'>
						<router-link :to="{ name : 'faq' }">
							<i class="metismenu-icon iconly-brokenChat"></i> FAQ
						</router-link>
					</li>
					<li class="menu-link v-faq-2"
						v-if="GET_USER_UTIL === 'true' || (GET_USER !== null && GET_USER.company !== null)">
						<a href="javascript:void(0);" @click="$store.commit('utility/OPEN_INVITE_MODAL')">
							<i class="metismenu-icon iconly-brokenAdd-User"></i> {{ $t('inviteUsers') }}
						</a>
					</li>
					<li class="menu-link v-addapp-4">
						<router-link :to="{ name : 'terms' }">
							<i class="metismenu-icon iconly-brokenBag-2"></i> {{ $t('termsAndCondition') }}
						</router-link>
					</li>
					<!-- <li class="menu-link v-bill-4" data-cy='support'>
						<router-link :to="{ name : 'support' }">
							<i class="metismenu-icon iconly-brokenChat"></i> {{ $t('support') }}
						</router-link>
					</li> -->
					<li class="menu-link" v-if="GET_USER_UTIL === 'true'">
						<a :href="baseURL+'/accountant'">
							<i class="metismenu-icon iconly-brokenLogout"></i> {{ $t('exitUser') }}
						</a>
					</li>
					<!-- <li class="menu-link" v-if="GET_IN_HOUSE_UTIL === 'true'">
						<a :href="baseURL+'/regular'">
							<i class="metismenu-icon iconly-brokenLogout"></i> {{ $t('exitUser') }}
						</a>
					</li> -->
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ORDERS_AND_PRODUCTS} from '../app/index'

export default {
	name: 'sidebar-dash',
	data() {
		return {
			baseURL: window.location.origin,
			showSalesPanel: false,
			userSubscription: [],
			userObject: null,
			showSubscription: false,
			appManagementPath: ['ordermanagement', 'productmanagement'],
			is_staff: 0
		}
	},
	watch: {
		$route(to, from) {
			// console.log('to::', to.path)
			// console.log('from::', from.path)
			if (to.path !== from.path) {
				this.showSubscription = false
				document.getElementById('sub_id').classList.remove('mm-show')
				document.getElementById('top-side-to-scroll').classList.remove('overflow-y-scroll')
			}
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_USER_UTIL: 'accountant/GET_USER_UTIL',
			GET_IN_HOUSE_UTIL: 'house/GET_IN_HOUSE_UTIL',
			GET_USER: 'customer/GET_USER',
			IS_COLLEAGUE: 'customer/GET_IS_COLLEAGUE',
		})
	},
	mounted() {
		this.getAuthUser()
		// Implement two-way fetching
	},
	methods: {
		openUrl(path) {
			this.$router.push(path)
			// const routerName = this.$router.resolve(path)
			// window.location.href = routerName.href
		},
		openSubscription() {
			this.showSubscription = !this.showSubscription
			if (this.showSubscription) {
				document.getElementById('sub_id').classList.add('mm-show')
				// document.getElementById('sub_id').classList.add('override-height')
				// document.getElementById('top-side-to-scroll').classList.add('overflow-y-scroll')
			} else {
				document.getElementById('sub_id').classList.remove('mm-show')
				// document.getElementById('top-side-to-scroll').classList.remove('overflow-y-scroll')
			}
		},
		appRouteNameValidate: function (routeName) {
			if (this.appManagementPath.includes(routeName)) {
				return true
			} else {
				return false
			}
		},
		getAuthUser() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_USER)
			this.$store.dispatch('customer/getAuthUser', {URL: this.$services.endpoints.GET_USER})
				.then(res => {
					if (res.data.data.user.is_staff === true) {
						this.is_staff = 1
					} else {
						this.is_staff = 0
					}

					this.loadingCompany = false

					// this.userObject = res.data.data.user
					// if (res.data.success) {
					// }
				}).catch(err => {
				this.loadingCompany = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAuthUser()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},

	}
}
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

.app-sidebar .app-sidebar__inner {
	padding: unset !important;
}

.prevent-click {
	pointer-events: none;
}

.sidebar-bg-custom {
	background: $sidebar-bg-color !important;
	border-right: 1px solid #344258;
}

.app-sidebar__heading {
	// color: #ffffff !important;
	// font-size: 0.6rem;
}

.vertical-nav-menu li.mm-active > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	font-weight: unset !important;
}

.vertical-nav-menu li a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
}

.vertical-nav-menu li.menu-link:hover {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
}

.vertical-nav-menu ul > li > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	transition: all .3s ease;
}

.vertical-nav-menu li a:hover:not(.router-link-exact-active) {
	background: unset !important;
	text-decoration: none;
	margin-left: 13px;

	i.metismenu-state-icon {
		margin-left: 10px;
		position: sticky;
	}
}

.vertical-nav-menu i.metismenu-icon {
	opacity: unset !important;
}

.vertical-nav-menu ul {
	transition: padding 300ms;
	padding: .5em 0 0 0.6rem !important;
}

.router-link-exact-active {
	// border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
	left: 11px;
}

// .subscription-section li .router-link-exact-active{
//     color: #bfc3ce !important;
//     font-size: 0.8rem;
//     font-weight: bold;
// }
</style>
