import bus from './../../bus'

export default {
    notification (message, status, _) {
        if (status === 'success') {
            bus.$emit('notify-me', {
                permanent: false,
                status: 'is-success shadow-lg rounded',
                data: {
                    id : uid('d'),
                    title: '',
                    text: message
                }
            })
        } else if (status === 'error') {
            bus.$emit('notify-me', {
                permanent: false,
                status: 'is-danger shadow-lg rounded',
                data: {
                    id : uid('d'),
                    title: '',
                    text: message
                }
            })
        } else {
            bus.$emit('notify-me', {
                permanent: false,
                status: 'is-info shadow-lg rounded',
                data: {
                    id : uid('d'),
                    title: '',
                    text: message
                }
            })
        }
    },
    validateEmailAddress (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    },
    // oldNotification (message, status, self) {
    //     if (status === 'success') {
    //         self.$notify({ group: 'top', title: 'Success', text: `${message}` }, 3900)
    //     } else if (status === 'error') {
    //         self.$notify({ group: 'top', title: 'Error', text: `${message}` }, 3900)
    //     } else if (status === 'progress') {
    //         self.$notify({ group: 'top', title: 'Processing', text: `${message}` }, 3900)
    //     } else {
    //         self.$notify({ group: 'top', title: 'Warning', text: `${message}` }, 3900)
    //     }
    // },
    capitalizeString  (name) {
        return name !== undefined && name !== null ? name.split(' ').map(v => typeof v !== 'string' ? v + ' ' : v.charAt(0).toUpperCase() + v.toLowerCase().slice(1) + ' ').join('').trim() : ''
    },
    removeUnderscores (name) {
        const newName = name.replace('_', ' ')
        return this.capitalizeEachString(newName)
    },
    animateValue : (obj, start, end, duration) => {
        // console.log('obj:', document.getElementById(obj))
        if (document.getElementById(obj) !== null) {
            let startTimestamp = null
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp
                const progress = Math.min((timestamp - startTimestamp) / duration, 1)
                if (document.getElementById(obj)) {
                    document.getElementById(obj).innerHTML = Math.floor(progress * (end - start) + start)
                    if (progress < 1) {
                        window.requestAnimationFrame(step)
                    }
                }
            }
            window.requestAnimationFrame(step)
        }
    },
    allCurrencies () {
        return [
            'EUR', 'GBP', 'AUD', 'NZD', 'USD', 'CAD', 'CHF',
            'NOK', 'SEK', 'DKK', 'JPY', 'CNY', 'KRW', 'SGD', 'IDR', 'THB',
            'TWD', 'HKD', 'PHP', 'INR', 'MYR', 'VND', 'BRL', 'PEN', 'ARS',
            'MXN', 'CLP', 'COP', 'JMD', 'TTD', 'BMD', 'CZK', 'PLN', 'TRY',
            'HUF', 'RON', 'RUB', 'HRK', 'KZT', 'BGN', 'ILS', 'AED', 'QAR',
            'SAR', 'EGP', 'NGN', 'ZAR', 'XBT'
        ]
    },
	getDedicatedServer (server) {
		const dedicatedServers = {
		pro: 'https://pro.automatiseramera.se/api/v1/',
		standard: 'https://integration.automatiseramera.se/api/v1/',
		growth: 'https://pro.automatiseramera.se/api/v1/',
		enterprise: 'https://enterprise.automatiseramera.se/api/v1/',
	   }
		return dedicatedServers[server]
  }
}

function uid (e) {
    e = {}
    if (e.uid) return e.uid
    const key = Math.random()
        .toString(16)
        .slice(2)
    e.uid = key
    return e.uid
}
