import {getIsAccountant, getIsMasterAccountant} from './../../../../services/services-jwt'

export default {
	dashboardMetrics: {
		affiliate_type: null,
		total_accountants: 0,
		total_customers: 0,
		total_hours_saved: 0,
		total_kickbacks_and_discounts: null,
		total_money_saved: 0,
		total_subscriptions: 0,
		total_transactions: 0,
	},
	usersCompany: [],
	copyAllCompanies: [],
	firmCompany: [],
	firmCompanies: [],
	firmAccountants: [],
	accountantAssistanceCompany: {},
	userSpecificCompany: {},
	userSpecificCompanyCopy: {},
	utility: getIsAccountant() || null,
	masterAccountant: getIsMasterAccountant() || null,
	firmPayout: {
		count: 0,
		next: null,
		previous: null,
		results: []
	},
	firmPayoutCopy: {
		count: 0,
		next: null,
		previous: null,
		results: []
	},
	firmPayoutFilter: []
}
