import {getIsAccountant, getIsMasterAccountant} from './../../../../services/services-jwt'

export default {
	dashboardMetrics: {
		affiliate_type: null,
		total_accountants: 0,
		total_customers: 0,
		total_hours_saved: 0,
		total_kickbacks_and_discounts: null,
		total_money_saved: 0,
		total_subscriptions: 0,
		total_transactions: 0,
	},
	usersCompany: [],
	copyAllCompanies: [],
	firmCompany: [],
	firmCompanies: [],
	firmAccountants: [],
	accountantAssistanceCompany: {},
	userSpecificCompany: {},
	userSpecificCompanyCopy: {},
	// token : window.localStorage.getItem('amdjango') || null,
	// userCompanyID : window.sessionStorage.getItem('xmdjango') || null,
	// userFirmID : window.sessionStorage.getItem('xfirm') || null,
	// refreshToken : window.localStorage.getItem('amre') || null,
	// setLocale : window.localStorage.getItem('xlocale') || 'se',
	utility: getIsAccountant() || null,
    masterAccountant : getIsMasterAccountant() || null,
	// regularHouse : window.localStorage.getItem('reghouse') || null,
	// appIntegration : [],
	// copyAppIntegration : [],
	// allSubscription : {},
	// appFilterState : [],
	// faqData : [],
	// companyBilling : {},
	// setPaymentType : null,
	// copyFaqData : [],
	// faqCategory : [],
	// copyFaqCategory : [],
	// model_invite : false,
	// model_photo : false,
	// model_util : false,
	// modal_inhouse : false
}
