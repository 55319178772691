export default {
	MODAL_INVITE(state) {
		return state.model_invite
	},
	MODAL_PHOTO(state) {
		return state.model_photo
	},
	MODAL_UTIL(state) {
		return state.model_util
	},
	GET_NOTIFICATION_DRAWER(state) {
		return state.notificationDrawer
	},
	GET_APP_SYNC_DRAWER(state) {
		return state.appSynDrawer
	},
	MODAL_IN_HOUSE_FAQ(state) {
		return state.modal_inhouse
	},
	MODAL_IN_HOUSE_CREATE_FAQ_CATEGORY(state) {
		return state.modal_faq_create_category_inhouse
	},
	MODAL_USER_CREATE_FAQ(state) {
		return state.modal_user_create_faq
	},
	GET_MODAL_INHOUSE_EDIT_FAQ(state) {
		return state.modal_faq_edit
	},
	GET_API_QUERIES(state) {
		return state.modal_api_queries
	},
	GET_EVENT_TASKS_DETAIL(state) {
		return state.modal_tasks_detail
	},
	GET_LOCALE_LANG(state) {
		return state.setLocale
	}
}
