import {saveIsAccountant, saveIsMasterAccountant} from "../../../../services/services-jwt"

export default {
	UPDATE_DASHBOARD_ACCOUNTANT_METRICS(state, payload) {
		state.dashboardMetrics = payload
	},
	UPDATE_ASSISTANCE_COMPANIES(state, payload) {
		state.accountantAssistanceCompany = payload
	},
	UPDATE_USERS_COMPANY(state, payload) {
		state.usersCompany = JSON.parse(JSON.stringify(payload))
		state.copyAllCompanies = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_USER_SPECIFIC_COMPANY(state, payload) {
		state.userSpecificCompany = JSON.parse(JSON.stringify(payload))
		state.userSpecificCompanyCopy = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_ACCOUNTANT(state, payload) {
		if (payload.user.is_accountant) {
			state.utility = payload.user.is_accountant
			saveIsAccountant(payload)
		}
	},
	UPDATE_MASTER_ACCOUNTANT(state, payload) {
		state.masterAccountant = payload
		saveIsMasterAccountant(payload)
	},
	REMOVE_ALL_ACCOUNTANT(state, _) {
		state.utility = null
		state.masterAccountant = null
	},
	REMOVE_USER_DATA(state, payload) {
		state.utility = payload
	},
	UPDATE_FIRM_COMPANY(state, payload) {
		state.firmCompany = payload
	},
	UPDATE_FIRM_COMPANIES(state, payload) {
		state.firmCompanies = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_FIRM_ACCOUNTANT(state, payload) {
		state.firmAccountants = JSON.parse(JSON.stringify(payload))
	},
	FILTER_DEFAULT(state, _) {
		state.usersCompany.results = [...state.copyAllCompanies.results]
	},
	FILTER_COMPANY(state, payload) {
		const filter = state.copyAllCompanies.results.filter(item => {
			return item.company.name.toLowerCase().includes(payload.toLowerCase())
		})
		state.usersCompany.results = filter
	},
	ASCENDING_ORDER_NAME(state, _) {
		if (state.usersCompany) {
			const sortName = state.copyAllCompanies.results.sort((a, b) => {
				if (a.company.name.toUpperCase() < b.company.name.toUpperCase()) {
					return -1
				}
				if (a.company.name.toUpperCase() > b.company.name.toUpperCase()) {
					return 1
				}
			})
			state.usersCompany.results = sortName
		}
	},
	DESCENDING_ORDER_NAME(state, _) {
		if (state.usersCompany) {
			const sortName = state.copyAllCompanies.results.sort((a, b) => {
				if (a.company.name.toUpperCase() > b.company.name.toUpperCase()) {
					return -1
				}
				if (a.company.name.toUpperCase() < b.company.name.toUpperCase()) {
					return 1
				}
			})
			state.usersCompany.results = sortName
		}
	},
	SORT_ASC(state, payload) {
		let stateList = {
			'userSpecificCompany': state.userSpecificCompany.results,
			'userSpecificCompanyCopy': state.userSpecificCompanyCopy.results,
			'payout': state.firmPayout.results,
			'payoutCopy': state.firmPayoutCopy.results
		}
		if (stateList[payload['copy']]) {
			if (stateList[payload['copy']].length > 0) {
				let single = stateList[payload['copy']][0]
				let sortValue = single[payload['item']]
				if (typeof sortValue === 'number') {
					stateList[payload['state']] = stateList[payload['copy']].sort((a, b) => {
						return a[payload['item']] - b[payload['item']]
					})
				} else if (typeof sortValue === 'string') {
					if (isNaN(parseInt(sortValue))) {
						stateList[payload['state']] = stateList[payload['copy']].sort((a, b) => {
							return a[payload['item']] === b[payload['item']] ? 0 : a[payload['item']] > b[payload['item']] ? 1 : -1
						})
					} else {
						stateList[payload['state']] = stateList[payload['copy']].sort((a, b) => {
							return a[payload['item']] - b[payload['item']]
						})
					}

				} else if (typeof sortValue === 'object') {
					let filter = stateList[payload['copy']].filter(item => item[payload['item']] !== null)
					stateList[payload['state']] = filter.sort((a, b) => {
						return a[payload['item']].name === b[payload['item']].name ? 0 : a[payload['item']].name > b[payload['item']].name ? 1 : -1
					})
				} else {
					stateList[payload['state']] = stateList[payload['copy']]
				}
			}
		}


	},
	SORT_DESC(state, payload) {
		let stateList = {
			'userSpecificCompany': state.userSpecificCompany.results,
			'userSpecificCompanyCopy': state.userSpecificCompanyCopy.results,
			'payout': state.firmPayout.results,
			'payoutCopy': state.firmPayoutCopy.results
		}
		if (stateList[payload['copy']]) {
			if (stateList[payload['copy']].length > 0) {
				let single = stateList[payload['copy']][0]
				let sortValue = single[payload['item']]
				if (typeof sortValue === 'number') {
					stateList[payload['state']] = stateList[payload['copy']].sort((a, b) => {
						return b[payload['item']] - a[payload['item']]
					})
				} else if (typeof sortValue === 'string') {
					if (isNaN(parseInt(sortValue))) {
						stateList[payload['state']] = stateList[payload['copy']].sort((a, b) => {
							return a[payload['item']] === b[payload['item']] ? 0 : a[payload['item']] < b[payload['item']] ? 1 : -1
						})
					} else {
						stateList[payload['state']] = stateList[payload['copy']].sort((a, b) => {
							return b[payload['item']] - a[payload['item']]
						})
					}

				} else if (typeof sortValue === 'object') {
					let filter = stateList[payload['copy']].filter(item => item[payload['item']] !== null)
					stateList[payload['state']] = filter.sort((a, b) => {
						return a[payload['item']].name === b[payload['item']].name ? 0 : a[payload['item']].name < b[payload['item']].name ? 1 : -1
					})
				} else {
					stateList[payload['state']] = stateList[payload['copy']]
				}
			}
		}
	},
	UPDATE_PAYOUT(state, payload) {
		state.firmPayout = payload.data
		state.firmPayoutCopy = payload.data
	},
	UPDATE_PAYOUT_FILTER(state, payload) {
		let currentFilter = state.firmPayoutFilter
		let filterExist = currentFilter.findIndex(item => item.mode === payload.mode)
		if (filterExist > -1) {
			currentFilter[filterExist] = payload
		} else {
			currentFilter.push(payload)
		}
		state.firmPayoutFilter = currentFilter
	},
	REMOVE_PAYOUT_FILTER(state, payload) {
		let currentFilter = state.firmPayoutFilter
		state.firmPayoutFilter = currentFilter.filter(item => item.mode !== payload.mode)
	},
}

