<template>
	<div class="container">
		<div class="card main-card">
			<div class="card-body" v-loading="loadingSub">
				<form v-on:submit.prevent="submit">
					<div class="row">
						<div class="col-md-12">
							<label>{{ $t('app_name') }}</label>
							<select class="form-control form-select app-select" id="app-select"
									v-model="form.subscription"
							>
							</select>
						</div>
						<div class="col-md-12">
							<div class="mt-3">
								<label>{{ $t('sync_type') }}</label>
								<select class="form-control form-select" v-model="form.sync_type"
								>
									<option value="payment" v-if="showPaymentOnly">
										{{ $t('payment') }}
									</option>
									<option value="all_product" v-if="showAllOptions || showArticleAppOnly">
										{{ $t('all_product') }}
									</option>
									<option value="all_order" v-if="showAllOptions || showOrderAppOnly">
										{{ $t('all_order') }}
									</option>
									<option value="order_specific" v-if="showAllOptions || showOrderAppOnly">
										{{ $t('order_specific') }}
									</option>
									<option value="product_specific" v-if="showAllOptions || showArticleAppOnly">
										{{ $t('product_specific') }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-12" v-if="['all_product','all_order','payment'].includes(form.sync_type)">
							<div class="mt-3">
								<label>{{ $t('date') }}</label>
								<input type="date" v-model="form.last_processed_date" class="form-control form-input"/>
							</div>
						</div>
						<div class="col-md-6" v-if="['order_specific','product_specific'].includes(form.sync_type)">
							<div class="mt-3">
								<label v-if="form.sync_type === 'order_specific'">{{ $t('order_number') }}</label>
								<label v-else>{{ $t('article_number') }}</label>
								<input type="text" v-model="form.number" class="form-control form-input"/>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group mt-5">
								<button
									class="btn addingCustomAccountbtn hover:text-white text-base btn-custom pad-overi"
									style="border-radius: 0px;" :disabled="loading"> {{ $t('runSync') }} <i
									v-if="loading === true"
									class="fa fa-spinner fa-spin"></i>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {ENABLED_V3_SYNC_APP, ALLOWED_V3_SYNC_REPORT_APPS} from '../../../app/index'
import {mapGetters} from "vuex"

window.$ = window.jQuery = require('jquery')
require('./../../../assets/jquery-ui/jquery-ui')
require('./../../../assets/select2/select2.min')
export default {
	name: "AppSynchronizeForm",
	data: function () {
		return {
			subscriptionList: [],
			pay: {
				key: 'payment', name: 'Payment',
			},
			syncOrderType: [],
			syncType: [
				{
					name: 'All Product', key: 'all_product'
				},
				{
					name: 'All Order', key: 'all_order'
				},
				{
					key: 'order_specific', name: 'Order Specific',

				},
				{
					key: 'product_specific', name: 'Product Specific',
				}
			],
			form: {
				sync_type: '',
				subscription: '',
				start_at_article: '',
				stop_at_article: '',
				last_processed_date: '',
				number: ''
			},
			loading: false,
			paymentApp: [60, 38, 57, 53, 49, 69, 1, 37, 72,],
			orderAppOnly: [60, 61, 62, 37, 70, 58, 2, 37, 41, 75, 54],
			articleAppOnly: [75],
			allApps: [51, 52, 42, 19, 63, 40],
			showAllOptions: false,
			showArticleAppOnly: false,
			showPaymentOnly: false,
			showOrderAppOnly: false,
			loadingSub: false,
		}
	},
	methods: {
		getSubscriptionList(userID) {
			this.loadingSub = true
			const URL = userID ? this.$services.endpoints.GET_USER_SUBSCRIPTION + `${userID}/` : this.$services.endpoints.GET_USER_SUBSCRIPTION
			this.$store.dispatch('customer/getSubscriptionList', {URL: URL})
				.then(() => {
					let V3 = []
					ALLOWED_V3_SYNC_REPORT_APPS.map(item => {
						V3.push(parseInt(item['key']))
					})
					this.subscriptionList = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => V3.includes(parseInt(item.app_type)) === true)

					const subscriptionListEl = document.querySelectorAll('.app-select')
					subscriptionListEl.forEach(element => {
						const accountSelect = element
						if (accountSelect.options.length < 2) {
							if (this.subscriptionList.length > 0) {
								this.subscriptionList.forEach(item => {
									const option = document.createElement('option')
									option.text = item.app_type_name
									option.value = item.id
									accountSelect.add(option)
								})
								window.$(element).select2({
									tags: true,
									createTag: function (params) {
										return {
											id: params.id,
											text: params.text,
										}
									}
								})
								window.$(element).val('').change()
							}
						}
					})
					let self = this
					window.$('#app-select').on("select2:open", function (e) {
						window.$('.select2-container--open').css('z-index', '99999')
					});

					window.$('#app-select').on("select2:select", function (e) {
						self.form.subscription = e.target.value
						self.onChange()
					});
					this.loadingSub = false

				}).catch((err) => {
				this.loadingSub = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								if (this.GET_USER_COMPANY_ID) {
									this.getSubscriptionList(this.GET_USER_COMPANY_ID)
								} else {
									this.getSubscriptionList()
								}
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		async getSubscriptionListing() {
			let url = 'get_all_fields=True&page_size=50'
			this.$store.dispatch('house/getAllSubscription', {data: url})
				.then(resp => {
					this.subscriptions.results = resp.data.results
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		submit: function () {
			let sub = this.subscriptionList.filter(item => item.id === parseInt(this.form.subscription))
			if (sub.length > 0) {
				this.loading = true
				let payload = {
					subscription: sub[0].id,
					sync_type: this.form.sync_type
				}
				if (['payment', 'all_order', 'all_product'].includes(this.form.sync_type)) {
					if (this.form.last_processed_date === '') {
						this.$services.helpers.notification(this.$t('dateRequired'), 'error', this)
						this.loading = false
						return false
					}
					const subscriptionCreatedDate = sub[0].created
					const difference = this.$services.moment(this.form.last_processed_date).diff(subscriptionCreatedDate, 'days')
					if (difference < 0) {
						this.$services.helpers.notification(this.$t('syncDateError'), 'error', this)
						this.loading = false
						return false
					}
					payload['last_processed_date'] = this.$services.moment(this.form.last_processed_date).format('YYYY-MM-DD')
				} else if (['order_specific', 'product_specific'].includes(this.form.sync_type)) {
					if (this.form.number === '') {
						if (this.form.sync_type === 'order_specific') {
							this.$services.helpers.notification(this.$t('orderNumberError'), 'error', this)
						} else {
							this.$services.helpers.notification(this.$t('articleNumberError'), 'error', this)
						}
						this.loading = false
						return false
					}
					payload['number'] = this.form.number
				} else {
					this.$services.helpers.notification(this.$t('appSelectError'), 'error', this)
					this.loading = false
					return false
				}
				let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.APP_SYNC_REPORTS + `?user_id=${this.GET_USER_COMPANY_ID}` : this.$services.endpoints.APP_SYNC_REPORTS
				this.$store.dispatch('customer/customPostRequest', {URL: URL, data: payload}).then(_ => {
					this.loading = false
					this.$services.helpers.notification(this.$t('syncEntrySuccess'), 'success', this)
					Object.keys(this.form).map(item => {
						this.form[item] = ''
					})
					window.$(`#app-select`).val('').trigger('change');
					window.Bus.$emit('reload-sync-event')
				}).catch(err => {
					this.loading = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									if (this.GET_USER_COMPANY_ID) {
										this.getSubscriptionList(this.GET_USER_COMPANY_ID)
									} else {
										this.getSubscriptionList()
									}
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						} else {
							if (err.response.data.hasOwnProperty('message') && err.response.data.hasOwnProperty('message_swe')) {
								if (this.GET_LOCALE === 'se') {
									this.$services.helpers.notification(err.response.data.message_swe, 'error', this)
								} else {
									this.$services.helpers.notification(err.response.data.message, 'error', this)
								}
							} else {
								this.$services.helpers.notification(err.response.data.message, 'error', this)
							}
						}
					} else {
						this.$services.helpers.notification(this.$t('generalError'), 'error', this)
					}

				})
			} else {
				this.$services.helpers.notification(this.$t('appSelectError'), 'error', this)
			}
		},
		onChange: function () {
			let sub = this.subscriptionList.filter(item => item.id === parseInt(this.form.subscription))
			this.form.sync_type = ''
			if (sub.length > 0) {
				this.showAllOptions = false
				this.showOrderAppOnly = false
				this.showArticleAppOnly = false
				this.showPaymentOnly = false
				if (this.paymentApp.includes(parseInt(sub[0].app_type))) {
					this.showPaymentOnly = true
				}
				if (this.allApps.includes(parseInt(sub[0].app_type))) {
					this.showAllOptions = true
				}
				if (this.orderAppOnly.includes(parseInt(sub[0].app_type))) {
					this.showOrderAppOnly = true
				}
				if (this.articleAppOnly.includes(parseInt(sub[0].app_type))) {
					this.showArticleAppOnly = true
				}
			}

		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		// Implementing two-way fetching
		if (this.GET_USER_COMPANY_ID) {
			this.getSubscriptionList(this.GET_USER_COMPANY_ID)
		} else {
			this.getSubscriptionList()
		}
	}
}
</script>

<style scoped>
.form-select {
	border: 1px solid rgba(239, 82, 135, 0.38) !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
	font-size: 15px !important;
}

.form-input {
	border: 1px solid rgba(239, 82, 135, 0.38) !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
	font-size: 15px !important;
}

.form-control:focus {
	color: #495057 !important;
	background-color: #fff !important;
	border-color: transparent !important;
	outline: 0;
	box-shadow: 0 0 0 1px rgba(239, 82, 135, 0.38) !important;
}
</style>
