<template>
	<div class="app-header header-shadow">
		<div class="app-header__logo">
			<div class="logo-sr">
				<img class="img-fluid h-5 logo-top" :src="baseURL+'/assets/images/Automatisera/header_logo.png'" alt="">
				<!-- <img class="img-fluid ml-5 h-5 logo-top" :src="baseURL+'/assets/images/Automatisera/header_logo.png'" alt=""> -->
			</div>
			<div class="header__pane ml-auto">
				<div>
					<!-- <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
						<span class="hamburger-box">
							<span class="hamburger-inner"></span>
						</span>
					</button> -->
				</div>
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button type="button" id="hamburger-btn" @click="toggleSidebar"
						class="hamburger hamburger--elastic mobile-toggle-nav">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
                <span>
                    <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
							@click="toggleNavTop">
                        <span class="btn-icon-wrapper">
                            <i class="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
		</div>
		<div class="app-header__content" id="div-open">
			<div class="app-header-left">
				<!-- <div class="search-wrapper">
					<div class="input-holder">
						<input type="text" class="search-input" placeholder="Type to search">
						<button class="search-icon"><span></span></button>
					</div>
					<button class="close"></button>
				</div> -->
				<ul class="header-megamenu nav">
					<!-- <li class="nav-item">
						<a href="javascript:void(0);" data-placement="bottom" rel="popover-focus" data-offset="300" data-toggle="popover-custom" class="nav-link" data-original-title="" title="">
							<i class="nav-link-icon pe-7s-gift"> </i> Mega Menu
							<i class="fa fa-angle-down ml-2 opacity-5"></i>
						</a>
						<div class="rm-max-width">
							<div class="d-none popover-custom-content">
								<div class="dropdown-mega-menu">
									<div class="grid-menu grid-menu-3col">
										<div class="no-gutters row">
											<div class="col-sm-6 col-xl-4">
												<ul class="nav flex-column">
													<li class="nav-item-header nav-item"> Overview</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">
															<i class="nav-link-icon lnr-inbox"></i>
															<span> Contacts</span>
														</a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">
															<i class="nav-link-icon lnr-book"></i>
															<span> Incidents</span>
															<div class="ml-auto badge badge-pill badge-danger">5</div>
														</a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">
															<i class="nav-link-icon lnr-picture"></i>
															<span> Companies</span>
														</a>
													</li>
													<li class="nav-item">
														<a disabled="" href="javascript:void(0);" class="nav-link disabled">
															<i class="nav-link-icon lnr-file-empty"></i>
															<span> Dashboards</span>
														</a>
													</li>
												</ul>
											</div>
											<div class="col-sm-6 col-xl-4">
												<ul class="nav flex-column">
													<li class="nav-item-header nav-item"> Favourites</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link"> Reports Conversions </a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link"> Quick Start
															<div class="ml-auto badge badge-success">New</div>
														</a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">Users &amp; Groups</a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">Proprieties</a>
													</li>
												</ul>
											</div>
											<div class="col-sm-6 col-xl-4">
												<ul class="nav flex-column">
													<li class="nav-item-header nav-item">Sales &amp; Marketing</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">Queues </a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">Resource Groups </a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">Goal Metrics
															<div class="ml-auto badge badge-warning">3</div>
														</a>
													</li>
													<li class="nav-item">
														<a href="javascript:void(0);" class="nav-link">Campaigns</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="btn-group nav-item">
						<a class="nav-link" data-toggle="dropdown" aria-expanded="false">
							<span class="badge badge-pill badge-danger ml-0 mr-2">4</span> Settings
							<i class="fa fa-angle-down ml-2 opacity-5"></i>
						</a>
						<div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers dropdown-menu">
							<div class="dropdown-menu-header">
								<div class="dropdown-menu-header-inner bg-secondary">
									<div class="menu-header-image opacity-5" style="background-image: url('assets/images/dropdown-header/abstract2.jpg');"></div>
									<div class="menu-header-content">
										<h5 class="menu-header-title">Overview</h5>
										<h6 class="menu-header-subtitle">Dropdown menus for everyone</h6>
									</div>
								</div>
							</div>
							<div class="scroll-area-xs">
								<div class="scrollbar-container ps">
									<h6 tabindex="-1" class="dropdown-header">Key Figures</h6>
									<button type="button" tabindex="0" class="dropdown-item">Service Calendar</button>
									<button type="button" tabindex="0" class="dropdown-item">Knowledge Base</button>
									<button type="button" tabindex="0" class="dropdown-item">Accounts</button>
									<div tabindex="-1" class="dropdown-divider"></div>
									<button type="button" tabindex="0" class="dropdown-item">Products</button>
									<button type="button" tabindex="0" class="dropdown-item">Rollup Queries</button>
								<div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></div>
							</div>
							<ul class="nav flex-column">
								<li class="nav-item-divider nav-item"></li>
								<li class="nav-item-btn nav-item">
									<button class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
								</li>
							</ul>
						</div>
					</li>
					<li class="dropdown nav-item">
						<a aria-haspopup="true" data-toggle="dropdown" class="nav-link" aria-expanded="false">
							<i class="nav-link-icon pe-7s-settings"></i> Projects
							<i class="fa fa-angle-down ml-2 opacity-5"></i>
						</a>
						<div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-rounded dropdown-menu-lg rm-pointers dropdown-menu">
							<div class="dropdown-menu-header">
								<div class="dropdown-menu-header-inner bg-success">
									<div class="menu-header-image opacity-1" style="background-image: url('assets/images/dropdown-header/abstract3.jpg');"></div>
									<div class="menu-header-content text-left">
										<h5 class="menu-header-title">Overview</h5>
										<h6 class="menu-header-subtitle">Unlimited options</h6>
										<div class="menu-header-btn-pane">
											<button class="mr-2 btn btn-dark btn-sm">Settings</button>
											<button class="btn-icon btn-icon-only btn btn-warning btn-sm">
												<i class="pe-7s-config btn-icon-wrapper"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
							<button type="button" tabindex="0" class="dropdown-item">
								<i class="dropdown-icon lnr-file-empty"></i>Graphic Design
							</button>
							<button type="button" tabindex="0" class="dropdown-item">
								<i class="dropdown-icon lnr-file-empty"> </i>App Development
							</button>
							<button type="button" tabindex="0" class="dropdown-item">
								<i class="dropdown-icon lnr-file-empty"> </i>Icon Design
							</button>
							<div tabindex="-1" class="dropdown-divider"></div>
							<button type="button" tabindex="0" class="dropdown-item">
								<i class="dropdown-icon lnr-file-empty"></i>Miscellaneous
							</button>
							<button type="button" tabindex="0" class="dropdown-item">
								<i class="dropdown-icon lnr-file-empty"></i>Frontend Dev
							</button>
						</div>
					</li> -->
				</ul>
			</div>
			<div class="app-header-right">
				<div class="header-dots">
					<!-- <div class="dropdown">
						<button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" class="p-0 mr-2 btn btn-link">
							<span class="icon-wrapper icon-wrapper-alt rounded-circle">
								<span class="icon-wrapper-bg bg-primary"></span>
								<i class="icon text-primary ion-android-apps"></i>
							</span>
						</button>
						<div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
							<div class="dropdown-menu-header">
								<div class="dropdown-menu-header-inner bg-plum-plate">
									<div class="menu-header-image" style="background-image: url('assets/images/dropdown-header/abstract4.jpg');"></div>
									<div class="menu-header-content text-white">
										<h5 class="menu-header-title">Grid Dashboard</h5>
										<h6 class="menu-header-subtitle">Easy grid navigation inside dropdowns</h6>
									</div>
								</div>
							</div>
							<div class="grid-menu grid-menu-xl grid-menu-3col">
								<div class="no-gutters row">
									<div class="col-sm-6 col-xl-4">
										<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
											<i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i> Automation
										</button>
									</div>
									<div class="col-sm-6 col-xl-4">
										<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
											<i class="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Reports
										</button>
									</div>
									<div class="col-sm-6 col-xl-4">
										<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
											<i class="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Settings
										</button>
									</div>
									<div class="col-sm-6 col-xl-4">
										<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
											<i class="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Content
										</button>
									</div>
									<div class="col-sm-6 col-xl-4">
										<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
											<i class="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i> Activity
										</button>
									</div>
									<div class="col-sm-6 col-xl-4">
										<button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
											<i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Contacts
										</button>
									</div>
								</div>
							</div>
							<ul class="nav flex-column">
								<li class="nav-item-divider nav-item"></li>
								<li class="nav-item-btn text-center nav-item">
									<button class="btn-shadow btn btn-primary btn-sm">Follow-ups</button>
								</li>
							</ul>
						</div>
					</div> -->
					<div class="absolute bg-red-30 inset-0 z-0" @click="notifyOpen = false" v-if="notifyOpen"></div>


					<div class="dropdown">
						<button @click="openNotifyDiv" class="p-0 mr-2 btn btn-link no-deco">
							<!--type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"-->
							<span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <span class="icon-wrapper-bg bg-danger"></span>
                                    <i class="text-sm icon text-danger  mdi mdi-notifications-active"></i>
                                    <span class="badge badge-dot badge-dot-sm badge-danger"
										  v-if="GET_NOTIFICATION.unread_notifications_count > 0">{{
											$t('notify')
										}}</span>
                                </span>
						</button>
						<div v-if="notifyOpen"
							 class="w-60 width-div-mobile fixed md:absolute z-50 shadow bg-white border rounded mt-10 p-3 md:p-5 right-md">
							<div> <!--@scroll="handleScroll"-->
								<div class="flex flex-col">
									<div class="flex justify-center">
										<div class="w-36">
											<img class="img-fluid" src="./../../../assets/images/illus/notify.svg"
												 alt="notification">
										</div>
									</div>
									<div class="text-center menu-header-content text-dark">
										<h5 class="text-sm menu-header-title">{{ $t('notify') }}</h5>
										<h6 class="menu-header-subtitle text-xs font-light">{{ $t('have') }}
											<b>{{ GET_NOTIFICATION.unread_notifications_count }}</b> {{ $t('unread') }}
										</h6>
									</div>
								</div>
								<div class="overflow-y-scroll"
									 :class="GET_NOTIFICATION.unread_notifications.length > 0 ? 'notify-height' : ''"
									 id="extend-height">
									<div class="" v-for="(notify, i) in GET_NOTIFICATION.unread_notifications" :key="i">
										<NotificationHandler :notify="notify"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="dropdown">
						<button type="button" data-toggle="dropdown" class="p-0 mr-2 btn btn-link">
                                <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <span class="icon-wrapper-bg bg-focus"></span>
                                    <span class="language-icon opacity-8 flag small SE"
										  v-if="GET_LOCALE_LANG === 'se'"></span>
                                    <span class="language-icon opacity-8 flag small GB" v-else></span>
                                </span>
						</button>
						<div tabindex="-1" role="menu" aria-hidden="true"
							 class="rm-pointers dropdown-menu dropdown-menu-right">
							<div class="dropdown-menu-header">

							</div>
							<!-- <h6 tabindex="-1" class="dropdown-header"> Popular Languages</h6> -->
							<button type="button" @click="changeLocale('en')" tabindex="0" class="dropdown-item"
									v-if="GET_LOCALE_LANG === 'se'">
								<span class="mr-3 opacity-8 flag small GB"></span> English
							</button>
							<button type="button" @click="changeLocale('se')" tabindex="0" class="dropdown-item" v-else>
								<span class="mr-3 opacity-8 flag small SE"></span> Swedish
							</button>

						</div>
					</div>
				</div>
				<div class="header-btn-lg pr-0">
					<p class="bg-gray-300 w-40 h-4 rounded mr-2" v-if="loadingCompany"></p>
					<div class="header-user-name mr-2 link-theme-color font-semibold" v-else>{{
							GET_FIRM_COMPANY
						}}
					</div>
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left">
								<div class="btn-group">
									<a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
									   class="p-0 btn d-flex align-items-center">
										<p class="h-10 w-10 bg-gray-300 rounded-full" v-if="loadingCompany"></p>
										<div class="no-profile-img" :style="'background:'+rcolor"
											 v-if="!loadingCompany && GET_USER.img === null">
											<div>
												<p class="font-black">{{
														GET_USER.first_name + ' ' + GET_USER.last_name | initialNames
													}}</p>
											</div>
										</div>
										<img class="rounded-circle"
											 :src="$services.endpoints.LIVE_SERVER + GET_USER.img"
											 alt="automatisera | preview" width="42"
											 v-if="!loadingCompany && GET_USER.img">
										<!-- <img class="rounded-circle" :src="baseURL+'/assets/images/preview/preview.png'" alt="automatisera | preview" width="42" v-else> -->
										<i class="icon ion-ios-arrow-down ml-2 opacity-8"></i>
									</a>
									<div tabindex="-1" role="menu" aria-hidden="true"
										 class="rm-pointers tweak-style dropdown-menu-lg dropdown-menu dropdown-menu-right">

										<div class="scroll-area-xs override-height"><!--style="height: 150px;"-->
											<div class="scrollbar-container ps">
												<ul class="nav flex-column">
													<li class="nav-item">
														<a @click="$store.commit('utility/OPEN_PHOTO_MODAL')"
														   href="javascript:void(0);"
														   class="pt-0 pb-0 link-theme-color text-sm nav-link">
															<svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg"
																 viewBox="0 0 20 20" fill="currentColor">
																<path fill-rule="evenodd"
																	  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
																	  clip-rule="evenodd"/>
															</svg>
															<!-- <span class="text-xl icon-color iconly-boldFolder mr-2"></span> -->
															<!-- <i class="metismenu-icon pe-7s-user mr-2"></i>&nbsp; -->
															{{ $t('account') }}
														</a>
													</li>
<!--													<li class="nav-item">-->
<!--														<a href="javascript:void(0);"-->
<!--														   class="pt-0 pb-0 link-theme-color text-sm nav-link"-->
<!--														   @click="$store.commit('utility/OPEN_INVITE_MODAL')">-->
<!--															&lt;!&ndash; <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
<!--															<path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />-->
<!--															</svg> &ndash;&gt;-->
<!--															<span-->
<!--																class="text-xl icon-color mr-2 mt-1 mb-1 iconly-brokenAdd-User"></span>-->
<!--															&lt;!&ndash; <i class="metismenu-icon pe-7s-add-user mr-2"></i>&nbsp; &ndash;&gt;-->
<!--															{{ $t('inviteUsers') }}-->
<!--														</a>-->
<!--													</li>-->
													<!-- <li class="nav-item">
														<a href="javascript:void(0);" class="pt-0 pb-0 link-theme-color text-sm nav-link">
															<svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
															<path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" />
															<path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
															</svg>
															<i class="metismenu-icon pe-7s-portfolio mr-2"></i>&nbsp;
															Billing
														</a>
													</li> -->
													<li class="nav-item">
														<a href="javascript:void(0);" @click="logoutUser"
														   class="pt-0 pb-0 link-theme-color text-sm nav-link">
															<!-- <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
															<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
															</svg> -->
															<span
																class="text-xl icon-color mr-2 mt-1 mb-1 iconly-brokenLogout"></span>
															<!-- <i class="metismenu-icon pe-7s-power mr-2"></i>&nbsp; -->
															{{ $t('logout') }}
														</a>
													</li>
												</ul>
											</div>
										</div>
										<ul class="nav flex-column">
											<li class="nav-item-divider nav-item">
											</li>
											<li class="nav-item-btn text-center nav-item">
												Version 1.2.0
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NotificationHandler from "../../../customComponent/NotificationHandler";
import {mapGetters} from "vuex";
import bus from "../../../bus";
import i18n from "../../../plugins/i18n";

export default {
	name: "NavBar",
	components: {
		NotificationHandler
	},
	data() {
		return {
			baseURL: window.location.origin,
			user: {},
			firmCompany: null,
			modalVisible: false,
			rcolor: window.localStorage.getItem('randomc') || '#000000',
			loadingCompany: true,
			notification: [],
			notify_count: 0,
			unread_count: 0,
			unread_notify: false,
			notifyOpen: false
		}
	},
	watch: {
		notifyOpen(newVal, oldVal) {
			window.Bus.$emit('show-cover', this.notifyOpen)
			// if (newVal === true) {
			// }
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_NOTIFICATION: 'customer/GET_NOTIFICATION',
			GET_USER_FIRM_ID: 'customer/GET_USER_FIRM_ID',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG',
			GET_USER: 'customer/GET_USER',
			GET_FIRM_COMPANY: 'accountant/GET_FIRM_COMPANY',
		})
	},
	mounted() {
		if (this.GET_USER_FIRM_ID) {
			this.getAuthUser(this.GET_USER_FIRM_ID)
			// this.getUserFirm(this.GET_USER_FIRM_ID)
			// this.getUserNotification(this.GET_USER_FIRM_ID)
		} else {
			this.getAuthUser()
			// this.getAuthUser()
			// this.getUserNotification()
		}
		this.getUserNotification()
		bus.$on('update-user-pic-manager', () => {
			this.getAuthUser(this.GET_USER_FIRM_ID)
		})
		window.Bus.$on('close-cart', e => {
			this.notifyOpen = false
		})
	},
	methods: {
		openNotifyDiv() {
			this.notifyOpen = !this.notifyOpen
			// window.Bus.$emit('show-cover', this.notifyOpen)
		},
		getAuthUser(userID) {
			const URL = this.$services.endpoints.GET_USER
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_USER)
			this.$store.dispatch('customer/getAuthUser', {URL})
				.then(res => {
					this.loadingCompany = false
					// console.log('accountant: USER', res)
					if (res.data.success) {
						if (userID) {
							// this.user = res.data.data.user
							this.$store.commit('accountant/UPDATE_FIRM_COMPANY', JSON.parse(userID).firm)
						} else {
							// this.firmCompany = JSON.parse(userID).firm
							// this.user = res.data.data.user
							this.$store.commit('accountant/UPDATE_FIRM_COMPANY', res.data.data.user.accounting_firm)
							// this.firmCompany = res.data.data.user.accounting_firm
						}
					}
				}).catch(err => {
				this.loadingCompany = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAuthUser()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getUserFirm(userID) {
			const URL = this.$services.endpoints.IN_HOUSE_SINGLE_FIRM + '4ff14a7b-4711-41f9-a19d-07e5177b5e61/'
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('accountant/getUserFirm', {URL})
				.then(res => {
					// console.log('user Firm:', res)
					this.loadingCompany = false
				}).catch(err => {
				this.loadingCompany = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								if (this.GET_USER_FIRM_ID) {
									this.getUserFirm(this.GET_USER_FIRM_ID)
								} else {
									this.getUserFirm()
								}
							})
							.catch(_ => {
								this.$store.commit('CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getUserNotification(userID) {
			const URL = userID ? this.$services.endpoints.GET_USER_NOTIFICATION + `${userID}/` : this.$services.endpoints.GET_USER_NOTIFICATION
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(URL)
			this.$store.dispatch('customer/getUserNotification', {URL: URL})
				.then(res => {
					// console.table('notification:', res)
					// if (res.data.success) {
					//     this.notification = res.data.data.all_notifications
					//     this.unread_count = res.data.data.unread_notifications_count
					//     this.notify_count = res.data.data.all_notifications_count
					// }
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								if (this.GET_USER_COMPANY_ID) {
									this.getUserNotification(this.GET_USER_COMPANY_ID)
								} else {
									this.getUserNotification()
								}
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		changeLocale(locale) {
			i18n.locale = locale
			this.$store.commit('utility/CHANGE_LOCALE', locale)
		},
		handleScroll(e) {
			if (parseInt(e.target.scrollTop) > 60) {
				document.getElementById('remove-div').classList.add('hide-transition-height')
				document.getElementById('extend-height').classList.add('notify-height')
			} else {
				document.getElementById('remove-div').classList.remove('hide-transition-height')
				document.getElementById('extend-height').classList.remove('notify-height')
			}
		},
		toggleSidebar() {
			document.getElementById('hamburger-btn').classList.toggle('is-active')
			document.getElementById('app-root-container').classList.toggle('sidebar-mobile-open')
		},
		toggleNavTop() {
			// console.log('clicking')
			// console.log('what:', document.getElementById('div-open'))
			document.getElementById('div-open').classList.toggle('header-mobile-open')
		},
		logoutUser() {
			this.$store.commit('auth/CLEAR_AUTH_USER', null)
			this.$store.commit('accountant/REMOVE_USER_DATA', null)
			window.Bus.$emit('sign-out-accountant')
		}
	}
}
</script>

<style lang="scss" scoped>
.app-theme-gray.fixed-header {
	.app-header__logo {
		background: #fff !important;
	}
}

.h-5 {
	height: 3rem;
}

.logo-top {
	margin-top: -0.5rem;
}

.scrollbar-container {
	.nav-item {
		.nav-link {
			.metismenu-icon {
				font-size: 2rem;
			}
		}
	}
}

.app-theme-gray .app-header .app-header__logo {
	border-right: unset !important;
}

.header-user-name {
	font-weight: 600;
	font-size: 1.2rem;
}

.app-header__logo .logo-src {
	// height: 46px !important;
	// width: 99px !important;
	// background-repeat: no-repeat;
	// margin-left: 4rem;
}

.tweak-style {
	left: 40px !important;
}

.dropdown-menu.dropdown-menu-lg {
	min-width: 15rem !important;
}

.text-big {
	font-size: 1rem !important;
}

.app-theme-gray .app-header {
	background: #ffff !important;
}

.ps__thumb-y {
	background: #EE4D81 !important;
}

.notify-height {
	height: 250px !important;
	transition: height 1s ease-out;
}

.hide-transition-height {
	height: 0px;
	opacity: 0;
	display: none;
}

.transition-div {
	transition: display 1s ease-out;
}
</style>
