<template>
    <div class="app-container bg-gray-100 body-tabs-shadow app-theme-gray fixed-header fixed-sidebar" id="app-root-container">
        <NavbarDash />
        <div class="app-main single-main"> <!--accountant-main -->
            <div class="absolute bg-red-30 inset-0 z-100" style="z-index:9999999999" @click="closeCartDiv" v-if="showFullCover"></div>
            <SidebarDash /> <!--v-show="false"-->
            <div class="app-main__outer">
                <router-view />
                <FooterDash />
            </div>
        </div>
        <Notification />
        <el-dialog
        :visible.sync="modal_invite"
        width="60%"
        :handleClose="handleClose"
        center>
        <div class="container">
            <div class="row">
                <div class="col-md-6 -mt-10">
                    <img src="./../../assets/images/illus/mail.svg" class="img-fluid d-none d-lg-block" alt="mail">
                    <h2 class="text-2xl text-gray-900 font-semibold">{{ $t('inviteUsers') }}</h2>
                    <!-- <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi asperiores adipisci corporis sit repellat in aperiam quia odio doloribus dolore.</span> -->
                </div>
                <div class="col-md-6">
                    <form class="flex flex-col space-y-3" @submit.prevent="inviteUser">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="firstname">{{ $t('firstName') }}</label>
                                    <input type="text" v-model="firstname" class="form-control w-full pr-1" v-bind:placeholder="$t('firstName')" required>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="firstname">{{ $t('lastName') }}</label>
                                    <input type="text" v-model="lastname" class="form-control w-full pl-1" v-bind:placeholder="$t('lastName')" required>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="email">{{ $t('email') }}</label>
                                    <input type="email" v-model="email" class="form-control w-full pr-1" v-bind:placeholder="$t('email')" required>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="companyname">{{ $t('companyName') }}</label>
                                    <input type="text" v-model="companyName" class="form-control w-full pr-1" v-bind:placeholder="$t('companyName')" required>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="comNumber">{{ $t('companyNumber') }}</label>
                                    <input type="text" v-model="companyNumber" class="form-control w-full pr-1" v-bind:placeholder="$t('companyNumber')" required>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-custom btn-primary" :disabled="creating">
                                    <span v-if="creating" class="text-white">{{ $t('creating') }}...</span>
                                    <span v-else class="text-white">{{ $t('create') }}</span>
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        </el-dialog>

        <ModalAssign v-if="assignAccountant"  title='' width='w-1/2'  @handleClose="assignAccountant = false" :close="!true">
            <div class="container" slot="body">
                <div class="row">

                </div>
            </div>
            <div slot="footer" class="flex space-x-5">

            </div>
        </ModalAssign>


        <ModalContainer v-if="modalVisible"  title='Add a note' @handleSave='createNoteNow'  @handleClose="modalVisible = false" :close="!true">
            <textarea v-model="stickyMessage" cols="30" rows="10" class="bg-stick focus:outline-none" slot="body">

            </textarea>
        </ModalContainer>

        <el-dialog
        :visible.sync="modal_photo"
        width="60%"
        :handleClose="handleClose"
        center>
        <div class="container">
            <div class="row">
                <div class="col-md-6 -mt-10">
                    <img src="./../../assets/images/illus/upload.svg" class="img-fluid d-none d-lg-block" alt="mail">
                    <h2 class="text-2xl text-gray-900 font-semibold">{{ $t('upload') }}</h2>
                    <!-- <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi asperiores adipisci corporis sit repellat in aperiam quia odio doloribus dolore.</span> -->
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="custom-file-upload">
                            <input type="file" id="upload" class="inputfile form-control-file" @change="croppie" accept="image/png, image/jpeg, image/jpg"/>
                            <i class="iconly-brokenUpload"></i> {{ $t('chooseFile') }}
                        </label>
                        <!-- <input type="file" name="file" id="file" class="inputfile" /> -->
                        <!-- <label for="file">
                            </label> -->
                    </div>
                    <div class="row">
                    </div>
                    <div v-show="showCrop">
                        <vue-croppie ref="croppieRef" :enableOrientation="true" :enableResize="false" :boundary="{ width: 250, height: 250}" :viewport="{width:150, height:150,'type':'circle' }">
                        </vue-croppie>
                    </div>
                    <div slot="footer" class="flex space-x-5" v-show="showCrop">
                        <button class="btn hover:text-white text-base btn-custom" @click="crop" :disabled="initializingCropping">
                            <span v-if="initializingCropping">{{ $t('cropping') }}</span>
                            <span v-else>{{ $t('crop') }}</span>
                        </button>

                        <button class="btn focus:outline-none text-white bg-green-500" @click="uploadPhoto" v-if="croppingSuccess" :disabled='uploadingNow'>
                            <span v-if="uploadingNow">{{ $t('uploading') }}.</span>
                            <span v-else>{{ $t('fileUpload') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="container">
            <div class="form-group">
                <input type="file" id="upload" class="form-control-file" @change="croppie" accept="image/png, image/jpeg, image/jpg"/>
            </div>
            <div class="row">
            </div>
            <div v-show="showCrop">
                <vue-croppie ref="croppieRef" :enableOrientation="true" :enableResize="false" :boundary="{ width: 250, height: 250}" :viewport="{width:150, height:150,'type':'circle' }">
                </vue-croppie>
            </div>
            <div slot="footer" class="flex space-x-5" v-show="showCrop">
                <button class="btn focus:outline-none btn-primary" @click="crop" :disabled="initializingCropping">
                    <span v-if="initializingCropping">Cropping...</span>
                    <span v-else>Crop</span>
                </button>

                <button class="btn focus:outline-none bg-green-500" @click="uploadPhoto" v-if="croppingSuccess" :disabled='uploadingNow'>
                    <span v-if="uploadingNow">Uploading...</span>
                    <span v-else>Finished & Upload</span>
                </button>
            </div>
        </div> -->
        </el-dialog>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import { v4 as uuidv4 } from 'uuid'
    import bus from './../../bus'
    import NavbarDash from './components/Navbar'
    import SidebarDash from './components/Sidebar'
    import FooterDash from '@/components/Footer'
    import ModalContainer from './../../customComponent/Modal'
    import ModalAssign from './../../customComponent/DownloadModal'
    import { Dialog } from 'element-ui'
    import VueCroppie from 'vue-croppie'
	import {destroyPath} from "../../services/storage-window";

    Vue.use(VueCroppie)
    Vue.use(Dialog)

    export default {
        name : 'main-root-dash',
        components : {
            NavbarDash,
            SidebarDash,
            FooterDash,
            ModalContainer,
            ModalAssign,
            Notification : () => import('./../../customComponent/NotificationAlert')
        },
        data () {
            return {
                baseURL : window.location.origin,
                modalVisible : false,
                firstname : '',
                lastname : '',
                email : '',
                companyName : '',
                companyNumber : '',
                stickyMessage : '',
                noteObj : {},
                role : 'Select role',
                creating : false,
                showCrop : false,
                croppieImage : null,
                initializingCropping : false,
                croppingSuccess : false,
                showPreviewCropping : false,
                uploadingNow : false,
                showFullCover : false,
                assignAccountant : false,
                accountantInfo : null,
            }
        },
        computed : {
            modal_invite : {
                get : function () {
                    return this.MODAL_INVITE
                },
                set : function () {
                    return this.$store.commit('utility/OPEN_INVITE_MODAL')
                }
            },
            modal_photo : {
                get : function () {
                    return this.MODAL_PHOTO
                },
                set : function () {
                    return this.$store.commit('utility/OPEN_PHOTO_MODAL')
                }
            },
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER : 'customer/GET_USER',
                MODAL_INVITE : 'utility/MODAL_INVITE',
                MODAL_PHOTO : 'utility/MODAL_PHOTO',
                GET_USER_UTIL : 'accountant/GET_USER_UTIL',
                GET_IN_HOUSE_UTIL : 'house/GET_IN_HOUSE_UTIL'
            })
        },
        mounted () {
            destroyPath()
            const self = this
            document.addEventListener('contextmenu', function (e) {
                e.preventDefault()
            })
            bus.$on('open-sticky', e => {
                // console.log(e)
                this.modalVisible = true
                this.noteObj = e
                this.stickyMessage = e.message
            })
            window.Bus.$on('sign-out-accountant', _ => {
                if (this.$route.path !== '/') {
                    // this.$router.push("/admin");
                    this.$router.replace({ name : 'login' })
                }
            })
            window.Bus.$on('show-cover', e => {
                if (e === true) {
                    this.showFullCover = true
                } else {
                    this.showFullCover = false
                }
            })

            window.Bus.$on('remove-accountant', e => {
                self.removeAccountantInFirm (e.user)
            })

            window.Bus.$on('disconnect-company', e => {
                self.removeCompanyFromFirm (e.user)
            })
        },
        beforeMount () {
            if (this.USER_TOKEN) {
                // if (this.GET_IN_HOUSE_UTIL === 'true') {
                //     window.location.href = this.baseURL + '/regular'
                // }
                if (this.GET_USER_UTIL === 'false') {
                    this.$router.replace({ name: 'dashboard' })
                    // window.location.href = this.baseURL + '/automatisera'
                } else {
                    // if (this.GET_IN_HOUSE_UTIL === 'true') {

                    // }
                }
            } else {
                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                if (this.$route.path !== '/') {
                    // this.$router.push("/admin");
                    this.$router.replace({ name : 'login' })
                }
            }
        },
        methods : {
            closeCartDiv () {
                window.Bus.$emit('close-cart')
                this.showFullCover = false
            },
            handleClose () {
                this.$store.commit('utility/OPEN_INVITE_MODAL')
            },
            createNoteNow () {
                if (this.noteObj.updateUser) {
                    this.updateUserNote()
                } else {
                    this.createCompanyNote()
                }
            },
            createCompanyNote () {
                if (this.stickyMessage !== '') {
                    this.$services.helpers.notification(this.$t('createNote'), 'progress', this)
                    const noteText = this.stickyMessage
                    // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
                    // this.$services.axios.post(this.$services.endpoints.MANAGERS_NOTE + `${this.noteObj.userId}/`, {
                    //     })
                    const payload = {
                        recipient: this.noteObj.userId,
                        message: noteText,
                        read: false
                    }
                    this.$store.dispatch('accountant/createCompanyNote', { data : payload, URL : this.$services.endpoints.COMPANY_NOTES + `?user_id=${this.noteObj.userId}/` })
                    .then(res => {
                        // console.log(res)
                        if (res.data.success) {
                            this.$services.helpers.notification(this.noteObj.agent + ' ' + this.$t('noteCreated'), 'success', this)
                            this.stickyMessage = ''
                            this.noteObj = {}
                            this.modalVisible = false
                        }
                    }).catch((_) => {

                    })
                } else {
                    this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
                }
            },
            updateUserNote () {
                if (this.stickyMessage !== '') {
                    this.$services.helpers.notification(this.$t('updateNote'), 'progress', this)
                    const noteText = this.stickyMessage
                    // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
                    // this.$services.axios.put(this.$services.endpoints.MANAGERS_NOTE + `update/${this.noteObj.noteID}/${this.noteObj.userId}/`, {
                    //     })
                    const payload = {
                        recipient: this.noteObj.userId,
                        message: noteText,
                    }
                    this.$store.dispatch('accountant/updateUserNote', { data : payload, URL : this.$services.endpoints.COMPANY_NOTES + `${this.noteObj.noteID}/?user_id=${this.noteObj.userId}` })
                    .then(_ => {
                        this.$services.helpers.notification(this.noteObj.agent + ' ' + this.$t('noteUpdated'), 'success', this)
                        this.stickyMessage = ''
                        this.noteObj = {}
                        this.modalVisible = false
                        // if (res.data.success) {
                        // }
                    }).catch((err) => {
                        // console.log(err.response.data)
                    })
                } else {
                    this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
                }
            },
            inviteUser () {
                if (this.firstname === '' || this.lastname === '' || this.email === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (this.companyName === '' || this.companyNumber === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (this.firstname.length > 50 || this.lastname.length > 50 || this.email.length > 50 ||
                this.companyName.length > 50 || this.companyNumber.length > 50) {
                    this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
                } else if (!this.$services.helpers.validateEmailAddress(this.email)) {
                    this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
                } else if (this.validateCompanyNumber(this.companyNumber)) {
                    this.$services.helpers.notification(this.$t('invalidCompany'), 'error', this)
                } else {
                    this.creating = true
                    const payload = {
                        email : this.email,
                        first_name : this.firstname,
                        last_name : this.lastname,
                        company_name : this.companyName,
                        company_number : this.companyNumber
                    }
                    // this.$services.axios.post(this.$services.endpoints.ACCOUNTANT_INVITE_COM, {
                    // })
                    this.$store.dispatch('accountant/inviteUser', payload)
                    .then(_ => {
                        this.creating = false
                        this.$services.helpers.notification(this.$t('invitedSuccessful'), 'success', this)
                        setTimeout(() => {
                            this.clearInviteForm()
                            this.$store.commit('utility/OPEN_INVITE_MODAL')
                        }, 1000)
                        // if (res.status === 200) {
                        // }
                    }).catch((err) => {
                        this.creating = false
                        if (err.response.status === 400) {
                            if (err.response.data.code === 401) {
                                this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                                .then(_ => {

                                })
                                .catch(_ => {
                                    // console.log('here')
                                    this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                    // window.location.reload()
                                })
                            }
                        }
                    })
                }
            },
            croppie (e) {
                const self = this
                const docType = ['png', 'jpg', 'jpeg']
                const fileType = e.target.files[0].type.split('/')[1]

                if (docType.includes(fileType)) {
                    self.showCrop = true

                    var files = e.target.files || e.dataTransfer.files
                    if (!files.length) return

                    var reader = new FileReader()
                    reader.onload = e => {
                        this.$refs.croppieRef.bind({
                            url: e.target.result
                        })
                    }
                    reader.readAsDataURL(files[0])
                } else {
                    this.$services.helpers.notification(this.$t('invalidFile'), 'error', this)
                    document.getElementById('upload').value = ''
                }
            },
            crop () {
                this.initializingCropping = true
                this.croppingSuccess = false
                const options = {
                    type: 'base64',
                    size: 'viewport',
                    format: 'png',
                    circle : true
                }
                this.$refs.croppieRef.result(options, output => {
                    this.croppieImage = output
                    // console.log('Croppeds:', this.croppieImage)
                    this.initializingCropping = false
                    this.croppingSuccess = true
                    this.showPreviewCropping = true
                    this.addedImage = true
                    return this.getBase64ToFire(this.croppieImage)
                })
            },
            getBase64ToFire (base64URL) {
                const renamFile = `${uuidv4()}.png`
                fetch(base64URL)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], renamFile, { type: 'image/png' })
                    if (file !== undefined) {
                        this.userPhoto = file
                    }
                })
            },
            uploadPhoto () {
                const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPLOAD_USER_PHOTO + `${GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPLOAD_USER_PHOTO
                this.uploadingNow = true
                const formData = new FormData()
                formData.append('file', this.userPhoto)

                this.$store.dispatch('accountant/uploadPhoto', { URL, formData : formData })
                .then(_ => {
                    this.uploadingNow = false
                    bus.$emit('update-user-pic-manager', 'update-now')
                    this.$services.helpers.notification(this.$t('imageUploadSuccessful'), 'success', this)
                    this.clearImageField()
                    this.$store.commit('OPEN_PHOTO_MODAL')
                    // if (res.data.success) {
                    // }
                }).catch((err) => {
                    this.uploadingNow = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                // console.log('refresh')
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.location.reload()
                            })
                        }
                    }
                })
            },
            removeCompanyFromFirm (data) {
                const self = this
                this.$confirm(`${this.$t('removeCompany')}?`, `${this.$t('warning')}`, {
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('cancel'),
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = 'Loading...'

                            const URL = this.$services.endpoints.DISCONNECT_COMPANY + `${this.GET_USER.id}/${data}/`

                            self.$store.dispatch('accountant/disconnectCompany', URL)
                            .then(_ => {
                                done()
                                this.$services.helpers.notification(this.$t('successfulDisconnectCompany'), 'success', this)
                                instance.confirmButtonLoading = false
                                window.Bus.$emit('refetch-company')
                            }).catch((err) => {
                                done()
                                instance.confirmButtonLoading = false
                                if (err.response.status === 400) {
                                    this.$services.helpers.notification(err.response.data, 'error', this)
                                }
                            })
                        } else {
                            done()
                        }
                    }
                }).then(() => {
                    // window.Bus.$emit('fetch-article')
                    // window.Bus.$emit('')
                })
                .catch(() => {

                })
            },
            removeAccountantInFirm (id) {
                const self = this
            },
            validateCompanyNumber (number) {
                if (!number.includes('x')) {
                    return true
                }
                if (number.split('x')[0].length === 6 && number.split('x')[1].length === 4) {
                    return false
                }
                return true
            },
            clearImageField () {
                document.getElementById('upload').value = ''
                this.userPhoto = {}
                this.showCrop = false
                this.croppieImage = null
                this.initializingCropping = false
                this.croppingSuccess = false
                this.showPreviewCropping = false
                this.uploadingNow = false
            },
            clearInviteForm () {
                this.email = ''
                this.firstname = ''
                this.lastname = ''
                this.companyName = ''
                this.companyNumber = ''
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import '~croppie/croppie.css';
@import "~vue-tour/dist/vue-tour.css";

// .app-theme-gray.app-container{
//     background: #fcfcfc !important;
// }
.app-theme-gray.app-container{
    background: #f1f4f6 !important
}
.form-select{
    padding-top: unset !important;
}
.form-control{
    height: unset !important;
    padding: .275rem .75rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.form-control, .form-select{
    border: 1px solid $form-border !important;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    // border: 1px solid #ccc;
    color: white;
    border-radius: 5px;
    background-color: $theme-primary-color;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
</style>
