<template>
	<div class="flex flex-col">
		<div class="fixed flex justify-between w-full z-50 p-3 h-18 bg-blue-900" v-show="showMaintenance">
			<div>
				<span class="text-white" v-if="GET_SYSTEM_NOTIFY.length > 0"><span class="text-base font-semibold">{{
						GET_SYSTEM_NOTIFY[0].announcement_subject
					}}:</span> {{ GET_SYSTEM_NOTIFY[0].announcement_body }}</span>
			</div>
			<div class="cursor-pointer" @click="removeSystemNotification(GET_SYSTEM_NOTIFY[0])">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white font-semibold" viewBox="0 0 20 20"
					 fill="currentColor">
					<path fill-rule="evenodd"
						  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						  clip-rule="evenodd"/>
				</svg>
			</div>
		</div>
		<div class="app-container bg-gray-100 body-tabs-shadow app-theme-gray fixed-header fixed-sidebar"
			 id="app-root-container">
			<NavbarDash/>
			<div id="main-holder" class="app-main single-main">
				<div class="absolute bg-red-30 inset-0 z-100" style="z-index:9999999999" @click="closeCartDiv"
					 v-if="showFullCover"></div>
				<SidebarDash/>
				<div class="app-main__outer">
					<router-view/>
					<FooterDash/>
				</div>
			</div>
			<Notification/>
			<el-dialog
				:visible.sync="modal_invite"
				width="60%"
				:handleClose="handleClose"
				center>
				<div class="container">
					<div class="row">
						<div class="col-md-6 -mt-10">
							<img src="./../../assets/images/illus/mail.svg" class="img-fluid d-none d-lg-block"
								 alt="mail">
							<h2 class="text-2xl text-gray-900 font-semibold">{{ $t('inviteUsers') }}</h2>
							<!-- <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi asperiores adipisci corporis sit repellat in aperiam quia odio doloribus dolore.</span> -->
						</div>
						<div class="col-md-6">
							<form class="flex flex-col space-y-3" @submit.prevent="inviteUser">
								<div class="row">
									<div class="col-md-12">
										<div class="form-group">
											<label for="firstname">{{ $t('firstName') }}</label>
											<input type="text" v-model="firstname"
												   class="form-control font-light text-xs w-full pr-1"
												   v-bind:placeholder="$t('firstName')" required>
										</div>
									</div>
									<div class="col-md-12">
										<div class="form-group">
											<label for="firstname">{{ $t('lastName') }}</label>
											<input type="text" v-model="lastname"
												   class="form-control font-light text-xs w-full pl-1"
												   v-bind:placeholder="$t('lastName')" required>
										</div>
									</div>
									<div class="col-md-12">
										<div class="form-group">
											<label for="email">{{ $t('email') }}</label>
											<input type="email" v-model="email"
												   class="form-control font-light text-xs w-full pr-1"
												   v-bind:placeholder="$t('email')" required>
										</div>
									</div>
									<div class="col-md-12">
										<div class="form-group">
											<label for="role">{{ $t('userRole') }}</label>
											<select class="form-control w-full font-light text-xs" v-model="role"
													required>
												<option selected disabled>{{ $t('userRole') }}</option>
												<option :value="true">{{ $t('accountant') }}</option>
												<option :value="false">{{ $t('other') }}</option>
											</select>
										</div>
									</div>
									<div class="col-md-12">
										<button type="submit" class="btn hover:text-white text-base btn-custom"
												:disabled="creating">
											<span v-if="creating">{{ $t('creating') }}</span>
											<span v-else>{{ $t('create') }}</span>
										</button>
									</div>
								</div>

							</form>
						</div>
					</div>
				</div>
			</el-dialog>

			<el-dialog
				:visible.sync="modal_photo"
				width="60%"
				:handleClose="handleClose"
				center>
				<div class="container">
					<div class="row">
						<div class="col-md-6 -mt-10">
							<img src="./../../assets/images/illus/upload.svg" class="img-fluid d-none d-lg-block"
								 alt="mail">
							<h2 class="text-2xl text-gray-900 font-semibold">{{ $t('upload') }}</h2>
							<!-- <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi asperiores adipisci corporis sit repellat in aperiam quia odio doloribus dolore.</span> -->
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label class="custom-file-upload">
									<input type="file" id="upload" class="inputfile form-control-file" @change="croppie"
										   accept="image/png, image/jpeg, image/jpg"/>
									<i class="iconly-brokenUpload"></i> {{ $t('chooseFile') }}
								</label>
								<!-- <input type="file" name="file" id="file" class="inputfile" /> -->
								<!-- <label for="file">
									</label> -->
							</div>
							<div class="row">
							</div>
							<div v-show="showCrop">
								<vue-croppie ref="croppieRef" :enableOrientation="true" :enableResize="false"
											 :boundary="{ width: 250, height: 250}"
											 :viewport="{width:150, height:150,'type':'circle' }">
								</vue-croppie>
							</div>
							<div slot="footer" class="flex space-x-5" v-show="showCrop">
								<button class="btn hover:text-white text-base btn-custom" @click="crop"
										:disabled="initializingCropping">
									<span v-if="initializingCropping">{{ $t('cropping') }}</span>
									<span v-else>{{ $t('crop') }}</span>
								</button>

								<button class="btn focus:outline-none text-white btn-success" @click="uploadPhoto"
										v-if="croppingSuccess" :disabled='uploadingNow'>
									<span v-if="uploadingNow">{{ $t('uploading') }}.</span>
									<span v-else>{{ $t('fileUpload') }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</el-dialog>

			<el-dialog
				v-bind:title="$t('addAccountNumber')"
				:visible.sync="modal_util"
				width="40%"
				:handleClose="modal_util"
				center>
				<div class="container">
					<form class="flex flex-col space-y-3" @submit.prevent="addAccountNumberForm">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label for="account">{{ $t('accountNumber') }}</label><br>
									<input type="text" v-model="accountNumber"
										   class="form-control font-light text-xs w-full pr-1"
										   v-bind:placeholder="$t('enterNumber')" required>
								</div>
							</div>
						</div>

						<span slot="footer" class="dialog-footer flex justify-end">
                        <button type="submit" class="btn btn-custom hover:text-white ml-3" :disabled="adding">
                            <span v-if="adding">{{ $t('creating') }}</span>
                            <span v-else>{{ $t('addAccountNumber') }}</span>
                        </button>
                    </span>
					</form>
					<div slot="footer" class="flex space-x-5">
						<button class="btn border border-gray-200 rounded" @click="modal_util = false">{{
								$t('cancel')
							}}
						</button>

					</div>
				</div>
			</el-dialog>

			<ModalContainer v-if="uploadFile" title='' @handleClose="closeFileUploadModal" :close="!true">
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-12">
							<div v-if="progress" class="space-y-4">
								<h3 class="font-semibold text-base">{{ $t('processingFile') }}</h3><br>
								<k-progress :percent="percentage" active :show-text="false" :color="color"
											:line-height="10"></k-progress>
							</div>

							<div v-if="uploadSuccess" class="space-y-4">
								<div v-if="uploadResponse.result || uploadResponse.status">
									<h2 class="text-base font-bold"
										v-if="uploadResponse.result && uploadResponse.result.voucher_ids">
										{{ $t('fileSuccessHead') }}</h2>
									<!-- <p>Status: <span v-if="uploadResponse.status === 'Successful'">{{ $t('stateSuccessful') }}</span></p> -->
									<p class="text-blue-900" v-for="(item, i) in Object.keys(uploadResponse.result)"
									   :key="i">
									<ul style="list-style-type:disc !important" v-if="item === 'voucher_ids'">
										<li v-for="(list, a) in uploadResponse.result[item]" :key="a">{{ list }}</li>
									</ul>
									</p>
									<p class="text-dark font-semibold text-xl"
									   v-if="uploadResponse.result.message && parseInt(accounting) === 0">
										{{ $t('fileProcessingSent') }}</p>
									<p class="text-dark font-semibold text-xl"
									   v-if="uploadResponse.result.message && parseInt(accounting) === 1">
										{{ $t('fileProcessingSentVisma') }}</p>
									<p class="text-dark font-semibold text-xl"
									   v-if="uploadResponse.result.message === false">
										{{ $t('fileProcessingSent') }}
									</p>
								</div>
							</div>
							<div v-if="uploadFailure" class="space-y-4">
								<h2 class="text-base text-red-600">{{ $t('fileResponseError') }}</h2>
								<div v-if="uploadResponse.result !== undefined">
									<p class="text-blue-900" v-for="(item, i) in Object.keys(uploadResponse.result)"
									   :key="i">
										<span v-if="item !== 'task_id'">{{ item }} : {{
												uploadResponse.result[item]
											}}</span>
									</p>
								</div>
								<p v-if="uploadResponse.info !== undefined">{{ uploadResponse.info }}</p>
								<p v-else>{{ uploadResponse.data }}</p>
							</div>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>

			<ModalContainer v-if="performSingleTest" title='' @handleClose="closeTestModal" :close="!true">
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-12">
							<div v-if="progress" class="space-y-4">
								<h3 class="font-semibold text-base">{{ $t('processingFile') }}</h3><br>
								<!-- <k-progress :percent="percentage" active :show-text="false" :color="color" :line-height="10"></k-progress> -->
								<div class="flex justify-center">
									<svg class="animate-spin mr-2 h-5 w-5 text-gray-400"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
								</div>
							</div>
							<div v-if="uploadSuccess" class="space-y-4">
								<div v-if="uploadResponse.result">
									<h2 class="text-base font-bold"
										v-if="Object.keys(uploadResponse.result) && uploadResponse.result.invoice_ids && uploadResponse.result.invoice_ids.length > 0">
										{{ $t('testOrderHead') }}</h2>
									<p class="text-blue-900" v-for="(item, i) in Object.keys(uploadResponse.result)"
									   :key="i">
									<ul style="list-style-type:disc !important" v-if="item === 'invoice_ids'">
										<!-- && uploadResponse.result[item].length > 0-->
										<li v-for="(list, a) in uploadResponse.result[item]" :key="a">{{ list }}</li>
									</ul>
									<span class="text-base text-dark font-bold"
										  v-if="item === 'order_ids' && uploadResponse.result[item].length > 0">{{
											$t('orderTopicSuccess')
										}}</span>
									<ul style="list-style-type:disc !important"
										v-if="item === 'order_ids' && uploadResponse.result[item].length > 0">
										<li v-for="(list, a) in uploadResponse.result[item]" :key="a">{{ list }}</li>
									</ul>
									<span class="text-base text-dark font-bold"
										  v-if="item === 'payment_ids' && uploadResponse.result[item].length > 0">{{
											$t('paymenetTopicSuccess')
										}}</span>
									<ul style="list-style-type:disc !important"
										v-if="item === 'payment_ids' && uploadResponse.result[item].length > 0">
										<li v-for="(list, a) in uploadResponse.result[item]" :key="a">{{ list }}</li>
									</ul>
									<span class="text-base text-dark font-bold"
										  v-if="item === 'voucher_ids' && uploadResponse.result[item].length > 0">{{
											$t('testOrderHead')
										}}</span>
									<ul style="list-style-type:disc !important"
										v-if="item === 'voucher_ids' && uploadResponse.result[item].length > 0">
										<li v-for="(list, a) in uploadResponse.result[item]" :key="a">{{ list }}</li>
									</ul>
									</p>
									<p class="text-dark font-semibold text-xl"
									   v-if="uploadResponse.result.hasOwnProperty('message') && parseInt(accounting) === 0">
										{{ $t('noDataSentFortnox') }}</p>
									<p class="text-dark font-semibold text-xl"
									   v-if="uploadResponse.result.hasOwnProperty('message') && parseInt(accounting) === 1">
										{{ $t('noDataSentVisma') }}</p>
								</div>

							</div>
							<div v-if="uploadFailure" class="space-y-4">
								<h2 class="text-base text-red-600">{{ $t('testResponseError') }}</h2><br>
								<p class="text-blue-900" v-for="(item, i) in Object.keys(uploadResponse)" :key="i">
									<span v-if="item !== 'task_id'">{{ item }} : {{ uploadResponse[item] }}</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>
			<ModalContainer v-if="appSyncDetailModal" title='' @handleClose="closeTestModal"
							:close="!appSyncDetailModal" width='w-1/2'>
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-12" v-if="['Success'].includes(syncDict.status)"
							 style="height: 200px;overflow-y: scroll;overflow-x: hidden">

							<div
								v-if="['all_product','all_order','payment'].includes(syncDict.sync_type)">
								<div class="row mb-3" v-if="parseSyncData(syncDict.passed_number_in_app).length > 0">
									<div class="col-md-12">
										<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_product'">{{
												$t('articleSync')
											}}</h4>
										<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_order'">{{
												$t('orderSync')
											}}</h4>
										<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'payment'">{{
												$t('paymentSync')
											}}</h4>
									</div>
									<div class="col-md-12">
										<div class="grid grid-cols-3 gap-3">
											<app-sync-card :item="dt"
														   v-for="(dt,i) in parseSyncData(syncDict.passed_number_in_app)"
														   :key="i"/>

										</div>
									</div>

								</div>
								<hr style="padding-top: 10px;
    padding-bottom: 3px;">
								<div class="row" v-if="parseSyncData(syncDict.failed_number_in_app).length > 0">
									<div class="col-md-12">
										<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_product'">{{
												$t('articleNotSync')
											}}</h4>
										<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_order'">{{
												$t('orderNotSync')
											}}</h4>
										<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'payment'">{{
												$t('paymentNotSync')
											}}</h4>
									</div>
									<div class="col-md-12">
										<div class="grid grid-cols-3 gap-3">
											<app-sync-card :item="dt"
														   v-for="(dt,i) in parseSyncData(syncDict.failed_number_in_app)"
														   :key="i"/>
										</div>
									</div>
									<div class="col-md-12">
										<h4 style="margin-top: 15px;" class="head-text">{{ $t('reason') }}</h4>
										<p style="overflow-wrap: break-word;margin-top: 5px">
											{{ syncDict.long_description }}</p>
									</div>
								</div>
							</div>
							<p style="overflow-wrap: break-word;"
							   v-else>
								{{ syncDict.long_description }}
							</p>
						</div>
						<div class="col-md-12" v-if="['Failed'].includes(syncDict.status)"
							 style="height: 200px;overflow-y: scroll;overflow-x: hidden">
							<!--							<h4 class="mb-3">{{ $t('message') }}</h4>-->
							<p style="overflow-wrap: break-word;">
								{{ syncDict.long_description }}
							</p>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>

			<ModalContainer v-if="skuModal" title='' @handleClose="closeTestModal" :close="!true">
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-12">
							<div v-if="progress" class="space-y-4">
								<h3 class="font-semibold text-base">{{ $t('skuFileProcessing') }}</h3><br>
								<!-- <k-progress :percent="percentage" active :show-text="false" :color="color" :line-height="10"></k-progress> -->
								<div class="flex justify-center">
									<svg class="animate-spin mr-2 h-5 w-5 text-gray-400"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
										<path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
								</div>
							</div>
							<div v-if="uploadSuccess" class="space-y-4">
								<h2 class="text-base font-bold"
									v-if="Object.keys(uploadResponse) && uploadResponse.affected_products !== null">
									{{ $t('uniqueSkuNumber') }}</h2>
								<p class="text-blue-900" v-for="(item, i) in Object.keys(uploadResponse)" :key="i">
								<ul style="list-style-type:disc !important"
									v-if="item === 'affected_products' && uploadResponse[item] !== null && uploadResponse[item].length > 0">
									<li v-for="(list, a) in uploadResponse[item]" :key="a">{{ list }}</li>
								</ul>
								</p>
								<p class="text-dark font-semibold text-xl"
								   v-if="uploadResponse.affected_products === null || uploadResponse.affected_products === undefined">
									{{ $t('perfectSku') }}</p>
							</div>
							<div v-if="uploadFailure" class="space-y-4 text-center">
								<h2 class="text-base text-red-600">{{ $t('testResponseError') }}</h2><br>
								<span class="text-red-600 text-center">{{ uploadResponse }}</span>
							</div>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>
			<ModalContainer v-if="openProductModal" :title="'Product filter'" @handleClose="closeTestModal"
							:close="!true">
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<input type="text" class="form-control font-light text-xs w-full pl-1"
									   v-bind:placeholder="$t('search')" required>
							</div>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>
			<ModalContainer v-if="openCancelSubscription" :title="''" width='lg:w-1/2 md:w-full'
							@handleClose="closeTestModal"
							:close="!true">
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-12">
									<h4 class="font-weight-bold">{{ $t('cancellation_information') }}</h4>
								</div>
								<div class="col-md-12">
									<div style="margin-top: 36px;">
										<p>
											{{ $t('cancellation_text_one') }}

										</p>
										<p>
											{{ $t('cancellation_text_two') }}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label>{{ $t('reasons') }}</label>
										<select class="form-control"
												v-model="cancelSubscriptionPayload.cancellation_reason">
											<option disabled>{{ $t('select_reasons') }}...</option>
											<option value="1"> {{ $t('change_platform') }}</option>
											<option value="2"> {{ $t('company_closedown') }}</option>
											<option value="3"> {{ $t('not_please_with_connector') }}</option>
											<option value="4"> {{ $t('other') }}</option>
										</select>
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group">
										<label>{{ $t('comments') }}</label>
										<textarea name="" class="form-control"
												  v-model="cancelSubscriptionPayload.cancellation_comments" id=""
												  cols="50" rows="10"></textarea>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12" v-if="selectedSub.fixed_contract">
							<div class="alert alert-info">
								{{ $t('subscription_cancel_text') }} {{ getContractEnd(selectedSub.created) }}
							</div>
						</div>
						<div class="col-md-12">
							<div class="center-elem">
								<button class="btn btn-primary mt-3 btn-pink"
										v-on:click="submitCancelSubscription"><span
									v-if="loading">{{ $t('cancelling') }}...</span><span
									v-else>Skicka</span></button>
							</div>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>
			<ModalContainer v-if="openModalError" :title="$t('info')" width='lg:w-1/2 md:w-full'
							@handleClose="closeTestModal"
							:close="!true">
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-12">
							<div class="row">
								<div class="col-md-12">
									<div style="margin-top: 36px;">
										<p>
											{{ modalErrorMessage }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>
			<ModalContainer v-if="openActivateModal" :title="$t('activateSubscription')" width='lg:w-1/2 md:w-full'
							@handleClose="closeTestModal"
							:close="!true">
				<div class="container" slot="body">
					<div class="row">
						<div class="col-md-12">
							<div class="row">
								<div class="col-md-12">
									<div style="margin-top: 36px;">
										<p class="py-1" v-if="GET_LANG === 'se'">Vänligen läs <a target="_blank"
																								 href="/automatisera/faq"
																								 class="text-pink">FAQs</a>
											för denna integration innan du aktiverar integrationen</p>
										<p class="py-1" v-else>Please make sure to read the <a target="_blank"
																							   href="/automatisera/faq"
																							   class="text-pink">FAQs</a>
											for this connector</p>
										<p class="py-1" v-if="GET_LANG === 'se'">Läs gärna också igenom <a
											href="/automatisera/terms-and-condition" target="_blank" class="text-pink">Avtalsvillkoren </a>
											så att du är väl införstådda kring vad som ingår i tjänsten</p>
										<p class="py-1" v-else>Also ensure you have read the <a
											href="/automatisera/terms-and-condition" target="_blank" class="text-pink">Terms
											of Services</a> to be
											fully clear on
											which
											parties have what responsibilities</p>
										<div class="flex py-3">
											<span v-if="GET_LANG === 'se'">Jag bekräftar att jag har läst ovan</span>
											<span v-else>I confirm I have read all necessary information</span>
											<span>
											<input
												v-model="subscriptionActivate"
												type="checkbox" class="form-control h-12 checkbox-height" style="    height: 20px !important;
    width: 20px !important;
    margin-left: 10px;padding: 0px !important">
										</span></div>
									</div>
								</div>
								<div class="col-md-12">
									<button :disabled='activating || subscriptionActivate === false'
											@click="activateSubscription"
											class="mt-3 c-btn btn-activate mb-3 bg-green-500 activate-btn px-10 text-white text-base py-2 rounded shadow-xl float-right">
										<div class="flex items-center m-auto" v-if="activating">
											<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<circle class="opacity-25" cx="12" cy="12" r="10"
														stroke="currentColor" stroke-width="4"></circle>
												<path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
											</svg>
											{{ $t('activating') }}
										</div>
										<span v-else>{{ $t('activateNow') }}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</ModalContainer>

			<el-drawer
				:title="$t('notify')"
				:visible.sync="notificationDrawer"
				size="27%"
				custom-class="notifiy-drawer-style"
				:direction="direction">
				<div class="container">
					<div class="row">
						<div class="col-md-12 flex-none">
							<div class="form-group">
								<input type="text" class="form-control font-light text-xs w-full pl-1"
									   v-bind:placeholder="$t('search')" required>
							</div>
						</div>
						<div class="col-md-12 flex-1 h-screen overflow-y-scroll">
							<div class="flex flex-col" v-for="(notify, i) in GET_NOTIFICATION.all_notifications"
								 :key="i">
								<NotificationHandler :notify="notify" :archive="true"/>
							</div>
						</div>
					</div>
				</div>
			</el-drawer>
			<el-drawer
				:title="$t('createSyncEntry')"
				:visible.sync="appSyncModal"
				:direction="direction"
				:size="`40%`"
				custom-class="demo-drawer"
				ref="drawer"
			>
				<div class="demo-drawer__content">
					<app-synchronize-form :key="componentID"/>
				</div>
			</el-drawer>
			<el-drawer
				:title="$t('updateCustomAccount')"
				:visible.sync="customAccountSlider"
				:direction="direction"
				:size="`${customAccountSliderSize}%`"
				custom-class="demo-drawer"
				ref="drawer-custom-account"
			>
				<div class="demo-drawer__content">
					<edit-custom-account :account="customAccount" v-if="customAccountSlider"/>
				</div>
			</el-drawer>
			<el-dialog
				:visible.sync="modal_user_create_faq"
				width="50%"
				:handleClose="handleCloseFaq"
				center>
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h2 data-cy='faq-modal-head'
								class="heading font-semibold text-gray-900 text-4xl font-medium">
								{{ $t('addFrequent') }}</h2>
							<!-- <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste harum minus tempore quam amet illo laborum. Id officiis quisquam iusto.</span> -->
						</div>
					</div>
					<form @submit.prevent="addFaqData">
						<div class="row mt-4">
							<div class="col-md-12 mb-2">
								<div class="form-group">
									<label for="question" class="text-base text-gray-800 font-medium">{{
											$t('question')
										}}</label>
									<!-- <input type="text"  class="form-control" required> -->
									<textarea cols="30" data-cy='question' rows="5" v-model="faqObj.question"
											  class='form-control font-light text-xs'
											  :placeholder="$t('genericQuestion')"></textarea>
									<!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<div class="form-group">
									<label for="question" class="text-base text-gray-800 font-medium">{{
											$t('email')
										}}</label>
									<input type="email" class="form-control" v-model="faqObj.customer_email_request"
										   required>
									<!--								<textarea cols="30" data-cy='question' rows="5" v-model="faqObj.question"-->
									<!--										  class='form-control font-light text-xs'-->
									<!--										  :placeholder="$t('genericQuestion')"></textarea>-->
									<!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<button type="submit" data-cy='btn-add-faq' :disabled='creatingFaq'
											class="btn hover:text-white text-base btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creatingFaq" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {{ $t('creating') }}
                                </span>
										<span v-else>{{ $t('add') }}</span>
									</button>
								</div>
							</div>
						</div>
					</form>

					<div slot="footer" class="flex space-x-5">

					</div>
				</div>
			</el-dialog>

		</div>
		<v-tour name="dashboard" :steps="dashboardStep" :options="myOptions"
				:callbacks="dashboardTourCallback"></v-tour>
		<v-tour name="appSettingTab1" :steps="appSetting.tab1" :options="myOptions"
				:callbacks="appSetting.callbackTab1"></v-tour>
		<v-tour name="appSettingTab2" :steps="appSetting.tab2" :options="myOptions"
				:callbacks="appSetting.callbackTab2"></v-tour>
		<v-tour name="appSettingTab3" :steps="appSetting.tab3" :options="myOptions"
				:callbacks="appSetting.callbackTab3"></v-tour>
		<v-tour name="addApps" :steps="addAppPage" :options="myOptions" :callbacks='addAppCallback'></v-tour>
		<v-tour name="billing" :steps="billingPage" :options="myOptions" :callbacks='billingCallback'></v-tour>
		<v-tour name="faq" :steps="faqPage" :options="myOptions" :callbacks='faqCallback'></v-tour>

	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {v4 as uuidv4} from 'uuid'
import KProgress from 'k-progress'
import bus from './../../bus'
import NavbarDash from '@/components/Navbar'
import SidebarDash from '@/components/Sidebar'
import FooterDash from '@/components/Footer'
import ModalContainer from '@/customComponent/DownloadModal'
import NotificationHandler from '@/customComponent/NotificationHandler'
// import moment from 'moment'
import {getDedicatedServerInstance} from "../../services/vue-axios/dedicatedServerAxios";
import axios from "axios";
import {Dialog, Drawer} from 'element-ui'
import VueCroppie from 'vue-croppie'
import {destroyPath} from '../../services/storage-window'
import AppSynchronizeForm from "./components/AppSynchronizeForm";
import appSyncCard from './components/appSyncCard'
import {SKU_VALIDATOR} from "../../app/index";
import editCustomAccount from "./components/editCustomAccount";

Vue.use(Dialog)
Vue.use(Drawer)
Vue.use(VueCroppie)

export default {
	name: 'main-root-dash',
	components: {
		appSyncCard,
		NavbarDash,
		editCustomAccount,
		SidebarDash,
		ModalContainer,
		'k-progress': KProgress,
		NotificationHandler,
		FooterDash,
		AppSynchronizeForm,
		Notification: () => import('./../../customComponent/NotificationAlert')
	},
	data() {
		return {
			baseURL: window.location.origin,
			firstname: '',
			lastname: '',
			email: '',
			accountNumber: '',
			role: 'Select role',
			color: '#0DB23C',
			percentage: 0,
			openCancelSubscription: false,
			creating: false,
			adding: false,
			loading: false,
			modalVisible: false,
			performSingleTest: false,
			appSyncDetailModal: false,
			skuModal: false,
			userObject: null,
			userPhoto: {},
			syncDict: {long_description: '', status: '', passed_number_in_app: [], 'failed_number_in_app': []},
			showCrop: false,
			croppieImage: null,
			initializingCropping: false,
			croppingSuccess: false,
			showPreviewCropping: false,
			uploadingNow: false,
			subId: null,
			creatingFaq: false,
			faqObj: {
				question: '',
				visible_for_customers_only: true,
				hidden: true,
				customer_email_request: '',
			},
			showFullCover: false,
			uploadFile: false,
			progress: false,
			uploadSuccess: false,
			uploadFailure: false,
			openProductModal: false,
			uploadResponse: '',
			drawer: false,
			direction: 'rtl',
			showMaintenance: false,
			accounting: null,
			myOptions: {
				useKeyboardNavigation: false,
				// startTimeout : 1000,
				labels: {
					buttonNext: this.$t('nextTour'),
					buttonPrevious: this.$t('previousTour'),
					buttonSkip: this.$t('skipTour'),
					buttonStop: this.$t('finishedTour')
				}
			},
			dashboardStep: [
				{
					target: '.v-step-0',
					content: this.$t('dashboardTourProfilePicture')
				},
				{
					target: '.v-step-1',
					content: this.$t('dashboardTourTranslationButton')
				},
				{
					target: '.v-step-2',
					content: this.$t('dashboardTourNotification'),
					params: {
						placement: 'top'
					}
				},
				{
					target: '.v-step-3',
					content: this.$t('dashboardTourMetrics')
				},
				{
					target: '.v-step-4',
					content: this.$t('dashboardTourTransactionHistory'),
					params: {
						placement: 'top'
					}
				},
				{
					target: '.v-step-5',
					content: this.$t('dashboardTourAppSetting'),
					params: {
						placement: 'left'
					}
				},
			],
			appSetting: {
				tab1: [
					{
						target: '.v-app-1',
						content: this.$t('appTabInfoGuide'),
						params: {
							placement: 'right'
						}
					},
					{
						target: '.v-app-2',
						content: this.$t('appTabContinue')
					},
				],
				tab2: [
					{
						target: '.v-app-3',
						content: this.$t('appTabConfigSettings'),
						params: {
							placement: 'top'
						}
					},
					{
						target: '.v-app-4',
						content: this.$t('appTabUpdateBtn')
					},
					{
						target: '.v-app-5',
						content: this.$t('appTabConfigContinue')
					},

				],
				tab3: [
					{
						target: '.v-app-6',
						content: this.$t('appTabSendTest')
					},
					{
						target: '.v-app-7',
						content: this.$t('appTabActivate')
					},
					{
						target: '.v-app-8',
						content: this.$t('appTabAddIntegration'),
						params: {
							placement: 'left'
						}
					},
				],
				callbackTab1: {
					onSkip: this.completeAllTour,
					onFinish: this.completeAllTour
				},
				callbackTab2: {
					onSkip: this.completeAllTour,
					onFinish: this.completeAllTour
				},
				callbackTab3: {
					onSkip: this.completeAllTour,
					onFinish: this.completeAllTour
				},
			},
			addAppPage: [
				{
					target: '.v-addapp-1',
					content: this.$t('addAppFilter')
				},
				{
					target: '.v-addapp-2',
					content: this.$t('addAppSearchBar')
				},
				{
					target: '.v-addapp-3',
					content: this.$t('addAppIntegration'),
					params: {
						placement: 'right'
					}
				},
				{
					target: '.v-addapp-4',
					content: this.$t('addAppClickPayment'),
					params: {
						placement: 'right'
					}
				},
			],
			billingPage: [
				{
					target: '.v-bill-1',
					content: this.$t('billingPage')
				},
				{
					target: '.v-bill-2',
					content: this.$t('billingUpdate')
				},
				{
					target: '.v-bill-3',
					content: this.$t('billingTable')
				},
				{
					target: '.v-bill-4',
					content: this.$t('billingFaq'),
					params: {
						placement: 'right'
					}
				},
			],
			faqPage: [
				{
					target: '.v-faq-1',
					content: this.$t('faqSearchBar')
				},
				{
					target: '.v-faq-2',
					content: this.$t('faqInviteStaff'),
					params: {
						placement: 'right'
					}
				},
			],
			modalTour: [
				{
					target: '.v-modal-1',
					content: this.$t('inviteModalTour')
				},
			],
			dashboardTourCallback: {
				onSkip: this.completeAllTour,
				onFinish: this.completeAllTour
			},
			addAppCallback: {
				onSkip: this.completeAllTour,
				onFinish: this.completeAllTour
			},
			billingCallback: {
				onSkip: this.completeAllTour,
				onFinish: this.completeAllTour
			},
			faqCallback: {
				onSkip: this.completeAllTour,
				onFinish: this.completeAllTour
			},
			selectedSub: [],
			selectedApp: {},
			cancelSubscriptionPayload: {
				cancellation_comments: '',
				cancellation_reason: ''
			},
			appSyncModal: false,
			customAccountSlider: false,
			customAccount: {},
			customAccountSliderSize: 40,
			componentID: '',
			modalErrorMessage: '',
			openModalError: false,
			openActivateModal: false,
			activating: false,
			subscriptionActivate: false,
			subscription: {}
		}
	},
	watch: {
		$route(to, from) {
			const {key, acc} = to.query
			if (key !== undefined && key !== null) {
				this.subId = key
				this.accounting = acc
			}


			if (key === undefined) {
				window.Bus.$emit('reset-tab')
			}
			if (from.name === 'billings') {
				this.$tours['billing'].stop()
			}
			if (from.name === 'addapps') {
				this.$tours['addApps'].stop()
			}
			if (from.name === 'dashboard') {
				this.$tours['dashboard'].stop()
			}
			if (from.name === 'appsettings') {
				this.$tours['appSettingTab1'].stop()
				this.$tours['appSettingTab2'].stop()
				this.$tours['appSettingTab3'].stop()
			}
			if (from.name === 'faq') {
				this.$tours['faq'].stop()
			}
		},
	},
	computed: {
		notificationDrawer: {
			get: function () {
				return this.GET_NOTIFICATION_DRAWER
			},
			set: function () {
				return this.$store.commit('utility/OPEN_NOTIFICATION_DRAWER')
			}
		},
		appSyncDrawer: {
			get: function () {
				return this.GET_APP_SYNC_DRAWER
			},
			set: function () {
				return this.$store.commit('utility/OPEN_APP_SYNC_DRAWER')
			}
		},
		modal_invite: {
			get: function () {
				return this.MODAL_INVITE
			},
			set: function () {
				return this.$store.commit('utility/OPEN_INVITE_MODAL')
			}
		},
		modal_photo: {
			get: function () {
				return this.MODAL_PHOTO
			},
			set: function () {
				return this.$store.commit('utility/OPEN_PHOTO_MODAL')
			}
		},
		modal_util: {
			get: function () {
				return this.MODAL_UTIL
			},
			set: function () {
				return this.$store.commit('utility/OPEN_MODAL_UTIL')
			}
		},
		modal_user_create_faq: {
			get: function () {
				return this.GET_MODAL_USER_FAQ_CREATE_MODAL
			},
			set: function () {
				return this.$store.commit('utility/OPEN_MODAL_USER_FAQ_CREATE_MODAL')
			}
		},
		getMessageKey() {
			return this.GET_LANG === 'en' ? 'message' : 'message_swe'
		},
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			MODAL_INVITE: 'utility/MODAL_INVITE',
			MODAL_PHOTO: 'utility/MODAL_PHOTO',
			MODAL_UTIL: 'utility/MODAL_UTIL',
			GET_MODAL_USER_FAQ_CREATE_MODAL: 'utility/MODAL_USER_CREATE_FAQ',
			GET_NOTIFICATION_DRAWER: 'utility/GET_NOTIFICATION_DRAWER',
			GET_NOTIFICATION: 'customer/GET_NOTIFICATION',
			GET_SYSTEM_NOTIFY: 'auth/GET_SYSTEM_NOTIFY',
			GET_USER_UTIL: 'accountant/GET_USER_UTIL',
			GET_USER:'customer/GET_USER',
			GET_IN_HOUSE_UTIL: 'house/GET_IN_HOUSE_UTIL',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_CUSTOMER_TOUR: 'customer/GET_CUSTOMER_TOUR',
			GET_SUBSCRIPTIONS: 'customer/GET_SUBSCRIPTION',
			GET_MASTER_ACCOUNTANT: 'accountant/GET_MASTER_ACCOUNTANT',
			GET_COLLEAGUE: 'colleague/GET_COLLEAGUE',
			GET_ASSIST_ACCOUNTANT: 'assistanceAccountant/GET_ASSIST_ACCOUNTANT',
			GET_LANG: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		destroyPath()
		const self = this
		document.addEventListener('contextmenu', function (e) {
			e.preventDefault()
		})

		this.getSystemNotification()

		const {key} = this.$route.query
		if (key !== undefined && key !== null) {
			this.subId = key
		}

		window.Bus.$on('sign-out', _ => {
			this.$store.commit('customer/REMOVE_COMPANY_USER', null)
			if (this.$route.path !== '/login') {
				this.$router.replace({name: 'login'})
			}
		})
		window.Bus.$on('open-custom-account-edit', e => {
			this.customAccount = e
			this.customAccountSlider = true
		})
		window.Bus.$on('show-cover', e => {
			if (e === true) {
				this.showFullCover = true
			} else {
				this.showFullCover = false
			}
		})

		bus.$on('perform-single-test', e => {
			this.performSingleTest = true
			this.progress = true
			this.beginTest(e)
		})

		// bus.$on('redirect-user-dashboard', _ => {
		// 	if (self.GET_IN_HOUSE_UTIL === 'true') {
		// 		self.$router.replace({name: 'regulardash'})
		// 	} else if (self.GET_USER_UTIL === 'true') {
		// 		self.$router.replace({name: 'accountantdash'})
		// 	} else if (self.GET_MASTER_ACCOUNTANT) {
		// 		self.$router.replace({name: 'master_accountant_dashboard'})
		// 	} else if (self.GET_ASSIST_ACCOUNTANT) {
		// 		self.$router.replace({name: 'accountant_assistance'})
		// 	} else if (self.GET_COLLEAGUE) {
		// 		self.$router.replace({name: 'colleague_settings'})
		// 	}
		// })

		window.Bus.$on('upload-setting-file', e => {
			this.uploadFile = true
			this.beginFileSettingUpload(e)
			this.progress = true
		})

		window.Bus.$on('open-notification-drawer', e => {
			self.$store.commit('utility/OPEN_MODAL_UTIL')
		})
		window.Bus.$on('open-app-sync-drawer', e => {
			this.componentID = new Date().getMilliseconds()
			this.appSyncModal = true
		})

		window.Bus.$on('start-dashboard-tour', _ => {
			// console.log('fired')
			setTimeout(() => {
				this.runDashboardTour()
			}, 1000)
		})

		window.Bus.$on('start-appsetting-tab1-tour', _ => {
			this.runAppSettingTab1Tour()
			this.$tours['appSettingTab2'].stop()
			this.$tours['appSettingTab3'].stop()
		})

		window.Bus.$on('start-appsetting-tab2-tour', _ => {
			this.runAppSettingTab2Tour()
			this.$tours['appSettingTab1'].stop()
			this.$tours['appSettingTab3'].stop()
		})

		window.Bus.$on('start-appsetting-tab3-tour', _ => {
			this.runAppSettingTab3Tour()
			this.$tours['appSettingTab1'].stop()
			this.$tours['appSettingTab2'].stop()
		})

		window.Bus.$on('start-integration-tour', _ => {
			this.runAddApp()
		})

		window.Bus.$on('start-billing-tour', _ => {
			this.runBillingTour()
		})

		window.Bus.$on('start-faq-tour', _ => {
			this.runFaqTour()
		})

		bus.$on('sku-modal', e => {
			this.skuModal = true
			this.progress = true
			if (['pro', 'growth', 'enterprise'].includes(e.server) === true) {
				this.beginSKUModalOnDedicatedServer(e)
			} else {
				this.beginSKUModal(e)
			}


		})
		window.Bus.$on('open-product-filter', _ => {
			this.openProductModal = true
		})
		window.Bus.$on('open-app-sync-detail', e => {
			this.syncDict = e
			this.appSyncDetailModal = true
		})
		window.Bus.$on('open-cancel-subscription', e => {

			this.cancelSubscriptionPayload = {
				cancellation_comments: '',
				cancellation_reason: ''
			}
			this.selectedApp = e
			if (this.GET_SUBSCRIPTIONS) {
				this.selectedSub = this.GET_SUBSCRIPTIONS.active_subscriptions.filter(item => item.app_type === parseInt(this.selectedApp.type))
			}
			this.openCancelSubscription = true
			// if (['pro', 'growth', 'enterprise'].includes(e.server) === true) {
			// 	this.selectedSub = [{
			// 		'id': e.key,
			// 		'created': e.created,
			// 		'fixed_contract': e.fixed_contract,
			// 		'server': e.server
			// 	}]
			// } else {
			//
			//
			// }


			// if (this.selectedSub.length > 0){
			// 	this.getSubscription(this.selectedSub[0].id)
			// }
		})
		window.Bus.$on('output-modal-error', e => {
			this.modalErrorMessage = e.message
			this.openModalError = true
		})
		window.Bus.$on('open-activate-modal', e => {
			this.subscription = e.subscription
			this.openActivateModal = true
		})

		window.Bus.$on('open-user-add-faq', e => {
			this.$store.commit('utility/OPEN_MODAL_USER_FAQ_CREATE_MODAL')
		})
	},
	beforeMount() {
		if (this.USER_TOKEN) {
			if (this.GET_USER_UTIL === 'true' && !this.GET_USER_COMPANY_ID) {
				this.$router.replace({name: 'accountantdash'})
			}
		} else {
			this.$store.commit('auth/CLEAR_AUTH_USER', null)
			this.$router.replace({name: 'login'})
		}
	},
	methods: {
		addFaqData() {
			if (this.faqObj.question === '' || this.faqObj.customer_email_request === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.creatingFaq = true
				this.faqObj.customer_name = this.GET_USER.first_name + ' '+ this.GET_USER.last_name
				this.$store.dispatch('house/addFaqData', this.faqObj)
					.then(_ => {
						this.creatingFaq = false
						this.$services.helpers.notification(this.$t('customerFaqAdded'), 'success', this)
						setTimeout(() => {
							this.$store.commit('utility/OPEN_MODAL_USER_FAQ_CREATE_MODAL')
						}, 1000)
						// if (res.status === 201) {
						// }
					}).catch((err) => {
					this.creatingFaq = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})
			}
		},
		parseSyncData: function (data) {
			let dt = data.replace('[', '').replace(']', '').split(',')
			if (dt.length > 0) {
				if (dt[0] === "") {
					return []
				} else {
					return dt
				}
			} else {
				return []
			}
			// if (Array.isArray(data)) {
			// 	console.log('am here')
			// 	return data
			// } else if (typeof data === String) {
			// 	console.log('am here s')
			// 	data.split(',')
			// } else {
			//
			// 	console.log('am here')
			// 	return []
			// }

		},
		getContractEnd() {
			return this.$services.moment(this.selectedApp.created).add(12, 'months').subtract(1, "days").format('DD-MM-YYYY')
		},
		submitCancelSubscription() {
			// this method handles cancell of subscription of an app
			if (this.cancelSubscriptionPayload.cancellation_reason === '') {
				return this.$services.helpers.notification('Orsak för avbokning krävs', 'error', this)
			}
			if (this.cancelSubscriptionPayload.cancellation_comments === '') {
				return this.$services.helpers.notification('Kommentarer krävs', 'error', this)
			}
			if (this.selectedSub.length === 0) {
				return this.$services.helpers.notification('Något gick fel när din förfrågan gjordes, försök snart igen', 'error', this)
			}
			let data = {
				cancellation_reason: this.cancelSubscriptionPayload.cancellation_reason,
				cancellation_comments: this.cancelSubscriptionPayload.cancellation_comments,
				subscription_id: this.selectedSub[0].id,
			}
			let payload = {
				URL: `${this.$services.endpoints.SUBSCRIPTIONS}${this.selectedSub[0].id}/cancel/`,
				data: data
			}
			this.loading = true
			this.$store.dispatch('customer/cancelAppSubscription', payload).then(resp => {
				this.loading = false
				this.openCancelSubscription = false
				window.Bus.$emit('reload-current-subscription', {id: this.selectedSub[0].id})
				return this.$services.helpers.notification('Tack din uppsägning är nu mottagen och registrerad',
					'success', this
				)
			}).catch(err => {
				this.loading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.submitCancelSubscription()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					} else {
						return this.$services.helpers.notification(err.response.data.message,
							'error', this
						)
					}
				} else {

					return this.$services.helpers.notification(this.$t('generic_error'), 'error', this)
				}
			})
			// if ('server' in this.selectedSub[0]) {
			// 	if (['pro', 'growth', 'enterprise'].includes(this.selectedSub[0].server)) {
			// 		const http = getDedicatedServerInstance({baseURL: this.$services.helpers.getDedicatedServer(this.selectedSub[0].server)})
			// 		const URL = `${this.$services.endpoints.SUBSCRIPTIONS}${this.selectedSub[0].id}/cancel/`
			// 		http.put(URL, data).then(() => {
			// 			this.loading = false
			// 			this.openCancelSubscription = false
			// 			window.Bus.$emit('reload-current-subscription', {id: this.selectedSub[0].id})
			// 			return this.$services.helpers.notification('Subscription cancellation successful',
			// 				'success', this
			// 			)
			// 		}).catch(err => {
			// 			this.loading = false
			// 			if (err.response.status === 400) {
			// 				if (err.response.data.code === 401) {
			// 					this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
			// 						.then(_ => {
			// 							this.submitCancelSubscription()
			// 						})
			// 						.catch(_ => {
			// 							this.$store.commit('auth/CLEAR_AUTH_USER', null)
			// 							window.Bus.$emit('sign-out')
			// 						})
			// 				} else {
			// 					return this.$services.helpers.notification(err.response.data.message,
			// 						'error', this
			// 					)
			// 				}
			// 			} else {
			// 				return this.$services.helpers.notification(this.$t('generic_error'), 'error', this)
			// 			}
			// 		})
			// 	}
			// } else {
			//
			// }


		},
		getSubscription(subscription_id) {
			this.$store.dispatch('customer/getSubscription', {subscription_id})
				.then(res => {
					console.log(res)
				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getSubscription(subscription_id)
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})

		},
		runDashboardTour() {
			if (!this.GET_CUSTOMER_TOUR.dashboard) {
				this.$tours['dashboard'].start()
			}
		},
		runAppSettingTab1Tour() {
			if (!this.GET_CUSTOMER_TOUR.appTab1) {
				this.$tours['appSettingTab1'].start()
			}
		},
		runAppSettingTab2Tour() {
			if (!this.GET_CUSTOMER_TOUR.appTab2) {
				this.$tours['appSettingTab2'].start()
			}
		},
		runAppSettingTab3Tour() {
			if (!this.GET_CUSTOMER_TOUR.appTab3) {
				this.$tours['appSettingTab3'].start()
			}
		},
		runAddApp() {
			if (!this.GET_CUSTOMER_TOUR.addAppPage) {
				this.$tours['addApps'].start()
			}
		},
		runBillingTour() {
			if (!this.GET_CUSTOMER_TOUR.billingPage) {
				this.$tours['billing'].start()
			}
		},
		runFaqTour() {
			if (!this.GET_CUSTOMER_TOUR.faqPage) {
				this.$tours['faq'].start()
			}
		},
		// completeDashboardTour () {
		//     this.$store.commit('customer/UPDATE_CUSTOMER_TOUR', { view : 'dashboard' })
		// },
		// completeSettingTabTour () {
		//     this.$store.commit('customer/UPDATE_CUSTOMER_TOUR', { view : 'appTab1' })
		// },
		// completeSettingTab2Tour () {
		//     this.$store.commit('customer/UPDATE_CUSTOMER_TOUR', { view : 'appTab2' })
		// },
		// completeSettingTab3Tour () {
		//     this.$store.commit('customer/UPDATE_CUSTOMER_TOUR', { view : 'appTab3' })
		// },
		// completeIntegrationTour () {
		//     this.$store.commit('customer/UPDATE_CUSTOMER_TOUR', { view : 'addAppPage' })
		// },
		// completeBillingTour () {
		//     console.log('let complete now')
		//     this.$store.commit('customer/UPDATE_CUSTOMER_TOUR', { view : 'billingPage' })
		// },
		// completeFaqTour() {
		//     this.$store.commit('customer/UPDATE_CUSTOMER_TOUR', { view : 'faqPage' })
		// },
		completeAllTour() {
			const payload = {
				dashboard: true,
				appTab1: true,
				appTab2: true,
				appTab3: true,
				addAppPage: true,
				billingPage: true,
				faqPage: true,
			}
			this.$store.commit('customer/UPDATE_CUSTOMER_TOUR_ALL', payload)
		},
		removeSystemNotification(data) {
			this.$store.commit('auth/REMOVE_NOTIFICATION', data)
			this.showMaintenance = false
			this.removeSystemClass()
		},
		closeCartDiv() {
			window.Bus.$emit('close-cart')
			this.showFullCover = false
		},
		handleClose() {
			this.$store.commit('utility/OPEN_INVITE_MODAL')
		},
		handleCloseFaq() {
			this.$store.commit('utility/OPEN_MODAL_USER_FAQ_CREATE_MODAL')
		},
		inviteUser() {
			if (this.firstname === '' || this.lastname === '' || this.email === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (!this.$services.helpers.validateEmailAddress(this.email)) {
				this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
			} else if (this.role === '') {
				this.$services.helpers.notification(this.$t('userRole'), 'error', this)
			} else {
				this.creating = true
				const payload = {
					email: this.email,
					first_name: this.firstname,
					last_name: this.lastname,
					as_accountant: this.role
				}
				this.$store.dispatch('customer/inviteUser', payload)
					.then(res => {
						this.creating = false
						if (res.data.success) {
							this.clearInviteField()
							this.$services.helpers.notification(this.$t('invitedSuccessful'), 'success', this)
						}
					}).catch((err) => {
					this.creating = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// console.log('refresh')
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
								})
						} else {
							if (err.response.data.data.hasOwnProperty('message')) {
								this.$services.helpers.notification(err.response.data.data.message, 'error', this)
							} else {
								this.$services.helpers.notification('Något gick fel när din inbjudan skickades', 'error', this)
							}
						}
					} else {
						this.$services.helpers.notification('Något gick fel när din inbjudan skickades', 'error', this)
					}
				})
			}
		},
		checkNotificationRender() {
			if (this.GET_SYSTEM_NOTIFY.length > 0 && !this.GET_SYSTEM_NOTIFY[0].close) {
				this.addSystemClass()
				this.showMaintenance = true
			} else {
				this.showMaintenance = false
			}
		},
		croppie(e) {
			const self = this
			const docType = ['png', 'jpg', 'jpeg']
			const fileType = e.target.files[0].type.split('/')[1]

			if (docType.includes(fileType)) {
				self.showCrop = true

				var files = e.target.files || e.dataTransfer.files
				if (!files.length) return

				var reader = new FileReader()
				reader.onload = e => {
					this.$refs.croppieRef.bind({
						url: e.target.result
					})
				}
				reader.readAsDataURL(files[0])
			} else {
				this.$services.helpers.notification(this.$t('invalidFile'), 'error', this)
				document.getElementById('upload').value = ''
			}
		},
		crop() {
			this.initializingCropping = true
			this.croppingSuccess = false
			const options = {
				type: 'base64',
				size: 'viewport',
				format: 'png',
				circle: true
			}
			this.$refs.croppieRef.result(options, output => {
				this.croppieImage = output
				this.initializingCropping = false
				this.croppingSuccess = true
				this.showPreviewCropping = true
				this.addedImage = true
				return this.getBase64ToFire(this.croppieImage)
			})
		},
		getBase64ToFire(base64URL) {
			const renamFile = `${uuidv4()}.png`
			fetch(base64URL)
				.then(res => res.blob())
				.then(blob => {
					const file = new File([blob], renamFile, {type: 'image/png'})
					if (file !== undefined) {
						this.userPhoto = file
					}
				})
		},
		uploadPhoto() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPLOAD_USER_PHOTO + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPLOAD_USER_PHOTO
			this.uploadingNow = true
			const formData = new FormData()
			formData.append('file', this.userPhoto)

			this.$store.dispatch('customer/uploadPhoto', {formData: formData, URL: URL})
				.then(_ => {
					this.uploadingNow = false
					bus.$emit('update-user-pic', 'update-now')
					this.$services.helpers.notification(this.$t('imageUploadSuccessful'), 'success', this)
					this.clearImageField()
					this.$store.commit('utility/OPEN_PHOTO_MODAL')
				}).catch((err) => {
				this.uploadingNow = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.location.reload()
							})
					}
				}
			})
		},
		closeTestModal() {
			this.performSingleTest = false
			this.skuModal = false
			// Reusing state variable
			this.percentage = 0
			this.uploadResponse = ''
			this.uploadSuccess = false
			this.uploadFailure = false
			this.openProductModal = false
			this.openCancelSubscription = false
			this.appSyncDetailModal = false
			this.openModalError = false
			this.openActivateModal = false
		},
		closeFileUploadModal() {
			this.percentage = 0
			this.uploadResponse = ''
			this.uploadSuccess = false
			this.uploadFailure = false
			this.uploadFile = false
		},
		getTaskDetails(taskId, value) {
			const self = this
			if (value === 'FILE') {
				this.progress = true
			} else {
				this.progress = false
				this.skuModal = false
			}

			this.$store.dispatch('customer/getSubscriptionTaskDetails', taskId)
				.then(res => {
					if (res.data.data.state === 'SUCCESS' && value === 'SKU') {
						this.progress = false
						this.uploadSuccess = true
						this.uploadFailure = false
						this.uploadResponse = res.data.data.result
						this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-sku-test')
					}

					if (res.data.data.state === 'SUCCESS' && value === 'TEST') {
						this.progress = false
						this.uploadSuccess = true
						this.uploadFailure = false
						this.uploadResponse = res.data.data
						this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-test')
					}

					if (res.data.data.state === 'SUCCESS' && value === 'FILE') {

						this.progress = false
						this.uploadSuccess = true
						this.uploadFailure = false
						this.uploadResponse = res.data.data
						// this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-upload')
					}

					if (res.data.data.state === 'PENDING') {
						setTimeout(() => {
							self.getTaskDetails(taskId, value)
						}, 5000);
					}

					if (res.data.state === 'FAILURE' && value === 'FILE') {
						this.progress = false
						this.uploadSuccess = false
						this.uploadFailure = true
						this.uploadResponse = res.data
						// this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-upload')
					}

					if (res.data.code === 500 && value === 'FILE') {
						this.progress = false
						this.uploadSuccess = false
						this.uploadFailure = true
						this.uploadResponse = res.data.data
						// this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-upload')
					}


				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									self.getTaskDetails(taskId, value)
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						} else {
							this.progress = false
							this.uploadSuccess = false
							this.uploadFailure = true
							this.uploadResponse = err.response.data
							this.$toastr.removeByName("sku-test-run");

							window.Bus.$emit('finished-sku-test-failed')
							window.Bus.$emit('finished-test')
							window.Bus.$emit('finished-upload')
						}
					}
				})
		},
		getTaskDetailsOnDedicatedServer(taskId, value, server) {
			const self = this
			if (value === 'FILE') {
				this.progress = true
			} else {
				this.progress = false
				this.skuModal = false
			}
			if (['pro', 'growth', 'enterprise'].includes(server)) {
				const http = getDedicatedServerInstance({baseURL: this.$services.helpers.getDedicatedServer(server)})
				let URL = `${this.$services.endpoints.TASK_DETAILS_ENDPOINT}${taskId}/`
				http.get(URL).then(resp => {
					if (resp.data.data.state === 'SUCCESS' && value === 'SKU') {
						this.progress = false
						this.uploadSuccess = true
						this.uploadFailure = false
						this.uploadResponse = resp.data.data.result
						this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-sku-test')
					}

					if (resp.data.data.state === 'SUCCESS' && value === 'TEST') {
						this.progress = false
						this.uploadSuccess = true
						this.uploadFailure = false
						this.uploadResponse = resp.data.data
						this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-test')
					}

					if (resp.data.data.state === 'SUCCESS' && value === 'FILE') {
						console.log('it LAND HERE')
						this.progress = false
						this.uploadSuccess = true
						this.uploadFailure = false
						this.uploadResponse = resp.data.data
						// this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-upload')
					}

					if (resp.data.data.state === 'PENDING') {
						setTimeout(() => {
							self.getTaskDetailsOnDedicatedServer(taskId, value, server)
						}, 5000);
					}

					if (resp.data.state === 'FAILURE' && value === 'FILE') {
						this.progress = false
						this.uploadSuccess = false
						this.uploadFailure = true
						this.uploadResponse = resp.data
						// this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-upload')
					}

					if (resp.data.code === 500 && value === 'FILE') {
						this.progress = false
						this.uploadSuccess = false
						this.uploadFailure = true
						this.uploadResponse = resp.data.data
						// this.$toastr.removeByName("sku-test-run");
						window.Bus.$emit('finished-upload')
					}
				}).catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									self.getTaskDetailsOnDedicatedServer(taskId, value, server)
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						} else {
							this.progress = false
							this.uploadSuccess = false
							this.uploadFailure = true
							this.uploadResponse = err.response.data
							this.$toastr.removeByName("sku-test-run");

							window.Bus.$emit('finished-sku-test-failed')
							window.Bus.$emit('finished-test')
							window.Bus.$emit('finished-upload')
						}
					}
				})
			}
		},
		beginSKUModalOnDedicatedServer(payload) {
			this.$services.axios.defaults.headers.common.Authorization = `Bearer ${window.localStorage.getItem('dedicatedServerToken')}`
			this.$services.axios.post(
				this.$services.helpers.getDedicatedServer(payload.server) + payload.URL,
				{},
				{
					onUploadProgress: function (progressEvent) {
						this.percentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
						// console.log('Progress:', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
					}.bind(this),
				}
			).then(res => {
				if (res.status === 200) {
					this.$toastr.Add({
						name: "sku-test-run", // this is give you ability to use removeByName method
						title: "Data processing", // Toast Title
						msg: "Product data is examined in progress", // Toast Message
						clickClose: false, // Click Close Disable
						timeout: 0,
						classNames: ["animated", "zoomInUp"],
						//progressBarValue: 50, // Manually update progress bar value later; null (not 0) is default
						position: "toast-bottom-right", // Toast Position.
						type: "success", // Toast type,
						preventDuplicates: true, //Default is false,
						style: {
							backgroundColor: "white",
							width: "100%",
							'border-left': '7px solid #ee4d81',
							'color': '#0d1b3eb3'
						} // bin;d inline style to toast  (check [Vue doc](https://vuejs.org/v2/guide/class-and-style.html#Binding-Inline-Styles) for more examples)
					});
					this.getTaskDetailsOnDedicatedServer(res.data.data.task_id, 'SKU', payload.server)
				}
			}).catch((err) => {
				this.uploading = false
				window.Bus.$emit('finished-sku-test-failed')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						let REFRESH_URL = `${this.$services.helpers.getDedicatedServer(payload.server)}token/refresh/`
						axios.post(REFRESH_URL, {refresh: window.localStorage.getItem('dedicatedServerRefreshToken')}).then(resp => {
							window.localStorage.setItem('dedicatedServerRefreshToken', resp.data.data.refresh)
							window.localStorage.setItem('dedicatedServerToken', resp.data.data.access)
						}).catch(_ => {
							this.$store.commit('auth/CLEAR_AUTH_USER', null)
							window.Bus.$emit('sign-out')
						})
						// this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
						// 	.then(_ => {
						// 	})
						// 	.catch(_ => {

						// 	})
					} else {
						this.uploadFailure = true
						this.uploadSuccess = false
						this.progress = false
						this.uploadResponse = err.response.data.data
					}
					// this.$services.helpers.notification(err.response.data.data, 'error', this)
				}
			})
		},
		beginSKUModal(payload) {
			this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			this.$services.axios.post(
				process.env.VUE_APP_BASE_URL_EXTERNAL + payload.URL,
				{},
				{
					onUploadProgress: function (progressEvent) {
						this.percentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
						// console.log('Progress:', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
					}.bind(this),
				}
			)
				.then(res => {
					if (res.status === 200) {
						// console.log('hey you.', res.data.data.task_id)
						this.$toastr.Add({
							name: "sku-test-run", // this is give you ability to use removeByName method
							title: "Data processing", // Toast Title
							msg: "Product data is examined in progress", // Toast Message
							clickClose: false, // Click Close Disable
							timeout: 0,
							classNames: ["animated", "zoomInUp"],
							//progressBarValue: 50, // Manually update progress bar value later; null (not 0) is default
							position: "toast-bottom-right", // Toast Position.
							type: "success", // Toast type,
							preventDuplicates: true, //Default is false,
							style: {
								backgroundColor: "white",
								width: "100%",
								'border-left': '7px solid #ee4d81',
								'color': '#0d1b3eb3'
							} // bin;d inline style to toast  (check [Vue doc](https://vuejs.org/v2/guide/class-and-style.html#Binding-Inline-Styles) for more examples)
						});
						this.getTaskDetails(res.data.data.task_id, 'SKU')
						// this.progress = false
						// this.uploadSuccess = true
						// this.uploadFailure = false
						// this.uploadResponse = res.data.data
						// window.Bus.$emit('finished-sku-test')
					}
				}).catch((err) => {
				this.uploading = false
				window.Bus.$emit('finished-sku-test-failed')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					} else {
						this.uploadFailure = true
						this.uploadSuccess = false
						this.progress = false
						this.uploadResponse = err.response.data.data
					}
					// this.$services.helpers.notification(err.response.data.data, 'error', this)
				}
			})
		},
		beginTest(payload) {
			this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			this.$services.axios.get(
				process.env.VUE_APP_BASE_URL_EXTERNAL + payload.URL,
				{
					onUploadProgress: function (progressEvent) {
						this.percentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
						// console.log('Progress:', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
					}.bind(this),
				}
			)
				.then(res => {
					if (res.status === 200) {
						this.$toastr.Add({
							name: "sku-test-run", // this is give you ability to use removeByName method
							title: "Data processing", // Toast Title
							msg: "Product data is examined in progress", // Toast Message
							clickClose: false, // Click Close Disable
							timeout: 0,
							classNames: ["animated", "zoomInUp"],
							//progressBarValue: 50, // Manually update progress bar value later; null (not 0) is default
							position: "toast-bottom-right", // Toast Position.
							type: "success", // Toast type,
							preventDuplicates: true, //Default is false,
							style: {
								backgroundColor: "white",
								width: "100%",
								'border-left': '7px solid #ee4d81',
								'color': '#0d1b3eb3'
							} // bin;d inline style to toast  (check [Vue doc](https://vuejs.org/v2/guide/class-and-style.html#Binding-Inline-Styles) for more examples)
						});
						this.getTaskDetails(res.data.data.task_id, 'TEST')
						// this.progress = false
						// this.uploadSuccess = true
						// this.uploadFailure = false
						// this.uploadResponse = res.data.data
						// bus.$emit('finished-test')
					}
				}).catch((err) => {
				this.uploading = false
				bus.$emit('finished-test')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					} else {
						this.uploadFailure = true
						this.uploadSuccess = false
						this.progress = false
						this.uploadResponse = err.response.data.data
					}
					// this.$services.helpers.notification(err.response.data.data, 'error', this)
				}
			})
		},
		beginFileSettingUpload(payload) {
			this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			this.$services.axios.post(
				process.env.VUE_APP_BASE_URL_EXTERNAL + payload.URL,
				payload.formData,
				{
					onUploadProgress: function (progressEvent) {
						this.percentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
						// console.log(parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
					}.bind(this),
				}
			)
				.then(res => {
					if (res.status === 200) {
						// BELOW NOTIFICATION IS MEANT FOR TEST & SKU VALIDATION ONLY NOT SWISH & ADYEN
						// this.$toastr.Add({
						// 	name: "sku-test-run", // this is give you ability to use removeByName method
						// 	title: "Data processing", // Toast Title
						// 	msg: "Product data is examined in progress", // Toast Message
						// 	clickClose: false, // Click Close Disable
						// 	timeout: 0,
						// 	classNames: ["animated", "zoomInUp"],
						// 	//progressBarValue: 50, // Manually update progress bar value later; null (not 0) is default
						// 	position: "toast-bottom-right", // Toast Position.
						// 	type: "success", // Toast type,
						// 	preventDuplicates: true, //Default is false,
						// 	style: {
						// 		backgroundColor: "white",
						// 		width: "100%",
						// 		'border-left': '7px solid #ee4d81',
						// 		'color': '#0d1b3eb3'
						// 	} // bin;d inline style to toast  (check [Vue doc](https://vuejs.org/v2/guide/class-and-style.html#Binding-Inline-Styles) for more examples)
						// });
						this.getTaskDetails(res.data.data.task_id, 'FILE')
						// this.progress = false
						// this.uploadSuccess = true
						// this.uploadFailure = false
						// this.uploadResponse = res.data.data
						// window.Bus.$emit('finished-upload')
					}
				}).catch((err) => {
				window.Bus.$emit('finished-upload')
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					} else {
						this.uploadFailure = true
						this.uploadSuccess = false
						this.progress = false
						this.uploadResponse = err.response.data.data
					}
					// this.$services.helpers.notification(err.response.data.data, 'error', this)
				}
			})
		},
		addAccountNumberForm() {
			if (this.accountNumber === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (!parseInt(this.accountNumber)) {
				this.$services.helpers.notification(this.$t('invalidNumber'), 'error', this)
			} else {
				this.adding = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ADD_ACCOUNT_NUMBERS + `${this.subId}/${this.accountNumber}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ADD_ACCOUNT_NUMBERS + `${this.subId}/${this.accountNumber}/`
				this.$store.dispatch('customer/addAccountNumberForm', {URL: URL})
					.then(_ => {
						this.adding = false
						this.$services.helpers.notification(this.$t('accountAddedSuccess'), 'success', this)
						bus.$emit('fire-account-number')
						this.$store.commit('utility/OPEN_MODAL_UTIL')
					}).catch((err) => {
					this.adding = false
					if (err.response.status === 400) {
						if (err.response.data.code === 400) {
							this.$services.helpers.notification(err.response.data.data, 'error', this)
						}
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getSubscriptionList()
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.location.reload()
								})
						}
					}
				})
			}
		},
		getSystemNotification() {
			this.$store.dispatch('auth/getSystemNotification')
				.then(_ => {
					this.checkNotificationRender()
				})
				.catch(_ => {
				})
		},
		addSystemClass() {
			if (document.getElementById('header-holder') && document.getElementById('main-holder')) {
				document.getElementById('header-holder').classList.add('system-maintain-top')
				document.getElementById('main-holder').classList.add('system-main')
			}
		},
		removeSystemClass() {
			if (document.getElementById('header-holder') && document.getElementById('main-holder')) {
				document.getElementById('header-holder').classList.remove('system-maintain-top')
				document.getElementById('main-holder').classList.remove('system-main')
			}
		},
		clearInviteField() {
			this.firstname = ''
			this.lastname = ''
			this.email = ''
			this.role = ''
		},
		clearImageField() {
			document.getElementById('upload').value = ''
			this.userPhoto = {}
			this.showCrop = false
			this.croppieImage = null
			this.initializingCropping = false
			this.croppingSuccess = false
			this.showPreviewCropping = false
			this.uploadingNow = false
		},
		activateSubscription: function () {
			const {key} = this.$route.query
			if (key) {
				if (SKU_VALIDATOR.includes(parseInt(this.apptype))) {
					// const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/1/`
					let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.SKU_ACTIVATE_SUBSCRIPTION + `${key}/`
					bus.$emit('sku-modal', {URL: URL})

				} else {
					this.activating = true
					const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.ACTIVATE_SUBSCRIPTION + `${key}/1/`
					this.$store.dispatch('customer/activateSubscription', {URL})
						.then(res => {
							this.activating = false
							this.$services.helpers.notification(this.$t('activatedSubscription'), 'success', this)
							this.closeTestModal()
							window.Bus.$emit('reload-app-subscription-after-activate')
						}).catch((err) => {
						this.activating = false
						if (err.response.status === 400) {
							if (err.response.data.code === 401) {
								this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
									.then(_ => {
										// this.getSubscriptionConfig()
									})
									.catch(_ => {
										// console.log('here')
										this.$store.commit('auth/CLEAR_AUTH_USER', null)
										window.Bus.$emit('sign-out')
									})
							}
						}
					})
				}
			}
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import '~croppie/croppie.css';
@import "~vue-tour/dist/vue-tour.css";

.form-select {
	padding-top: unset !important;
}

.form-control {
	height: unset !important;
	padding: .275rem .75rem !important;
}

.head-text {
	font-size: 15px !important;
}

.app-theme-gray.app-container {
	background: #f1f4f6 !important
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control, .form-select {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

@media only screen and (max-width: 992px) {
	.system-maintain-top {
		top: 80px !important;
	}
	.system-main {
		padding-top: 200px !important;
	}
}

@media only screen and (min-width: 993px) {
	.system-maintain-top {
		top: 54px !important;
	}
	.system-main {
		padding-top: 120px !important;
	}
}

.btn-pink {
	background: $theme-primary-color;;
	border: 1px solid #ef5287;
	color: #ffffff
}

.btn-pink:hover {
	background: $theme-primary-color;;
	border: 1px solid #ef5287;
	color: #ffffff
}

.center-elem {
	display: table;
	margin: auto
}

.activate-btn {
	background-color: #0DB23C;
}

.text-pink {
	color: $theme-primary-color !important;
}
</style>
