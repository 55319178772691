import Login from '../../views/Auth/Login.vue'
import Main from '../../views/Automatisera/Main.vue'
import AccountantMain from '../../views/Accountant/Main.vue'
import RegularMain from '../../views/Regular/Main.vue'
import AssistantMain from '../../views/AssistAccountant/Main.vue'
import ColleagueMain from '../../views/Colleague/Main.vue'
import MasterMain from '../../views/MasterAccountant/Main.vue'

function chunkRoutes(view) {
	return () => import(/* webpackChunkName: "auth-chunck" */ `@/views/Auth/${view}.vue`)
}

const routes = [
	{
		path: '',
		//   redirect : to => {
		//       return { name : 'login' }
		//   }
		name: 'login',
		component: Login
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: 'Automatisera Mera',
		},
		component: Login
	},
	{
		path: '/register',
		name: 'register',
		meta: {
			title: 'Automatisera Mera | Register',
		},
		// route level code-splitting
		// this generates a separate chunk (register.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: chunkRoutes('Register')
	},
	{
		path: '/forget-password',
		name: 'forget',
		meta: {
			title: 'Automatisera Mera | Forget Password',
		},
		component: () => import(/* webpackChunkName: "forget-chunk" */ '../../views/Auth/forget-pass/Forget.vue')
	},
	{
		path: '/invite/',
		name: 'invite',
		meta: {
			title: 'Automatisera Mera | Invitation',
		},
		component: () => import(/* webpackChunkName: "invite-chunk" */ '../../views/Invite/AccountInvite.vue')
	},
	{
		path: '/confirmation/',
		name: 'confirmation',
		meta: {
			title: 'Automatisera Mera | Confirmation',
		},
		component: () => import(/* webpackChunkName: "confirm-chunk" */ '../../views/Invite/ConfirmAccount.vue')
	},
	{
		path: '/unavailable/',
		name: 'notfound',
		meta: {
			title: 'Unvailable',
		},
		component: () => import(/* webpackChunkName: "notfound-chunk" */ '../../views/NotFound.vue')
	},
	{
		path: '/invite-confirmation/',
		name: 'inviteconfirm',
		meta: {
			title: 'Automatisera Mera | Confirmation',
		},
		component: () => import(/* webpackChunkName: "invite-confirm-chunk" */ '../../views/Invite/ConfirmInvite.vue')
	},
	{
		path: '/reset-password/',
		name: 'reset',
		meta: {
			title: 'Automatisera Mera | Reset Password',
		},
		component: () => import(/* webpackChunkName: "reset-chunk" */ '../../views/Auth/ResetPassword.vue')
	},
	{
		path: '/assistance',
		meta: {requireAuth: true},
		component: AssistantMain,
		children: [
			{
				path: '',
				name: 'accountant_assistance',
				meta: {
					title: 'Automatisera Mera | Dashboard',
				},
				component: () => import(/* webpackChunkName: "assistant-dashboard-chunk" */ '../../views/AssistAccountant/Dashboard.vue')
			},
		]
	},
	{
		path: '/colleague',
		meta: {requireAuth: true},
		component: ColleagueMain,
		children: [
			{
				path: '',
				name: 'colleague_settings',
				meta: {
					title: 'Settings | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "dashboard-colleague-chunk" */ '../../views/Colleague/Dashboard')
			},
		]
	},
	{
		path: '/master_accountant',
		meta: {requireAuth: true},
		component: MasterMain,
		children: [
			{
				path: '',
				name: 'master_accountant_dashboard',
				meta: {
					title: 'Automatisera Mera | Dashboard',
				},
				component: () => import(/* webpackChunkName: "master-dashboard-chunk" */ '../../views/MasterAccountant/Dashboard')
			},
			{
				path: '/master_accountant/add-apps',
				name: 'master_accountant_apps',
				meta: {
					title: 'Automatisera Mera | WebShops App',
				},
				component: () => import(/* webpackChunkName: "add-apps-chunk" */ '../../views/Accountant/AddApps')
			},
			{
				path: '/master_accountant/firm',
				name: 'master-accountant-firmInfo',
				meta: {
					title: 'Accounting Firm Information',
				},
				component: () => import(/* webpackChunkName: "firm-chunk" */ '../../views/MasterAccountant/accountingFirm.vue')
			},
			{
				path: '/master_accountant/payout',
				name: 'master-accountant-payout',
				meta: {
					title: 'Accounting Firm Discount Payout',
				},
				component: () => import(/* webpackChunkName: "chunk" */ '../../views/MasterAccountant/Payout')
			},
			{
				path: '/master_accountant/faq',
				name: 'master-accountant-faq',
				meta: {
					title: 'Accounting Firm Discount Payout',
				},
				component: () => import(/* webpackChunkName: "chunk" */ '../../views/MasterAccountant/Faq')
			},
		]
	},
	{
		path: '/accountant',
		// name: 'appaccount',
		meta: {requireAuth: true},
		component: AccountantMain,
		children: [
			{
				path: '',
				name: 'accountantdash',
				meta: {
					title: 'Automatisera Mera | Dashboard',
				},
				component: () => import(/* webpackChunkName: "dashboard-accountant-chunk" */ '../../views/Accountant/Dashboard.vue')
			},
			{
				path: '404/unavailable',
				name: 'accountantnotfound',
				meta: {
					title: 'Unavailable | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "accountant-notfound-chunk" */ '../../views/Accountant/NotFound.vue')
			},
			{
				path: 'firm',
				name: 'firmInfo',
				meta: {
					title: 'Accounting Firm Information',
				},
				component: () => import(/* webpackChunkName: "accountinig-firm-chunk" */ '../../views/Accountant/accountingFirm.vue')
			},
			{
				path: 'add-apps',
				name: 'appcatelog',
				meta: {
					title: 'WebShop Apps | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "accountant-add-apps-chunk" */ '../../views/Accountant/AddApps.vue')
			},
			// {
			//     path: 'logs',
			//     name: 'accountantlog',
			//     meta: { },
			//     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Accountant/Log.vue')
			// },
			{
				path: 'faq',
				name: 'accountantfaq',
				meta: {
					title: 'Frequest Answers & Questions',
				},
				component: () => import(/* webpackChunkName: "faq-accountant-chunk" */ '../../views/Accountant/FAQ.vue')
			},
		]
	},
	{
		path: '/regular',
		name: 'regularaccount',
		meta: {requireAuth: true},
		component: RegularMain,
		children: [
			{
				path: '',
				name: 'regulardash',
				meta: {requireAuth: true},
				component: () => import(/* webpackChunkName: "regular-dashboard-chunk" */ '../../views/Regular/Dashboard.vue')
			},
			// {
			//     path: 'firm',
			//     name: 'firm',
			//     meta: { requireAuth: true },
			//     component: () => import(/* webpackChunkName: "regular-firm-chunk" */ '../../views/Regular/Firm.vue')
			// },
			{
				path: '404/unavailable',
				name: 'adminnotfound',
				meta: {
					requireAuth: true,
					title: 'Unavailable | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "regular-notfound-chunk" */ '../../views/Regular/NotFound.vue')
			},
			{
				path: 'firm/:partner',
				name: 'singlefirm',
				meta: {requireAuth: true, title: 'Firm | Automatisera Mera',},
				component: () => import(/* webpackChunkName: "single-firm-chunk" */ '../../views/Regular/SingleFirm.vue')
			},
			// {
			//     path: 'company',
			//     name: 'company',
			//     meta: { requireAuth: true },
			//     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/Company.vue')
			// },
			{
				path: 'logs',
				name: 'reglogs',
				meta: {requireAuth: true, title: 'Logs | Automatisera Mera',},
				component: () => import(/* webpackChunkName: "regular-log-chunk" */ '../../views/Regular/Logs.vue')
			},
			{
				path: 'api-queries',
				name: 'api-queries',
				meta: {requireAuth: true, title: 'Api Queries | Automatisera Mera'},
				component: () => import(/* webpackChunkName: "regular-api-query-chunk" */ '../../views/Regular/apiQueries.vue')
			},
			{
				path: 'periodic-tasks',
				name: 'periodic-tasks',
				meta: {requireAuth: true, title: 'Periodic Tasks| Automatisera Mera'},
				component: () => import(/* webpackChunkName: "regular-api-query-chunk" */ '../../views/Regular/userSpecificTasks')
			},
			{
				path: 'accounts',
				name: 'accounts',
				meta: {requireAuth: true, title: 'Accounts | Automatisera Mera'},
				component: () => import(/* webpackChunkName: "regular-api-query-chunk" */ '../../views/Regular/customAccounts')
			},
			{
				path: 'generic-resource',
				name: 'generic-resource',
				meta: {requireAuth: true, title: 'Generic Resource | Automatisera Mera'},
				component: () => import(/* webpackChunkName: "regular-api-query-chunk" */ '../../views/Regular/genericViews')
			},
			{
				path: 'reports',
				name: 'admin-sync-report',
				meta: {},
				component: () => import(/* webpackChunkName: "customer-report-chunk" */ '../../views/Regular/syncEntryReport')
			},
			{
				path: 'notifications',
				name: 'admin-notifications',
				meta: {},
				component: () => import(/* webpackChunkName: "customer-report-chunk" */ '../../views/Regular/notificationComponent')
			},
			{
				path: 'unprocessed_subscription',
				name: 'admin-unprocessed_subscription',
				meta: {},
				component: () => import(/* webpackChunkName: "customer-report-chunk" */ '../../views/Regular/unprocessedSubscription')
			},
			{
				path: 'user-management',
				name: 'user-management',
				meta: {},
				component: () => import(/* webpackChunkName: "customer-report-chunk" */ '../../views/Regular/users')
			},
			{
				path: 'faq',
				name: 'regfaq',
				meta: {requireAuth: true, title: 'FAQ | Automatisera Mera',},
				component: () => import(/* webpackChunkName: "regular-faq-chunk" */ '../../views/Regular/FAQ.vue')
			},
		]
	},
	{
		path: '/automatisera',
		name: 'app',
		meta: {
			requireAuth: true,
			title: 'Dashboard | Automatisera Mera',
		},
		component: Main,
		children: [
			{
				path: '',
				name: 'dashboard',
				meta: {
					title: 'Dashboard | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-dash-chunk" */ '../../views/Automatisera/Dashboard.vue')
			},
			{
				path: 'appsettings',
				name: 'appsettings',
				meta: {
					title: 'Settings | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-app-config-chunk" */ '../../views/Automatisera/AppSetting.vue')
			},
			{
				path: 'terms-and-condition',
				name: 'terms',
				meta: {
					title: 'T&C | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-term-chunk" */ '../../views/Automatisera/Terms.vue')
			},
			{
				path: 'other-services',
				name: 'other-services',
				meta: {
					title: 'Other Services',
				},
				component: () => import(/* webpackChunkName: "other-services-chunk" */ '../../views/Automatisera/OtherServices.vue')
			},
			{
				path: '404/unavailable',
				name: 'usernotfound',
				meta: {
					title: 'Unavailable | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-notfound-chunk" */ '../../views/Automatisera/NotFound.vue')
			},
			{
				path: 'visma/success',
				name: 'vismasuccess',
				meta: {
					title: 'Success | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-visma-chunk" */ '../../views/Automatisera/Visma.vue')
			},
			{
				path: 'billing',
				name: 'billings',
				meta: {
					title: 'Billing | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-billing-chunk" */ '../../views/Automatisera/Billing.vue')
			},
			{
				path: 'add-apps',
				name: 'addapps',
				meta: {
					title: 'WebShop Apps | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "cutomer-add-apps-chunk" */ '../../views/Automatisera/AddApps.vue')
			},
			{
				path: 'sales-panel',
				name: 'sales',
				meta: {
					title: 'Sales | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-visma-chunk" */ '../../views/Automatisera/SalesPanel.vue')
			},
			{
				path: 'app-synchronize',
				name: 'app-synchronize',
				meta: {
					title: 'App Synchronize | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-app-sync-chunk" */ '../../views/Automatisera/AppSynchronizationPage')
			},
			{
				path: 'reports',
				name: 'appreport',
				meta: {},
				component: () => import(/* webpackChunkName: "customer-report-chunk" */ '../../views/Automatisera/AppReport.vue')
			},
			{
				path: 'product-management',
				name: 'productmanagement',
				meta: {},
				component: () => import(/* webpackChunkName: "customer-product-chunk" */ '../../views/Automatisera/AppProduct.vue')
			},
			{
				path: 'order-management',
				name: 'ordermanagement',
				meta: {},
				component: () => import(/* webpackChunkName: "customer-order-chunk" */ '../../views/Automatisera/AppOrder.vue')
			},
			{
				path: 'logs',
				name: 'applogs',
				meta: {
					title: 'Apps logs | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-log-chunk" */ '../../views/Automatisera/AppLog.vue')
			},
			{
				path: 'cardpayment/success',
				name: 'success',
				meta: {
					title: 'Card Payments | Automatisera Mera',
				},
				component: () => import(/* webpackChunkName: "customer-payment-chunk" */ '../../views/Automatisera/PaymentSuccess.vue')
			},
			{
				path: 'faq',
				name: 'faq',
				meta: {
					title: 'FAQ',
				},
				component: () => import(/* webpackChunkName: "customer-faq-chunk" */ '../../views/Automatisera/FAQ.vue')
			},
			{
				path: 'support',
				name: 'support',
				meta: {
					title: 'Support Center',
				},
				component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/Support.vue')
			},
			{
				path: 'shopify-guide',
				name: 'shopify-guide',
				meta: {
					title: 'Shopify Guide',
				},
				component: () => import(/* webpackChunkName: "customer-shopify-guide" */ '../../views/Automatisera/ShopifyGuide')
			},
		]
	},
	{
		path: '*',
		meta: {
			title: 'Unavailable | Automatisera Mera',
		},
		redirect: to => {
			if (window.localStorage.getItem('util')) {
				return {name: 'accountantnotfound'}
			} else if (window.localStorage.getItem('reghouse')) {
				return {name: 'adminnotfound'}
			} else if (window.localStorage.getItem('amdjango')) {
				return {name: 'usernotfound'}
			} else {
				return {name: 'notfound'}
			}
		}
	},
	// {
	//   path: '',
	//   redirect : to => {
	//       return { name : 'login' }
	//   }
	//   name: 'login',
	//   component: Login
	// },
	// {
	//     path: '/login',
	//     name: 'login',
	//     meta: {
	//         title: 'Automatisera Mera',
	//     },
	//     component: Login
	// },
	// {
	//     path: '/register',
	//     name: 'register',
	//     meta: {
	//         title: 'Automatisera Mera | Register',
	//     },
	//   // route level code-splitting
	//   // this generates a separate chunk (register.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: chunkRoutes('Register')
	// },
	// {
	//     path: '/forget-password',
	//     name: 'forget',
	//     meta: {
	//         title: 'Automatisera Mera | Forget Password',
	//     },
	//     component: () => import(/* webpackChunkName: "forget-chunk" */ '../../views/Auth/forget-pass/Forget.vue')
	// },
	// {
	//     path: '/invite/',
	//     name: 'invite',
	//     meta: {
	//         title: 'Automatisera Mera | Invitation',
	//     },
	//     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Invite/AccountInvite.vue')
	// },
	// {
	//     path: '/confirmation/',
	//     name: 'confirmation',
	//     meta: {
	//         title: 'Automatisera Mera | Confirmation',
	//     },
	//     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Invite/ConfirmAccount.vue')
	// },
	// {
	//     path: '/unavailable/',
	//     name: 'notfound',
	//     meta: {
	//         title: 'Unvailable',
	//     },
	//     component: () => import(/* webpackChunkName: "chunk" */ '../../views/NotFound.vue')
	// },
	// {
	//     path: '/invite-confirmation/',
	//     name: 'inviteconfirm',
	//     meta: {
	//         title: 'Automatisera Mera | Confirmation',
	//     },
	//     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Invite/ConfirmInvite.vue')
	// },
	// {
	//     path: '/reset-password/',
	//     name: 'reset',
	//     meta: {
	//         title: 'Automatisera Mera | Reset Password',
	//     },
	//     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Auth/ResetPassword.vue')
	// },
	// {
	//     path: '/assistance',
	//     meta: { requireAuth: true },
	//     component: AssistantMain,
	//     children: [
	//         {
	//             path: '',
	//             name: 'accountant_assistance',
	//             meta: {
	//                 title: 'Automatisera Mera | Dashboard',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/AssistAccountant/Dashboard.vue')
	//         },
	//     ]
	// },
	// {
	//     path: '/colleague',
	//     meta: { requireAuth: true },
	//     component: ColleagueMain,
	//     children: [
	//         {
	//             path: '',
	//             name: 'colleague_settings',
	//             meta: {
	//                 title: 'Settings | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Colleague/Dashboard')
	//         },
	//     ]
	// },
	// {
	//     path: '/accountant',
	//     // name: 'appaccount',
	//     meta: { requireAuth: true },
	//     component: AccountantMain,
	//     children: [
	//         {
	//             path: '',
	//             name: 'accountantdash',
	//             meta: {
	//                 title: 'Automatisera Mera | Dashboard',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Accountant/Dashboard.vue')
	//         },
	//         {
	//             path: '404/unavailable',
	//             name: 'accountantnotfound',
	//             meta: {
	//                 title: 'Unavailable | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Accountant/NotFound.vue')
	//         },
	//         {
	//             path: 'firm',
	//             name: 'firmInfo',
	//             meta: {
	//                 title: 'Accounting Firm Information',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Accountant/accountingFirm.vue')
	//         },
	//         {
	//             path: 'add-apps',
	//             name: 'appcatelog',
	//             meta: {
	//                 title: 'WebShop Apps | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Accountant/AddApps.vue')
	//         },
	//         // {
	//         //     path: 'logs',
	//         //     name: 'accountantlog',
	//         //     meta: { },
	//         //     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Accountant/Log.vue')
	//         // },
	//         {
	//             path: 'faq',
	//             name: 'accountantfaq',
	//             meta: {
	//                 title: 'Frequest Answers & Questions',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Accountant/FAQ.vue')
	//         },
	//     ]
	// },
	// {
	//     path: '/regular',
	//     name: 'regularaccount',
	//     meta: { requireAuth: true },
	//     component: RegularMain,
	//     children: [
	//         {
	//             path: '',
	//             name: 'regulardash',
	//             meta: { requireAuth: true },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/Dashboard.vue')
	//         },
	//         // {
	//         //     path: 'firm',
	//         //     name: 'firm',
	//         //     meta: { requireAuth: true },
	//         //     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/Firm.vue')
	//         // },
	//         {
	//             path: '404/unavailable',
	//             name: 'adminnotfound',
	//             meta: {
	//                 requireAuth: true,
	//                 title: 'Unavailable | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/NotFound.vue')
	//         },
	//         {
	//             path: 'firm/:partner',
	//             name: 'singlefirm',
	//             meta: { requireAuth: true, title: 'Firm | Automatisera Mera', },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/SingleFirm.vue')
	//         },
	//         // {
	//         //     path: 'company',
	//         //     name: 'company',
	//         //     meta: { requireAuth: true },
	//         //     component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/Company.vue')
	//         // },
	//         {
	//             path: 'logs',
	//             name: 'reglogs',
	//             meta: { requireAuth: true, title: 'Logs | Automatisera Mera', },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/Logs.vue')
	//         },
	//         {
	//             path: 'faq',
	//             name: 'regfaq',
	//             meta: { requireAuth: true, title: 'FAQ | Automatisera Mera', },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Regular/FAQ.vue')
	//         },
	//     ]
	// },
	// {
	//     path: '/automatisera',
	//     name: 'app',
	//     meta: {
	//         requireAuth: true,
	//         title: 'Dashboard | Automatisera Mera',
	//     },
	//     component: Main,
	//     children: [
	//         {
	//             path: '',
	//             name: 'dashboard',
	//             meta: {
	//                 title: 'Dashboard | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/Dashboard.vue')
	//         },
	//         {
	//             path: 'appsettings',
	//             name: 'appsettings',
	//             meta: {
	//                 title: 'Settings | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/AppSetting.vue')
	//         },
	//         {
	//             path: 'terms-and-condition',
	//             name: 'terms',
	//             meta: {
	//                 title: 'T&C | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/Terms.vue')
	//         },
	//         {
	//             path: '404/unavailable',
	//             name: 'usernotfound',
	//             meta: {
	//                 title: 'Unavailable | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/NotFound.vue')
	//         },
	//         {
	//             path: 'visma/success',
	//             name: 'vismasuccess',
	//             meta: {
	//                 title: 'Success | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/Visma.vue')
	//         },
	//         {
	//             path: 'billing',
	//             name: 'billings',
	//             meta: {
	//                 title: 'Billing | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/Billing.vue')
	//         },
	//         {
	//             path: 'add-apps',
	//             name: 'addapps',
	//             meta: {
	//                 title: 'WebShop Apps | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/AddApps.vue')
	//         },
	//         {
	//             path: 'reports',
	//             name: 'appreport',
	//             meta: { },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/AppReport.vue')
	//         },
	//         {
	//             path: 'product-management',
	//             name: 'productmanagement',
	//             meta: { },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/AppProduct.vue')
	//         },
	//         {
	//             path: 'order-management',
	//             name: 'ordermanagement',
	//             meta: { },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/AppOrder.vue')
	//         },
	//         {
	//             path: 'logs',
	//             name: 'applogs',
	//             meta: {
	//                 title: 'Apps logs | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/AppLog.vue')
	//         },
	//         {
	//             path: 'cardpayment/success',
	//             name: 'success',
	//             meta: {
	//                 title: 'Card Payments | Automatisera Mera',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/PaymentSuccess.vue')
	//         },
	//         {
	//             path: 'faq',
	//             name: 'faq',
	//             meta: {
	//                 title: 'FAQ',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/FAQ.vue')
	//         },
	//         {
	//             path: 'support',
	//             name: 'support',
	//             meta: {
	//                 title: 'Support Center',
	//             },
	//             component: () => import(/* webpackChunkName: "chunk" */ '../../views/Automatisera/Support.vue')
	//         },
	//     ]
	// },
	// {
	//     path: '*',
	//     meta: {
	//         title: 'Unavailable | Automatisera Mera',
	//     },
	//     redirect : to => {
	//         if (window.localStorage.getItem('util')) {
	//             return { name : 'accountantnotfound' }
	//         } else if (window.localStorage.getItem('reghouse')) {
	//             return { name : 'adminnotfound' }
	//         } else if (window.localStorage.getItem('amdjango')) {
	//             return { name : 'usernotfound' }
	//         } else {
	//             return { name : 'notfound' }
	//         }
	//     }
	// },
]


export default routes
