import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from "vue";
import {getToken} from "../services-jwt";

Vue.use(VueAxios, axios)

export const getDedicatedServerInstance = (config) => {
	return Vue.axios.create({
		baseURL: config.baseURL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Authorization': `Bearer ${window.localStorage.getItem('dedicatedServerToken')}`
		}
	})
}
