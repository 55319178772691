import Vue from 'vue'

Vue.filter('capitalizeEachString', function (name) {
    return name !== null ? name.split(' ').map(v => typeof v !== 'string' ? v + ' ' : v.charAt(0).toUpperCase() + v.toLowerCase().slice(1) + ' ').join('').trim() : name
})

Vue.filter('capitalizeNames', function (name) {
    return name !== null ? name.charAt(0).toUpperCase() + name.toLowerCase().slice(1) : name
})

Vue.filter('initialNames', function (name) {
    return name !== null ? name.split(' ').map(v => typeof v !== 'string' ? v : v.charAt(0).toUpperCase()).join('').trim() : name
})

Vue.filter('removeUnderscores', function (name) {
    return name.replace('_', ' ')
})