<template>
	<div class="container">
		<div class="card main-card">
			<div class="card-body" v-loading="loadingSub">
				<form v-on:submit.prevent="submit">
					<div class="row">
						<div class="col-md-12 mb-[10px]">
							<div class="mb-3">
								<label class="w-full">{{ $t('app_name') }}</label>
								<el-select v-model="form.subscription" :placeholder="$t('search')" filterable
										   class="w-full"
										   remote
										   reserve-keyword
										   v-on:change="onChange"
										   :remote-method="remoteMethod">
									<el-option
										v-for="(item,key) in subscriptionList"
										:key="key"
										:label="`(${item.id})  ${item.app_type_name} ${item.company_name}` "
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="col-md-12">
							<div>
								<el-upload
									class="upload-demo w-full upload-csv"
									drag
									:auto-upload="false"
									action="https://jsonplaceholder.typicode.com/posts/"
									:on-preview="handlePreview"
									:on-change="csvUpload"
									:on-remove="handleRemove"
									:file-list="fileList"
									:accept="`.csv`"
									:limit="1">
									<i class="el-icon-upload"></i>
									<div class="el-upload__text">{{ $t('dragAndDropCsvfile') }}</div>
								</el-upload>
							</div>
						</div>
						<div class="col-md-12">
							<div class="flex justify-start space-x-3 mt-2">
								<div>{{ $t('deleteAccounts') }}</div>
								<div>
									<input type="checkbox" class="checkbox-height" v-model="form.delete_accounts">
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group mt-5">
								<button
									class="btn addingCustomAccountbtn hover:text-white text-base btn-custom pad-overi"
									style="border-radius: 0px;" :disabled="loading"> {{ $t('uploadFile') }} <i
									v-if="loading === true"
									class="fa fa-spinner fa-spin"></i>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {Upload, Select, Option} from 'element-ui'
import Vue from "vue";
import {mapGetters} from "vuex"

Vue.use(Upload)
Vue.use(Select)
Vue.use(Option)
export default {
	name: "customAccountUploadForm",
	data: function () {
		return {
			fileList: [],
			subscriptionList: [],
			form: {
				subscription: '',
				delete_accounts: true,
			},
			loading: false,
			loadingSub: false,
		}
	},
	methods: {
		submit: function () {
			this.loading = true
			if (this.fileList.length === 0) {
				this.loading = false
				return false
			}
			let formdata = new FormData()
			let daccount = this.form.delete_accounts === true ? 1 : 0
			formdata.append('subscription', this.form.subscription)
			formdata.append('delete_accounts', daccount)
			formdata.append('media_file', this.fileList[0])

			let URL = this.$services.endpoints.UPLOAD_CUSTOM_ACCOUNTS + `${this.form.subscription}/`
			this.$store.dispatch('house/customPostRequest', {URL: URL, data: formdata}).then(_ => {
				this.loading = false
				this.$services.helpers.notification(this.$t('entryCreated'), 'success', this)
				Object.keys(this.form).map(item => {

					this.form[item] = ''
				})
				this.form.delete_accounts = false
				this.fileList = []
				window.Bus.$emit('reload-reload-custom-account')
			}).catch(err => {
				this.loading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					} else {
						if (err.response.data.hasOwnProperty('message') && err.response.data.hasOwnProperty('message_swe')) {
							if (this.GET_LOCALE === 'se') {
								this.$services.helpers.notification(err.response.data.message_swe, 'error', this)
							} else {
								this.$services.helpers.notification(err.response.data.message, 'error', this)
							}
						} else {
							this.$services.helpers.notification(err.response.data.message, 'error', this)
						}
					}
				} else {
					this.$services.helpers.notification(this.$t('generalError'), 'error', this)
				}

			})
		},
		onChange: function () {
			let sub = this.subscriptionList.filter(item => item.id === parseInt(this.form.subscription))
			this.form.sync_type = ''
			if (sub.length > 0) {
				this.showAllOptions = false
				this.showOrderAppOnly = false
				this.showArticleAppOnly = false
				this.showPaymentOnly = false
				if (this.paymentApp.includes(parseInt(sub[0].app_type))) {
					this.showPaymentOnly = true
				}
				if (this.allApps.includes(parseInt(sub[0].app_type))) {
					this.showAllOptions = true
				}
				if (this.orderAppOnly.includes(parseInt(sub[0].app_type))) {
					this.showOrderAppOnly = true
				}
				if (this.articleAppOnly.includes(parseInt(sub[0].app_type))) {
					this.showArticleAppOnly = true
				}
			}

		},
		getSubscriptionList() {
			this.loadingSub = true
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + '?limit=50'
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(response => {
					this.subscriptionList = response.data.data.results

					this.loadingSub = false
				}).catch((err) => {
				this.loadingSub = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		remoteMethod(query) {
			if (query !== '') {
				this.loadingSub = false
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&limit=50`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(response => {
						this.subscriptionList = response.data.data.results
						this.loadingSub = false


					}).catch((err) => {
					this.loadingSub = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		handlePreview: function () {

		},
		handleRemove(file, fileList) {
			this.fileList.pop()
		},
		csvUpload(files, fileList) {
			const fileType = ['csv']
			const file = files.raw.type.split('/')[1]
			if (this.fileList.length <= 0) {
				if (fileType.includes(file)) {
					this.fileList.push(files.raw)
				} else {
					return this.$services.helpers.notification(this.$t('invalidFormat'), 'error', this)
				}
			} else {
				// this.productImages.push(files.raw)
				this.fileList.pop()
				return this.$services.helpers.notification(this.$t('maxFileUploadExceed'), 'error', this)
			}
		},
	},
	computed: {
		...mapGetters({
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		this.getSubscriptionList()
	}
}
</script>

<style scoped>

</style>
