import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";
import auth from './modules/auth'
import house from './modules/house'
import accountant from './modules/accountant'
import customer from './modules/customer'
import utility from './modules/utility'
import assistanceAccountant from './modules/assistanceAccountant'
import colleague from './modules/colleague'
import master_accountant from './modules/master_accountant'

// Let remove this as it leading to BUG
// const vuexLocal = new VuexPersistence({
// 	storage: window.sessionStorage
// });
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		house,
		accountant,
		customer,
		utility,
		colleague,
		assistanceAccountant,
		master_accountant,
	},
    // plugins: [vuexLocal.plugin]
})
