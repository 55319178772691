<template>
	<div class=""
		>
		<p class="p-1">{{ item }}</p>
	</div>
</template>

<script>
export default {
	name: "appSyncCard",
	props: {
		item: {
			type: String
		}
	}
}
</script>

<style scoped>

</style>
