const TYPES = {
	UPDATE_DASHBOARD_ACCOUNTANT_METRICS: 'UPDATE_DASHBOARD_ACCOUNTANT_METRICS',
	UPDATE_USERS_COMPANY: 'UPDATE_USERS_COMPANY',
	UPDATE_ASSISTANCE_COMPANY: 'UPDATE_ASSISTANCE_COMPANY',
	UPDATE_USER_SPECIFIC_COMPANY: 'UPDATE_USER_SPECIFIC_COMPANY',
	UPDATE_FIRM_COMPANIES: 'UPDATE_USER_SPECIFIC_COMPANY',
	UPDATE_FIRM_ACCOUNTANT: 'UPDATE_FIRM_ACCOUNTANT',
	UPDATE_PAYOUT: 'UPDATE_PAYOUT',
	UPDATE_PAYOUT_FILTER: 'UPDATE_PAYOUT_FILTER'
}

export default TYPES
