import { saveUser, destroyToken } from './../../../../services/services-jwt'
import { deleteUserData, storeSystemNotify } from './../../../../services/storage-window'

export default {
    UPDATE_USER_TOKEN (state, payload) {
        state.token = payload.access
        state.refreshToken = payload.refresh
        saveUser(payload)
    },
    CLEAR_AUTH_USER (state, payload) {
        state.token = null
        destroyToken()
        deleteUserData()
		window.localStorage.removeItem('dedicatedServerToken')
    },
    UPDATE_SYSTEM_NOTIFICATION (state, payload) {
        if (state.systemNotify.length > 0) {
            if (state.systemNotify[0].start_date !== payload[0].start_date) {
                state.systemNotify = payload
                storeSystemNotify(payload)
            }
        } else {
            state.systemNotify = payload
            storeSystemNotify(payload)
        }
    },
    REMOVE_NOTIFICATION (state, payload) {
        if (state.systemNotify[0].start_date === payload.start_date) {
            state.systemNotify[0].close = true
            storeSystemNotify(state.systemNotify)
        }
    },
	SET_USER_CREDENTIAL (state,payload) {
		state.loginCredential = payload
	},
}
