<template>
	<div class="app-container body-tabs-shadow app-theme-gray fixed-header fixed-sidebar" id="app-root-container">
		<NavbarDash/>
		<div class="app-main single-main">
			<div class="absolute bg-red-30 inset-0 z-100" style="z-index:9999999999" @click="closeCartDiv"
				 v-if="showFullCover"></div>
			<SidebarDash/>
			<div class="app-main__outer">
				<router-view/>
				<FooterDash/>
			</div>
		</div>
		<Notification/>

		<el-dialog
			:visible.sync="modal_invite"
			width="60%"
			:handleClose="handleClose"
			center>
			<div class="container">
				<div class="row">
					<div class="col-md-12 flex justify-between">
						<div class="text-gray-900 font-semibold text-base">{{ $t('connected') }}</div>
						<div class="form-group">
							<div class="input-icons">
								<i class="iconly-brokenSearch"></i>
								<input type="text" @input="searchInputTrigger"
									   class="text-sm h-8 form-control custom-search" v-bind:placeholder="$t('search')">
							</div>
						</div>
						<!-- <div>
						</div> -->
					</div>
				</div>
				<table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered"
					   v-loading='loadingCompanies'>
					<thead>
					<tr>
						<th class="text-xs text-gray-800 font-bold">{{ $t('companyName') }}</th>
						<th class="text-xs text-gray-800 font-bold">{{ $t('companyNumber') }}</th>
						<th class="text-xs text-gray-800 font-bold">{{ $t('action') }}</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="company in GET_CONNECTED_COMPANY.results" :key="uid(company)">
						<td class="text-xs font-light">{{ company.company.name }}</td>
						<td class="text-xs font-light">{{ company.company.company_id }}</td>
						<td class="flex space-x-2">
							<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
								<span @click="getCompanyUserID(company.id)"
									  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
							</el-tooltip>
							<el-tooltip :content="$t('noteHovering')" placement="top">
								<span @click="checkCompanyNoteModal(company.id, 'Company')"
									  class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span>
							</el-tooltip>
							<el-tooltip :content="$t('deleteHovering')" placement="top">
								<span class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
							</el-tooltip>
							<!-- <span @click="getInviteUserCompany(company.id)">
								<svg class="h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
								</svg>
							</span>
							<span @click="getCompanyUserID(company.id)">
								<svg class="h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
								</svg>
							</span>
							<span>
								<svg class="h-5 w-5 text-yellow-400 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
								</svg>
							</span>
							<span>
								<svg class="h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
								<path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
								</svg>
							</span> -->
						</td>
					</tr>
					<tr v-if="GET_CONNECTED_COMPANY.length < 1" class="odd font-light text-center">
						<td colspan="5" class="dataTables_empty text-xs-custom" valign="top">{{
								$t('noDataTable')
							}}
						</td>
					</tr>
					</tbody>
				</table>
				<div class="float-right mb-5" v-if="GET_CONNECTED_COMPANY.results">
					<paginate
						:page-count="GET_CONNECTED_COMPANY.total_page"
						:click-handler="changePageNumber"
						prev-text="<"
						next-text=">"
						:container-class="'pagination'">
					</paginate>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</div>
		</el-dialog>

		<el-dialog
			:visible.sync="modal_faq"
			width="50%"
			:handleClose="handleClose"
			center>
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h2 data-cy='faq-modal-head' class="heading font-semibold text-gray-900 text-4xl font-medium">
							{{ $t('addFrequent') }}</h2>
						<!-- <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste harum minus tempore quam amet illo laborum. Id officiis quisquam iusto.</span> -->
					</div>
				</div>
				<form @submit.prevent="addFaqData">
					<div class="row mt-4">
						<div class="col-md-12 mb-2">
							<div class="form-group">
								<label for="question" class="text-base text-gray-800 font-medium">{{
										$t('question')
									}}</label>
								<!-- <input type="text"  class="form-control" required> -->
								<textarea cols="30" data-cy='question' rows="3" v-model="question"
										  class='form-control font-light text-xs'
										  :placeholder="$t('genericQuestion')"></textarea>
								<!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
							</div>
						</div>
						<div class="col-md-6 mb-2">
							<div class="form-group">
								<label for="question" class="text-base text-gray-800 font-medium">{{
										$t('category')
									}}</label>
								<select data-cy='category' class='add-pad font-light text-xs form-select w-full'
										v-model="category" required>
									<option :value="cate.id" v-for="cate in GET_FAQ_CATEGORY" :key="uid(cate)">
										{{ cate.category_name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-6 mb-2">
							<div class="form-group md:mt-10 flex space-x-3 cursor-pointer"
								 v-on:click="onOpenCreateFaqCategory">
								<label for="question" class="text-base text-gray-800 font-medium cursor-pointer">{{
										$t('addCategory')
									}}</label>
								<span>
                                <svg class="w-5 h-5 link-color" xmlns="http://www.w3.org/2000/svg" fill="none"
									 viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									  d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                </svg>
                            </span>
							</div>
						</div>
						<div class="col-md-12 mb-3">
							<div class="form-group">
								<label for="question" class="text-base text-gray-800 font-medium">{{
										$t('answer')
									}}</label>
								<!-- <textarea class='form-control'></textarea> -->
								<quill-editor
									data-cy='editor'
									class="editor"
									ref="myTextEditor"
									v-model="content"
									:options="editorOption"
									@change="onEditorChange"
									@blur="onEditorBlur($event)"
									@focus="onEditorFocus($event)"
									@ready="onEditorReady($event)"
								/>
								<input type="file" id="getFile" @change="uploadFunction($event)"/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<button type="submit" data-cy='btn-add-faq' :disabled='creatingFaq'
										class="btn hover:text-white text-base btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creatingFaq" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {{ $t('creating') }}
                                </span>
									<span v-else>{{ $t('add') }}</span>
								</button>
							</div>
						</div>
						<div class="col-md-6">
							<div class="flex justify-end space-x-3">
								<div>{{ $t('dashboardViewOnly') }}</div>
								<div>
									<input type="checkbox" class="checkbox-height">
								</div>
							</div>
						</div>
					</div>
				</form>

				<div slot="footer" class="flex space-x-5">

				</div>
			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="modal_edit_faq"
			width="50%"
			:handleClose="handleFaqEditClose"
			center>
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h2 data-cy='faq-modal-head' class="heading font-semibold text-gray-900 text-4xl font-medium">
							{{ $t('updateFrequent') }}</h2>
						<!-- <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste harum minus tempore quam amet illo laborum. Id officiis quisquam iusto.</span> -->
					</div>
				</div>
				<form @submit.prevent="updateFaqData">
					<div class="row mt-4">
						<div class="col-md-12 mb-2">
							<div class="form-group">
								<label for="question" class="text-base text-gray-800 font-medium">{{
										$t('question')
									}}</label>
								<!-- <input type="text"  class="form-control" required> -->
								<textarea cols="30" data-cy='question' rows="3" v-model="faqPayload.question"
										  class='form-control font-light text-xs'
										  :placeholder="$t('genericQuestion')"></textarea>
								<!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
							</div>
						</div>
						<div class="col-md-12 mb-2">
							<div class="form-group">
								<label for="question" class="text-base text-gray-800 font-medium">{{
										$t('category')
									}}</label>
								<select data-cy='category' class='add-pad font-light text-xs form-select w-full'
										v-model="faqPayload.category" required>
									<option :value="cate.id" v-for="cate in GET_FAQ_CATEGORY" :key="uid(cate)">
										{{ cate.category_name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-12 mb-2">
							<div class="form-group">
								<label for="email" class="text-base text-gray-800 font-medium">{{
										$t('customer_email')
									}}</label>
								<input type="email" class="form-control" v-model="faqPayload.customer_email_request"
								>
							</div>
						</div>
						<div class="col-md-12 mb-2">
							<div class="form-group">
								<label for="customer_name" class="text-base text-gray-800 font-medium">{{
										$t('customer_name')
									}}</label>
								<input type="email" class="form-control" v-model="faqPayload.customer_name"
								>
							</div>
						</div>
						<div class="col-md-6">
							<div class="flex  space-x-3 mb-2 lg:mb-3">
								<div>{{ $t('isHidden') }}</div>
								<div>
									<input type="checkbox" class="checkbox-height" v-model="faqPayload.hidden">
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="flex space-x-3  mb-2 lg:mb-3">
								<div>{{ $t('visible_for_customers_only') }}</div>
								<div>
									<input type="checkbox" class="checkbox-height"
										   v-model="faqPayload.visible_for_customers_only">
								</div>
							</div>
						</div>
						<div class="col-md-12 mb-3">
							<div class="form-group">
								<label for="answer" class="text-base text-gray-800 font-medium">{{
										$t('answer')
									}}</label>
								<!-- <textarea class='form-control'></textarea> -->
								<quill-editor
									data-cy='editor'
									class="editor"
									ref="myTextEditor"
									v-model="faqPayload.answer"
									:options="editorOption"
									@change="onEditorChange"
									@blur="onEditorBlur($event)"
									@focus="onEditorFocus($event)"
									@ready="onEditorReady($event)"
								/>
								<input type="file" id="getFile" @change="uploadFunction($event)"/>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<button type="submit" data-cy='btn-add-faq' :disabled='creatingFaq'
										class="btn hover:text-white text-base h-12 btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creatingFaq" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </span>
									<span v-else>{{ $t('update') }}</span>
								</button>
							</div>
						</div>
						<div class="col-md-6">
							<div class="flex justify-end space-x-3">
								<div>{{ $t('notifyUserForUpdate') }}</div>
								<div>
									<input type="checkbox" class="checkbox-height" v-model="notify_user">
								</div>
							</div>
						</div>
					</div>
				</form>

				<div slot="footer" class="flex space-x-5">

				</div>
			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="modal_faq_create_category"
			width="50%"
			:handleClose="handleCloseFaqCategory"
			center>
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h2 data-cy='faq-modal-head' class="heading font-semibold text-gray-900 text-4xl font-medium">
							{{ $t('createFaqCategory') }}</h2>
						<!-- <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste harum minus tempore quam amet illo laborum. Id officiis quisquam iusto.</span> -->
					</div>
				</div>
				<form @submit.prevent="addFaqCategoryData">
					<div class="row mt-4">
						<div class="col-md-12 mb-2">
							<div class="form-group">
								<label for="question" class="text-base text-gray-800 font-medium">{{
										$t('Name')
									}}</label>
								<input type="text" class="form-control" v-model="categoryObj.category_name" required>

								<!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
							</div>
						</div>
						<div class="col-md-12 mb-2">
							<div class="form-group">
								<label for="description" class="text-base text-gray-800 font-medium">{{
										$t('description')
									}}</label>
								<textarea cols="30" data-cy='question' rows="3" v-model="categoryObj.description"
										  class='form-control font-light text-xs'
										  :placeholder="$t('description')"></textarea>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<button type="submit" data-cy='btn-add-faq' :disabled='creatingFaq'
										class="btn hover:text-white text-base btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creatingFaq" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {{ $t('creating') }}
                                </span>
									<span v-else>{{ $t('add') }}</span>
								</button>
							</div>
						</div>
						<div class="col-md-6">

						</div>
					</div>
				</form>

				<div slot="footer" class="flex space-x-5">

				</div>
			</div>
		</el-dialog>

		<el-dialog
			:visible.sync="modal_util"
			width="60%"
			:handleClose="handleClose"
			center>
			<div class="container">
				<div class="row -mt-16 mb-3">
					<div class="col-md-3"></div>
					<div class="col-md-6 text-center">
						<img src="./../../assets/images/illus/team.svg" class="img-fluid d-none d-lg-block" alt="team">
						<div class="text-xl text-gray-800 whitespace-nowrap md:-mt-10">{{
								$t('massReassignAccountant')
							}}
						</div>
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>

			<div class="container">
				<!-- <h2 class="mb-2 text-xl text-gray-400">{{ $t('accountantCompanies') }}</h2> -->
				<table style="width: 100%;" id="exampledestroy" class="table table-hover table-striped table-bordered">
					<thead>
					<tr>
						<th class="text-xs text-gray-800 font-bold">{{ $t('companyName') }}</th>
						<th class="text-xs text-gray-800 font-bold">{{ $t('companyNumber') }}</th>
						<th class="text-xs text-gray-800 font-bold">{{ $t('action') }}</th>
					</tr>
					</thead>
					<tbody v-loading='loadSpecific'>
					<tr v-for="company in GET_ACCOUNT_COMPANY" :key="uid(company)">
						<td class="text-xs font-light">{{ company.company.name }}</td>
						<td class="text-xs font-light">{{ company.company.company_id }}</td>
						<td class="flex space-x-2">
							<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
								<span @click="getCompanyUserID(company.id)"
									  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
							</el-tooltip>
							<el-tooltip :content="$t('noteHovering')" placement="top">
								<span @click="checkCompanyNoteModal(company.id, 'Company')"
									  class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span>
							</el-tooltip>
							<el-tooltip :content="$t('deleteHovering')" placement="top">
								<span class="text-base cursor-pointer iconly-boldDelete icon-color"></span>
							</el-tooltip>
						</td>
					</tr>
					<tr v-if="GET_ACCOUNT_COMPANY.length < 1" class="odd text-center">
						<td colspan="5" class="dataTables_empty text-xs-custom" valign="top">{{
								$t('noDataTable')
							}}
						</td>
					</tr>
					</tbody>
				</table>

				<hr>
				<!-- <div class="row">
					<div class="col-md-12 mb-3">

					</div>
				</div> -->

				<div class="row">
					<div class="col-md-12">
						<h2 class="text-xl text-gray-700 mb-2 mt-3">{{ $t('reassignAccountant') }}
							<span>({{ accountName }})</span>
							<!-- <span v-else>()</span> -->
						</h2>
					</div>
					<div class="col-md-6">
						<div class="form-group md:-mt-1">
							<label for="exampleEmail"
								   class="override-pb font-medium text-base text-gray-400 override-pt col-form-label -mt-3">{{
									$t('assignTo')
								}}</label>
							<div>
								<select v-model="destination" class="set-pad form-select w-full focus:outline-none">
									<option :value="accountant.id" v-for="accountant in reAssignAccount"
											:key="uid(accountant)">
										<span v-if="accountant.first_name">{{
												accountant.first_name + ' ' + accountant.last_name | capitalizeEachString
											}}</span>
										<span v-else>{{ accountant.username | capitalizeEachString }}</span>
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<!-- <span class="font-semibold text-gray-600"><span class="text-base font-medium text-gray-400">{{ $t('accountnt') }}:</span> {{ accountName | capitalizeEachString }}</span> -->
						<span class="font-semibold text-gray-600"><span
							class="text-base font-medium text-gray-400">{{ $t('selectCompanies') }}</span></span>
						<el-select size="small" class="w-full company-select" v-model="clients" multiple
								   placeholder="utvalda företag">
							<el-option
								v-for="com in GET_ACCOUNT_COMPANY"
								:key="uid(com)"
								:label="com.company.name"
								:value="com.id">
							</el-option>
						</el-select>
					</div>

				</div>
				<div class="row mt-5">
				</div>
				<div class="flex justify-end">
					<button @click="reAssignAccountant" :disabled="assigning"
							class="btn hover:text-white font-medium text-base btn-custom cursor-not-allowed">
                    <span v-if="assigning" class="flex align-items-center space-x-3">
                        <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							 viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
							  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{ $t('assigning') }}
                    </span>
						<span v-else>{{ $t('assign') }}</span>
					</button>
				</div>
				<div slot="footer" class="flex space-x-5">

				</div>
			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="openApiQueriesSlider"
			width="94%"
			:handleClose="handleCloseQueries"
		>
			<div class="container">
				<div class="p-3 json-editor">
					<vue-json-pretty :data="queryResponse" :showLineNumber="true" :showIcon="true"/>
				</div>
			</div>
		</el-dialog>

		<ModalContainer v-if="modalVisible" title='Add a note' @handleSave='createNoteNow'
						@handleClose="modalVisible = false" :close="!true">
            <textarea v-model="stickyMessage" cols="30" rows="10" class="bg-stick font-light focus:outline-none"
					  slot="body">

            </textarea>
		</ModalContainer>

		<el-dialog
			:visible.sync="modal_photo"
			width="60%"
			:handleClose="handleClose"
			center>
			<div class="container">
				<div class="row">
					<div class="col-md-6 -mt-10">
						<img src="./../../assets/images/illus/upload.svg" class="img-fluid d-none d-lg-block"
							 alt="mail">
						<h2 class="text-2xl text-gray-900 font-semibold">{{ $t('upload') }}</h2>
						<!-- <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi asperiores adipisci corporis sit repellat in aperiam quia odio doloribus dolore.</span> -->
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label class="custom-file-upload">
								<input type="file" id="upload" class="inputfile form-control-file" @change="croppie"
									   accept="image/png, image/jpeg, image/jpg"/>
								<i class="iconly-brokenUpload"></i> {{ $t('chooseFile') }}
							</label>
						</div>
						<div class="row">
						</div>
						<div v-show="showCrop">
							<vue-croppie ref="croppieRef" :enableOrientation="true" :enableResize="false"
										 :boundary="{ width: 250, height: 250}"
										 :viewport="{width:150, height:150,'type':'circle' }">
							</vue-croppie>
						</div>
						<div slot="footer" class="flex space-x-5" v-show="showCrop">
							<button class="btn hover:text-white text-base btn-custom" @click="crop"
									:disabled="initializingCropping">
								<span v-if="initializingCropping">{{ $t('cropping') }}</span>
								<span v-else>{{ $t('crop') }}</span>
							</button>

							<button class="btn focus:outline-none text-white bg-green-500" @click="uploadPhoto"
									v-if="croppingSuccess" :disabled='uploadingNow'>
								<span v-if="uploadingNow">{{ $t('uploading') }}.</span>
								<span v-else>{{ $t('fileUpload') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="container">
				<div class="form-group">
					<input type="file" id="upload" class="form-control-file" @change="croppie" accept="image/png, image/jpeg, image/jpg"/>
				</div>
				<div class="row">
				</div>
				<div v-show="showCrop">
					<vue-croppie ref="croppieRef" :enableOrientation="true" :enableResize="false" :boundary="{ width: 250, height: 250}" :viewport="{width:150, height:150,'type':'circle' }">
					</vue-croppie>
				</div>
				<div slot="footer" class="flex space-x-5" v-show="showCrop">
					<button class="btn focus:outline-none btn-primary" @click="crop" :disabled="initializingCropping">
						<span v-if="initializingCropping">Cropping...</span>
						<span v-else>Crop</span>
					</button>

					<button class="btn focus:outline-none bg-green-500" @click="uploadPhoto" v-if="croppingSuccess" :disabled='uploadingNow'>
						<span v-if="uploadingNow">{{ $t('uploading') }}</span>
						<span v-else>{{ $t('uploadFinished') }}</span>
					</button>
				</div>
			</div> -->
		</el-dialog>
		<el-dialog
			:visible.sync="open_tasks_sync_modal"
			width="60%"
			:handleClose="handleCloseEventTasks"
			center>
			<div class="container">
				<div class="row">
					<div class="col-md-12" v-if="['Success'].includes(syncDict.status)"
						 style="height: 200px;overflow-y: scroll;overflow-x: hidden">

						<div
							v-if="['all_product','all_order','payment'].includes(syncDict.sync_type)">
							<div class="row mb-3" v-if="parseSyncData(syncDict.passed_number_in_app).length > 0">
								<div class="col-md-12">
									<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_product'">{{
											$t('articleSync')
										}}</h4>
									<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_order'">{{
											$t('orderSync')
										}}</h4>
									<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'payment'">{{
											$t('paymentSync')
										}}</h4>
								</div>
								<div class="col-md-12">
									<div class="grid grid-cols-3 gap-3">
										<app-sync-card :item="dt"
													   v-for="(dt,i) in parseSyncData(syncDict.passed_number_in_app)"
													   :key="i"/>

									</div>
								</div>

							</div>
							<hr style="padding-top: 10px;
    padding-bottom: 3px;">
							<div class="row" v-if="parseSyncData(syncDict.failed_number_in_app).length > 0">
								<div class="col-md-12">
									<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_product'">{{
											$t('articleNotSync')
										}}</h4>
									<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'all_order'">{{
											$t('orderNotSync')
										}}</h4>
									<h4 class="mb-3 head-text" v-if="syncDict.sync_type === 'payment'">{{
											$t('paymentNotSync')
										}}</h4>
								</div>
								<div class="col-md-12">
									<div class="grid grid-cols-3 gap-3">
										<app-sync-card :item="dt"
													   v-for="(dt,i) in parseSyncData(syncDict.failed_number_in_app)"
													   :key="i"/>
									</div>
								</div>
								<div class="col-md-12">
									<h4 style="margin-top: 15px;" class="head-text">{{ $t('reason') }}</h4>
									<p style="overflow-wrap: break-word;margin-top: 5px">
										{{ syncDict.long_description }}</p>
								</div>
							</div>
						</div>
						<p style="overflow-wrap: break-word;"
						   v-else>
							{{ syncDict.long_description }}
						</p>
					</div>
					<div class="col-md-12" v-if="['Failed'].includes(syncDict.status)"
						 style="height: 200px;overflow-y: scroll;overflow-x: hidden">
						<!--							<h4 class="mb-3">{{ $t('message') }}</h4>-->
						<p style="overflow-wrap: break-word;">
							{{ syncDict.long_description }}
						</p>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5">

			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="openNotificationCreateModal"
			width="60%"
			:handleClose="handleCloseNotificationModal"
			center>
			<div class="container">
				<create-notification :key="componentID"/>
			</div>
			<div slot="footer" class="flex space-x-5">

			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="openNotificationEditModal"
			width="60%"
			:handleClose="handleCloseNotificationModal"
			center>
			<div class="container">
				<update-notification :key="componentID" :notification="notificationObj"/>
			</div>
			<div slot="footer" class="flex space-x-5">

			</div>
		</el-dialog>
		<el-dialog
			:visible.sync="openCompanyEdit"
			width="80%"
			:handleClose="handleCloseNotificationModal"
			center>
			<div class="container">
				<edit-company-profile :company="companyEdit" :key="componentID"/>
			</div>
			<div slot="footer" class="flex space-x-5">

			</div>
		</el-dialog>
		<el-drawer
			:title="$t('createSyncEntry')"
			:visible.sync="appSyncModal"
			:direction="'rtl'"
			:size="`40%`"
			custom-class="demo-drawer"
			ref="drawer"
		>
			<div class="demo-drawer__content">
				<app-synchronize-form :key="componentID"/>
			</div>
		</el-drawer>
		<el-drawer
			:title="$t('uploadCustomAccount')"
			:visible.sync="customAccountUpload"
			:direction="'rtl'"
			:size="`40%`"
			custom-class="demo-drawer"
			ref="drawer"
		>
			<div class="demo-drawer__content">
				<custom-account-upload-form :key="componentID"/>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapState} from 'vuex'
import debounce from 'lodash/debounce'
import {v4 as uuidv4} from 'uuid'
import bus from './../../bus'
import NavbarDash from '@/components/RNavbar'
import SidebarDash from '@/components/RSidebar'
import FooterDash from '@/components/Footer'
import {quillEditor} from 'vue-quill-editor'
import VueQuillEditor from 'vue-quill-editor'
import VueJsonPretty from 'vue-json-pretty';
import ModalContainer from './../../customComponent/Modal'
import Paginate from 'vuejs-paginate'
import {Dialog, Tooltip, Select, Option, Drawer} from 'element-ui'
import VueCroppie from 'vue-croppie'
import {destroyPath} from "../../services/storage-window";
import appSyncCard from "../Automatisera/components/appSyncCard";
import appSynchronizeForm from "./appSynchronizeForm";
import customAccountUploadForm from "./customAccountUploadForm";
import createNotification from "./notifications/createNotification";
import updateNotification from "./notifications/updateNotification";
import editCompanyProfile from "./editCompanyProfile";

Vue.use(VueCroppie)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Option)

Vue.component(
	'VueQuillEditor',
	// A dynamic import returns a Promise.
	() => import('vue-quill-editor')
)

export default {
	name: 'main-root-dash',
	components: {
		paginate: Paginate,
		NavbarDash,
		VueJsonPretty,
		createNotification,
		updateNotification,
		SidebarDash,
		FooterDash,
		quillEditor,
		appSyncCard,
		editCompanyProfile,
		appSynchronizeForm,
		customAccountUploadForm,
		// Dialog,
		// Select,
		// Tooltip,
		ModalContainer,
		Notification: () => import('./../../customComponent/NotificationAlert')
	},
	data() {
		return {
			baseURL: window.location.origin,
			translate: this.$t('awesome'),
			notify_user: false,
			modalVisible: false,
			appSyncModal: false,
			openCompanyEdit: false,
			companyEdit: {},
			customAccountUpload: false,
			componentID: '',
			syncDict: {long_description: '', status: '', passed_number_in_app: [], 'failed_number_in_app': []},
			editorOption: {
				modules: {
					toolbar: {
						container: [
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{header: 1}, {header: 2}],
							[{list: 'ordered'}, {list: 'bullet'}],
							[{script: 'sub'}, {script: 'super'}],
							[{indent: '-1'}, {indent: '+1'}],
							[{direction: 'rtl'}],
							[{size: ['small', false, 'large', 'huge']}],
							[{header: [1, 2, 3, 4, 5, 6, false]}],
							[{font: []}],
							[{color: []}, {background: []}],
							[{align: []}],
							['clean'],
							['link', 'image', 'video'],
						],

						handlers: {
							image: function () {
								document.getElementById('getFile').click()
							}
						},
					},
					// syntax: {
					//     highlight: text => hljs.highlightAuto(text).value
					// }
				},
				placeholder: this.$t('awesome'),
			},
			content: '',
			firstname: '',
			lastname: '',
			email: '',
			question: '',
			stickyMessage: '',
			amDataSlider: false,
			queryResponse: {},
			notificationObj: {},
			noteObj: {},
			category: 'Välj kategori',
			categoryObj: {
				category_name: '',
				description: '',
			},
			faqPayload: {
				category: '',
				id: '',
				question: '',
				answer: '',
				hidden: false,
				visible_for_customers_only: false,
				customer_email_request: false,

			},
			allCompanies: [],
			loadingCompanies: false,
			accountantCom: [],
			accountName: '',
			accountId: '',
			destination: '',
			clients: [],
			reAssignAccount: [],
			categoryList: [],
			// activeBoardIndex: 0,
			// boards: [],
			history: [],
			role: 'Select role',
			creatingFaq: false,
			creating: false,
			assigning: false,
			userObject: null,
			showCrop: false,
			croppieImage: null,
			initializingCropping: false,
			croppingSuccess: false,
			showPreviewCropping: false,
			uploadingNow: false,
			updateUser: false,
			createNote: false,
			userIdNote: null,
			noteId: null,
			userAgent: null,
			loadSpecific: true,
			showFullCover: false,
			openNotificationEditModal: false,
			openNotificationCreateModal: false,
		}
	},
	watch: {
		$route(to, from) {
			// react to route changes...Kod för Betalvillkor
			const {partner} = to.params
			const {partnerId} = to.query
			if (partner && partnerId) {
				// console.log('watching working')
				this.getConnectCompany(partner)
			} else {
				this.accountId = ''
				this.accountName = ''
				this.reAssignAccount = []
				this.$store.commit('house/UPDATE_ACCOUNTANT_COMPANY', [])
			}
			this.category = this.$t('chooseCategory')
		},
		placeholder: (el, val) => {
			// console.log('new', el)
			// console.log('old', val)
		}
	},
	computed: {
		// activeBoard () {
		//     return this.boards[this.activeBoardIndex] || false
		// },
		modal_invite: {
			get: function () {
				return this.MODAL_INVITE
			},
			set: function () {
				return this.$store.commit('utility/OPEN_INVITE_MODAL')
			}
		},
		modal_photo: {
			get: function () {
				return this.MODAL_PHOTO
			},
			set: function () {
				return this.$store.commit('utility/OPEN_PHOTO_MODAL')
			}
		},
		open_tasks_sync_modal: {
			get: function () {
				return this.GET_EVENT_TASKS_DETAIL
			},
			set: function () {
				if (this.GET_EVENT_TASKS_DETAIL === true) {
					return this.$store.commit('utility/OPEN_TASKS_MODAL_DETAIL', false)
				} else {
					return this.$store.commit('utility/OPEN_TASKS_MODAL_DETAIL', true)
				}

			}
		},
		modal_faq: {
			get: function () {
				return this.MODAL_IN_HOUSE_FAQ
			},
			set: function () {
				this.$store.commit('house/UPDATE_ACCOUNTANT_COMPANY', [])
				return this.$store.commit('utility/OPEN_MODAL_FAQ')
			}
		},
		modal_edit_faq: {
			get: function () {
				return this.GET_MODAL_INHOUSE_EDIT_FAQ
			},
			set: function () {
				this.$store.commit('house/UPDATE_ACCOUNTANT_COMPANY', [])
				return this.$store.commit('utility/OPEN_MODAL_FAQ_EDIT_MODAL')
			}
		},
		modal_faq_create_category: {
			get: function () {
				return this.MODAL_IN_HOUSE_CREATE_FAQ_CATEGORY
			},
			set: function () {
				this.$store.commit('house/UPDATE_ACCOUNTANT_COMPANY', [])
				return this.$store.commit('utility/OPEN_MODAL_FAQ_CREATE_MODAL')
			}
		},
		modal_util: {
			get: function () {
				return this.MODAL_UTIL
			},
			set: function () {
				this.$store.commit('house/UPDATE_ACCOUNTANT_COMPANY', [])
				return this.$store.commit('utility/OPEN_MODAL_UTIL')
			}
		},
		openApiQueriesSlider: {
			get: function () {
				return this.GET_API_QUERIES
			},
			set: function () {
				this.$store.commit('house/UPDATE_ACCOUNTANT_COMPANY', [])
				if (this.GET_API_QUERIES === true) {
					return this.$store.commit('utility/OPEN_API_QUERIES', false)
				} else {
					return this.$store.commit('utility/OPEN_API_QUERIES', true)
				}

			}
		},
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			MODAL_INVITE: 'utility/MODAL_INVITE',
			MODAL_PHOTO: 'utility/MODAL_PHOTO',
			MODAL_UTIL: 'utility/MODAL_UTIL',
			MODAL_IN_HOUSE_FAQ: 'utility/MODAL_IN_HOUSE_FAQ',
			GET_MODAL_INHOUSE_EDIT_FAQ: 'utility/GET_MODAL_INHOUSE_EDIT_FAQ',
			MODAL_IN_HOUSE_CREATE_FAQ_CATEGORY: 'utility/MODAL_IN_HOUSE_CREATE_FAQ_CATEGORY',
			GET_API_QUERIES: 'utility/GET_API_QUERIES',
			GET_EVENT_TASKS_DETAIL: 'utility/GET_EVENT_TASKS_DETAIL',
			GET_USER_UTIL: 'accountant/GET_USER_UTIL',
			// GET_IN_HOUSE_UTIL : 'house/GET_IN_HOUSE_UTIL',
			GET_IN_HOUSE_DATA: 'house/GET_IN_HOUSE_DATA',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_FAQ_CATEGORY: 'customer/GET_FAQ_CATEGORY',
			GET_CONNECTED_COMPANY: 'house/GET_CONNECTED_COMPANY',
			GET_ACCOUNT_COMPANY: 'house/GET_ACCOUNT_COMPANY',

			GET_MASTER_ACCOUNTANT: 'accountant/GET_MASTER_ACCOUNTANT',
			GET_COLLEAGUE: 'colleague/GET_COLLEAGUE',
			GET_ASSIST_ACCOUNTANT: 'assistanceAccountant/GET_ASSIST_ACCOUNTANT',
		}),
		...mapState({
			// GET_IN_HOUSE_UTIL : state => state.house.regularHouse
		})
	},
	created() {
		// if (this.boards.length === 0) {
		//     this.boards.push(this.createBoard(true))
		// }
	},
	mounted() {
		destroyPath()
		// const { partner } = this.$route.params
		// const { partnerId } = this.$route.query
		// if (partner) {
		//     this.getConnectCompany(partner)
		//     console.log('lok', partner)
		// }
		document.addEventListener('contextmenu', function (e) {
			e.preventDefault()
		})
		this.category = this.$t('chooseCategory')
		this.getFaqCategoryList()

		bus.$on('open-sticky', e => {
			// console.log(e)
			this.modalVisible = true
			this.noteObj = e
			this.stickyMessage = e.message
		})
		window.Bus.$on('open-add-faq', e => {
			this.category = ''
			this.content = ''
			this.question = ''
			this.$store.commit('utility/OPEN_MODAL_FAQ')
		})
		window.Bus.$on('open-faq-edit', e => {
			this.faqPayload = e

			this.$store.commit('utility/OPEN_MODAL_FAQ_EDIT_MODAL')
		})
		window.Bus.$on('open-add-faq-category', e => {
			Object.keys(this.categoryObj).map(k => {
				this.categoryObj[k] = ''
			})
			this.$store.commit('utility/OPEN_MODAL_FAQ_CREATE_MODAL')
		})
		bus.$on('specific', e => {
			// console.log('spec:', e)
			this.accountId = e.chosenAccountant.id
			this.accountName = e.chosenAccountant.first_name ? e.chosenAccountant.first_name + ' ' + e.chosenAccountant.last_name : e.chosenAccountant.username
			this.getAccountantSpecificCompany(e.chosenAccountant.id)
			const filterAccountant = e.accountant.filter(item => item.id !== e.chosenAccountant.id)
			this.reAssignAccount = filterAccountant
		})
		const self = this
		bus.$on('connected-com', e => {
			self.getConnectCompany(e)
		})
		window.Bus.$on('open-app-sync-detail', e => {
			this.syncDict = e
			this.$store.commit('utility/OPEN_TASKS_MODAL_DETAIL', true)
		})

		window.Bus.$on('open-app-sync-drawer', e => {
			this.componentID = new Date().getMinutes()
			this.appSyncModal = true
		})
		window.Bus.$on('open-company-edit-profile', e => {
			this.componentID = new Date().getMinutes()
			this.companyEdit = e
			this.openCompanyEdit = true
		})
		bus.$on('redirect-user-dashboard', _ => {
			if (self.GET_USER_UTIL === 'true') {
				self.$router.replace({name: 'accountantdash'})
			} else if (self.GET_MASTER_ACCOUNTANT) {
				self.$router.replace({name: 'master_accountant_dashboard'})
			} else if (self.GET_ASSIST_ACCOUNTANT) {
				self.$router.replace({name: 'accountant_assistance'})
			} else if (self.GET_COLLEAGUE) {
				self.$router.replace({name: 'colleague_settings'})
			}
		})

		window.Bus.$on('sign-out', _ => {
			if (this.$route.path !== '/') {
				this.$router.replace({name: 'login'})
			}
		})
		window.Bus.$on('show-cover', e => {
			if (e === true) {
				this.showFullCover = true
			} else {
				this.showFullCover = false
			}
		})
		window.Bus.$on('open-am-queries', e => {
			this.queryResponse = e.data
			this.$store.commit('utility/OPEN_API_QUERIES', true)
		})
		window.Bus.$on('open-custom-account-uploader', e => {
			this.componentID = new Date().getMilliseconds()
			this.customAccountUpload = true
		})
		window.Bus.$on('open-am-queries', e => {
			this.queryResponse = e.data
			this.$store.commit('utility/OPEN_API_QUERIES', true)
		})
		window.Bus.$on('open-notification-edit', e => {
			this.componentID = new Date().getMinutes()
			this.openNotificationEditModal = true
		})
		window.Bus.$on('open-notification-create', e => {
			this.componentID = new Date().getMinutes()
			this.openNotificationCreateModal = true
		})
		window.Bus.$on('open-notification-edit', e => {
			this.componentID = new Date().getMinutes()
			this.notificationObj = e.data
			this.openNotificationEditModal = true
		})
		// var self = this

		// bus.$on('remove-note', id => {
		//     var noteIndex = self.activeBoard.notes.findIndex(note => {
		//         return (id === note.id)
		//     })
		//     self.activeBoard.notes.splice(noteIndex, 1)
		// })

		// bus.$on('save-update', () => {
		//     if (this.updateUser) {
		//         this.updateUserNote()
		//     } else if (this.createNote) {
		//         this.createCompanyNote()
		//     }
		// })

		// bus.$on('load-board', id => {
		//     self.activeBoardIndex = id
		// })

		// bus.$on('create-board', () => {
		//     self.boards.push(self.createBoard(false))
		//     self.activeBoardIndex = self.boards.length - 1
		// })

		// bus.$on('remove-board', id => {
		//     const last = self.boards.length - 1
		//     if (self.activeBoardIndex === last && id === last) {
		//         self.activeBoardIndex--
		//     }
		//     self.boards.splice(id, 1)
		// })

		// bus.$on('clear-board', () => {
		//     self.activeBoard.notes.splice(0, self.activeBoard.notes.length)
		// })
	},
	beforeMount() {
		if (this.USER_TOKEN) {
			// console.log('You:', this.GET_IN_HOUSE_DATA)
			if (this.GET_IN_HOUSE_DATA !== 'true') {
				// console.log('what your value:', this.GET_IN_HOUSE_DATA)
				this.$store.commit('auth/CLEAR_AUTH_USER', null)
				if (this.$route.path !== '/') {
					this.$router.replace({name: 'login'})
				}
			}
			// setTimeout(() => {
			// }, 4000)
		} else {
			this.$store.commit('auth/CLEAR_AUTH_USER', null)
			if (this.$route.path !== '/') {
				this.$router.replace({name: 'login'})
			}
		}
	},
	methods: {
		parseSyncData: function (data) {
			let dt = data.replace('[', '').replace(']', '').split(',')
			if (dt.length > 0) {
				if (dt[0] === "") {
					return []
				} else {
					return dt
				}
			} else {
				return []
			}
			// if (Array.isArray(data)) {
			// 	console.log('am here')
			// 	return data
			// } else if (typeof data === String) {
			// 	console.log('am here s')
			// 	data.split(',')
			// } else {
			//
			// 	console.log('am here')
			// 	return []
			// }

		},
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterAccountants(event)
			} else {
				this.$store.commit('house/FILTER_COMPANIES_ALL')
			}
		}, 500),
		filterAccountants(event) {
			this.$store.commit('house/FILTER_CON_COMPANIES', event.target.value)
		},
		closeCartDiv() {
			window.Bus.$emit('close-cart')
			this.showFullCover = false
		},
		uploadFunction(e) {
			// you can get images data in e.target.files
			// an single example for using formData to post to server
			const self = this
			var form = new FormData()
			form.append('file', e.target.files[0])

			this.$store.dispatch('house/uploadEditorImage', form)
				.then(response => {
					const fileString = response.data.data.data.file_path
					// console.log('file:', fileString)
					const range = self.$refs.myTextEditor.quill.getSelection()
					self.$refs.myTextEditor.quill.insertEmbed(range.index, 'image', fileString)
					// var reader = new FileReader()
					// reader.onload = function (e) {
					// }
					// reader.readAsDataURL(e.target.files[0])
				})
				.catch(_ => {
					// console.log(err)
				})


			// var xhr = new XMLHttpRequest()
			// xhr.open('POST', this.$services.endpoints.UPLOAD_FAQ_IMAGE, true)
			// xhr.setRequestHeader('Authorization', `Bearer ${this.USER_TOKEN}`)
			// xhr.onreadystatechange = function () {
			//     if (xhr.readyState === 4) {
			//         var response = JSON.parse(xhr.responseText)
			//         console.log(response)
			//         if (response.code === 200) {
			//             const fileString = response.data.data.file_path
			//             const range = self.$refs.myTextEditor.quill.getSelection()
			//             self.$refs.myTextEditor.quill.insertEmbed(range.index, 'image', fileString)
			//         } else {
			//             var reader = new FileReader()
			//             reader.onload = function (e) {
			//             }
			//             reader.readAsDataURL(e.target.files[0])
			//         }
			//     }
			// }
			// xhr.send(form)
		},
		onEditorChange({html, _}) {
			console.debug('editor change!', html)
			this.content = html
		},
		onEditorBlur(_) {
			// console.log('editor blur!', editor)
		},
		onEditorFocus(_) {
			// console.log('editor focus!', editor)
		},
		onEditorReady(_) {
			// console.log('editor ready!', editor)
		},
		handleClose() {
			this.$store.commit('utility/OPEN_INVITE_MODAL')
		},
		handleCloseEventTasks() {
			this.$store.commit('utility/OPEN_TASKS_MODAL_DETAIL')
		},
		handleCloseNotificationModal() {
			this.openNotificationCreateModal = false
			this.openNotificationEditModal = false
			this.openCompanyEdit = false
		},
		handleCloseQueries() {
			this.$store.commit('utility/OPEN_API_QUERIES')
		},
		handleFaqEditClose() {
			this.$store.commit('utility/OPEN_MODAL_FAQ_EDIT_MODAL')
		},
		handleCloseFaqCategory() {
			this.$store.commit('utility/OPEN_MODAL_FAQ_CREATE_MODAL')
		},
		reAssignAccountant() {
			if (this.accountId === '') {
				this.$services.helpers.notification(this.$t('invalidAccountID'), 'error', this)
				return false
			} else if (this.destination === '') {
				this.$services.helpers.notification(this.$t('selectAssignee'), 'error', this)
				return false
			} else if (this.clients.length < 1) {
				this.$services.helpers.notification(this.$t('selectCompany'), 'error', this)
				return false
			} else {
				this.assigning = true
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(this.$services.endpoints.IN_HOUSE_REASSIGNING, {
				//     source_accountant : this.accountId,
				//     destination_accountant : this.destination,
				//     clients : this.clients
				// })
				const payload = {
					source_accountant: this.accountId,
					destination_accountant: this.destination,
					clients: this.clients
				}
				this.$store.dispatch('house/assignAccountant', payload)
					.then(res => {
						this.assigning = false
						// console.log(res)
						this.$services.helpers.notification(this.$t('successAssign'), 'success', this)
						setTimeout(() => {
							bus.$emit('assign')
							this.$store.commit('utility/OPEN_MODAL_UTIL')
						}, 1000)
						// if (res.status === 200) {
						// }
					}).catch((err) => {
					this.assigning = false
					// console.log(err.response)
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									// console.log('refresh')
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})
			}
		},
		createNoteNow() {
			if (this.noteObj.updateUser) {
				this.updateUserNote()
			} else {
				this.createCompanyNote()
			}
		},
		createCompanyNote() {
			if (this.stickyMessage !== '') {
				this.$services.helpers.notification(this.$t('createNote'), 'progress', this)
				const noteText = this.stickyMessage
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(this.$services.endpoints.MANAGERS_NOTE + `${this.noteObj.userId}/`, {
				//     recipient: this.noteObj.userId,
				//     message: noteText,
				//     read: false
				// })
				const payload = {
					recipient: this.noteObj.userId,
					message: noteText,
					read: false
				}
				this.$store.dispatch('accountant/createCompanyNote', {
					data: payload,
					URL: this.$services.endpoints.COMPANY_NOTES + `?user_id=${this.noteObj.userId}`
				})
					.then(res => {
						// console.log(res)
						if (res.data.success) {
							this.$services.helpers.notification(this.noteObj.agent + ' ' + this.$t('noteCreated'), 'success', this)
							this.stickyMessage = ''
							this.noteObj = {}
							this.modalVisible = false
						}
					}).catch((_) => {

				})
			} else {
				this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
			}
		},
		updateUserNote() {
			if (this.stickyMessage !== '') {
				this.$services.helpers.notification(this.$t('updateNote'), 'progress', this)
				const noteText = this.stickyMessage
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.put(this.$services.endpoints.MANAGERS_NOTE + `update/${this.noteObj.noteID}/${this.noteObj.userId}/`, {
				//     recipient: this.noteObj.userId,
				//     message: noteText,
				// })
				const payload = {
					recipient: this.noteObj.userId,
					message: noteText,
				}
				this.$store.dispatch('accountant/updateUserNote', {
					data: payload,
					URL: this.$services.endpoints.COMPANY_NOTES + `${this.noteObj.noteID}/?user_id=${this.noteObj.userId}`
				})
					.then(res => {
						// console.log(res)
						if (res.data.success) {
							this.$services.helpers.notification(this.noteObj.agent + ' ' + this.$t('noteUpdated'), 'success', this)
							this.stickyMessage = ''
							this.noteObj = {}
							this.modalVisible = false
						}
					}).catch((_) => {
					// console.log(err.response.data)
				})
			} else {
				this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
			}
		},
		onOpenCreateFaqCategory: function () {
			window.Bus.$emit('open-add-faq-category')
		},
		getFaqCategoryList() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_FAQ_CATEGORY_LIST)
			this.$store.dispatch('customer/getFaqCategoryList')
				.then(_ => {
					// console.log('catelist:', res)
					// if (res.status === 200) {
					//     this.categoryList = res.data.data
					// }
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getFaqCategoryList()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		addFaqData() {
			if (this.question === '' || this.category === '' || this.content === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (!parseInt(this.category)) {
				this.$services.helpers.notification(this.$t('categorySelect'), 'error', this)
			} else {
				this.creatingFaq = true
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(this.$services.endpoints.CREATING_FAQ, {
				//     // slug : "none",
				//     // absolute_url : null
				// })
				const payload = {
					category: this.category,
					question: this.question,
					answer: this.content,
				}
				this.$store.dispatch('house/addFaqData', payload)
					.then(_ => {
						this.creatingFaq = false
						bus.$emit('reload-faq')
						this.$services.helpers.notification(this.$t('faqAdded'), 'success', this)
						setTimeout(() => {
							this.$store.commit('utility/OPEN_MODAL_FAQ')
						}, 1000)
						// if (res.status === 201) {
						// }
					}).catch((err) => {
					this.creatingFaq = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})
			}
		},
		updateFaqData() {
			if (this.faqPayload.question === '' || this.faqPayload.category === '' || this.faqPayload.answer === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (!parseInt(this.faqPayload.category)) {
				this.$services.helpers.notification(this.$t('categorySelect'), 'error', this)
			} else {
				this.creatingFaq = true
				let payload = {}
				for (const [key, value] of Object.entries(this.faqPayload)) {
					if (value !== '' && value !== null && value !== undefined) {
						payload[key] = value
					}
				}
				payload['notify_user'] = this.notify_user

				this.$store.dispatch('house/updateFaqData', {data: payload, id: this.faqPayload.id})
					.then(_ => {
						this.creatingFaq = false
						// bus.$emit('reload-faq')
						this.$services.helpers.notification(this.$t('faqAdded'), 'success', this)
						setTimeout(() => {
							this.$store.commit('utility/OPEN_MODAL_FAQ_EDIT_MODAL')
						}, 1000)
					}).catch((err) => {
					this.creatingFaq = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})
			}
		},
		addFaqCategoryData() {
			if (this.categoryObj.category_name === '' || this.categoryObj.description === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.creatingFaq = true
				// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
				// this.$services.axios.post(this.$services.endpoints.CREATING_FAQ, {
				//     // slug : "none",
				//     // absolute_url : null
				// })
				const payload = {
					category: this.category,
					question: this.question,
					answer: this.content,
				}
				this.$store.dispatch('house/addFaqCategoryData', this.categoryObj)
					.then(_ => {
						this.creatingFaq = false
						bus.$emit('reload-faq-category')
						this.$services.helpers.notification(this.$t('faqAdded'), 'success', this)
						setTimeout(() => {
							this.$store.commit('utility/OPEN_MODAL_FAQ_CREATE_MODAL')
						}, 1000)
						// if (res.status === 201) {
						// }
					}).catch((err) => {
					this.creatingFaq = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})
			}
		},
		// inviteUser () {
		//     if (this.firstname === '' || this.lastname === '' || this.email === '') {
		//         this.$services.helpers.notification('User fields are required', 'error', this)
		//     } else if (this.role === '') {
		//         this.$services.helpers.notification('User role is required', 'error', this)
		//     } else {
		//         this.creating = true
		//         this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
		//         this.$services.axios.post(this.$services.endpoints.ACCOUNTANT_INVITE, {
		//             email : this.email,
		//             first_name : this.firstname,
		//             last_name : this.lastname,
		//             as_accountant : this.role
		//         })
		//         .then(res => {
		//             console.log('success:', res)
		//             this.creating = false
		//             if (res.data.success) {
		//                 this.clearInviteField()
		//                 this.$services.helpers.notification('Invitation sent to user', 'success', this)
		//             }
		//         }).catch((err) => {
		//             console.log(err)
		//             this.creating = false
		//             if (err.response.status === 400) {
		//                 if (err.response.data.code === 401) {
		//                     this.$store.dispatch('refreshUserToken', this.USER_REFRESH)
		//                     .then(_ => {
		//                         console.log('refresh')
		//                     })
		//                     .catch(_ => {
		//                         this.$store.commit('CLEAR_AUTH_USER', null)
		//                         window.Bus.$emit('sign-out')
		//                     })
		//                 }
		//             }
		//         })
		//     }
		// },
		croppie(e) {
			const self = this
			const docType = ['png', 'jpg', 'jpeg']
			const fileType = e.target.files[0].type.split('/')[1]

			if (docType.includes(fileType)) {
				self.showCrop = true

				var files = e.target.files || e.dataTransfer.files
				if (!files.length) return

				var reader = new FileReader()
				reader.onload = e => {
					this.$refs.croppieRef.bind({
						url: e.target.result
					})
				}
				reader.readAsDataURL(files[0])
			} else {
				this.$services.helpers.notification(this.$t('invalidFile'), 'error', this)
				document.getElementById('upload').value = ''
			}
		},
		crop() {
			this.initializingCropping = true
			this.croppingSuccess = false
			const options = {
				type: 'base64',
				size: 'viewport',
				format: 'png',
				circle: true
			}
			this.$refs.croppieRef.result(options, output => {
				this.croppieImage = output
				// console.log('Croppeds:', this.croppieImage)
				this.initializingCropping = false
				this.croppingSuccess = true
				this.showPreviewCropping = true
				this.addedImage = true
				return this.getBase64ToFire(this.croppieImage)
			})
		},
		getBase64ToFire(base64URL) {
			const renamFile = `${uuidv4()}.png`
			fetch(base64URL)
				.then(res => res.blob())
				.then(blob => {
					const file = new File([blob], renamFile, {type: 'image/png'})
					if (file !== undefined) {
						this.userPhoto = file
					}
				})
		},
		uploadPhoto() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPLOAD_USER_PHOTO + `${GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPLOAD_USER_PHOTO
			this.uploadingNow = true
			const formData = new FormData()
			formData.append('file', this.userPhoto)

			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.post(URL, formData)
			this.$store.dispatch('customer/uploadPhoto', {formData: formData, URL: URL})
				.then(res => {
					this.uploadingNow = false
					if (res.data.success) {
						bus.$emit('update-user-pic-house', 'update-now')
						this.$services.helpers.notification(this.$t('imageUploadSuccessful'), 'success', this)
						this.clearImageField()
						this.$store.commit('utility/OPEN_PHOTO_MODAL')
					}
				}).catch((err) => {
				this.uploadingNow = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// console.log('refresh')
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		changePageNumber(num) {
			const {partner} = this.$route.params
			this.loadingCompanies = true
			this.$store.dispatch('house/getConnectedCompany', {URL: this.$services.endpoints.IN_HOUSE_GET_FIRM_COMPANIES + `${partner}?page=${num}`})
				.then(_ => {
					this.loadingCompanies = false
				}).catch(err => {
				this.loadingCompanies = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getConnectCompany(partnerId)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getConnectCompany(partnerId) {
			this.loadingCompanies = true
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.IN_HOUSE_GET_FIRM_COMPANIES + `${partnerId}`)
			this.$store.dispatch('house/getConnectedCompany', {URL: this.$services.endpoints.IN_HOUSE_GET_FIRM_COMPANIES + `${partnerId}`})
				.then(_ => {
					this.loadingCompanies = false
					// console.log('connected commpany::::', res)
					// if (res.data.success) {
					//     this.allCompanies = res.data.data
					// }
				}).catch(err => {
				this.loadingCompanies = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getConnectCompany(partnerId)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getAccountantSpecificCompany(id) {
			// console.log('spec:', id)
			this.loadSpecific = true
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_USER_BY_MANAGER + `${id}`)
			this.$store.dispatch('house/getAccountantSpecificCompany', {URL: this.$services.endpoints.GET_USER_BY_MANAGER + `${id}`})
				.then(_ => {
					this.loadSpecific = false
					// console.log('specific commpany::::', res)
					// if (res.data.success) {
					//     this.accountantCom = res.data.data
					// }
				}).catch(err => {
				this.loadSpecific = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAccountantSpecificCompany(id)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
					if (err.response.data.code === 500) {
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				}
			})
		},
		// createCompanyNote () {
		//     if (this.boards[0].notes.length > 0 && this.boards[0].notes[0].text !== '') {
		//         this.$services.helpers.notification(this.$t('createNote'), 'progress', this)
		//         const noteText = this.boards[0].notes
		//         this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
		//         this.$services.axios.post(this.$services.endpoints.MANAGERS_NOTE + `${this.companyID}/`, {
		//             recipient: this.companyID,
		//             message: noteText[0].text,
		//             read: false
		//         })
		//         .then(res => {
		//             console.log(res)
		//             if (res.data.success) {
		//                 this.$services.helpers.notification(this.userAgent + ' ' + this.$t('nCreated'), 'success', this)
		//                 bus.$emit('remove-note', this.boards[0].notes[0].id)
		//             }
		//         }).catch((_) => {

		//         })
		//     } else {
		//         this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
		//     }
		// },
		// updateUserNote () {
		//     if (this.boards[0].notes.length > 0 && this.boards[0].notes[0].text !== '') {
		//         this.$services.helpers.notification(this.$t('pWait'), 'progress', this)
		//         const noteText = this.boards[0].notes
		//         this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
		//         this.$services.axios.put(this.$services.endpoints.MANAGERS_NOTE + `update/${this.noteId}/${this.userIdNote}/`, {
		//             recipient: this.userIdNote,
		//             message: noteText[0].text,
		//         })
		//         .then(res => {
		//             console.log(res)
		//             if (res.data.success) {
		//                 this.$services.helpers.notification(this.userAgent + ' ' + this.$t('nUpdated'), 'success', this)
		//                 bus.$emit('remove-note', this.boards[0].notes[0].id)
		//             }
		//         }).catch((err) => {
		//             console.log(err.response.data)
		//         })
		//     } else {
		//         this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
		//     }
		// },
		checkCompanyNoteModal(userId, agent) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// console.log(this.$services.endpoints.COMPANY_NOTES + `?user_id=${userId}`)
			const URL = this.$services.endpoints.COMPANY_NOTES + `?user_id=${userId}`
			this.$store.dispatch('accountant/checkCompanyNote', URL).then(res => {
				bus.$emit('hide-notify-me', '')

				if (res.data.data.length > 0) {
					this.noteObj.userId = userId
					this.noteObj.updateUser = true
					this.noteObj.agent = agent
					this.noteObj.noteID = res.data.data[0].id
					this.noteObj.message = res.data.data[0].message
					this.stickyMessage = res.data.data[0].message
					this.modalVisible = true
				} else {
					this.noteObj.userId = userId
					this.noteObj.updateUser = false
					this.noteObj.agent = agent
					this.noteObj.message = ''
					this.modalVisible = true
				}
			}).catch(err => {

			})
			// this.$services.axios.get(this.$services.endpoints.COMPANY_NOTES + `?user_id=${userId}`)
			// 	.then(res => {
			//
			// 	}).catch((_) => {
			//
			// })
		},
		uid(e) {
			if (e.uid) return e.uid
			const key = Math.random()
				.toString(16)
				.slice(2)
			this.$set(e, 'uid', key)
			return e.uid
		},
		getCompanyUserID(userID) {
			this.$store.commit('customer/SET_USER_COMPANY_ID', userID)
			setTimeout(() => {
				// window.location.href = '/automatisera'
				window.open('/automatisera', '_blank')
			}, 1000)
		},
		getInviteUserCompany(userID) {
			this.$store.commit('customer/SET_USER_COMPANY_ID', userID)
			setTimeout(() => {
				window.open('/automatisera', '_blank')
				// this.$store.commit('SET_USER_COMPANY_ID', userID)
			}, 1000)
		},
		clearInviteField() {
			this.firstname = ''
			this.lastname = ''
			this.email = ''
			this.role = ''
		},
		clearImageField() {
			document.getElementById('upload').value = ''
			this.userPhoto = {}
			this.showCrop = false
			this.croppieImage = null
			this.initializingCropping = false
			this.croppingSuccess = false
			this.showPreviewCropping = false
			this.uploadingNow = false
		},
		// boardTitle () {
		//     return 'Am Django'
		// },
		// createBoard (initial) {
		//     const board = {
		//         title: this.boardTitle(),
		//         notes: [],
		//         initial: initial
		//     }
		//     return board
		// },
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~croppie/croppie.css';
@import "~vue-tour/dist/vue-tour.css";

.app-theme-gray.app-container {
	background: #f1f4f6 !important;
}

.set-pad {
	padding: 0.2rem;
}

// .form-select{
//     // padding-top: unset !important;
// }
.form-control {
	height: unset !important;
	padding: .275rem .75rem !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

.input-icons i {
	position: absolute;
	margin: 7px;
}

.input-icons {
	width: 100%;
	margin-bottom: 3px;
}

.icon {
	padding: 10px;
	min-width: 40px;
}

.custom-search {
	padding-left: 1.5rem !important;
}

.add-pad {
	padding-top: 0.5rem !important;
	padding-right: 2.5rem !important;
	padding-bottom: 0.5rem !important;
	padding-left: 0.75rem !important;
}

.form-control, .form-select {
	border: 1px solid $form-border !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.ql-container.ql-snow {
	height: 200px;
}
</style>
