import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import services from './services'
import './filters'
import './veevalidate'
import { MessageBox, Loading } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
// import 'tailwindcss/dist/tailwind.css'
import Notifications from 'vt-notifications'
import infiniteScroll from 'vue-infinite-scroll'
import VueSanitize from 'vue-sanitize'
import VWave from 'v-wave'
import VueToastr from "vue-toastr";
// import VueCroppie from 'vue-croppie'
import i18n from '@/plugins/i18n'
// import VueApexCharts from 'vue-apexcharts'
import VueTour from 'vue-tour'
// require('vue-tour/dist/vue-tour.css')
import './assets/css/main.css'
import './assets/css/icon.css'
import './assets/css/iconly.css'
import './assets/css/material-icons.min.css'
import './assets/css/style.css'

// import VueLazyload from 'vue-lazyload'


// Vue.use(VueLazyload)
Vue.config.productionTip = false
locale.use(lang)
// Vue.component('apexchart', VueApexCharts)

Vue.use(VueTour)
Vue.use(VueSanitize)
Vue.use(infiniteScroll)
Vue.use(services)
Vue.use(Notifications)
Vue.use(Loading.directive)
// Vue.use(DatePicker)
// Vue.use(Dialog)
// Vue.use(Select)
// Vue.use(Option)
// Vue.use(Button)
// Vue.use(Drawer)
// Vue.use(Tooltip)
Vue.use(VWave)
// Vue.use(VueCroppie)
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});

Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm

window.Bus = new Vue()

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// Uncomment this code if running test
// these script is the result of the multiple
// request from API endpoint
// const app = new Vue({
//     i18n,
//     router,
//     store,
//     render: h => h(App)
// }).$mount('#app')

// if (window.Cypress) {
//     window.__app__ = app
// }
