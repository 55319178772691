import {getLocale} from './../../../../services/storage-window'

export default {
	setLocale: getLocale() || 'se',
	model_invite: false,
	modal_tasks_detail: false,
	model_photo: false,
	model_util: false,
	modal_inhouse: false,
	modal_api_queries: false,
	modal_faq_create_category_inhouse: false,
	modal_faq_edit: false,
	modal_user_create_faq: false,
	notificationDrawer: false,
	appSynDrawer: false,
}
