import HTTP from './../../../../services/vue-axios/axios'
import TYPES from './../types'

export default {
	async getColleagueConnectCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					commit(TYPES.UPDATE_COLLEAGUE_COMPANY, res.data)
					resolve(res)

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						commit('auth/CLEAR_AUTH_USER', null)
					}
				}
			})
		})
	},
}
