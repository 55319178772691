import HTTP from './../../../../services/vue-axios/axios'
import DownloadHTTP from './../../../../services/vue-axios/download'
import DownloadExcel from './../../../../services/vue-axios/download_excel'
import endpoints from './../../../../services/endpoint'
import TYPES from './../types'


export default {
	async inviteUser({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(endpoints.ACCOUNTANT_INVITE, payload)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async uploadPhoto({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.formData)
				.then(res => {
					if (res.data.success === true) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async addAccountNumberForm({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success === true) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAuthUser({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						commit(TYPES.UPDATE_USER_INFO, res.data.data.user)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSubscriptionList({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						commit(TYPES.SUBSCRIPTION_ALL, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAppList({commit}, payload) {
		return new Promise((resolve, reject) => {
			let URL = ''
			if (!payload.value) {
				URL += endpoints.GET_APP_LIST
			} else {
				URL += endpoints.GET_APP_LIST + `?accounting_id=${payload.value}`
			}
			HTTP.get(URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_APP_INTEGRATION, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getMoreAppList({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_APP_LIST + `?page=${payload.page}`)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.PUSH_APP_INTEGRATION, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAccountingPlatform({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_ACCOUNTING_PLATFORM)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_ACCOUNTING_TYPE, res.data.results)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getDashboardMetrics({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.data.success) {
						// this.dashboardMetrics = res.data.data
						commit(TYPES.UPDATE_DASHBOARD_METRICS, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserTransaction({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getFaqList({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_FAQ_LIST)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FAQ_DATA, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getFaqCategoryList({commit}, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.GET_FAQ_CATEGORY_LIST)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FAQ_CATEGORY, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserSwitchPayment({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserNotification({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						commit(TYPES.UPDATE_NOTIFICATION, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSystemAppLogs({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					// console.log('system:', res.data.data)
					if (res.data.success) {
						commit(TYPES.UPDATE_SYSTEM_LOGS, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAppLogs({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					// console.log('app:', res)
					if (res.data.success) {
						commit(TYPES.UPDATE_APP_LOGS, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserBillingHistory({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.data.success) {
						commit(TYPES.UPDATE_BILLING_HISTORY, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async switchToInvoice({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async switchToYearly({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserInitializePayment({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async updateUserCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.data)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getColleagueConnectCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_COLLEAGUE_COMPANY, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						commit('auth/CLEAR_AUTH_USER', null)
					}
				}
			})
		})
	},
	// App config
	async getConfigForm(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async runShopifyAuth(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async runStripeAuth(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSubscriptionConfig(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async addCustomAccount(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.data)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async updateCustomAccount(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.data)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async deleteCustomAccount(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(payload.URL)
				.then(res => {
					if (res.status === 204) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getVoucherSeries({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getPaypalSupplier({_}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getPriceList({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSubscriptionReadInfo(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async activateSubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async deactivateSubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async updateAppSettings(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.dataObject)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async cancelAppSubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.data)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getCustomAccounts(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async readNotification({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAccountType(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getMatchType(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getCountry(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	// async getSubscriptionListApp (_, payload) {
	//     return new Promise((resolve, reject) => {
	//         HTTP.get(payload.URL)
	//         .then(res => {
	//             if (res.status === 200) {
	//                 resolve(res)
	//             }
	//         }).catch((err) => {
	//             reject(err)
	//         })
	//     })
	// },
	async getWaysOfDeliveryOption(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getWaysOfDeliverySubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getTermOfDeliveryOption(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getPaymentTermOption(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getActiveAccountNumbers(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async addAccountNumbers(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getPaymentProvider(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getPaymentTermSubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getVismaBankAccountNumbers(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getVismaForeignPaymentCode(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getRequestFromBE(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async createWayDeliverySubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.dataObject)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async createPaymentTermSubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.dataObject)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async deleteWaysOfDeliverySubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(payload.URL)
				.then(res => {
					if (res.status === 204) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async deletePaymentTermSubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(payload.URL)
				.then(res => {
					if (res.status === 204) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async performTestSubscription(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async CreateBambooMapping(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.obj)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async deleteBambooData(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(payload.URL)
				.then(res => {
					if (res.status === 204) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getBambooType(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getBambooFortnoxCause(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getBambooTimeOffs(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getBambooEmpField(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getBambooMapData(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async uploadSwishFile(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.formData)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async downloadFile({commit}, payload) {
		return new Promise((resolve, reject) => {
			DownloadHTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async checkVismaAvailability({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.VISMA_AVAILABILITY + `${payload.sub}/`)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSubscriptionTaskDetails({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(endpoints.TASK_DETAILS_ENDPOINT + `${payload}/`)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async autoSaveShopName({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.patch(payload.URL, payload)
				.then(res => {
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async autoSaveAmazonRegion({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.patch(payload.URL, payload.data)
				.then(res => {
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async getAmazonAuthUrl({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL, payload)
				.then(res => {
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
	async customGetRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customDeleteRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customPutRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.data)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async customPostRequest({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.data)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getSyncReport({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					commit(TYPES.UPDATE_SYNCHRONIZE_REPORT, {data: res.data})

					resolve(res)

				}).catch((err) => {
				reject(err)
			})
		})
	},
	async exportSyncReport({commit}, payload) {
		return new Promise((resolve, reject) => {
			DownloadExcel.get(payload.URL)
				.then(res => {
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
}
