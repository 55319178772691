<template>
	<div class="app-sidebar sidebar-shadow sidebar-bg-custom">
		<div class="app-header__logo">
			<div class="logo-srcs"></div>
			<div class="header__pane ml-auto">
				<div>
					<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
							data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
					</button>
				</div>
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
		</div>
		<div class="scrollbar-sidebar">
			<div class="app-sidebar__inner">
				<ul class="vertical-nav-menu">
					<li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">Menu</li>
					<li class="menu-link mm-active">
						<router-link :to="{ name : 'master_accountant_dashboard' }">
							<i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'master_accountant_apps' }">
							<i class="metismenu-icon iconly-brokenPlus"></i> {{ $t('appCatalogue') }}
						</router-link>
					</li>
					<!-- <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('logs') }}</li> -->
					<!-- <li class="menu-link">
						<router-link :to="{ name : 'accountantlog' }">
							<i class="metismenu-icon iconly-brokenDocument"></i> {{ $t('appLogs') }}
						</router-link>
					</li> -->
					<li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7"
						v-if="[2,3].includes(parseInt(GET_USER.dashboard_type))">Management
					</li>
					<li class="menu-link" v-if="[2,3].includes(parseInt(GET_USER.dashboard_type))">
						<router-link :to="{ name : 'master-accountant-firmInfo' }">
							<i class="metismenu-icon iconly-brokenBag-2"></i> {{ $t('overview') }}
						</router-link>
					</li>
					<li class="menu-link" v-if="[2,3].includes(parseInt(GET_USER.dashboard_type))">
						<router-link :to="{ name : 'master-accountant-payout' }">
							<i class="metismenu-icon iconly-brokenPaper-Plus"></i> {{ $t('firmPayout') }}
						</router-link>
					</li>


					<li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('help') }}</li>
					<li class="menu-link">
						<router-link :to="{ name : 'master-accountant-faq' }">
							<i class="metismenu-icon iconly-brokenChat"></i> FAQ
						</router-link>
					</li>
					<li class="menu-link" v-if="GET_IN_HOUSE_UTIL === 'true'">
						<a href="javascript:void(0);" @click="$store.commit('utility/OPEN_INVITE_MODAL')">
							<i class="metismenu-icon iconly-brokenAdd-User"></i> {{ $t('inviteUsers') }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	name: "ASidebar",
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_USER_UTIL: 'accountant/GET_USER_UTIL',
			GET_USER: 'customer/GET_USER',
			GET_IN_HOUSE_UTIL: 'house/GET_IN_HOUSE_UTIL',
		})
	}
}
</script>


<style lang="scss" scoped>
@import "../../../assets/variables.scss";
@import "~vue-tour/dist/vue-tour.css";

.app-sidebar .app-sidebar__inner {
	padding: unset !important;
}

.sidebar-bg-custom {
	background: $sidebar-bg-color !important;
	border-right: 1px solid #344258;
}

.app-sidebar__heading {
	color: #ffffff !important;
	font-size: 0.6rem;
}

.vertical-nav-menu li.mm-active > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	font-weight: unset !important;
}

.vertical-nav-menu li a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
}

.vertical-nav-menu li.menu-link:hover {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
}

.vertical-nav-menu ul > li > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	transition: all .3s ease;
}

.vertical-nav-menu li a:hover:not(.router-link-exact-active) {
	background: unset !important;
	text-decoration: none;
	margin-left: 13px;

	i.metismenu-state-icon {
		margin-left: 10px;
		position: sticky;
	}
}

.vertical-nav-menu i.metismenu-icon {
	opacity: unset !important;
}

.vertical-nav-menu ul {
	transition: padding 300ms;
	padding: .5em 0 0 0.6rem !important;
}

.router-link-exact-active {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
}

// .subscription-section li .router-link-exact-active{
//     color: #bfc3ce !important;
//     font-size: 0.8rem;
//     font-weight: bold;
// }
</style>
