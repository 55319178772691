export default {
    GET_ASSISTANCE_COMPANY (state) {
        return state.accountantAssistanceCompany
    },
    GET_ASSIST_ACCOUNTANT (state) {
        return state.isAssistanceAccountant
    },
	GET_USER_UTIL(state) {
		return state.utility;
	}
}
