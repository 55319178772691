const TYPES = {
    UPDATE_HOUSE_METRICS : 'UPDATE_HOUSE_METRICS',
    UPDATE_HOUSE_GRAPH_METRICS : 'UPDATE_HOUSE_GRAPH_METRICS',
    UPDATE_ALL_FIRM : 'UPDATE_ALL_FIRM',
    UPDATE_SEARCH_FIRM : 'UPDATE_SEARCH_FIRM',
    UPDATE_ALL_COMPANY : 'UPDATE_ALL_COMPANY',
    UPDATE_SEARCH_COMPANY : 'UPDATE_SEARCH_COMPANY',
    UPDATE_CONNECTED_COM : 'UPDATE_CONNECTED_COM',
    UPDATE_ACCOUNTANT_COMPANY : 'UPDATE_ACCOUNTANT_COMPANY',
    UPDATE_FIRM : 'UPDATE_FIRM',
    UPDATE_FIRM_ACCOUNTANT : 'UPDATE_FIRM_ACCOUNTANT',
    SUBSCRIPTION_ALL : 'SUBSCRIPTION_ALL',
}

export default TYPES
