<template>
	<div class="app-sidebar sidebar-shadow sidebar-bg-custom">
		<div class="app-header__logo">
			<div class="logo-srcs"></div>
			<div class="header__pane ml-auto">
				<div>
					<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
							data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
					</button>
				</div>
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
		</div>
		<div class="scrollbar-sidebar">
			<div class="app-sidebar__inner">
				<ul class="vertical-nav-menu">
					<li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">Menu</li>
					<li class="menu-link mm-active">
						<router-link :to="{ name : 'accountantdash' }">
							<i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }}
						</router-link>
					</li>
					<li class="menu-link">
						<router-link :to="{ name : 'appcatelog' }">
							<i class="metismenu-icon iconly-brokenPlus"></i> {{ $t('appCatalogue') }}
						</router-link>
					</li>

					<li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('help') }}</li>
					<li class="menu-link">
						<router-link :to="{ name : 'accountantfaq' }">
							<i class="metismenu-icon iconly-brokenChat"></i> FAQ
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "sideBar",
	data() {
		return {
			baseURL: window.location.origin,
			userSubscription: [],
			userObject: null,
			showSubscription: false
		}
	},
	watch: {
		$route(to, from) {
			// console.log('to::', to.path)
			// console.log('from::', from.path)
			// if (to.path !== from.path) {
			//     this.showSubscription = false
			//     document.getElementById('sub_id').classList.remove('mm-show')
			// }
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_USER_UTIL: 'accountant/GET_USER_UTIL',
			GET_USER: 'customer/GET_USER',
			GET_IN_HOUSE_UTIL: 'house/GET_IN_HOUSE_UTIL',
		})
	},
	mounted() {
		// this.getAuthUser()
		// Implement two-way fetching
		// if (this.GET_USER_COMPANY_ID) {
		//     this.getSubscriptionList(this.GET_USER_COMPANY_ID)
		// } else {
		//     this.getSubscriptionList()
		// }
	},
	methods: {
		openSubscription() {
			this.showSubscription = !this.showSubscription
			if (this.showSubscription) {
				document.getElementById('sub_id').classList.add('mm-show')
				// document.getElementById('sub_id').classList.add('override-height')
			} else {
				document.getElementById('sub_id').classList.remove('mm-show')
			}
		},
		getAuthUser() {
			this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			this.$services.axios.get(this.$services.endpoints.GET_USER)
				.then(res => {
					// console.log('user:', res)
					this.loadingCompany = false
					if (res.data.success) {
						this.userObject = res.data.data.user
					}
				}).catch(err => {
				this.loadingCompany = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAuthUser()
							})
							.catch(_ => {
								this.$store.commit('CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		// getSubscriptionList (userID) {
		//     const URL = userID ? this.$services.endpoints.GET_USER_SUBSCRIPTION + `${userID}/` : this.$services.endpoints.GET_USER_SUBSCRIPTION
		//     this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
		//     this.$services.axios.get(URL)
		//     .then(res => {
		//         console.log('HHHH:', res.data.data)
		//         if (res.data.success) {
		//             this.userSubscription = res.data.data.all_subscriptions
		//             this.$store.commit('SUBSCRIPTION_ALL', res.data.data)
		//         }
		//     }).catch((err) => {
		//         if (err.response.status === 400) {
		//             if (err.response.data.code === 401) {
		//                 this.$store.dispatch('refreshUserToken', this.USER_REFRESH)
		//                 .then(_ => {
		//                     if (this.GET_USER_COMPANY_ID) {
		//                         this.getSubscriptionList(this.GET_USER_COMPANY_ID)
		//                     } else {
		//                         this.getSubscriptionList()
		//                     }
		//                 })
		//                 .catch(_ => {
		//                     console.log('error response')
		//                     // this.$store.commit('CLEAR_AUTH_USER', null)
		//                     // window.location.reload()
		//                 })
		//             }
		//         }
		//     })
		// }
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.app-sidebar .app-sidebar__inner {
	padding: unset !important;
}

.sidebar-bg-custom {
	background: $sidebar-bg-color !important;
	border-right: 1px solid #344258;
}

.app-sidebar__heading {
	// color: #ffffff !important;
	// font-size: 0.6rem;
}

.vertical-nav-menu li.mm-active > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	font-weight: unset !important;
}

.vertical-nav-menu li a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
}

.vertical-nav-menu li.menu-link:hover {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
}

.vertical-nav-menu ul > li > a {
	color: #bfc3ce !important;
	font-size: 0.8rem;
	transition: all .3s ease;
}

.vertical-nav-menu li a:hover:not(.router-link-exact-active) {
	background: unset !important;
	text-decoration: none;
	margin-left: 13px;

	i.metismenu-state-icon {
		margin-left: 10px;
		position: sticky;
	}
}

.vertical-nav-menu i.metismenu-icon {
	opacity: unset !important;
}

.vertical-nav-menu ul {
	transition: padding 300ms;
	padding: .5em 0 0 0.6rem !important;
}

.router-link-exact-active {
	border-left-color: $theme-primary-color !important;
	border-left-width: 2px;
	background: $menu-hover !important;
}

// .subscription-section li .router-link-exact-active{
//     color: #bfc3ce !important;
//     font-size: 0.8rem;
//     font-weight: bold;
// }
</style>
