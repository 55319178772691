// Global App ids
export const APPID = {
	COLLECTOR_PLUS: 0,
	// DIBS_BASIC : 1, (It rename to NETS_EASY)
	// DIBS_PLUS : 2, (It rename to NETS_EASY)
	NETS_EASY_BASIC: 1,
	NETS_EASY_PLUS: 2,
	KLARNA_BASIC: 3,
	COLLECTOR_BASIC: 4,
	KLARNA_PLUS: 5,
	//  KLARNA_PLUS_MFT : 6,
	PAYPAL_BASIC: 7,
	PAYPAL_PLUS: 8,
	STRIPE_BASIC: 9,
	//  STRIPE_INVOICE : 10,
	STRIPE_PLUS: 11,
	DAGSKASSA: 12,
	BAMBOOHR: 13,
	AUTO_CONTRACT: 14,
	KLARNA_PRO: 15,
	PAYPAL_SUPPLIER: 16,
	SWISH_HANDEL: 17,
	SWISH: 18,
	WOOCOMMERCE: 19,
	CHARGEBEE: 20,
	SHOPIFY: 21,
	ADYEN_PLUS: 22,
	SETTLEMENT_PRO: 23,
	ECOMMERCE_PRO: 24,
	SYNCHROTEAM: 25,
	ADYEN_BASIC: 26,
	SHOPIFY_PAYMENT: 27,
	CDON: 28,
	FYNDIQ: 47,
	INVOICE_POST: 30,
	PRESTASHOP: 31,
	AMAZON: 33,
	SQUARESPACE: 34,
	BRAINTREE: 35,
	STARWEB_PLUS: 36,
	OFFICERND: 37,
	SVEA_BASIC: 38,
	CDON_V3: 39,
	QUICKBUTIK: 40,
	SVEA_PLUS: 41,
	MAGENTO: 42,
	HUBSPOT: 43,
	WISH: 44,
	WIX: 45,
	SHIPMONDO: 46,
	FILE_IMPORT: 48,
	SHOPIFY_PAY: 49,
	SHOPIFYV3_PRO: 50,
	SHOPIFYV3: 51,
	SHOPIFY_V3: 51,
	AMAZONMARKETPLACE: 52,
	PAYPALV3_BASIC: 53,
	PAYPALV3_PLUS: 54,
	PAYPALV3_PRO: 55,
	PAYPALV3_SUPPLIER: 56,
	STRIPEV3_BASIC: 57,
	STRIPEV3_PLUS: 58,
	STRIPEV3_PRO: 59,
	KLARNAV3_BASIC: 60,
	KLARNAV3_PLUS: 61,
	KLARNAV3_PRO: 62,
	SWISHV3: 63,
	DAGSKASSAV3: 64,
	GOCARDLESS: 65,
	ECSTER_BASIC: 66,
	ECSTER_PLUS: 67,
	ECSTER_PRO: 68,
	WALLEY_BASIC: 69,
	WALLEY_PLUS: 70,
	WALLEY_PRO: 71,
	MOLLIE: 72,
	BANGOLUFSEN: 73,
	ADYEN_PRO: 74,
	SWISHHANDEL: 75,
	BAMBORA: 76,
	MONDIDO: 77,
	QUICKPAY_PLUS: 78,
	QUICKPAY_BASIC: 80,
	CDONMARKETPLACE: 79,
	QLIRO_BASIC: 81,
	QLIRO_PLUS: 82,
	QLIRO_PRO: 83,
	SHOPIFYB2B: 84,
	MIRAKL: 85,
    BAMBORA_BASIC: 86,
    HARVEST:87,
    NETS_EASY_PRO: 88,
}


// For accounting types
export const ACCOUNT_TYPE =
	{
		FORTNOX: 0,
		VISMA: 1,
		VISMA_ADMIN: 2
	}

// For all test button
export const TEST_ORDER_BUTTON = [40]
// export const OLD_TEST_ORDER_BUTTON = [2, 5, 8, 11, 15, 16, 19, 21, 31, 33, 36, 37, 38, 39, 40]

// For SKU Modal Validation
export const SKU_VALIDATOR = [19, 21, 40]

export const CUSTOM_TASK_ID_PROCESS = [18]

export const BAMBOO_TABLE_ONLY = [13]

export const PAYMENT_TERM_FIELD_FORTNOX = [2, 5, 18, 19, 20, 25, 31, 32, 33, 36, 37, 40, 41, 45, 52, 51, 63, 79, 82, 84, 85, 70]
export const PAYMENT_TERM_FIELD_VISMA = [21, 19, 40, 16, 51, 58, 61, 54, 70]
//export const FOREIGN_PAYMENT_TERM_FIELD_VISMA = [16]
export const PRICE_LIST_SUBSCRIPTION_FORTNOX = [19, 20, 21, 31, 42, 33, 34, 35, 36, 37, 40, 45, 52, 51, 61, 79, 84, 85]
export const VOUCHER_SERIES_FORTNOX = [0, 1, 2, 3, 4, 5, 9, 11, 12, 15, 17, 22, 26, 27, 33, 38, 39, 41, 49, 52, 59, 60, 61,
	62, 57, 58, 59, 69, 70, 71, 74, 75, 76, 72, 81, 82, 83]
export const VOUCHER_SERIES_VISMA = [0, 3, 5, 7, 8, 51, 57, 58, 59, 60, 61, 62, 69, 70]
export const CUSTOM_ACCOUNT_WIDGET_FORTNOX = [0, 1, 2, 3, 4, 5, 7, 8, 9, 11, 15, 19, 21, 22, 31, 32, 33, 36, 37, 38, 40, 41, 45, 49,
	50, 51, 52, 53, 54, 55, 57, 58, 59, 60, 61, 62, 69, 70, 72, 75, 76, 78, 79, 81, 82, 83, 84,85,86,87,88]
export const CUSTOM_PAYMENT_TERM_WIDGET_FORTNOX = [21, 19, 36, 40, 45, 51]
export const CUSTOM_DELIVERY_WIDGET_FORTNOX = [21, 40, 51, 84]
export const SHOW_FILE_UPLOADER = [17, 22]
export const SHOPIFY_BTN_REDIRECT = [21, 27, 51]
export const STRIPE_SUBSCRIPTIONS = [9, 11, 57, 58, 59]
export const RECONCILIATION_APPS = [1, 3, 7, 9, 26, 38, 39, 49, 53, 54, 57, 58, 59, 60, 69, 61, 62, 72, 76, 77, 78, 80, 81, 82]
export const PAYOUT_ACC_BANK_APPS = [26, 38, 41, 49, 57, 58, 60, 61, 62, 69, 70, 81, 82, 83]
export const CUSTOM_V3_APPS_FORTNOX = [1, 42, 2, 19,40, 36, 37, 38, 41, 43, 42, 44, 45, 46, 47, 51, 52, 54, 58, 60, 61, 62, 70, 78, 79, 80, 81, 82, 83, 84, 85,86,87,88]
export const CUSTOM_V3_APPS_VISMA = [5, 8, 11, 19, 21, 51]
export const ALLOWED_V3_APP_FOR_SYNC = [2, 38, 40, 42, 51, 52, 53, 54, 55, 60, 61, 62, 84]
export const CUSTOM_VAT_RATE_V3_APP_VISMA = ['0.06', '0.12', '0.25']
export const CUSTOM_VAT_RATE_V3_APP_FORTNOX = ['0.06', '0.12', '0.25']
export const COST_CENTER_LIST = [21, 51, 84]
export const STOCKPOINT_FORTNOX = [52, 51, 84]
export const SHOPIFY_V3_APPS = [21, 51,]
export const ORDER_APP_ONLY = [
	19, 36, 40, 42, 45, 51, 84
]
export const ARTICLE_APP_ONLY = [
	19, 36, 40, 42, 45, 51,
]
export const STRIPE_APPS_LIST = [9, 11, 57, 58, 59]
export const FORTNOX_SHOPIFY_GUIDE = [APPID.SHOPIFYB2B, APPID.SHOPIFY, APPID.SHOPIFY_PAYMENT, APPID.SHOPIFY_V3,
	APPID.SHOPIFY_PAY, APPID.SHOPIFYV3, APPID.SHOPIFYV3_PRO] // shopify app for fortnox to display guide button
export const ORDERS_AND_PRODUCTS = {
	WOOCOMMERCE: 19,
	OFFICERND: 37,
	QUICKBUTIK: 40,
	WIX: 45,
	SHOPIFY_V3: 51,
	KLARNA_BASIC_V3: 60,
	KLARNA_PLUS_V3: 61,
	KLARNA_PRO_V3: 62,
	CDONMARKETPLACE: 79,
	SHOPIFYB2B: 84,


}
export const ELEMENT_TYPES = ["Invoice", "Order", "Voucher", "Customer", "Article", "Invoice Payment", "Invoice Accrual"]
export const ALLOWED_V3_SYNC_REPORT_APPS = [
	{key: 19, name: 'Woo Commerce'},
	{key: 49, name: 'Shopify Pay'},
	{key: 50, name: 'Shopify Pro'},
	{key: 51, name: 'Shopify'},
	{key: 52, name: 'Amazon Market Place'},
	{key: 60, name: 'Klarna Basic'},
	{key: 61, name: 'Klarna Plus'},
	{key: 62, name: 'Klarna Pro'},
	{key: 53, name: 'Paypal Basic'},
	{key: 54, name: 'Paypal Plus'},
	{key: 55, name: 'Paypal Pro'},
	{key: 56, name: 'PAYPAL SUPPLIER'},
	{key: 57, name: 'Stripe Basic'},
	{key: 58, name: 'Stripe Plus'},
	{key: 59, name: 'Stripe Pro'},
	{key: 64, name: 'DAGSKASSA'},
	{key: 65, name: 'GOCARDLESS'},
	{key: 66, name: 'Ecster Basic'},
	{key: 67, name: 'Ecster Plus'},
	{key: 68, name: 'Ecster Pro'},
	{key: 69, name: 'Walley Basic'},
	{key: 70, name: 'Walley Plus'},
	{key: 71, name: 'Walley Pro'},
	{key: 72, name: 'MOLLIE'},
	{key: 73, name: 'BANGOLUFSEN'},
	{key: 74, name: 'Adyen Pro'},
	{key: 75, name: 'SWISHHANDEL'},
	{key: 63, name: 'SWISH'},
	{key: 42, name: 'Magneto'},
	{key: 1, name: 'Nets Easy Basic'},
	{key: 2, name: 'Nets Easy Plus'},
	{key: 37, name: 'Officernd'},
	{key: 38, name: 'Svea Basic'},
	{key: 41, name: 'Svea Plus'},
	{key: 40, name: 'QuickButik'},
	{key: 84, name: 'ShopifyB2B'},
]
export const ENABLED_V3_SYNC_APP = [
	{key: 19, name: 'Woo Commerce'},
	{key: 49, name: 'Shopify Pay'},
	{key: 50, name: 'Shopify Pro'},
	{key: 51, name: 'Shopify'},
	{key: 52, name: 'Amazon Market Place'},
	{key: 60, name: 'Klarna Basic'},
	{key: 61, name: 'Klarna Plus'},
	{key: 62, name: 'Klarna Pro'},
	{key: 53, name: 'Paypal Basic'},
	{key: 54, name: 'Paypal Plus'},
	{key: 55, name: 'Paypal Pro'},
	{key: 56, name: 'PAYPAL SUPPLIER'},
	{key: 57, name: 'Stripe Basic'},
	{key: 58, name: 'Stripe Plus'},
	{key: 59, name: 'Stripe Pro'},
	{key: 64, name: 'DAGSKASSA'},
	{key: 65, name: 'GOCARDLESS'},
	{key: 66, name: 'Ecster Basic'},
	{key: 67, name: 'Ecster Plus'},
	{key: 68, name: 'Ecster Pro'},
	{key: 69, name: 'Walley Basic'},
	{key: 70, name: 'Walley Plus'},
	{key: 71, name: 'Walley Pro'},
	{key: 72, name: 'MOLLIE'},
	{key: 73, name: 'BANGOLUFSEN'},
	{key: 74, name: 'Adyen'},
	{key: 75, name: 'SWISHHANDEL'},
	{key: 63, name: 'SWISH'},
	{key: 42, name: 'Magneto'},
	{key: 1, name: 'Nets Easy Basic'},
	{key: 2, name: 'Nets Easy Plus'},
	{key: 37, name: 'Officernd'},
	{key: 38, name: 'Svea Basic'},
	{key: 41, name: 'Svea Plus'},
	{key: 40, name: 'QuickButik'},
	{key: 84, name: 'ShopifyB2B'},
]
export const RECONCILIATION_APPS_WITH_NO_VAT_FEE = [1, 2, 53, 54, 55, 76]
export const RECONCILIATION_APPS_WITH_VAT_FEE = [38, 39, 41, 60, 61, 62, 69, 70, 39]
export const RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT = [26, 49, 57, 58, 52, 59, 74]
export const RECONCILIATION_APPS_WITH_NO_FEE = [63, 72, 78]
export const PRO_APP = [55, 59, 62, 74]
export const VOUCHERS_APP = [74, 75]
export const VISMA_PLUS_AND_BASIC_APP = [58, 57, 60, 61, 53, 54, 66, 67, 69, 70, 80, 78, 80, 81]

export default {
	APPID,
	ACCOUNT_TYPE,
	TEST_ORDER_BUTTON,
	SKU_VALIDATOR,
	// CUSTOM_ACCOUNT_TABLE_ONLY,
	// PAYMENT_TERM_SUBSCRIPTION_TABLE,
	// WAY_DELIVERY_SUBSCRIPTION_TABLE,
	VOUCHER_SERIES_FORTNOX,
	VOUCHER_SERIES_VISMA,
	PAYMENT_TERM_FIELD_FORTNOX,
	PRICE_LIST_SUBSCRIPTION_FORTNOX,
	CUSTOM_ACCOUNT_WIDGET_FORTNOX,
	CUSTOM_PAYMENT_TERM_WIDGET_FORTNOX,
	CUSTOM_DELIVERY_WIDGET_FORTNOX,
	PAYMENT_TERM_FIELD_VISMA,
	//FOREIGN_PAYMENT_TERM_FIELD_VISMA,
	SHOW_FILE_UPLOADER,
	BAMBOO_TABLE_ONLY,
	SHOPIFY_BTN_REDIRECT,
	STRIPE_SUBSCRIPTIONS,
	RECONCILIATION_APPS,
	CUSTOM_V3_APPS_FORTNOX,
	CUSTOM_V3_APPS_VISMA,
	CUSTOM_VAT_RATE_V3_APP_VISMA,
	STOCKPOINT_FORTNOX,
	SHOPIFY_V3_APPS,
	ORDERS_AND_PRODUCTS,
	ELEMENT_TYPES,
	ALLOWED_V3_SYNC_REPORT_APPS,
	ALLOWED_V3_APP_FOR_SYNC,
	ENABLED_V3_SYNC_APP,
	RECONCILIATION_APPS_WITH_NO_FEE,
	RECONCILIATION_APPS_WITH_VAT_FEE_AND_REVERSE_VAT,
	RECONCILIATION_APPS_WITH_VAT_FEE,
	RECONCILIATION_APPS_WITH_NO_VAT_FEE,
	PRO_APP, ORDER_APP_ONLY, ARTICLE_APP_ONLY, VOUCHERS_APP, VISMA_PLUS_AND_BASIC_APP, PAYOUT_ACC_BANK_APPS,
	FORTNOX_SHOPIFY_GUIDE,
	STRIPE_APPS_LIST
}
