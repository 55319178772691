import { getIsAssistanceAcc } from "../../../../services/services-jwt";
import {getIsAssistingAccountant} from "../../../../services/services-jwt";


export default {
    isAssistanceAccountant : getIsAssistanceAcc() || null,
	accountantAssistanceCompany: {},
	copyAssistanceCompany: {},
	utility: getIsAssistingAccountant() || null,
}
