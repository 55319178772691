<template>
    <div id="header-holder" class="app-header header-shadow">
            <div class="app-header__logo">
                <div data-cy='logo-sr' class="logo-sr">
                    <img data-cy='img-fluid' class="img-fluid h-5 logo-top" :src="baseURL+'/assets/images/Automatisera/header_logo.png'" alt="">
                </div>
                <div class="header__pane ml-auto">
                    <!-- <div>
                        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="app-header__mobile-menu">
                <div>
                    <button type="button" id="hamburger-btn" @click="toggleSidebar" class="hamburger hamburger--elastic mobile-toggle-nav">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div class="app-header__menu">
                <span>
                    <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" @click="toggleNavTop">
                        <span class="btn-icon-wrapper">
                            <i class="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>    
            <div class="app-header__content" id="div-open" data-cy='div-open'>
                <div class="app-header-left">
                    <ul class="header-megamenu nav">
                    </ul>        
                </div>
                <div class="app-header-right">
                    <div class="header-dots">
                        <div class="absolute bg-red-30 inset-0 z-0" @click="notifyOpen = false" v-if="notifyOpen"></div>
                        <div class="dropdown">
                            <button @click="openNotifyDiv" class="p-0 mr-2 btn btn-link no-deco"> <!--type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"-->
                                <span class="icon-wrapper icon-wrapper-alt rounded-circle v-step-2">
                                    <span class="icon-wrapper-bg bg-danger"></span>
                                    <i class="text-sm icon text-danger  mdi mdi-notifications-active"></i>
                                    <span class="badge badge-dot badge-dot-sm badge-danger" v-if="GET_NOTIFICATION.unread_notifications_count > 0">{{ $t('notify') }}</span>
                                </span>
                            </button>
                            <div v-if="notifyOpen" class="w-60 width-div-mobile fixed md:absolute z-50 shadow bg-white border rounded mt-10 p-3 md:p-5 right-md">
                                <div> <!--@scroll="handleScroll"-->
                                    <div class="flex flex-col">
                                        <div class="flex justify-center"> 
                                            <div class="w-36">
                                                <img class="img-fluid" src="./../assets/images/illus/notify.svg" alt="notification">
                                            </div>
                                        </div>
                                        <div class="text-center menu-header-content text-dark">
                                            <h5 class="text-sm menu-header-title">{{ $t('notify') }}</h5>
                                            <h6 class="menu-header-subtitle text-xs font-light">{{ $t('have') }} <b>{{ GET_NOTIFICATION.unread_notifications_count }}</b> {{ $t('unread') }}</h6>
                                        </div>
                                    </div>
                                    <div v-if="GET_NOTIFICATION.unread_notifications  !== undefined" class="overflow-y-scroll" :class="GET_NOTIFICATION.unread_notifications.length > 2 ? 'notify-height' : ''" id="extend-height">
                                        <div class="" v-for="(notify, i) in GET_NOTIFICATION.unread_notifications" :key="i">
                                            <NotificationHandler :notify="notify" />
                                        </div>
                                    </div>
                                    <ul class="nav flex-column" v-if="GET_NOTIFICATION.all_notifications.length > 0">
                                        <li class="nav-item-divider nav-item"></li>
                                        <li class="nav-item-btn text-center nav-item mt-2">
                                            <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm" @click="openNotificationSideDrawer">{{ $t('viewNotification') }}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="dropdown">
                            <button type="button" data-toggle="dropdown" class="p-0 mr-2 btn btn-link v-step-1">
                                <span class="icon-wrapper icon-wrapper-alt rounded-circle"> 
                                    <span class="icon-wrapper-bg bg-focus"></span>
                                    <span class="language-icon opacity-8 flag small SE" v-if="GET_LOCALE_LANG === 'se'"></span>
                                    <span class="language-icon opacity-8 flag small GB" v-else></span>
                                </span>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers dropdown-menu dropdown-menu-right">
                                <div class="dropdown-menu-header">
                                </div>
                                <!-- <h6 tabindex="-1" class="dropdown-header"> Popular Languages</h6> -->
                                <button type="button" @click="changeLocale('en')" tabindex="0" class="dropdown-item" v-if="GET_LOCALE_LANG === 'se'">
                                    <span class="mr-3 opacity-8 flag small GB"></span> English
                                </button>
                                <button type="button" @click="changeLocale('se')" tabindex="0" class="dropdown-item" v-else>
                                    <span class="mr-3 opacity-8 flag small SE"></span> Swedish
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="header-btn-lg pr-0">
                    <p class="bg-gray-300 w-40 h-4 rounded mr-2" v-if="loadingCompany"></p>
                    <div class="header-user-name mr-2 link-theme-color font-semibold" v-else-if="GET_OWN_COMPANY">{{ GET_OWN_COMPANY.name | capitalizeEachString }}</div>
                    <div class="header-user-name mr-2 link-theme-color font-semibold" v-else><span v-if="GET_ASSOCIATE_COM[0] !== undefined">{{ GET_ASSOCIATE_COM[0].company.name | capitalizeEachString }}</span></div>
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">                            

                                <div class="widget-content-left">
                                    <div class="btn-group" data-cy='show-logout'>
                                        <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn d-flex align-items-center v-step-0">
                                            <p class="h-10 w-10 bg-gray-300 rounded-full" v-if="loadingCompany"></p>
                                            <div class="no-profile-img" :style="'background:'+rcolor" v-if="!loadingCompany && GET_USER.img === null">
                                                <div>
                                                    <p class="font-black">{{ GET_USER.first_name + ' '+ GET_USER.last_name | initialNames }}</p>
                                                </div>
                                            </div>
                                            <!-- <img class="rounded-circle" :src="baseURL+'/assets/images/preview/preview.png'" alt="automatisera | preview" width="42" v-if="!loadingCompany && user.img"> -->
                                            <img class="rounded-circle" :src="$services.endpoints.LIVE_SERVER + GET_USER.img" alt="automatisera | preview" width="42" v-if="!loadingCompany && GET_USER.img">
                                            <i class="icon ion-ios-arrow-down ml-2 opacity-8"></i>
                                        </a>
                                        <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers tweak-style dropdown-menu-lg dropdown-menu dropdown-menu-right">
                                            <div class="scroll-area-xs override-height"><!--style="height: 150px;"-->
                                                <div class="scrollbar-container ps">
                                                    <ul class="nav flex-column">
                                                        <li class="nav-item">
                                                            <a @click="$store.commit('utility/OPEN_PHOTO_MODAL')" href="javascript:void(0);" class="text-sm font-light link-theme-color nav-link">
                                                                <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" />
                                                                </svg>
                                                                {{ $t('account') }}
                                                            </a>
                                                        </li>
                                                        <li class="font-light nav-item">
                                                            <a href="javascript:void(0);" data-cy='logout' @click="logoutUser" class="text-sm font-light link-theme-color nav-link">
                                                                <span class="text-xl font-light icon-color mr-2 mt-1 mb-1 iconly-brokenLogout"></span>
                                                                <!-- <i class="metismenu-icon pe-7s-power mr-2"></i>&nbsp; -->
                                                                {{ $t('logout') }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul class="nav flex-column">
                                                <li class="nav-item-divider nav-item">
                                                </li>
                                                <li class="nav-item-btn text-center nav-item">
                                                    Version 1.2.0
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'
    import bus from './../bus'
    import NotificationHandler from './../customComponent/NotificationHandler'

    export default {
        name : 'navbar-dash',
        components : {
            NotificationHandler
        },
        data () {
            return {
                baseURL : window.location.origin,
                user : {},
                notification : [],
                ownerCompany : null,
                company : [],
                notify_count : 0,
                unread_count : 0,
                rcolor : window.localStorage.getItem('randomc') || '#000000',
                unread_notify : false,
                loadingCompany : true,
                notifyOpen : false,
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG',
                GET_NOTIFICATION : 'customer/GET_NOTIFICATION',
                GET_OWN_COMPANY : 'customer/GET_OWN_COMPANY',
                GET_ASSOCIATE_COM : 'customer/GET_ASSOCIATE_COM',
                GET_USER : 'customer/GET_USER'
            })
        },
        watch : {
            notifyOpen (newVal, oldVal) {
                window.Bus.$emit('show-cover', this.notifyOpen)
                // if (newVal === true) {
                // }
            }
        },
        mounted () {
            if (this.GET_USER_COMPANY_ID) {
                this.getAuthUser(this.GET_USER_COMPANY_ID)
                this.getUserCompany(this.GET_USER_COMPANY_ID)
                this.getUserNotification(this.GET_USER_COMPANY_ID)
            } else {
                this.getAuthUser()
                this.getUserNotification()
            }
            bus.$on('update-user-pic', () => {
                this.getAuthUser(this.GET_USER_COMPANY_ID)
            })
            bus.$on('reload-notify', () => {
                if (this.GET_USER_COMPANY_ID) {
                    this.getUserNotification(this.GET_USER_COMPANY_ID)
                } else {
                    this.getUserNotification()
                }
            })
            window.Bus.$on('close-cart', e => {
                this.notifyOpen = false
            })
        },
        methods : {
            openNotificationSideDrawer () {
                this.$store.commit('utility/OPEN_NOTIFICATION_DRAWER')
            },
            openNotifyDiv () {
                this.notifyOpen = !this.notifyOpen
            },
            getAuthUser (userID) {
                const URL = userID ? this.$services.endpoints.GET_USER + `${userID}/` : this.$services.endpoints.GET_USER
                this.$store.dispatch('customer/getAuthUser', { URL : URL })
                .then(res => {
                    // console.log('user:', res)
                    this.loadingCompany = false
                    if (res.data.success) {
                        // this.user = res.data.data.user
                        if (this.GET_USER_COMPANY_ID) {
                            // 
                        } else {
                            if (res.data.data.user.associates) {
                                // this.company = res.data.data.user.associates
                                this.$store.commit('customer/UPDATE_ASSOCIATE_COM', res.data.data.user.associates)
                            } else {
                                // this.ownerCompany = res.data.data.user.company
                                this.$store.commit('customer/UPDATE_OWN_COMPANY', res.data.data.user.company)
                                this.$store.commit('customer/SET_USER_COMPANY_BILLING', res.data.data.user.company)
                                this.$store.commit('customer/SET_USER_COMPANY_PAYMENT_TYPE', res.data.data.user.payment_method)
                            }
                        }
                    }
                }).catch(err => {
                    this.loadingCompany = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getAuthUser()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            getUserCompany (userID) {
                const URL = userID ? this.$services.endpoints.GET_USER_COMPANY + `${userID}/` : this.$services.endpoints.GET_USER_COMPANY
                // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
                // this.$services.axios.get(URL)
                this.$store.dispatch('customer/getUserCompany', { URL : URL })
                .then(res => {
                    // console.log('user com:', res)
                    this.loadingCompany = false
                    // console.log('set')
                    // this.ownerCompany = res.data.data
                    this.$store.commit('customer/UPDATE_OWN_COMPANY', res.data.data)
                    this.$store.commit('customer/SET_USER_COMPANY_BILLING', res.data.data)
                    // if (res.data.success) {
                    // }
                }).catch(err => {
                    this.loadingCompany = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                if (this.GET_USER_COMPANY_ID) {
                                    this.getUserCompany(this.GET_USER_COMPANY_ID)
                                } else {
                                    this.getUserCompany()
                                }
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            getUserNotification (userID) {
                const URL = userID ? this.$services.endpoints.GET_USER_NOTIFICATION + `${userID}/` : this.$services.endpoints.GET_USER_NOTIFICATION
                this.$store.dispatch('customer/getUserNotification', { URL : URL })
                .then(res => {
                    // console.table('notification:', res)
                    // this.notification = res.data.data.all_notifications
                    // this.unread_count = res.data.data.unread_notifications_count
                    // this.notify_count = res.data.data.all_notifications_count
                    // if (res.data.success) {
                    // }
                }).catch(err => {
                    // console.log('note error:', err.response)
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                if (this.GET_USER_COMPANY_ID) {
                                    this.getUserNotification(this.GET_USER_COMPANY_ID)
                                } else {
                                    this.getUserNotification()
                                }
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            changeLocale (locale) {
                i18n.locale = locale
                this.$services.moment.locale(locale)
                this.$store.commit('utility/CHANGE_LOCALE', locale)
            },
            openImageModal () {
                // alert('Hey')
            },
            handleScroll (e) {
                if (parseInt(e.target.scrollTop) > 60) {
                    document.getElementById('remove-div').classList.add('hide-transition-height')
                    document.getElementById('extend-height').classList.add('notify-height')
                } else {
                    document.getElementById('remove-div').classList.remove('hide-transition-height')
                    document.getElementById('extend-height').classList.remove('notify-height')
                }
            },
            toggleSidebar () {
                // console.log('toggleing')
                document.getElementById('hamburger-btn').classList.toggle('is-active')
                document.getElementById('app-root-container').classList.toggle('sidebar-mobile-open')
            },
            toggleNavTop () {
                // console.log('clicking')
                // console.log('what:', document.getElementById('div-open'))
                document.getElementById('div-open').classList.toggle('header-mobile-open')
            },
            logoutUser () {
                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                this.$store.commit('customer/REMOVE_COMPANY_USER', null)
                this.$store.commit('assistanceAccountant/REMOVE_ASSITANCE_ACCOUNTANT', null)
                this.$store.commit('accountant/REMOVE_ALL_ACCOUNTANT', null)
                this.$store.commit('colleague/REMOVE_COLLEAGUE', null)
                // this.$router.replace({ name : 'login' })
                window.Bus.$emit('sign-out')
            }
        }
  }
</script>

<style lang="scss" scoped>
.app-theme-gray.fixed-header{
    .app-header__logo{
        background: #fff !important;
    }
} 
.h-5{
    height: 3rem;
}
.logo-top{
    margin-top: -0.5rem;
}
.scrollbar-container{
    .nav-item {
        .nav-link {
            .metismenu-icon{
                font-size: 2rem;
            }
        }
    }
}
.header-user-name{
    font-weight: 600;
    font-size: 1.2rem ;
}
.app-header__logo .logo-src {
    // height: 46px !important;
    // width: 99px !important;
    // background-repeat: no-repeat;
    // margin-left: 4rem;
}

.nav-item.nav-item-divider{
    margin: unset !important;
}
.dropdown-menu-header{
    margin-bottom: unset !important;
}
.nav-item.nav-item-btn{
    padding: unset !important;
}
.override-height{
    height: unset !important;
}
.skeleton-com-text{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    height: 15px;
    width: 150px;
    border-radius: 5px;
}
.skeleton-img{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    // height: px;
    // width: 45px;
    // border-radius: 5px;
}
.rounded-circle-top:hover{
    background: #fff !important;
    z-index: 999;
}
.change-style{
    padding: 1px;
    border: 1px solid #fff;
}
.tweak-style{
    left: 40px !important;
}
.dropdown-menu.dropdown-menu-lg{
    min-width: 15rem !important;
}
.text-big{
    font-size: 1rem !important;
}
.nav-link{
    padding: .1rem 1rem !important;
}
.app-theme-gray .app-header{
    background: #ffff !important;
}
.ps__thumb-y{
    background :#EE4D81 !important;
}
.notify-height{
    height: 250px !important;
    transition: height 1s ease-out;
}
.hide-transition-height{
    height: 0px;
    opacity: 0;
    display: none;
}
.transition-div{
    transition: display 1s ease-out;
}
</style>