import {getIsHouse} from './../../../../services/services-jwt'

export default {
	regularHouse: getIsHouse() || null,
	houseMetrics: {
		most_sold_apps: [],
		total_partners: 0,
		total_subscriptions: 0,
		total_subscriptions_this_month: 0,
	},
	houseGraphMetrics: {
		subscriptions_joined_by_month: [],
		subscriptions_cancelled_by_month: [],
		companies_joined_by_month: [],
		partners_joined_by_month: [],
	},
	allFirms: [],
	allCompanies: [],
	copyAllFirms: [],
	copyAllCompanies: [],
	categoryList: [],
	connectedCompany: [],
	copyConnectedCompany: [],
	accountantCompany: [],
	firm: {
		name: '',
		firm_number: '',
		zip_code: '',
		city: '',
		phone: '',
		billing_address: '',
		email: '',
		invoice_email: ''
	},
	allAccountants: [],
	copyAllAccountant: [],
	faqData: [],
	copyFaqCategory: [],
	faqCategory: [],
	copyFaqData: [],
	allSubscription: {},
}
