export default {
	GET_SUBSCRIPTION(state) {
		return state.allSubscription
	},
	GET_SINGLE_SUBSCRIPTION(state) {
		return state.subscription
	},
	GET_ACCOUNTING_TYPE(state) {
		return state.getAccountingType
	},
	GET_DASHBOARD_METRICS(state) {
		return state.dashboardMetrics
	},
	GET_OWN_COMPANY(state) {
		return state.ownerCompany
	},
	GET_ASSOCIATE_COM(state) {
		return state.associateCompany
	},
	GET_USER(state) {
		return state.UserInfo
	},
	GET_NOTIFICATION(state) {
		return state.notification
	},
	GET_SYSTEM_LOGS(state) {
		return state.sytemAppLogs
	},
	GET_APP_TYPE(state) {
		return state.appType
	},
	GET_APP_LOG(state) {
		return state.AppLogs
	},
	GET_BILLING_HISTORY(state) {
		return state.billingHistory
	},
	GET_USER_COMPANY_ID(state) {
		return state.userCompanyID
	},
	GET_USER_FIRM_ID(state) {
		return state.userFirmID
	},
	GET_COMPANY_BILLING(state) {
		return state.companyBilling
	},
	GET_PAYMENT_TYPE(state) {
		return state.setPaymentType
	},
	GET_APP_INTEGRATION(state) {
		return state.appIntegration
	},
	GET_APP_FILTER_STATE(state) {
		return state.appFilterState
	},
	GET_FAQ_DATA(state) {
		return state.faqData
	},
	GET_FAQ_CATEGORY(state) {
		return state.faqCategory
	},
	GET_CUSTOMER_TOUR(state) {
		return state.customerTour
	},
	GET_COLLEAGUE_CONNECT_COMPANY(state) {
		return state.colleagueCompany
	},
	GET_IS_COLLEAGUE(state) {
		return state.isColleague
	},
	GET_V3_APP(state) {
		return state.v3_apps
	},
	GET_COMPANY_UTIL(state) {
		return state.utility
	},
	GET_SYNC_REPORT(state) {
		return state.syncReport
	},
}
