<template>
	<div class="container">
		<div class="card main-card">
			<div class="card-body" v-loading="loadingSub">
				<form v-on:submit.prevent="submit">
					<div class="row">
						<div class="col-md-12">
							<label class="w-full">{{ $t('app_name') }}</label>
							<el-select v-model="form.subscription" :placeholder="$t('search')" filterable class="w-full"
									   remote
									   reserve-keyword
									   v-on:change="onChange"
									   :remote-method="remoteMethod">
								<el-option
									v-for="(item,key) in subscriptionList"
									:key="key"
									:label="`(${item.id})  ${item.app_type_name} ${item.company_name}` "
									:value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="col-md-12">
							<div class="mt-3">
								<label>{{ $t('sync_type') }}</label>
								<select class="form-control form-select" v-model="form.sync_type"
								>
									<option value="payment" v-if="showPaymentOnly">
										{{ $t('payment') }}
									</option>
									<option value="all_product" v-if="showAllOptions || showArticleAppOnly">
										{{ $t('all_product') }}
									</option>
									<option value="all_order" v-if="showAllOptions || showOrderAppOnly">
										{{ $t('all_order') }}
									</option>
									<option value="order_specific" v-if="showAllOptions || showOrderAppOnly">
										{{ $t('order_specific') }}
									</option>
									<option value="product_specific" v-if="showAllOptions || showArticleAppOnly">
										{{ $t('product_specific') }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-12" v-if="['all_product','all_order','payment'].includes(form.sync_type)">
							<div class="mt-3">
								<label>{{ $t('date') }}</label>
								<input type="date" v-model="form.last_processed_date" class="form-control form-input"/>
							</div>
						</div>
						<div class="col-md-6" v-if="['order_specific','product_specific'].includes(form.sync_type)">
							<div class="mt-3">
								<label v-if="form.sync_type === 'order_specific'">{{ $t('order_number') }}</label>
								<label v-else>{{ $t('article_number') }}</label>
								<input type="text" v-model="form.number" class="form-control form-input"/>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group mt-5">
								<button
									class="btn addingCustomAccountbtn hover:text-white text-base btn-custom pad-overi"
									style="border-radius: 0px;" :disabled="loading"> {{ $t('runSync') }} <i
									v-if="loading === true"
									class="fa fa-spinner fa-spin"></i>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {ALLOWED_V3_SYNC_REPORT_APPS} from "../../app/index";

export default {
	name: "appSynchronizeForm",
	data: function () {
		return {
			subscriptionList: [],
			pay: {
				key: 'payment', name: 'Payment',
			},
			syncOrderType: [],
			syncType: [
				{
					name: 'All Product', key: 'all_product'
				},
				{
					name: 'All Order', key: 'all_order'
				},
				{
					key: 'order_specific', name: 'Order Specific',

				},
				{
					key: 'product_specific', name: 'Product Specific',
				}
			],
			form: {
				sync_type: '',
				subscription: '',
				start_at_article: '',
				stop_at_article: '',
				last_processed_date: '',
				number: ''
			},
			loading: false,
			paymentApp: [60, 38, 57, 53, 49, 69, 1, 37, 72,],
			orderAppOnly: [60, 61, 62, 37, 70, 58, 2, 37, 41, 75, 54],
			articleAppOnly: [75],
			allApps: [51, 52, 42, 19, 63, 40],
			showAllOptions: false,
			showArticleAppOnly: false,
			showPaymentOnly: false,
			showOrderAppOnly: false,
			loadingSub: false,
		}
	},
	methods: {
		submit: function () {
			let sub = this.subscriptionList.filter(item => item.id === parseInt(this.form.subscription))
			if (sub.length > 0) {
				this.loading = true
				let payload = {
					subscription: sub[0].id,
					sync_type: this.form.sync_type
				}
				if (['payment', 'all_order', 'all_product'].includes(this.form.sync_type)) {
					if (this.form.last_processed_date === '') {
						this.$services.helpers.notification(this.$t('dateRequired'), 'error', this)
						this.loading = false
						return false
					}
					const subscriptionCreatedDate = sub[0].created
					const difference = this.$services.moment(this.form.last_processed_date).diff(subscriptionCreatedDate, 'days')
					if (difference < 0) {
						this.$services.helpers.notification(this.$t('syncDateError'), 'error', this)
						this.loading = false
						return false
					}
					payload['last_processed_date'] = this.$services.moment(this.form.last_processed_date).format('YYYY-MM-DD')
				} else if (['order_specific', 'product_specific'].includes(this.form.sync_type)) {
					if (this.form.number === '') {
						if (this.form.sync_type === 'order_specific') {
							this.$services.helpers.notification(this.$t('orderNumberError'), 'error', this)
						} else {
							this.$services.helpers.notification(this.$t('articleNumberError'), 'error', this)
						}
						this.loading = false
						return false
					}
					payload['number'] = this.form.number
				} else {
					this.$services.helpers.notification(this.$t('appSelectError'), 'error', this)
					this.loading = false
					return false
				}
				let URL = this.$services.endpoints.APP_SYNC_REPORTS + '?trigger_by=admin'
				this.$store.dispatch('house/customPostRequest', {URL: URL, data: payload}).then(_ => {
					this.loading = false
					this.$services.helpers.notification(this.$t('entryCreated'), 'success', this)
					Object.keys(this.form).map(item => {
						this.form[item] = ''
					})
					window.Bus.$emit('reload-sync-event')
				}).catch(err => {
					this.loading = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									if (this.GET_USER_COMPANY_ID) {
										this.getSubscriptionList(this.GET_USER_COMPANY_ID)
									} else {
										this.getSubscriptionList()
									}
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						} else {
							if (err.response.data.hasOwnProperty('message') && err.response.data.hasOwnProperty('message_swe')) {
								if (this.GET_LOCALE === 'se') {
									this.$services.helpers.notification(err.response.data.message_swe, 'error', this)
								} else {
									this.$services.helpers.notification(err.response.data.message, 'error', this)
								}
							} else {
								this.$services.helpers.notification(err.response.data.message, 'error', this)
							}
						}
					} else {
						this.$services.helpers.notification(this.$t('generalError'), 'error', this)
					}

				})
			} else {
				this.$services.helpers.notification(this.$t('appSelectError'), 'error', this)
			}
		},
		onChange: function () {
			let sub = this.subscriptionList.filter(item => item.id === parseInt(this.form.subscription))
			this.form.sync_type = ''
			if (sub.length > 0) {
				this.showAllOptions = false
				this.showOrderAppOnly = false
				this.showArticleAppOnly = false
				this.showPaymentOnly = false
				if (this.paymentApp.includes(parseInt(sub[0].app_type))) {
					this.showPaymentOnly = true
				}
				if (this.allApps.includes(parseInt(sub[0].app_type))) {
					this.showAllOptions = true
				}
				if (this.orderAppOnly.includes(parseInt(sub[0].app_type))) {
					this.showOrderAppOnly = true
				}
				if (this.articleAppOnly.includes(parseInt(sub[0].app_type))) {
					this.showArticleAppOnly = true
				}
			}

		},
		getSubscriptionList() {
			this.loadingSub = true
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + '?limit=50'
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(response => {
					let V3 = []
					ALLOWED_V3_SYNC_REPORT_APPS.map(item => {
						V3.push(parseInt(item['key']))
					})
					this.subscriptionList = response.data.data.results.filter(item => V3.includes(parseInt(item.app_type)) === true)
					this.loadingSub = false


				}).catch((err) => {
				this.loadingSub = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		remoteMethod(query) {
			if (query !== '') {
				this.loadingSub = false
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&limit=50`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(response => {
						let V3 = []
						ALLOWED_V3_SYNC_REPORT_APPS.map(item => {
							V3.push(parseInt(item['key']))
						})
						this.subscriptionList = response.data.data.results.filter(item => V3.includes(parseInt(item.app_type)) === true)
						this.loadingSub = false


					}).catch((err) => {
					this.loadingSub = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		}
	},
	mounted() {
		this.getSubscriptionList()
	}
}
</script>

<style scoped>

</style>
