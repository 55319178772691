import { saveIsAssistanceAcc } from "../../../../services/services-jwt"

export default {
    UPDATE_ASSISTANCE_COMPANY (state, payload) {
        state.accountantAssistanceCompany = JSON.parse(JSON.stringify(payload))
        state.copyAssistanceCompany = JSON.parse(JSON.stringify(payload))
    },
    FILTER_DEFAULT (state, _) {
        state.accountantAssistanceCompany.results = [...state.copyAssistanceCompany.results]
    },
    FILTER_COMPANY (state, payload) {
        const filter = state.copyAssistanceCompany.results.filter(item => {
            return item.company.name.toLowerCase().includes(payload.toLowerCase())
        })
        state.accountantAssistanceCompany.results = filter
    },
    UPDATE_ASSIST_ACCOUNTANT (state, payload) {
        state.isAssistanceAccountant = payload
        saveIsAssistanceAcc(payload)
    },
    REMOVE_ASSITANCE_ACCOUNTANT (state, _) {
        state.isAssistanceAccountant = null
    }
}
