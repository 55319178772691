export default {
    USER_TOKEN (state) {
        return state.token
    },
    USER_REFRESH (state) {
        return state.refreshToken
    },
    GET_SYSTEM_NOTIFY (state) {
        return state.systemNotify
    },
	GET_LOGIN_CREDENTIAL (state) {
		return state.loginCredential
	}
}
