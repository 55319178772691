export default {
	GET_USER_UTIL(state) {
		return state.utility
	},
    GET_MASTER_ACCOUNTANT (state) {
        return state.masterAccountant
    },
	GET_USERS_COMPANY(state) {
		return state.usersCompany
	},
	GET_FIRM_COMPANY(state) {
		return state.firmCompany
	},
	GET_DASHBOARD_METRICS(state) {
		return state.dashboardMetrics
	},
	GET_ASSISTANCE_COMPANY(state) {
		return state.AccountantAssistanceCompany
	},
	GET_USER_SPECIFIC_COMPANY(state) {
		return state.userSpecificCompany
	},
	GET_FIRM_ACCOUNTANTS(state) {
		return state.firmAccountants
	},
}
