<template>
	<div class="px-4">
		<div class="row">
			<div class="col-md-12 rounded md:pr-4 p-3 mb-3">
				<form class="flex flex-col"
					  @submit.prevent="updateCustomAccount">
					<div class="row ">
						<div class="col-sm-12 optional-config-form mb-2">
							<label
								class="text-gray-600 font-semibold">{{ $t('accountType') }}
								<span class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle cursor-pointer"></i><span
									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange vilken typ av konto ni vill mappa (t.ex. intäktskonto eller utbetalningskonto)</span></span></label><br>
							<select v-model="accType"
									@change="onChangeAccountType"
									id="konto-type-id"
									class="form-select override-form-ca w-full"
									required>
								<option :value="Object.keys(type)[0]"
										v-for="(type, i) in accountType"
										:key="i">{{
										Object.values(type)[0]
									}}
								</option>
							</select>
						</div>
						<div class="col-sm-12 optional-config-form mb-3">
							<label for="Matchningstyp"
								   class="text-gray-600 font-semibold">Matchningstyp
								<span class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle"></i><span
									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här anger ni om ni vill att matchningen avgörs per land eller per valuta.</span></span></label><br>
							<select :value="matchTyp" id="match-type"
									@change="switchType"
									class="form-select override-form-ca w-full"
									required>
								<option :value="Object.keys(type)[0]"
										v-for="(type, i) in matchType"
										:key="i">{{
										Object.values(type)[0]
									}}
								</option>
							</select>
						</div>
						<div class="col-sm-12 mb-3">
							<label for="Landskod"
								   class="text-gray-600 font-semibold"
								   v-if="parseInt(matchTyp) === 1">{{ $t('country') }}
								<span class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle"></i><span
									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange landet ni vill mappa till kontot i fråga.</span></span></label>
							<label for="Valuta"
								   class="text-gray-600 font-semibold"
								   v-else>{{ $t('currency') }} <span
								class="ml-1 tooltip-settings"><i
								class="text-sm help-color fa fa-question-circle"></i><span
								class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Ange valutan ni vill mappa till kontot i fråga.</span></span></label><br>
							<div>
								<div v-show="showCountry"
									 class="optional-config-form">
									<select v-model="matchCode"
											id="country-select-id"

											class="form-select select-field override-form-ca w-full">
										<option :value="country[0]"
												v-for="(country, i) in countries"
												:key="i">{{ country[1] }}
										</option>
									</select>
								</div>
								<div v-show="!showCountry"
									 class="optional-config-form">
									<select v-model="matchCode"
											id="currency-select-id"
											class="form-select select-field override-form-ca w-full">
										<option :value="currency"
												v-for="(currency, i) in allCurrencies"
												:key="i">{{ currency }}
										</option>
									</select>
								</div>
							</div>


						</div>
						<div class="col-sm-12 optional-config-form mb-3">
							<label for="kontotyp"
								   class="text-gray-600 font-semibold">Konto#
								<span class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle"></i><span
									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här anger ni kontonumret på det konto ni försöker lägga upp</span></span></label><br>
							<div
								class="flex form-group optional-config-form">
								<select v-model="acctNumber" v-if="[0,1].includes(parseInt($route.query.acc)) === true"
										class="form-select override-form-ca w-full"
										id="account-number-id">
									<option selected disabled></option>
									<option :value="account.Number"
											v-for="account in accountNumbers"
											:key="uid(account)">
										{{
											account.Number.length > 6 ? null : '(' + account.Number
												+ ') '
										}}
										{{ account.Description }}
									</option>
								</select>
								<input type="text" v-model="acctNumber"
									   class="border-pink-500 rounded border px-2 form-input w-full h-8 text-xs"
									   style="height: 32px !important;" v-else/>
							</div>

						</div>
						<div class="col-sm-12 mb-2"
							 v-if="hideReconciliationField">
							<label
								class="text-gray-600 font-semibold">{{
									$t('ruleType')
								}} <span class="ml-1 tooltip-settings"><i
									class="text-sm help-color fa fa-question-circle"></i><span
									class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Här har ni möjlighet att ange om detta konto ska användas för något specifikt, så som frakt eller företagskunder</span></span></label><br>
							<select v-model="ruleType"
									class="form-select override-form-ca w-full">
								<option value="company">{{
										$t('forCompany')
									}}
								</option>
								<option value="shipping">
									{{ $t('forShipping') }}
								</option>
								<option value="feelines">
									{{ $t('forFeeLines') }}
								</option>
								<option value="gift_card">
									{{ $t('forGiftCard') }}
								</option>
								<option value="discount">
									{{ $t('forDiscount') }}
								</option>
							</select>
						</div>
						<div class="col-sm-12 optional-config-form mb-2"
							 v-if="parseInt(accType) === 1 || parseInt(accType) === 3">
							<label for="vat"
								   class="text-gray-600 font-semibold"> {{ $t('vat') }} <span
								class="ml-1 tooltip-settings"><i
								class="text-sm help-color fa fa-question-circle"></i><span
								class="w-60 tooltiptext text-xs shadow-sm border word-breaking">Vat</span></span></label><br>
							<select v-model="vatValue" id="vat-type-value-id"
									class="form-select override-form-ca w-full">
								<option :value="`${item.vat}-${item.code}`" v-for="(item, i) in vatRates"
										:key="i">{{ item.country + `: ${item.vat}%` }}
								</option>
							</select>
						</div>
						<div class="col-sm-1">
							<label class="mb-3 mt-1"></label><br>
							<button
								class="addingCustomAccountbtn hover:text-white text-base btn-custom py-2 px-6 rounded"
								:disabled='addingCustomAccount'>
                                                        <span v-if="addingCustomAccount">
                                                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
																 xmlns="http://www.w3.org/2000/svg" fill="none"
																 viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10"
																	stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor"
																  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                        </span>
								<span v-else>
                                                            Spara
                                                        </span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {cleanVatValues} from "../../../services/constants/vatConstant";
import {RECONCILIATION_APPS} from "../../../app/index";
import {mapGetters} from "vuex"

window.$ = window.jQuery = require('jquery')
export default {
	name: "editCustomAccount",
	props: {
		account: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			ruleType: '',
			vatValue: '',
			accType: '',
			accountType: [],
			vatRates: cleanVatValues(),
			matchType: [],
			matchTypeCopy: [],
			customAccounts: [],
			allCurrencies: [],
			accountNumbers: [],
			matchTyp: '',
			showCountry: '',
			showCostCenter: false,
			matchCode: '',
			countries: '',
			acctNumber: '',
			hideReconciliationField: true,
			addingCustomAccount: false,
			accountObj: {},
			forCompany: false,
			forShipping: false,
			forFeeLines: false,
			forGiftCard: false,
			forDiscount: false,
			ruleList: {
				for_company: "company",
				for_discount: "discount",
				for_fee_lines: "feelines",
				for_gift_cards: "gift_cards",
				for_recurring_transactions: "recurring_transactions",
				for_shipping: "shipping"
			}


		}
	},
	methods: {
		reconciliationAppField(type) {
			if (RECONCILIATION_APPS.includes(parseInt(type))) {
				this.hideReconciliationField = false
			} else {
				this.hideReconciliationField = true
			}
		},
		uid(e) {
			if (e.uid) return e.uid
			const key = Math.random()
				.toString(16)
				.slice(2)
			this.$set(e, 'uid', key)
			return e.uid
		},
		switchType(e) {
			this.matchTyp = parseInt(e.target.value)
			if (parseInt(e.target.value) === 2) {
				// this.matchCode = ''
				this.showAllCountries = true
				this.showCountry = false

			} else {
				this.showAllCountries = true
				// this.matchCode = ''
				this.showCountry = true
			}
		},
		updateCustomAccount: function () {
			if (this.accType === '' || this.matchTyp === '' || this.matchCode === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.acctNumber === '') {
				this.$services.helpers.notification(this.$t('accountNumberRequired'), 'error', this)
			} else {
				this.addingCustomAccount = true
				const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.accountObj.subscription}/${this.accountObj.id}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.UPDATE_CUSTOM_ACCOUNT + `${this.accountObj.subscription}/${this.accountObj.id}/`
				const payload = {
					subscription: this.accountObj.subscription,
					acct_type: this.accType,
					match_type: this.matchTyp,
					match_code: this.matchCode,
					// vat_rate : this.vatCode,
					acct_no: this.acctNumber
				}

				if (parseInt(this.vatValue)) {
					// calculate vatcentage
					let val = this.vatValue.split('-')[0]
					payload.vat_rate = parseFloat(val / 100).toFixed(2)
				}

				if (!RECONCILIATION_APPS.includes(parseInt(this.appType))) {
					if (this.ruleType !== null) {
						this.forCompany = this.ruleType === 'company'
						this.forShipping = this.ruleType === 'shipping'
						this.forFeeLines = this.ruleType === 'feelines'
						this.forDiscount = this.ruleType === 'discount'
						this.forGiftCard = this.ruleType === 'gift_card'
					}

					payload.for_company = this.forCompany
					payload.for_shipping = this.forShipping
					payload.for_fee_lines = this.forFeeLines
					payload.for_gift_cards = this.forGiftCard
					payload.for_discount = this.forDiscount
				}
				this.$store.dispatch('customer/updateCustomAccount', {data: payload, URL})
					.then(res => {
						this.addingCustomAccount = false
						// console.log(res)
						if (res.status === 200) {
							this.$services.helpers.notification(this.$t('customAccountUpdated'), 'success', this)
							window.Bus.$emit('reload-custom-account', true)
						}
					}).catch((err) => {
					this.addingCustomAccount = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									// console.log('here')
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data, 'error', this)
					}
				})
			}
		},
		onChangeCurrencyCountry(val) {
			let onlySekCountryCode = [10, 13]
			if (onlySekCountryCode.includes(parseInt(this.accType))) {
				this.matchCode = 'SEK'
			} else {
				this.matchCode = val
			}

		},
		onSetData: function ($event) {
			let self = this
			this.accType = this.accountObj.acct_type
			this.matchCode = this.accountObj.match_code
			this.matchTyp = this.accountObj.match_type
			if (this.accountObj.vat_rate) {
				let vat = this.accountObj.vat_rate * 100
				this.vatValue = `${vat}-${this.matchCode}`
			}

			this.acctNumber = self.accountObj.acct_no
			if (this.account.for_company === true) {
				this.ruleType = 'company'
			} else if (this.account.for_gift_cards === true) {
				this.ruleType = 'gift_cards'
			} else if (this.account.for_fee_lines === true) {
				this.ruleType = 'feelines'
			} else if (this.account.for_shipping === true) {
				this.ruleType = 'shipping'
			} else if (this.account.for_recurring_transactions === true) {
				this.ruleType = 'recurring_transactions'
			} else if (this.account.for_discount === true) {
				this.ruleType = 'discount'
			}
			this.configureVatType()
		},
		configureVatType: function () {
			let self = this
			if (parseInt(this.accType) === 1 || parseInt(this.accType) === 3) {
				setTimeout(function () {
					window.$('#vat-type-value-id').select2({
						dropdownCssClass: "increasedzindexclass",
					})
					window.$('#vat-type-value-id').on("select2:select", function (e) {
						self.vatValue = e.target.value
					});
				})
			}
		},
		onChangeAccountType(e) {
			let onlySekCountryCode = [14, 13, 11]
			if ([3].includes(parseInt(e.target.value))) {
				this.matchType = this.matchTypeCopy
			} else {
				// show only  currency (valuta)
				let code = []
				for (var j = 0; j < this.matchTypeCopy.length; j++) {
					let mtype = this.matchTypeCopy[j]
					if (parseInt(Object.keys(mtype)[0]) === 2) {
						code.push(mtype)
					}
				}
				this.showCountry = false
				this.matchType = code
			}
			window.$('#currency-select-id').select2('destroy')
			if (onlySekCountryCode.includes(parseInt(e.target.value))) {
				this.allCurrencies = ['SEK']
				this.matchCode = 'SEK'
			} else {
				this.allCurrencies = this.$services.helpers.allCurrencies()
			}

			if ([10, 11, 12, 2, 14, 13].includes(parseInt(e.target.value))) {
				this.matchTyp = 2
				this.showCountry = false
			} else {
				this.matchTyp = 1
				this.matchCode = ''
				this.showCountry = true
			}
			setTimeout(function () {
				window.$('#currency-select-id').select2({
					dropdownCssClass: "increasedzindexclass",
				})
			}, 300)
			this.configureVatType()
		},
		onChangeAccountNumber(val) {
			this.acctNumber = val
		},
		onChangeVat(val) {
			this.vatValue = val
		},
		getAccountType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}` this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.account.subscription}/`
			// this.$services.axios.get(this.$services.endpoints.GET_ACCOUNT_TYPES)
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.account.subscription}/${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_ACCOUNT_TYPES + `${this.account.subscription}/`
			this.$store.dispatch('customer/getAccountType', {URL: URL})
				.then(res => {
					if (res.status === 200) {
						this.accountType = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAccountType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getMatchType() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_MATCH_TYPE)
			this.$store.dispatch('customer/getMatchType', {URL: this.$services.endpoints.GET_MATCH_TYPE})
				.then(res => {
					// console.log('Match TYPE::::::', res)
					if (res.status === 200) {
						this.matchType = res.data.data
						this.matchTypeCopy = res.data.data
					}
				}).catch((err) => {
				// this.showCustomAccount = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getMatchType()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},

	},
	mounted() {
		// this.customAccountData(this.account.account.subscription)
		let self = this
		this.accountObj = this.account.account
		this.onSetData()
		this.reconciliationAppField(this.$route.params.type)
		this.accountNumbers = this.account.accountNumbers
		this.countries = this.account.countries
		this.allCountries = this.account.countries
		this.allCurrencies = this.account.currencies
		this.accountType = this.account.accountType
		this.matchType = this.account.matchType
		this.matchTypeCopy = this.account.matchType
		for (const [k, v] of Object.entries(this.accountObj)) {
			if (Object.keys(this.ruleList).includes(k) === true) {
				if (v === true) {
					this.ruleType = this.ruleList[k]
					break
				}
			}
		}
		window.$('#currency-select-id, #country-select-id, #account-number-id,#vat-type-value-id').select2({
			dropdownCssClass: "increasedzindexclass",
		})
		window.$('#country-select-id').on("select2:select", function (e) {
			self.matchCode = e.target.value
		});
		window.$('#currency-select-id').on("select2:select", function (e) {
			self.matchCode = e.target.value
		});
		window.$('#account-number-id').on("select2:select", function (e) {
			self.acctNumber = e.target.value
		});

		setTimeout(function () {

			window.$('#account-number-id').val(self.accountObj.acct_no).trigger('change');
			if (self.matchTyp === 1) {
				self.showCountry = true
				// window.$('#country-select-id').select2('val', self.accountObj.match_code, true);
				window.$('#country-select-id').val(self.accountObj.match_code);
				window.$('#country-select-id').trigger('change.select2');
			} else {
				self.showCountry = false
				window.$('#currency-select-id').val(self.accountObj.match_code);
				window.$('#currency-select-id').trigger('change.select2');
				// window.$('#currency-select-id').select2('val', self.accountObj.match_code, true);
			}
		}, 300)

	},
	computed: {
		...mapGetters({
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.align-box-center {
	width: 80%;
	margin: 0 auto;
}

.font-read {
	font-size: 1.6rem;
	background: #BB3333;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-configure {
	font-size: 1.6rem;
	background: #1A9DD6;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.font-activate {
	font-size: 1.6rem;
	background: #2FC058;
	color: #fff;
	font-weight: 800;
	border-radius: 5px;
	padding: 0.2rem;
}

.app-theme-gray .main-card > .card-body > .card-title::before {
	background: unset !important;
}

.btn-slider-b {
	color: #fff;
	background-color: #13c2c2;
	border-color: #13c2c2;
}

.tabs-animated .nav-link::before {
	background: #13c2c2 !important;
}

.override-form-ca {
	padding-bottom: 0.2rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
}

.override-form-numbers {
	padding-bottom: 0rem !important;
	padding-top: 0.2rem !important;
	font-size: unset !important;
	height: calc(1.75rem + 2px) !important;
}

// .form-control:focus{
//     // color: #495057;
//     // background-color: #fff;
//     // border-color: #e2e8f0 !important;
//     // outline: 0;
//     // box-shadow: unset !important;
// }
.input-group-append {
	height: calc(1.75rem + 2px);
}

.border-b-theme {
	border-bottom: 5px solid $form-border;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	// border: 1px solid $form-border !important;
}

.theme-color-tab {
	color: $form-border;
}

.theme-color-sec {
	color: $theme-primary-color !important;
	font-weight: 900;
}

.deep-b-theme {
	border-bottom: 5px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	// color: $theme-secondary-color !important;
}

.btn-full-theme {
	background: $theme-secondary-color !important;
}

.pad-overi {
	padding: .175rem .75rem !important;
}

.form-select, .form-input {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

input[type="file"] {
	display: none;
}

.custom-file-upload {
	// border: 1px solid #ccc;
	color: white;
	border-radius: 5px;
	background-color: $theme-primary-color;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

table th, .table td {
	padding: 0.2rem !important;
}

.disable-btn {
	background-color: #800080 !important;
	color: #ffff !important;
}

.w-f-100 {
	width: 100% !important;
}

.test-btn {
	background-color: #EE4D81;
	// border-color: #EE4D81;
}

.activate-btn {
	background-color: #0DB23C;
}

.deactivate-btn {
	background-color: #583EF9;
}

.table-responsive::-webkit-scrollbar {
	display: none !important; /* width of the entire scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
	background: $form-border; /* color of the tracking area */
}

.table th, .table td {
	border-top: unset !important;
}

.cancel-btn, .cancel-btn:hover {
	background: #10a4c2 !important;
	color: #ffffff
}

.c-btn {
	font-size: 15px !important;
}

.app-theme-gray .main-card {
	box-shadow: 0 0 0 0 transparent !important;
}

.pl-1, .px-1 {
	padding-left: 0.25rem !important;
}

.bg-white {
	background-color: #fff !important;
}

.color-text {
	color: #61696d !important;
	font-weight: 300 !important;
}
</style>
