<template>
	<div class="container">
		<form @submit.prevent="addNotification">
			<div class="row mt-4">
				<div class="col-md-12 mb-2">
					<div class="form-group">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t('title')
							}}</label>

						<input cols="30" id="title" data-cy='title' rows="3" v-model="form.title"
							   class='form-control font-light text-xs'
							   :placeholder="$t('title')"/>
					</div>
				</div>
				<div class="col-md-12 mb-2">
					<div class="form-group">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t('text')
							}}</label>
						<textarea cols="30" id="text" data-cy='question' rows="3" v-model="form.text"
								  class='form-control font-light text-xs'
								  :placeholder="$t('title')"></textarea>
					</div>
				</div>
				<div class="col-md-12 mb-2">
					<div class="form-group">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t('accountingType')
							}}</label>
						<el-select v-model="form.accounting" :placeholder="$t('search')" filterable class="w-full"
						>
							<el-option
								v-for="(item,key) in accountingType"
								:key="key"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="col-md-12 mb-2">
					<div class="form-group">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t('scheduleDate')
							}}</label>
						<el-date-picker
							class="w-full app-input"
							v-model="form.schedule_date"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy">
						</el-date-picker>
					</div>
				</div>
				<div class="col-md-12 mb-2">
					<div class="form-group">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t('appType')
							}}</label>
						<el-select v-model="form.app_type" :placeholder="$t('search')" filterable class="w-full"
						>
							<el-option
								v-for="(item,key) in subscriptionTypes"
								:key="key"
								:label="item[1]"
								:value="item[0]">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="col-md-12 mb-2">
					<button type="submit" data-cy='btn-add-faq' :disabled='creatingNotification'
							class="btn hover:text-white text-base btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creatingNotification" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </span>
						<span v-else class="text-xs">{{ $t('create') }}</span>
					</button>
				</div>
			</div>
		</form>
	</div>


</template>

<script>
import Vue from "vue"
import {DatePicker} from 'element-ui'

Vue.use(DatePicker)
export default {
	name: "createNotification",
	data: function () {
		return {
			form: {
				title: '',
				text: '',
				accounting: '',
				schedule_date: '',
				app_type: '',
			},
			accountingType: [],
			subscriptionTypes: [],
			creatingNotification: false
		}
	},
	methods: {
		addNotification: function () {
			let payload = {}
			for (const [key, value] of Object.entries(this.form)) {
				if (value === '') {
					this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
					return false
				} else {
					payload[key] = value
				}

			}
			this.creatingNotification = true
			let URL = this.$services.endpoints.NOTIFICATIONS
			this.$store.dispatch('house/customPostRequest', {URL: URL, data: payload}).then(res => {
				this.creatingNotification = false
				Object.keys(this.form).map(item => {
					this.form[item] = ''
				})
				window.Bus.$emit('reload-notification')
				this.$services.helpers.notification(this.$t('notificationSuccess'), 'success', this)
			}).catch(err => {
				this.$services.helpers.notification(err.response.data.message, 'error', this)
				this.creatingNotification = false
			})

		},
		getAccountingType: function () {
			let URL = ''
			this.$store.dispatch('house/getAccountingList').then(resp => {
				this.accountingType = resp.data.data
			})
		},
		getSubscriptionType: function () {
			this.$store.dispatch('house/getSubscriptionTypes').then(resp => {
				this.subscriptionTypes = resp.data.data
			})
		},
	},
	mounted() {
		this.getAccountingType()
		this.getSubscriptionType()
	}
}
</script>

<style scoped>

</style>
