import HTTP from './../../../../services/vue-axios/axios'
import endpoints from './../../../../services/endpoint'
import TYPES from './../types'

export default {
	async getAccountantMetrics({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					// console.log('metrics:', res.data.data)
					if (res.data.success === true) {
						commit(TYPES.UPDATE_DASHBOARD_ACCOUNTANT_METRICS, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUsersCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			const URL = payload.URL ? payload.URL : endpoints.GET_USER_BY_MANAGER
			HTTP.get(URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_USERS_COMPANY, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getConnectCompany({commit}, payload) {
		// return new Promise((resolve, reject) => {
		await HTTP.get(payload.URL)
			.then(res => {
				// console.log('from action:', res.data)
				if (res.status === 200) {
					commit(TYPES.UPDATE_USERS_COMPANY, res.data)
					// resolve(res)
				}
			}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						commit('auth/CLEAR_AUTH_USER', null)
					}
				}
			})
		// })
	},
	async getCustomConnectCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
		 HTTP.get(payload.URL)
			.then(res => {
				resolve(res)
			}).catch((err) => {
				reject(err)
			})
		})
	},
	async getAssistanceConnectCompany({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_ASSISTANCE_COMPANY, res.data.data)
						resolve(res)
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						commit('auth/CLEAR_AUTH_USER', null)
					}
				}
			})
		})
	},
	async disconnectCompany({_}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(payload.URL)
				.then(res => {
					resolve(res)
					// if (res.status === 200) {
					// }
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async inviteUser({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(endpoints.ACCOUNTANT_INVITE_COM, payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async uploadPhoto({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.formData)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async checkCompanyNote({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async createCompanyNote({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.data)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async updateUserNote({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(payload.URL, payload.data)
				.then(res => {
					if (res.data.success) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserFirm({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.data.success) {

						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getUserFirms({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					commit(TYPES.UPDATE_USER_SPECIFIC_COMPANY, res.data.data)
					resolve(res)
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async getFirmAccountants({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						res.data['results'] = res.data.results.filter(item => item.dashboard_type === 1 && item.company === null)
						// console.log(res.data)
						commit(TYPES.UPDATE_FIRM_ACCOUNTANT, res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async searchFirmAccountants({commit}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(payload.URL)
				.then(res => {
					if (res.status === 200) {
						res.data['results'] = res.data.results.filter(item => item.dashboard_type === 1 && item.company === null)
						// console.log(res.data)
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	},
	async postRequest({}, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(payload.URL, payload.data)
				.then(res => {
					if (res.status === 200) {
						resolve(res)
					}
				}).catch((err) => {
				reject(err)
			})
		})
	}
}
