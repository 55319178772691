<template>
	<div class="container">

		<form v-on:submit.prevent="onSubmit">
			<div class="row mt-4">
				<div class="col-md-4 mb-2" v-for="(form,i) in formList">
					<div class="form-group" v-if="form.type === 'text'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<input cols="30" id="title" data-cy='title' rows="3" type="text"
							   class='form-control font-light text-xs'
							   :placeholder="$t(`${form.name}`)" v-model="form.value"/>
					</div>
					<div class="form-group" v-else-if="form.type === 'email'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<input cols="30" :id="`${form.name}`" data-cy='title' rows="3"
							   class='form-control font-light text-xs' type="email"
							   :placeholder="$t(`${form.name}`)" v-model="form.value"/>
					</div>
					<div class="form-group" v-else-if="form.type === 'select'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<el-select v-model="form.value" :placeholder="$t(`${form.name}`)" filterable
								   class="w-full"
								   size="large">
							<el-option
								v-for="(item,key) in form.valueList"
								:key="key"
								:label="`${item.name}`"
								:value="item.key">
							</el-option>
						</el-select>
					</div>
					<div class="form-group" v-else-if="form.type === 'date'">
						<label class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<el-date-picker
							class="w-full text-xs font-light app-input"
							v-model="form.value"
							type="date"
							size="mini"
							value-format="yyyy-MM-dd"
							format="yyyy/MM/dd"
							start-placeholder="Start datum">
						</el-date-picker>
					</div>
					<div class="form-group" v-else-if="form.type === 'datetime'">
						<label for="title" class="text-xs text-gray-800 font-medium">{{
								$t(`${form.name}`)
							}}</label>

						<el-date-picker
							class="w-full text-xs font-light app-input"
							v-model="form.value"
							type="datetime"
							size="mini"
							value-format="yyyy-MM-dd HH:mm:ss"
							format="yyyy/MM/dd HH:mm:ss"
							start-placeholder="Start datum">
						</el-date-picker>
					</div>

				</div>
				<div class="col-md-12 mb-2">
					<button type="submit" data-cy='btn-add-faq' :disabled='creating'
							class="btn hover:text-white text-base btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creating" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </span>
						<span v-else class="text-xs">{{ $t('update') }}</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import moment from "moment";
import {checkIfExist, getDataLocalStorage, saveDataLocalStorage} from "../../services/storage-window";

export default {
	name: "companyProfile",
	props: {
		company: {
			type: Object
		}
	},
	data: function () {
		return {
			creating: false,
			formList: [
				{
					type: 'text',
					name: 'name',
					value: '',
					required: true
				},
				{
					type: 'text',
					name: 'company_id',
					value: '',
					required: true
				},
				{
					type: 'email',
					name: 'email',
					value: '',
					required: false
				},
				{
					type: 'email',
					name: 'billing_email',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'phone_mobile',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'phone_office',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'zip_code',
					value: '',
					required: false
				},
				{
					type: 'text',
					name: 'city',
					value: '',
					required: false
				},
				{
					type: 'select',
					name: 'country',
					value: '',
					required: false,
					valueList: []
				},
				{
					type: 'textarea',
					name: 'address_co',
					required: false,
					value: ''
				},
				{
					type: 'textarea',
					name: 'address_line_one',
					required: false,
					value: ''
				},
				{
					type: 'textarea',
					name: 'address_line_two',
					required: false,
					value: ''
				},
			]
		}
	},
	methods: {
		onSubmit: function () {
			let payload = {}
			this.creating = true
			for (let i = 0; i < this.formList.length; i++) {
				let item = this.formList[i]
				if (item.required === true) {
					if (item.value === '' || item.value === null || item.value === undefined) {
						this.creating = false
						this.$services.helpers.notification(this.$t(`${item.name.replace('_', ' ')} ${this.$t('required')}`))
						return false
					} else {
						payload[item.name] = item.value
					}
				} else {
					if (item.value !== '' && item.value !== null && item.value !== undefined) {
						if (item.type === 'date') {
							payload[item.name] = moment(item.value).format('YYYY-MM-DD')
						} else if (item.type === 'datetime') {
							payload[item.name] = moment(item.value).format('YYYY-MM-DD')
						} else {
							payload[item.name] = item.value
						}

					}
				}
			}
			if (this.company.id === null || this.company.id === '' || this.company.id === undefined) {
				this.$services.helpers.notification(this.$t('userNotaCompany'), 'error', this)
				return false
			}
			const URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `/update_company/${this.company.id}/`
			this.$store.dispatch('house/customPutRequest', {URL: URL, data: payload}).then(resp => {
				this.creating = false
				this.$services.helpers.notification(this.$t('success'), 'success', this)
			}).catch(err => {
				this.creating = false
				if (err.response.data.hasOwnProperty('errors')) {
					this.$services.helpers.notification(err.response.data.errors, 'error', this)
				} else {
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				}

			})
		},
		getCountry() {
			let formIndex = this.formList.findIndex(item => item.name === 'country')
			this.$store.dispatch('house/customGetRequest', {URL: this.$services.endpoints.GET_COUNTRIES})
				.then(res => {
					if (formIndex > -1) {
						this.formList[formIndex].valueList = []
						res.data.data.map(item => {
							if (item) {
								this.formList[formIndex].valueList.push({key: item[0], name: item[1]})
							}
						})
						// this.formList[formIndex].valueList = res.data.data
					}
				}).catch((err) => {
			})
		},
	},
	mounted() {

		this.formList.map(item => {
			if (item.name in this.company) {
				item.value = this.company[item.name]
			}
		})

		this.getCountry()

	}
}
</script>

<style scoped>

</style>
