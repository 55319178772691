import {saveIsHouse} from './../../../../services/services-jwt'

export default {
	UPDATE_IN_HOUSE(state, payload) {
		if (payload.user.is_staff) {
			state.regularHouse = payload.user.is_staff.toString()
			saveIsHouse(payload)
		}
	},
	UPDATE_HOUSE_METRICS(state, payload) {
		state.houseMetrics = payload
	},
	UPDATE_HOUSE_GRAPH_METRICS(state, payload) {
		state.houseGraphMetrics = payload
	},
	UPDATE_ALL_FIRM(state, payload) {
		state.allFirms = JSON.parse(JSON.stringify(payload))
		state.copyAllFirms = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_SEARCH_FIRM(state, payload) {
		state.allFirms = JSON.parse(JSON.stringify(payload))
	},
	FILTER_DEFAULT_FIRM(state, _) {
		state.allFirms = state.copyAllFirms
	},
	UPDATE_ALL_COMPANY(state, payload) {
		state.allCompanies = JSON.parse(JSON.stringify(payload))
		state.copyAllCompanies = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_SEARCH_COMPANY(state, payload) {
		state.allCompanies = JSON.parse(JSON.stringify(payload))
	},
	FILTER_DEFAULT_COMPANY(state, _) {
		state.allCompanies = state.copyAllCompanies
	},
	UPDATE_CONNECTED_COM(state, payload) {
		state.connectedCompany = JSON.parse(JSON.stringify(payload))
		state.copyConnectedCompany = JSON.parse(JSON.stringify(payload))
	},
	FILTER_COMPANIES_ALL(state, _) {
		state.connectedCompany = state.copyConnectedCompany
	},
	FILTER_CON_COMPANIES(state, payload) {
		const filter = state.copyConnectedCompany.results.filter(item => {
			return item.company.name.toLowerCase().includes(payload.toLowerCase())
		})
		state.connectedCompany.results = filter
	},
	UPDATE_ACCOUNTANT_COMPANY(state, payload) {
		state.accountantCompany = payload
	},
	UPDATE_FIRM(state, payload) {
		state.firm = payload
	},
	UPDATE_FIRM_ACCOUNTANT(state, payload) {
		state.allAccountants = JSON.parse(JSON.stringify(payload)) // Using JSON.parse in other do away with variable referencing
		state.copyAllAccountant = JSON.parse(JSON.stringify(payload))
	},
	ASCENDING_ORDER_NAME(state, _) {
		if (state.allAccountants) {
			const sortName = state.copyAllAccountant.results.sort((a, b) => {
				if (a.first_name.toUpperCase() < b.first_name.toUpperCase()) {
					return -1
				}
				if (a.first_name.toUpperCase() > b.first_name.toUpperCase()) {
					return 1
				}
			})
			state.allAccountants.results = sortName
		}
	},
	DESCENDING_ORDER_NAME(state, _) {
		if (state.allAccountants) {
			const sortName = state.copyAllAccountant.results.sort((a, b) => {
				if (a.first_name.toUpperCase() > b.first_name.toUpperCase()) {
					return -1
				}
				if (a.first_name.toUpperCase() < b.first_name.toUpperCase()) {
					return 1
				}
			})
			state.allAccountants.results = sortName
		}
	},
	SORT_DATE_ASC(state, _) {
		if (state.allAccountants) {
			const filterD = state.copyAllAccountant.results.sort((a, b) => {
				return new Date(b.date_joined) - new Date(a.date_joined)
			})
			state.allAccountants.results = filterD
		}
	},
	SORT_DATE_DESC(state, _) {
		if (state.allAccountants) {
			const filterD = state.copyAllAccountant.results.sort((a, b) => {
				return new Date(a.date_joined) - new Date(b.date_joined)
			})
			state.allAccountants.results = filterD
		}
	},
	FILTER_ACCOUNTANTS(state, payload) {
		const filter = state.copyAllAccountant.results.filter(item => {
			return item.first_name.toLowerCase().includes(payload.toLowerCase())
		})
		state.allAccountants.results = filter
	},
	FILTER_ACCOUNTANT_ALL(state, _) {
		state.allAccountants = state.copyAllAccountant
	},
	UPDATE_FAQ_DATA(state, payload) {
		// let results = payload.filter(item => item.hidden === false && item.visible_for_customers_only === false)
		state.faqData = payload
		state.copyFaqData = payload
	},
	REMOVE_USER_DATA(state, payload) {
		state.userFirmID = payload
	},
	UPDATE_FAQ_CATEGORY(state, payload) {
		state.faqCategory = payload
		state.copyFaqCategory = payload
		if (state.faqData.length > 0) {
			state.faqCategory.forEach(element => {
				const objct = state.faqData.filter(item => item.category === element.id)
				element.subLink = objct
			})
		}
	},
	FILTER_CATEGORY(state, payload) {
		// let filterFaq = []
		// const filterCategory = state.copyFaqCategory.filter(item =>
		//     item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		// )
		const filterFaq = state.copyFaqData.filter(item =>
			item.question.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)

		if (filterFaq.length < 1) {
			const filterCategory = state.copyFaqCategory.filter(item =>
				item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
			)
			// console.log('maian:', filterCategory.length)
			if (filterCategory.length > 0) {
				state.faqCategory = filterCategory
				const filterq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
				// console.log('fil;', filterq)
				state.faqData = filterq
				// console.log('state data:', state.faqData)
			} else {
				state.faqCategory = state.copyFaqCategory
			}
		} else {
			state.faqCategory = state.copyFaqCategory
		}
		// if (filterCategory.length > 0) {
		//     filterFaq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
		// }
		// state.faqCategory = filterCategory
		// state.faqData = filterFaq
		state.faqData = filterFaq
	},
	SET_FAQ_LIST(state, payload) {
		const filterList = state.copyFaqData.filter(item => item.id === payload.id)
		state.faqData = filterList
	},
	CATEGORY_FAQ_ALL(state, _) {
		state.faqCategory = state.copyFaqCategory
		state.faqData = state.copyFaqData
	},
	SUBSCRIPTION_ALL(state, payload) {
		state.allSubscription = payload
	},
}
