const AMDJANGO_TOKEN = 'amdjango'
const AMDJANGO_REFRESH_TOKEN = 'amre'
const IS_ACCOUNTANT = 'util'
const IS_MASTER_ACCOUNTANT = '__mster__'
const IS_ASSISTANCE_ACCOUNTANT = '__assist__'
const IS_COMPANY = '__com__'
const IS_COLLEAGUE = '__coll__'

const IS_IN_HOUSE_USER = 'reghouse'

export const saveToken = token => {
	window.localStorage.setItem(AMDJANGO_TOKEN, token.access)
}

export const saveRefreshToken = token => {
	window.localStorage.setItem(AMDJANGO_REFRESH_TOKEN, token.refresh)
}

export const saveIsAccountant = data => {
	window.localStorage.setItem(IS_ACCOUNTANT, data.user.is_accountant)
}
export const saveIsCompany = data => {
	window.localStorage.setItem(IS_ACCOUNTANT, data)
}
export const saveIsMasterAccountant = data => {
	window.localStorage.setItem(IS_MASTER_ACCOUNTANT, data)
}
export const saveIsColleague = data => {
	window.localStorage.setItem(IS_COLLEAGUE, data)
}
export const saveIsAssistingAccountant = data => {
	window.localStorage.setItem(IS_ASSISTANCE_ACCOUNTANT, data)
}



export const saveIsAssistanceAcc = data => {
    window.localStorage.setItem(IS_ASSISTANCE_ACCOUNTANT, data)
}


export const getIsAccountant = () => {
	return window.localStorage.getItem(IS_ACCOUNTANT)
}
export const getIsAssistingAccountant = () => {
	return window.localStorage.getItem(IS_ASSISTANCE_ACCOUNTANT)
}
export const getIsMasterAccountant = () => {
	return window.localStorage.getItem(IS_MASTER_ACCOUNTANT)
}

export const getIsColleague = () => {
	return window.localStorage.getItem(IS_COLLEAGUE)
}
export const getIsCompany = () => {
	return window.localStorage.getItem(IS_COMPANY)
}


export const getIsAssistanceAcc = () => {
    return window.localStorage.getItem(IS_ASSISTANCE_ACCOUNTANT)
}



export const saveIsHouse = data => {
	window.localStorage.setItem(IS_IN_HOUSE_USER, data.user.is_staff)
}

export const getIsHouse = () => {
	return window.localStorage.getItem(IS_IN_HOUSE_USER)
}

export const saveUser = (data) => {
    // saveToken(data)
    // saveRefreshToken(data)
    // if (data.user) {
    //     if (data.user.is_accountant) {
    //         saveIsAccountant(data)
    //     }
    //     if (data.user.is_staff) {
    //         saveIsHouse(data)
    //     }
    // }
	saveToken(data)
	saveRefreshToken(data)
	// if (data.user) {
	// 	if (data.user.is_accountant) {
	// 		saveIsAccountant(data)
	// 	}
	// 	if (data.user.is_staff) {
	// 		saveIsHouse(data)
	// 	}
	// 	if (data.user.dashboard_type === 5) {
	// 		saveIsAssistingAccountant(data)
	// 	}
	// 	if (data.user.dashboard_type === 0) {
	// 		saveIsCompany('true')
	// 	}
	// 	if (data.user.dashboard_type === 3) {
	// 		saveIsMasterAccountant('true')
	// 	}
	// 	if (data.user.dashboard_type === 4) {
	// 		saveIsColleague('true')
	// 	}
	// }
}

export const getToken = () => {
	return window.localStorage.getItem(AMDJANGO_TOKEN)
}

export const getRefreshToken = () => {
	return window.localStorage.getItem(AMDJANGO_REFRESH_TOKEN)
}

export const destroyToken = () => {
    window.localStorage.removeItem(AMDJANGO_TOKEN)
    window.localStorage.removeItem(AMDJANGO_REFRESH_TOKEN)
    window.localStorage.removeItem(IS_ACCOUNTANT)
    window.localStorage.removeItem(IS_IN_HOUSE_USER)
    window.localStorage.removeItem(IS_MASTER_ACCOUNTANT)
    window.localStorage.removeItem(IS_ASSISTANCE_ACCOUNTANT)
    window.localStorage.removeItem(IS_COLLEAGUE)
}

export default {
	saveToken,
	getToken,
	saveRefreshToken,
	saveUser,
	getIsAccountant,
	getIsHouse,
	getIsMasterAccountant,
	getIsColleague,
	getIsAssistingAccountant,
	getRefreshToken,
	destroyToken
,
    saveIsMasterAccountant,
    saveIsAssistanceAcc,
    saveIsColleague,
    getIsAssistanceAcc,
}
