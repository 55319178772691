<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<h2 data-cy='faq-modal-head'
					class="heading font-semibold text-gray-900 text-4xl font-medium">
					{{ $t('addFrequent') }}</h2>
				<!-- <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste harum minus tempore quam amet illo laborum. Id officiis quisquam iusto.</span> -->
			</div>
		</div>
		<form @submit.prevent="addFaqData">
			<div class="row mt-4">
				<div class="col-md-12 mb-2">
					<div class="form-group">
						<label class="text-base text-gray-800 font-medium">{{
								$t('question')
							}}</label>
						<!-- <input type="text"  class="form-control" required> -->
						<textarea cols="30" data-cy='question' rows="5" v-model="faqObj.question"
								  class='form-control font-light text-xs'
								  :placeholder="$t('genericQuestion')"></textarea>
						<!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
					</div>
				</div>
				<div class="col-md-12 mb-2">
					<div class="form-group">
						<label class="text-base text-gray-800 font-medium">{{
								$t('email')
							}}</label>
						<input type="email" class="form-control" v-model="faqObj.customer_email_request"
							   required>
						<!--								<textarea cols="30" data-cy='question' rows="5" v-model="faqObj.question"-->
						<!--										  class='form-control font-light text-xs'-->
						<!--										  :placeholder="$t('genericQuestion')"></textarea>-->
						<!-- <textarea name="" id="" cols="30" rows="10"></textarea> -->
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<button type="submit" data-cy='btn-add-faq' :disabled='creatingFaq'
								class="btn hover:text-white text-base btn-custom font-medium text-2xl btn-wide btn-lg">
                                <span v-if="creatingFaq" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {{ $t('creating') }}
                                </span>
							<span v-else>{{ $t('add') }}</span>
						</button>
					</div>
				</div>
			</div>
		</form>

		<div slot="footer" class="flex space-x-5">

		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	name: "faqCreateComponent",
	data: function () {
		return {
			faqObj: {
				question: '',
				visible_for_customers_only: true,
				hidden: true,
				customer_email_request: '',
			},
			creatingFaq: false
		}
	},
	methods: {
		addFaqData() {
			if (this.faqObj.question === '' || this.faqObj.customer_email_request === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else {
				this.creatingFaq = true
				this.faqObj.customer_name = this.GET_USER.first_name + ' ' + this.GET_USER.last_name
				this.$store.dispatch('house/addFaqData', this.faqObj)
					.then(_ => {
						this.creatingFaq = false
						this.$services.helpers.notification(this.$t('customerFaqAdded'), 'success', this)
						// setTimeout(() => {
						// 	this.$store.commit('utility/OPEN_MODAL_USER_FAQ_CREATE_MODAL')
						// }, 1000)
						this.faqObj.question = ''
						this.faqObj.customer_email_request = ''
						window.Bus.$emit('close-faq-modal')
					}).catch((err) => {
					this.creatingFaq = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				})
			}
		},
	},
	computed: {
		...mapGetters({
			GET_USER: 'customer/GET_USER',
		})
	}
}
</script>

<style scoped>

</style>
