import {getFirm, getXAmDjango, getTourStorage} from './../../../../services/storage-window'

export default {
	userCompanyID: getXAmDjango() || null,
	userFirmID: getFirm() || null,
	utility:'',
	appIntegration: [],
	getAccountingType: [],
	copyAppIntegration: [],
	allSubscription: {},
	subscription: {},
	appFilterState: [],
	faqData: [],
	companyBilling: {},
	setPaymentType: null,
	copyFaqData: [],
	faqCategory: [],
	copyFaqCategory: [],
	dashboardMetrics: {
		total_accounting_savings: 0,
		total_subscriptions: 0,
		total_transactions_book_kept: 0
	},
	notification: {
		all_notifications: [],
		unread_notifications_count: 0,
		all_notifications_count: 0,
	},
	sytemAppLogs: [],
	copySystemLogs: [],
	appType: [],
	AppLogs: [],
	copyAppLog: [],
	billingHistory: [],
	copyBillingHistory: [],
	syncReport: {
		results: [],
		count: 1,
	},
	syncReportCopy: [],
	ownerCompany: null,
	associateCompany: [],
	UserInfo: {
		is_staff: false
	},
	customerTour: getTourStorage() || {},
	colleagueCompany: {},
	isColleague: false,
	v3_apps: [42, 2, 40, 36, 45, 44, 46, 27, 47, 43],
	dedicatedServers: {
		'pro': 'https://pro.automatiseramera.se/api/v1/',
		'standard': 'https://integration.automatiseramera.se/api/v1/',
		'growth': 'https://pro.automatiseramera.se/api/v1/',
		'enterprise': 'https://enterprise.automatiseramera.se/api/v1/',
	}
}
