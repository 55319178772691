export const VAT_VALUES = [
    {
        code : "AT",
        country : "Austria",
        vat_name : "Umsatzsteuer",
        vat_abbr : "USt",
        standard_rate : 20.00,
        reduced_rate : 10.00,
        reduced_rate_alt : 13.00,
        super_reduced_rate : false,
        parking_rate : 12.00
    },
    {
        code : 'BE',
        country : "Belgium",
        vat_name : "Taxe sur la valeur ajoutée",
        vat_abbr : "TVA/BTW",
        standard_rate : 21.00,
        reduced_rate : 12.00,
        reduced_rate_alt : 6.00,
        super_reduced_rate : false,
        parking_rate : 12.00
    },
    {
        code : 'BG',
        country : "Bulgaria",
        vat_name : "Dana Dobavena Stoynost",
        vat_abbr : "DDS",
        standard_rate : 20.00,
        reduced_rate : 9.00,
        reduced_rate_alt : false,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'CY',
        country : "Cyprus",
        vat_name : "Foros prostithemenis axias",
        vat_abbr : "FPA",
        standard_rate : 19.00,
        reduced_rate : 9.00,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'CZ',
        country : "Czech Republic",
        vat_name : "Dani z pridane hotnoty",
        vat_abbr : "DPH",
        standard_rate : 21.00,
        reduced_rate : 15.00,
        reduced_rate_alt : 10.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'DK',
        country : "Denmark",
        vat_name : "Omsaetningsavgift",
        vat_abbr : "MOMS",
        standard_rate : 25.00,
        reduced_rate : false,
        reduced_rate_alt : false,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'DE',
        country : "Germany",
        vat_name : "Umsatzsteuer",
        vat_abbr : "USt",
        standard_rate : 19.00,
        reduced_rate : 7.00,
        reduced_rate_alt : false,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'EE',
        country : "Estonia",
        vat_name : "Käibemaks",
        vat_abbr : "KMKR",
        standard_rate : 20.00,
        reduced_rate : 9.00,
        reduced_rate_alt : false,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'GR',
        country : "Greece",
        vat_name : "Foros prostithemenis axias",
        vat_abbr : "FPA",
        standard_rate : 24.00,
        reduced_rate : 13.00,
        reduced_rate_alt : 6.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'ES',
        country : "Spain",
        vat_name : "Impuesto sobre el valor añadido",
        vat_abbr : "IVA",
        standard_rate : 21.00,
        reduced_rate : 10.00,
        reduced_rate_alt : false,
        super_reduced_rate : 4.00,
        parking_rate : false
    },
    {
        code : 'ES',
        country : "Spain",
        vat_name : "Impuesto sobre el valor añadido",
        vat_abbr : "IVA",
        standard_rate : 4.00,
        reduced_rate : 4.00,
        reduced_rate_alt : false,
        super_reduced_rate : 4.00,
        parking_rate : false
    },
    {
        code : 'FI',
        country : "Finland",
        vat_name : "Arvonlisävero",
        vat_abbr : "AVL",
        standard_rate : 24.00,
        reduced_rate : 14.00,
        reduced_rate_alt : 10.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'FR',
        country : "France",
        vat_name : "Taxe sur la valeur ajoutée",
        vat_abbr : "TVA",
        standard_rate : 20.00,
        reduced_rate : 10.00,
        reduced_rate_alt : 5.50,
        super_reduced_rate : 2.10,
        parking_rate : false
    },
    {
        code : 'HR',
        country : "Croatia",
        vat_name : "Porez na dodanu vrijednost",
        vat_abbr : "PDV",
        standard_rate : 25.00,
        reduced_rate : 13.00,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'IT',
        country : "Italy",
        vat_name : "Imposta sul valore aggiunto",
        vat_abbr : "IVA",
        standard_rate : 22.00,
        reduced_rate : 10.00,
        reduced_rate_alt : 4.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'LV',
        country : "Latvia",
        vat_name : "Pievienotas vertibas nodoklis",
        vat_abbr : "PVN",
        standard_rate : 21.00,
        reduced_rate : 12.00,
        reduced_rate_alt : false,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'LT',
        country : "Lithuania",
        vat_name : "Pridetines vertes mokestis",
        vat_abbr : "PVM",
        standard_rate : 21.00,
        reduced_rate : 9.00,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'LU',
        country : "Luxembourg",
        vat_name : "Taxe sur la valeur ajoutée",
        vat_abbr : "TVA",
        standard_rate : 17.00,
        reduced_rate : 14.00,
        reduced_rate_alt : 8.00,
        super_reduced_rate : 3.00,
        parking_rate : 12.00
    },
    {
        code : 'HU',
        country : "Hungary",
        vat_name : "Általános forgalmi adó",
        vat_abbr : "AFA",
        standard_rate : 27.00,
        reduced_rate : 18.00,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'IE',
        country : "Ireland",
        vat_name : "Value added tax",
        vat_abbr : "VAT",
        standard_rate : 23.00,
        reduced_rate : 13.50,
        reduced_rate_alt : 9.00,
        super_reduced_rate : 4.80,
        parking_rate : 13.50
    },
    {
        code : 'MT',
        country : "Malta",
        vat_name : "Value added tax",
        vat_abbr : "VAT",
        standard_rate : 18.00,
        reduced_rate : 7.00,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'NL',
        country : "Netherlands",
        vat_name : "Omzetbelasting",
        vat_abbr : "OB",
        standard_rate : 21.00,
        reduced_rate : 9.00,
        reduced_rate_alt : false,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'PL',
        country : "Poland",
        vat_name : "Podatek od towaròw i uslug",
        vat_abbr : "VAT",
        standard_rate : 23.00,
        reduced_rate : 8.00,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'PT',
        country : "Portugal",
        vat_name : "Imposto sobre o valor acrescentado",
        vat_abbr : "IVA",
        standard_rate : 23.00,
        reduced_rate : 13.00,
        reduced_rate_alt : 6.00,
        super_reduced_rate : false,
        parking_rate : 13.00
    },
    {
        code : 'RO',
        country : "Romania",
        vat_name : "Taxa pe valoarea adãugata",
        vat_abbr : "TVA",
        standard_rate : 19.00,
        reduced_rate : 9.00,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'SI',
        country : "Slovenia",
        vat_name : "Davek na dodano vred nost",
        vat_abbr : "DDV",
        standard_rate : 22.00,
        reduced_rate : 9.50,
        reduced_rate_alt : 5.00,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'SK',
        country : "Slovakia",
        vat_name : "Dan z pridanej hodnoty",
        vat_abbr : "DPH",
        standard_rate : 20.00,
        reduced_rate : 10.00,
        reduced_rate_alt : false,
        super_reduced_rate : false,
        parking_rate : false
    },
    {
        code : 'SE',
        country : "Sweden",
        vat_name : "Mervärdeskatt",
        vat_abbr : "ML",
        standard_rate : 25.00,
        reduced_rate : 12.00,
        reduced_rate_alt : 6.00,
        super_reduced_rate : false,
        parking_rate : false
    }
]

export const cleanVatValues = () => {
    const payload = []
    VAT_VALUES.forEach(element => {
        if (element.hasOwnProperty('standard_rate')) {
            const obj = {}
            obj.code = element.code
            obj.country = element.country
            obj.mode = 'standard_rate'
            obj.vat = parseFloat(element.standard_rate)
            payload.push(obj)
        }
        if (element.hasOwnProperty('reduced_rate')) {
            if (parseInt(element.reduced_rate)) {
                const obj = {}
                obj.code = element.code
                obj.country = element.country
                obj.mode = 'reduced_rate'
                obj.vat = parseFloat(element.reduced_rate)
                payload.push(obj)
            }
        }
        if (element.hasOwnProperty('reduced_rate_alt')) {
            if (parseInt(element.reduced_rate_alt)) {
                const obj = {}
                obj.code = element.code
                obj.country = element.country
                obj.mode = 'reduced_rate_alt'
                obj.vat = parseFloat(element.reduced_rate_alt)
                payload.push(obj)
            }
        }
    })
    return payload
}

export default {
    cleanVatValues
}
