import { getRefreshToken, getToken } from './../../../../services/services-jwt'
import { getSystemNotify } from './../../../../services/storage-window'

export default {
    token : getToken() || null,
    refreshToken : getRefreshToken() || null,
    systemNotify : getSystemNotify() || [],
	loginCredential: { username : '', password : ''},
	dedicatedServerCredential: {
		token: '',
		refreshToken: '',
		server: 'standard',
	}
}
